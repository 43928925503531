/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { render } from "react-dom";
import "@fontsource/roboto";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { LightTheme } from "./DesignSystem/LightTheme";
import axios from "axios";

const engine = new Styletron();

import "./simor-site.scss";

import { AuthProvider } from "./Utils/AuthContext";
import { SiteProps, SiteContext } from "./Utils/SiteProps";
import { SimorApp } from "./SimorApp";
import KeycloakService from "./Utils/KeycloakService";
import { AuthenticationConfig } from "Api";

const AppRoot = ({
  baseUrl,
  subUrl,
  useKeycloak,
}: {
  baseUrl: string;
  subUrl: string;
  useKeycloak: boolean;
}): JSX.Element => {
  // TODO: Refactor to use createContext instead of useState
  // Possibly in a different file.
  const [siteProps, _setSiteProps] = React.useState<SiteProps>(new SiteProps(baseUrl, subUrl, useKeycloak));

  axios.defaults.baseURL = siteProps.topUrl;

  return (
    <SiteContext.Provider value={siteProps}>
      <AuthProvider>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <div style={{ backgroundColor: LightTheme.colors.backgroundPrimary }}>
              <SimorApp />
            </div>
          </BaseProvider>
        </StyletronProvider>
      </AuthProvider>
    </SiteContext.Provider>
  );
};

const rootUrl = `${window.location.protocol}//${window.location.host}`;

const isTopLevelRootPath = !globalThis.webserverRootPath || globalThis.webserverRootPath === "/";

// This is the variable written by the server to support sub urls.
// Because of the way the spa constructs paths, we never want
// to have a trailing slash in the path since it will have
// two slashes where path elements are concatenated like
// http://simor.com//resources
const subUrl = isTopLevelRootPath ? "" : globalThis.webserverRootPath;

const renderApp = (useKeycloak: boolean): void =>
  render(<AppRoot baseUrl={rootUrl} subUrl={subUrl} useKeycloak={useKeycloak} />, document.getElementById("SimorRoot"));

const loadAuthConfig = (): void => {
  // TODO: The axios context hasn't been set yet so we need to append the subUrl to
  // the request if applicable. The string interpolation is equivalent to SiteProps.topUrl
  // property. We should remove the prefix to /authinfo once the axios context is set
  // somewhere other than the AppRoot method.
  axios
    .get(`${rootUrl}${subUrl}/authinfo`)
    .then((response) => {
      const authConfig: AuthenticationConfig = response?.data;
      if (authConfig.useKeycloak) {
        KeycloakService.initKeycloak(renderApp, authConfig.keycloakConfig);
      } else {
        renderApp(false);
      }
    })
    .catch((error) => console.error("Failed to load auth config", error));
};

// Login flow:
// loadAuthConfig() asks the API whether to use Keycloak.
//   - YES:
//     * KeycloakService is initialized using the settings from the API call.
//     * The init() function initializes KeycloakJS which checks to see if the user is logged in.
//       If not it redirects to Keycloak login page.
//     * User enters credentials, if successful the user is redirected back to SIMOR.
//     * The same steps are repeated but this time KeycloakJS can see the user is logged in.
//     * renderApp() is called.
//     * UserApp makes a call to get the user's information.
//   - NO: renderApp() is called
loadAuthConfig();
