# import "./fragments/securityMarkingsData.gql"
# import "./fragments/userData.gql"

query SearchFiles($nameSubstring: String!) {
  searchFiles(nameSubstring: $nameSubstring) {
    id
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    attributes {
      name
      uploadDateUtc
      sizeMbs
      uploadedBy {
        ...userData
      }
    }
  }
}
