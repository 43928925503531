/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Textarea } from "baseui/textarea";
import React from "react";
import { normalizeTextControlValue, normalizeTextPropertyValue } from "./SimpleTextEditor";

export const MultiLineTextEditor = ({
  value,
  disabled,
  onChange,
}: {
  value: any;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
}): JSX.Element => {
  const valueAsText = React.useMemo(() => {
    return normalizeTextControlValue(value);
  }, [value]);

  function handleTextChange(event: React.FormEvent<HTMLTextAreaElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value);
    onChange(newText);
  }

  return (
    <>
      <Textarea value={valueAsText} onChange={handleTextChange} disabled={disabled} />
    </>
  );
};
