query {
  appInfo {
    version {
      descriptor
      abbreviatedCommitHash
      commitHash
      buildDate
    }
    schemaStatus {
      expectedMigrationId
      lastExecutedMigration {
        __typename
        ... on ExecutedMigration {
          changeId
          timestamp
        }
        ... on NoMigrationsExecuted {
          message
        }
        ... on ErrorGettingMigrations {
          errorMessage
        }
      }
    }    
    settings {
      fipsEnabled
      configurations {
        bannerMode
        bannerText
        bannerTextColor
        bannerColor
        securityControlColors {
          securityControl
          textColor
          backgroundColor
        }
        disseminationControls
        iFrameDetectionEnabled
        isLogoImageVisible
        isSensitiveSecurityContext
        logoText
        markingsRequired
        useSafeSimMarkingTool
      }
      endpoints {
        smrBaseAddress
        smrEnabled
      }
      jwt {
        audience
        authorizationSetClaim
        clearanceClaim
        digitalIdentifierClaim
        issuer
        myRealm
        secret
        userIdClaim
        userNameClaim
      }
      keycloak {
        baseAddress
        realm
        clientId
        authAddress
        verifierAddress
        jwksAddress
        userInfoAddress
        cacheSize
        cacheEntryExpirationInSeconds
        rateLimitBucketSize
        rateLimitRefillRateInSeconds
        leewayInSeconds
      }
      security {
        trustStorePath
      }
    }
  }
}
