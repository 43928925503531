/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { AssetType } from "Api";
import React from "react";
import {
  ModelDetailsLink,
  PlatformDetailsLink,
  ScenarioDetailsLink,
  StudyDetailsLink,
} from "../../Shared/NavigationLinks";
import { Uuid } from "../../Utils/Types";

export function AssetLink({ id, title, assetType }: { id: Uuid; title: string; assetType: AssetType }): JSX.Element {
  switch (assetType) {
    case "MODEL":
      return <ModelDetailsLink modelId={id} modelName={title} />;
    case "PLATFORM":
      return <PlatformDetailsLink platformId={id} platformName={title} />;
    case "SCENARIO":
      return <ScenarioDetailsLink scenarioId={id} scenarioName={title} />;
    case "STUDY":
      return <StudyDetailsLink studyId={id} studyName={title} />;
    default:
      return <span>{title}</span>;
  }
}
