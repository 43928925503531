/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { AppNavBar, NavItem, setItemActive } from "baseui/app-nav-bar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { DeleteAlt, Overflow } from "baseui/icon";

import { KmTopAppBar } from "../DesignSystem/KobayashiMaru/KmTopAppBar";
import { gql, useQuery, useApolloClient, QueryResult, useReactiveVar } from "@apollo/client";
import { SimorAuthContext } from "../Utils/AuthContext";
import appInfo from "../Api/Gql/AppInfo.gql";
import { formattedDateTime } from "../Api/ApiSerialization";
import { AppInfo, MigrationExecutionState } from "Api";
import SearchInput from "../Shared/SearchInput";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./HeaderBar.css";
import { UserContext } from "../Utils/UserContext";
import { appConfigurationsVar } from "../GlobalState";

const homeLinkStyle: React.CSSProperties = {
  textDecoration: "none",
  color: KmTopAppBar.defaultTextColor,
  fontSize: "1.5rem",
  fontFamily: "Roboto",
};

const logo = new URL("../resources/images/simor_light_mark.png", import.meta.url);

export const HeaderBar = ({
  nextSearchTextState: [nextSearchText, setNextSearchText],
  handleSearch,
}: {
  nextSearchTextState: [string, React.Dispatch<React.SetStateAction<string>>];
  handleSearch: (searchText: string) => void;
}): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);
  const userProfile = React.useContext(UserContext).profile;
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();

  const currentPathIncludesPathOfNavItem = React.useCallback(
    (navItem: NavItem): boolean => {
      const currentLocationParentPath = location.pathname.split("/")[1];
      const url = (navItem.info?.url as string) ?? "";
      const navItemParentPath = url.split("/")[1];
      return navItemParentPath === currentLocationParentPath;
    },
    [location]
  );

  const homeNavItem = {
    label: "Home",
    info: { url: "/" },
  };

  const initialItems: NavItem[] = [
    homeNavItem,
    {
      label: "Resources",
      info: { url: "/resources" },
    },
    {
      // We need the space to avoid the page white screening when the gear is clicked
      label: "Admin",
      info: {
        component: (
          <>
            Admin
            <FontAwesomeIcon icon={faGear} style={{ paddingLeft: ".5rem" }} />
          </>
        ),
        url: "/admin",
      },
    },
  ];
  const [mainItems, setMainItems] = React.useState<NavItem[]>(initialItems);

  const handleMainItemSelect = React.useCallback(
    (item: NavItem) => {
      if (item.info?.url) {
        setMainItems((prev) => setItemActive(prev, item));
        history.push(item.info.url);
      }
    },
    [history, setMainItems]
  );

  const userItems = [
    {
      icon: Overflow,
      label: "User Preferences",
      info: { url: "/userPreferences" },
    },
    {
      icon: Overflow,
      label: "Permissions Page",
      info: { url: "/permissionsPage" },
    },
    { icon: DeleteAlt, label: "Logout", info: { url: "/" } },
  ];

  const handleUserItemSelect = React.useCallback(
    (item: NavItem) => {
      if (item.label === "Logout") {
        sessionStorage.clear();
        client.cache.reset();
        simorAuth.logoutFromServer();
        history.push(item.info.url);
      } else {
        history.push(item.info.url);
      }
    },
    [simorAuth, client, history]
  );

  const appInfoQueryResult = useQuery(gql(appInfo), {
    fetchPolicy: "network-only",
  });

  return (
    <div
      className="whiteIcon"
      style={{
        backgroundColor: KmTopAppBar.headerColor,
        width: "100%",
        outline: `${KmTopAppBar.headerColor} solid`,
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          width: "fit-content",
        }}
      >
        <AppDescriptor homeDestination={homeNavItem.info.url} appInfoQueryResult={appInfoQueryResult} />
        <SearchInput
          searchValue={nextSearchText}
          placeholder="Search everything"
          onSearchTextChange={(newSearchText) => setNextSearchText(newSearchText)}
          onSearch={(searchText: string) => {
            handleSearch(searchText);
            // TODO: Feels like this should be up a level in handleSearch
            // but the history isn't the one associated with the router at that point.
            history.push("/resources");
          }}
          style={{
            paddingLeft: ".5rem",
            width: "25rem",
            // Without this there is a very narrow area in search control where the user can
            // click to enter text.
            // However, enabling it overrides the modal making the modal impossible to use on smaller screens.
            // zIndex: 1,
          }}
        />

        <AppNavBar
          mainItems={mainItems}
          isMainItemActive={currentPathIncludesPathOfNavItem}
          onMainItemSelect={handleMainItemSelect}
          mapItemToNode={function convertItem(item: NavItem): React.ReactNode {
            if (item.info?.component) {
              return item.info.component;
            } else {
              // This is consistent with the default baseweb behavior for defaultMapItemToNode
              // https://github.com/uber/baseweb/blob/master/src/app-nav-bar/utils.js
              return item.label;
            }
          }}
          username={userProfile?.person?.name?.displayName}
          userItems={userItems}
          onUserItemSelect={handleUserItemSelect}
          // NOTE: VS Code notes this as a compilation error, but it is still
          // valid typescript and will compile and run.
          overrides={{
            AppName: {
              style: () => ({
                display: "none",
              }),
              // eslint-disable-next-line react/display-name
            },
            MainMenuItem: {
              style: (props) => ({
                outline: null,
                color: KmTopAppBar.defaultTextColor,
                borderBottomColor: props.$active ? KmTopAppBar.defaultTextColor : KmTopAppBar.headerColor,
                ":hover": {
                  color: KmTopAppBar.secondaryTextColor,
                },
              }),
            },
            UserMenuButton: { props: { "data-testid": `user-menu-button` } },
            Root: { style: () => ({ backgroundColor: "transparent", width: "35rem" }) },
          }}
        />
      </div>
    </div>
  );
};

const AppDescriptor = ({
  homeDestination,
  appInfoQueryResult,
}: {
  homeDestination: string;
  appInfoQueryResult: QueryResult;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isLogoImageVisible = appConfigurations.isLogoImageVisible;
  const logoText = appConfigurations.logoText;

  return (
    <>
      <Link style={homeLinkStyle} to={homeDestination}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          aria-label="simor-home-logo"
        >
          {isLogoImageVisible && (
            <img
              src={logo.toString()}
              height="50rem"
              style={{ marginRight: "0.5rem" }}
              aria-label="simor-logo-image"
            ></img>
          )}
          {logoText}
        </div>
      </Link>
      <AppVersionInfo appInfoQueryResult={appInfoQueryResult} />
    </>
  );
};

const AppInfoTooltip = ({ appInfoValues }: { appInfoValues: AppInfo }): JSX.Element => {
  return (
    <LabelSmall color={KmTopAppBar.defaultTextColor}>
      <>
        Build Date: {appInfoValues?.version?.buildDate}
        <br />
        Commit: {appInfoValues?.version?.abbreviatedCommitHash}
        <MigrationStatus migrationState={appInfoValues?.schemaStatus?.lastExecutedMigration} />
      </>
    </LabelSmall>
  );
};

const AppVersionInfo = ({
  appInfoQueryResult: { loading, error, data },
}: {
  appInfoQueryResult: QueryResult;
}): JSX.Element => {
  const appInfoValues = data?.appInfo as AppInfo;

  return (
    <>
      {loading || error ? (
        <></>
      ) : (
        <StatefulTooltip placement={PLACEMENT.bottom} content={<AppInfoTooltip appInfoValues={appInfoValues} />}>
          <LabelXSmall marginTop={"10px"} color={KmTopAppBar.defaultTextColor}>
            &nbsp; {appInfoValues?.version?.descriptor}
          </LabelXSmall>
        </StatefulTooltip>
      )}
    </>
  );
};

const MigrationStatus = ({ migrationState }: { migrationState: MigrationExecutionState }): JSX.Element => {
  switch (migrationState.__typename) {
    case "ExecutedMigration":
      return (
        <div style={{ display: "block", whiteSpace: "pre" }}>
          {`Latest Data Migration: {\n` +
            `    Id: ${migrationState.changeId} \n` +
            `    Executed: ${formattedDateTime(migrationState.timestamp) ?? "N/A"} \n` +
            `}`}
        </div>
      );
    case "NoMigrationsExecuted":
      return <div>{migrationState.message}</div>;
    case "ErrorGettingMigrations":
      return <div>{migrationState.errorMessage}</div>;
    default:
      const _exhaustiveCheck: never = migrationState;
      return _exhaustiveCheck;
  }
};
