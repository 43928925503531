/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import * as React from "react";

import AddModel from "../Api/Gql/AddModel.gql";

import { notify } from "../Shared/Notify";
import { newModelDetailsState, ModelDetailsReducer } from "./ModelDetailsState";
import getModelUserDefinedSchema from "../Api/Gql/GetModelUserDefinedSchema.gql";
import { MetadataSchema, ModelInfo } from "Api";
import getModels from "../Api/Gql/GetModels.gql";
import { handleApolloError } from "../Shared/Errors";
import { useCopySettings } from "../Asset/Shared/CopyHelpers";
import { initializeAssetCopy } from "../Api/ApiExtensions";
import { AssetWikiCreatePage } from "../Asset/Editor/AssetWikiCreatePage";
import { ModelDetails } from "./ModelDetails";

export function ModelDetailsCreatePage(): JSX.Element {
  const copySettings = useCopySettings();

  const [state, dispatch] = React.useReducer(
    ModelDetailsReducer,
    newModelDetailsState(undefined, copySettings.isInCopyMode)
  );

  const [addModelMutation] = useMutation(gql(AddModel), {
    variables: { model: state.edited.asset },
    onCompleted: () => {
      notify.positive("Model saved");
    },
    onError: (error) => handleApolloError(error, `Error creating model.`),
  });

  const handleAddModel = React.useCallback((): void => {
    addModelMutation();
  }, [addModelMutation]);

  // TODO: Commonize shared code with ModelDetailsEditPage
  const { loading: customMetadataLoading } = useQuery(gql(getModelUserDefinedSchema), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(["setSchema", data?.getModelUserDefinedSchema as MetadataSchema]);
    },
  });

  const [modelLoadError, setModelLoadError] = React.useState<string>();

  // TODO: Consider a useModel hook to wrap getModels() and onComplete pass a single, nicely typed model
  const { loading: modelLoading } = useQuery(gql(getModels), {
    variables: { ids: [copySettings.assetId] },
    fetchPolicy: "network-only",
    skip: !copySettings.isInCopyMode,
    onCompleted: (data) => {
      const models = data.getModels as ModelInfo[];
      if (models[0]) {
        initializeAssetCopy(models[0]);
        dispatch(["resetOriginalAndEditedModel", models[0]]);
      } else {
        setModelLoadError("Model not found; model does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting model data."),
  });

  return (
    <AssetWikiCreatePage
      state={state}
      dispatch={dispatch}
      onAddAsset={handleAddModel}
      loading={customMetadataLoading || modelLoading}
      loadError={modelLoadError}
    >
      <ModelDetails state={state} dispatch={dispatch} />
    </AssetWikiCreatePage>
  );
}
