# import "./fragments/securityMarkingsData.gql"
# import "./fragments/relatedModelData.gql"
# import "./fragments/fileFoldersData.gql"

mutation UpdateScenario($scenario: ScenarioInput!) {
  updateScenario(scenario: $scenario) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      description
      version
      keywords
      notes
      startTime
      customProps
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
    relatedModels {
      __typename
      ...relatedModelData
    }
  }
}
