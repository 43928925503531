import React, { useRef, useCallback, useEffect, useState } from 'react';
import { triggerEvent } from '../../utilities/events';
import { v4 as uuidv4 } from 'uuid';

export function useSafesimDrawerHook(startValues = {}) {
  const [content, setContent] = useState(startValues.content);
  const [isOpen, setIsOpen] = useState(startValues.isOpen || false);
  const [otherProps, setOtherProps] = useState(startValues.otherProps);
  const [position, setPosition] = useState(startValues.position);

  // If useState gets a function, it assumes it's a lazy initializer. The function will return
  // the value when it's needed. Except, our value is itself a function. Hence, we need a function,
  // that returns a function, that returns a function.
  // Anyone calling setOnClose doesn't have to worry about this, and can just pass in
  // a function as normal.
  const [onClose, setOnCloseCallback] = useState(startValues.onClose === undefined ? () => () => setIsOpen(false) : () => startValues.onClose);
  const setOnClose = useCallback((func) => setOnCloseCallback(() => func), []);

  return { content, isOpen, onClose, otherProps, position, setContent, setIsOpen, setOnClose, setOtherProps, setPosition };
}

/** Event type that is used to request content in the Home Page Top Drawer to be changed */
export const OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED = 'SAFESIM/EVENTS/OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED';

/**
 * Triggers a OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED event
 * @param {React.ReactElement} drawerContent The content to render in the drawer
 */
const requestNewDrawerContent = (drawerContent) => {
  triggerEvent(OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED, drawerContent);
};

/**
 * React hook that provides a callback that, when executed, requests new drawer content to be rendered in the drawer
 *
 * @returns A callback that takes a ReactElement as an argument and triggers a drawer content change request
 */
export const useSafesimDrawerContentRequest = () => {
  /// id to denote the drawercontent specific to this hook, this will be used witin the drawer to unload only when the ///
  /// active drawer content is from this hook, to prevent unloading another hooks content when this component unmounts ///
  const hookUUID = useRef(uuidv4());

  /// don't want to use initial content, instead force the caller to use the method in their own useEffect if they
  /// want to invoke the drawer on their component load
  const hookCallback = useCallback((content) => {
    try {
      requestNewDrawerContent({ id: hookUUID.current, content });
    } catch (e) {}
  }, []);

  /// should be called when the component unloads to signal to close the drawer if this hooks content is on display ///
  useEffect(() => {
    /// linter noted that the ref .current may have changed when the return unload method is called and to set the current to a local variable first ///
    const hookID = hookUUID.current;
    return () => requestNewDrawerContent({ id: hookID, content: undefined });
  }, []);

  return hookCallback;
};