/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as _ from "lodash";

/**
 * Checks if a value exists in an array of strings. Case insensitive.
 */
export const valueDoesNotExistsInStrArray = (array: string[], value: string): boolean =>
  array.findIndex((item) => value.trim().toLowerCase() === item.trim().toLowerCase()) === -1;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function shallowCopy(array: any[]): any[] {
  return _.clone(array);
}

export function arraysContainSameElements(array?: any[], other?: any[]): boolean {
  const first = array ?? [];
  const second = other ?? [];

  return first.length == second.length && _.difference(first, second).length == 0;
}

export function containsAny<T>(array: T[], match: (T) => boolean): boolean {
  const firstMatch = array.find((item) => match(item));
  return firstMatch !== undefined;
}

export function replaceBy<T>(
  source: T[],
  replacements: T[],
  getId: (item: T) => any,
  insertMissingReplacements: boolean = true
): T[] {
  const updatedArray = shallowCopy(source);
  const getIndexById = (element: T): number =>
    source.findIndex((existingItem) => {
      return getId(existingItem) === getId(element);
    });

  replacements.forEach((updatedFile) => {
    const existingIndex = getIndexById(updatedFile);
    if (existingIndex !== -1) {
      updatedArray[existingIndex] = updatedFile;
    } else if (insertMissingReplacements) {
      updatedArray.push(updatedFile);
    }
    // else it isn't present and we don't want to insert it if it's missing; ignore it.
  });

  return updatedArray;
}

export function removeBy<T>(source: T[], removedItems: T[], getValue: (item: T) => any): T[] {
  const removedValues = removedItems.map((item) => getValue(item));
  return source.filter((sourceItem) => {
    const sourceValue = getValue(sourceItem);
    return !removedValues.includes(sourceValue);
  });
}

export function distinctBy<T>(source: T[], getValue: (item: T) => any): T[] {
  const valuesToDistinct = source.map((sourceItem) => getValue(sourceItem));

  return source.filter((_, index) => {
    const valueAtIndex = valuesToDistinct[index];
    // Return true if it is the first value.
    return valuesToDistinct.indexOf(valueAtIndex) === index;
  });
}

export function toggleAdd<TItem, TId>(
  source: TItem[] | undefined,
  item: TItem,
  getId?: (sourceItem: TItem) => TId
): TItem[] {
  const validSource = source ?? [];

  if (getId) {
    const sourceIds = validSource.map((sourceItem) => getId(sourceItem));
    const itemId = getId(item);
    return sourceIds.includes(itemId) ? removeBy(validSource, [item], getId) : [...validSource, item];
  } else {
    return validSource.includes(item) ? validSource.filter((existing) => existing !== item) : [...validSource, item];
  }
}

export function addToNewArray<T>(listToAddTo: Array<T> | undefined | null, valueToAdd: T): Array<T> {
  return [...(listToAddTo ?? []), valueToAdd];
}
