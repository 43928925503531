/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import * as Arrays from "../../Utils/Array";
import { ScenarioSpec, ScenarioWizardStep } from "./ScenarioSpec";
import { TaskMeasure } from "Api";
import { LabelLarge, LabelSmall } from "baseui/typography";
import { CenteredContent } from "../../DesignSystem/Containers";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useStyletron } from "baseui";
import { TextEntry } from "../../Utils/TableCells";
import { SelectedTableRowStyle } from "../SmrStyles";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { StepBody } from "./StepBody";

export function ChooseMeasuresStep({
  scenarioSpec,
  onSpecUpdated,
  onStepChange,
}: {
  scenarioSpec?: ScenarioSpec;
  onSpecUpdated: (updatedSpec: ScenarioSpec) => void;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  const tasks = scenarioSpec?.tasks ?? [];

  const [expanded, setExpanded] = React.useState<React.Key[]>(tasks.length ? [tasks[0].id] : []);

  function handleMeasureSelected(_event: React.MouseEvent<HTMLDivElement, MouseEvent>, measure: TaskMeasure): void {
    const newMeasures = Arrays.toggleAdd(scenarioSpec?.measures, measure);
    const newSpec: ScenarioSpec = {
      ...scenarioSpec,
      measures: newMeasures,
      models: undefined,
    };

    onSpecUpdated(newSpec);
  }

  return (
    <StepBody
      stepName={`Choose Measures`}
      previousStep={"Define Outline"}
      previousDisabled={false}
      nextStep={"Pick Models"}
      nextDisabled={!scenarioSpec?.measures?.length}
      onStepChange={onStepChange}
    >
      <div style={{ padding: "1rem 3rem 1rem 2rem" }}>
        {tasks.length > 0 ? (
          <>
            <div style={{ padding: "1rem 0rem 1rem 0rem" }}>
              <LabelSmall>{`These measures are the recommended for evaluating success. Which feels the most appropriate?`}</LabelSmall>
            </div>
            <StatelessAccordion
              accordion={false}
              expanded={expanded}
              onChange={({ expanded }) => {
                setExpanded(expanded);
              }}
            >
              {tasks.map((task) => (
                <Panel title={`Task: ${task.title}`} key={task.id}>
                  <MeasureList
                    key={task.id}
                    measures={task.measures}
                    selectedMeasures={scenarioSpec?.measures ?? []}
                    onMeasureSelected={handleMeasureSelected}
                  />
                </Panel>
              ))}
            </StatelessAccordion>
          </>
        ) : (
          <CenteredContent height="20rem">
            <LabelLarge>{`No tasks have been defined.`}</LabelLarge>
          </CenteredContent>
        )}
      </div>
    </StepBody>
  );
}

function MeasureList({
  measures,
  selectedMeasures,
  onMeasureSelected,
}: {
  measures: TaskMeasure[];
  selectedMeasures: TaskMeasure[];
  onMeasureSelected: (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, measure: TaskMeasure) => void;
}): JSX.Element {
  const styleOverrides = React.useMemo(
    () => SelectedTableRowStyle((rowIndex) => selectedMeasures.includes(measures[rowIndex])),
    [selectedMeasures, measures]
  );

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  if (!measures.length) {
    return (
      <CenteredContent height="10rem">
        <LabelLarge>{`No measures are associated with this task.`}</LabelLarge>
      </CenteredContent>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "3rem",
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <TableBuilder data={measures} overrides={styleOverrides}>
          <TableBuilderColumn id="number" header="Number" sortable>
            {(row: TaskMeasure) => (
              <TextEntry
                text={row.measureNumber}
                styleClass={cellStyle}
                dataId={row.id}
                onClick={(event) => onMeasureSelected(event, row)}
              />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn id="unit" header="Unit" sortable>
            {(row: TaskMeasure) => (
              <TextEntry
                text={row.unit}
                styleClass={cellStyle}
                dataId={row.id}
                onClick={(event) => onMeasureSelected(event, row)}
              />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn id="description" header="Description" sortable>
            {(row: TaskMeasure) => (
              <TextEntry
                text={row.description}
                styleClass={cellStyle}
                dataId={row.id}
                onClick={(event) => onMeasureSelected(event, row)}
              />
            )}
          </TableBuilderColumn>
        </TableBuilder>
      </div>
    </div>
  );
}
