/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { AssetSummary, ScenarioInput, TaskInfo, TaskMeasure } from "Api";
import { newId } from "../../Utils/Types";

export class ScenarioSpec {
  missionDescription?: string;
  location?: string;
  tasks?: TaskInfo[];
  measures?: TaskMeasure[];
  models?: AssetSummary[];
  scenarioInput?: ScenarioInput;
}

export type ScenarioWizardStep =
  | "Describe Mission"
  | "Define Outline"
  | "Choose Measures"
  | "Pick Models"
  | "Refine Scenario"
  | "Create Scenario";

export function toScenarioInput(spec: ScenarioSpec | undefined): ScenarioInput {
  const title = getTitleText(spec);

  const locationText = getLocationText(spec);
  const taskText = getTaskText(spec);
  const measuresText = getMeasuresText(spec);
  const filledNotesSections = [locationText, taskText, measuresText].filter((section) => section);

  return {
    id: newId(),
    metadata: {
      title,
      description: spec?.missionDescription,
      notes: filledNotesSections.join("\n\n"),
    },
    fileFolders: [],
    relatedModelIds: spec?.models?.map((modelSummary) => modelSummary.id),
  };
}

function getTitleText(spec?: ScenarioSpec): string | undefined {
  const location = spec?.location?.length ? spec.location : "Anywhere";

  if (spec?.tasks?.length == 1) {
    return `${location} - ${spec.tasks[0].title}`;
  } else if (spec?.tasks?.length) {
    return `${location} - ${spec.tasks.length} tasks`;
  } else {
    return location;
  }
}

function getLocationText(spec?: ScenarioSpec): string | undefined {
  if (!spec?.location) return undefined;

  return `Location: ${spec.location}`;
}

function getTaskText(spec?: ScenarioSpec): string | undefined {
  if (!spec?.tasks?.length) return undefined;

  const tasksContent = spec.tasks.map((task) => `- ${task.hierarchyNumber} ${task.title}`).join("\n");

  return `Tasks:\n${tasksContent}`;
}

function getMeasuresText(spec?: ScenarioSpec): string | undefined {
  if (!spec?.measures?.length) return undefined;

  const measuresContent = spec.measures.map((measure) => `- ${measure.description} (${measure.unit})`).join("\n");

  return `Measures:\n${measuresContent}`;
}
