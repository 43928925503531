/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import React from "react";
import { AssetInput } from "../../Api/ApiExtensions";
import { AssetDetailsPageAction, AssetDetailsState } from "../Editor/AssetDetailsState";
import { MetadataFieldsAddEditor } from "../Editor/MetadataFieldsAddEditor";
import { FileUploadDetails } from "../Tree/FileUploadDetails";
import { VerticalStack } from "../../DesignSystem/Containers";
import { AssetFileManager } from "./AssetFileManager";

const fileItemProps = {
  overrides: {
    Block: {
      style: () => ({
        width: "30%",
        flexGrow: 0,
      }),
    },
  },
};

export const AssetPane = ({
  state,
  dispatch,
  propertiesStyle,
}: {
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
  propertiesStyle?: React.CSSProperties;
}): JSX.Element => {
  return (
    <VerticalStack style={propertiesStyle}>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" flexGridRowGap="scale800" height="scale10000">
        <FlexGridItem {...fileItemProps}>
          <AssetFileManager
            root={state.edited.fileTreeRoot}
            downloadZipBlocked={state.downloadZipBlocked}
            dispatch={dispatch}
          />
        </FlexGridItem>
        <FlexGridItem>
          {state.selectedFileTreeNode?.type === "File" ? (
            <FileUploadDetails fileUpload={state.selectedFileTreeNode.file} dispatch={dispatch} />
          ) : (
            <MetadataFieldsAddEditor state={state} dispatch={dispatch}></MetadataFieldsAddEditor>
          )}
        </FlexGridItem>
      </FlexGrid>
    </VerticalStack>
  );
};
