mutation AddSelectMultipleField($pickListSchema: PickListSchemaInput!) {
  addSelectMultipleField(pickListSchema: $pickListSchema) {
    id
    fields {
      selectMultiple {
        id
        attributeName
        valueOptions {
          value
          description
        }
      }
    }
  }
}