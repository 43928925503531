/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { PickListSchema, PickListValueOption } from "Api";
import { Value, Select } from "baseui/select";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { LabelXSmall } from "baseui/typography";
import * as React from "react";

export const PickList = ({
  multi,
  pickListData,
  value,
  disabled,
  onChange,
}: {
  multi: boolean;
  pickListData: PickListSchema;
  value: Value;
  disabled?: boolean;
  onChange?: (values: Value) => void;
}): JSX.Element => {
  const pickListValueOptionsToValue = (pickListValueOptions: PickListValueOption[]): Value => {
    return pickListValueOptions.map((option) => {
      return {
        id: option.value,
        label: option.description,
      };
    });
  };
  const pickListOptions = React.useMemo(() => {
    const nullSafeValues: PickListValueOption[] = pickListData.valueOptions ?? [];
    return pickListValueOptionsToValue(nullSafeValues);
  }, [pickListData]);

  const getDescriptionLabel = ({ option }: any): JSX.Element => {
    return (
      <StatefulTooltip
        placement={PLACEMENT.left}
        content={() => (
          <LabelXSmall maxWidth={"30rem"} color="white">
            {option.label ? option.label : "No description provided."}
          </LabelXSmall>
        )}
      >
        <div style={{ width: "100%" }}>{option.id}</div>
      </StatefulTooltip>
    );
  };

  return (
    <Select
      multi={multi}
      options={pickListOptions}
      onChange={({ value }) => {
        if (onChange) {
          onChange(value);
        }
      }}
      overrides={{
        ValueContainer: {
          props: {
            "data-testid": `${pickListData.attributeName}-selector`,
          },
        },
        DropdownContainer: {
          props: {
            "data-testid": `${pickListData.attributeName}-dropdown`,
          },
        },
      }}
      value={value}
      disabled={disabled}
      // The option label sets the component for the
      // values listed in the dropdown.
      getOptionLabel={getDescriptionLabel}
      // The value label sets the component for the
      // selected tag items in the picklist.
      getValueLabel={getDescriptionLabel}
    />
  );
};
