/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import _ from "lodash";
import { MetadataSchema, ModelMetadataInput, ValueRecordType, MarkableValueRecord } from "../../Api/Api";
import {
  AssetMetadataInput,
  getRecordType,
  isMarkedString,
  isValueRecord,
  unknownValueType,
} from "../../Api/ApiExtensions";
import {
  dateFieldSpec,
  FieldSpec,
  multiLineTextFieldSpec,
  numericTextFieldSpec,
  pickListFieldSpec,
  simpleTextFieldSpec,
  tagListFieldSpec,
  TextListQuerySpec,
  unknownFieldSpecType,
} from "./FieldSpec";
import { compareCaseInsensitive, equalIgnoreCase } from "../../Utils/Sort";
import { FileUpload } from "../Tree/FileUpload";
import GetKeywords from "../../Api/Gql/GetKeywords.gql";

export const TITLE_FIELD = simpleTextFieldSpec("Title");
export const VERSION_FIELD = simpleTextFieldSpec("Version");
export const DESCRIPTION_FIELD = multiLineTextFieldSpec("Description");

const keywordsQuerySpec: TextListQuerySpec = {
  matchValuesQuery: GetKeywords,
  queryParameterName: "keywordSubstring",
  responseFieldName: "getKeywords",
};
export const KEYWORDS_FIELD = tagListFieldSpec(keywordsQuerySpec, "Keywords");

export const NOTES_FIELD = multiLineTextFieldSpec("Notes", null, false, true);

// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
export const COMMON_FIXED_SCHEMA_FIELDS: FieldSpec[] = [
  TITLE_FIELD,
  VERSION_FIELD,
  DESCRIPTION_FIELD,
  KEYWORDS_FIELD,
  NOTES_FIELD,
];

function isFixedSchema(propertyName: string, fields: FieldSpec[]): boolean {
  if (propertyName == "customProps") {
    return false;
  }
  return fields.find((field) => field.propertyName === propertyName) ? true : false;
}

export function getCustomPropFields(
  fields: FieldSpec[],
  customProps?: Record<string, MarkableValueRecord>
): FieldSpec[] {
  if (!customProps) {
    return fields;
  }

  const schemaPropertyNames = fields.map((field) => field.propertyName);
  const unspecifiedPropNames = Object.keys(customProps)
    .filter((customPropertyName) => !schemaPropertyNames.includes(customPropertyName))
    .sort(compareCaseInsensitive);

  const unspecifiedFields = unspecifiedPropNames.map((unspecifiedPropName) =>
    toCustomPropsFieldSpec(unspecifiedPropName, customProps[unspecifiedPropName])
  );

  return unspecifiedFields;
}

export function concatUnspecifiedFields(
  fields: FieldSpec[],
  customProps?: Record<string, MarkableValueRecord>
): FieldSpec[] {
  if (!customProps) {
    return fields;
  }

  const schemaPropertyNames = fields.map((field) => field.propertyName);
  const unspecifiedPropNames = Object.keys(customProps)
    .filter((customPropertyName) => !schemaPropertyNames.includes(customPropertyName))
    .sort(compareCaseInsensitive);

  const unspecifiedFields = unspecifiedPropNames.map((unspecifiedPropName) =>
    toCustomPropsFieldSpec(unspecifiedPropName, customProps[unspecifiedPropName])
  );

  return fields.concat(unspecifiedFields);
}

function toCustomPropsFieldSpec(propertyName: string, record: MarkableValueRecord): FieldSpec {
  const recordType = getRecordType(record);
  switch (recordType) {
    case "NUMERIC":
      return numericTextFieldSpec(propertyName, propertyName, true);
    case "TEXT":
      return simpleTextFieldSpec(propertyName, propertyName, true, true);
    case "TEXT_LIST":
      if (isValueRecord(record)) {
        return pickListFieldSpec(record?.value);
      } else {
        throw Error("Text lists are only supported with plain value records.");
      }
    case "DATE_TIME":
      return dateFieldSpec(propertyName, propertyName, true);
    default:
      return unknownValueType(recordType);
  }
}

export function isExpandedSchema(
  fields: FieldSpec[],
  propertyName: string,
  userDefinedSchema?: MetadataSchema
): boolean {
  // This is a special case; customProps is where all user-defined
  // schema information is stored.
  if (equalIgnoreCase(propertyName, "customProps")) {
    return true;
  }
  const fieldForProperty = allMetadataFields(fields, userDefinedSchema).filter((value) =>
    equalIgnoreCase(value.propertyName, propertyName)
  );
  return fieldForProperty && fieldForProperty.length > 0;
}

export function allMetadataFields(fields: FieldSpec[], userDefinedSchema?: MetadataSchema): FieldSpec[] {
  const newFields: FieldSpec[] = [...fields];
  if (userDefinedSchema) {
    const pickListFields = userDefinedSchema.fields.selectMultiple?.map((pickListSchema) => {
      return pickListFieldSpec(pickListSchema);
    });
    if (pickListFields) {
      newFields.push(...pickListFields);
    }
  }
  return newFields;
}

export function flattenPresentMetadata(metadata?: AssetMetadataInput): Record<string, any> {
  if (!metadata) {
    return {};
  }
  const values = {
    ...metadata.customProps,
    ...metadata,
  };

  Object.keys(values).forEach((propertyName) => {
    if (values[propertyName] === null) {
      delete values[propertyName];
    } else if (isValueRecord(values[propertyName])) {
      values[propertyName] = values[propertyName].value;
    }
  });

  return values;
}

export function addMetadataProperty<TMetadataInput extends AssetMetadataInput>(
  metadata: TMetadataInput,
  fixedSchemaFields: FieldSpec[],
  newProperty: FieldSpec
): TMetadataInput {
  const newType = toValueRecordType(newProperty.type);

  return applyMetadataProperty(
    metadata,
    fixedSchemaFields,
    newProperty.propertyName,
    newType,
    newProperty.defaultValue
  );
}

function toValueRecordType(fieldSpecType?: FieldSpec["type"]): ValueRecordType {
  switch (fieldSpecType) {
    case "NumericTextField":
      return "NUMERIC";
    case "SimpleTextField":
      return "TEXT";
    case "MultiLineTextField":
      return "TEXT";
    case "PickListField":
      return "TEXT_LIST";
    case "TagListField":
      return "TEXT_LIST";
    case "DateTextField":
      return "DATE_TIME";
    default:
      return unknownFieldSpecType(fieldSpecType);
  }
}

export function updateMetadataProperty<TMetadataInput extends AssetMetadataInput>(
  metadata: TMetadataInput,
  fixedSchemaFields: FieldSpec[],
  allFields: FieldSpec[],
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any
): TMetadataInput {
  const fieldForName = allFields.find((field) => field.propertyName === propertyName);

  const valueRecordType = toValueRecordType(fieldForName?.type);

  return applyMetadataProperty(metadata, fixedSchemaFields, propertyName, valueRecordType, value);
}

function toMarkableValueRecord(propertyName: string, type: ValueRecordType, value?: any): MarkableValueRecord {
  // Only marked strings are currently supported
  if (isMarkedString(value)) {
    return {
      contents: {
        name: propertyName,
        type,
        value: value.contents,
      },
      securityMarkings: value.securityMarkings,
    };
  } else {
    return {
      name: propertyName,
      type,
      value,
    };
  }
}

function applyMetadataProperty<TMetadataInput extends AssetMetadataInput>(
  metadata: TMetadataInput,
  fixedSchemaFields: FieldSpec[],
  propertyName: string,
  type: ValueRecordType,
  value?: any
): TMetadataInput {
  const updatedMetadata: TMetadataInput = {
    ...metadata,
  };
  if (isFixedSchema(propertyName, fixedSchemaFields)) {
    updatedMetadata[propertyName] = value;
  } else {
    if (!updatedMetadata.customProps) {
      updatedMetadata.customProps = {};
    }

    const markableValueRecord = toMarkableValueRecord(propertyName, type, value);
    updatedMetadata.customProps = {
      ...updatedMetadata.customProps,
      [propertyName]: markableValueRecord,
    };
  }

  return updatedMetadata;
}

function distinctTagSet(originalSet: string[], newSet: string[]): string[] {
  const sanitizedValues = (originalSet ?? [])
    .concat(newSet)
    .filter((value) => value !== undefined && value !== null)
    .map((value) => value.trim());

  const distinctValues = new Set(sanitizedValues);
  return Array.from(distinctValues);
}

export function applyKeywords<TMetadata extends AssetMetadataInput>(
  fileUploads: FileUpload[],
  toEdited: TMetadata
): TMetadata {
  const newMetadata = { ...toEdited };

  const scanKeywords = fileUploads.flatMap((file) => file.metadataScan?.scanMetadata?.keywords?.value);

  newMetadata.keywords = distinctTagSet(newMetadata.keywords, scanKeywords);
  return newMetadata;
}

export function applyFrameworks<TMetadataInput extends ModelMetadataInput>(
  fileUploads: FileUpload[],
  toEdited: TMetadataInput
): TMetadataInput {
  const newMetadata = { ...toEdited };

  const scanSimulationFrameworks = fileUploads.flatMap(
    (file) => file.metadataScan?.scanMetadata?.simulationFrameworks?.value
  );

  newMetadata.simulationFrameworks = distinctTagSet(newMetadata.simulationFrameworks, scanSimulationFrameworks);
  return newMetadata;
}

export function applyKeywordsAndFrameworks<TMetadataInput extends ModelMetadataInput>(
  fileUploads: FileUpload[],
  toEdited: TMetadataInput
): TMetadataInput {
  const newMetadata = { ...toEdited };

  newMetadata.keywords = applyKeywords(fileUploads, newMetadata).keywords;
  newMetadata.simulationFrameworks = applyFrameworks(fileUploads, newMetadata).simulationFrameworks;

  return newMetadata;
}

export function deleteCustomProperty(customProps: Record<string, any>, propertyName: string): Record<string, any> {
  const customPropsWithoutProperty = {
    ...customProps,
  };
  delete customPropsWithoutProperty[propertyName];

  return customPropsWithoutProperty;
}
