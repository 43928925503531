/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import { HorizontalStack } from "../../DesignSystem/Containers";
import { DetailsPage } from "../../Shared/DetailsPages";

// TODO: Consider removing this page when complications with the file page can be resolved.

export const AssetDetailsPage = ({
  headerActionButtons,
  loading,
  loadError,
  children,
}: {
  headerActionButtons?: React.ReactNode;
  loading?: boolean;
  loadError?: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <DetailsPage loading={loading} loadError={loadError}>
      {headerActionButtons}
      <HorizontalStack gap="0.5rem">
        {React.Children.map(children, (child) => {
          return child;
        })}
      </HorizontalStack>
    </DetailsPage>
  );
};
