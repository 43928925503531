query GetTaskLists($taskListIds: [UUID!]!) {
  getTaskLists(taskListIds: $taskListIds) {
    id
    name
    tasks {
      id
      category
      hierarchyNumber
      title
      priority
      djsApprovalDate
      description
      notes
      requiredCapabilities
      measures {
        id
        measureNumber
        unit
        description
      }
    }
  }
}
