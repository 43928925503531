// This component uses hooks and components from allSafesimComponents, 
// so it creates a circular dependency when included in allSafesimComponents
import { SafesimNavbar } from "./allSafesimComponents";
import { SafesimErrorBoundary } from "../utilities/SafesimErrorBoundaries";
import { NAVBAR_ACTION_ITEM_CHANGE_REQUESTED } from "./safesim_hooks/useSafesimNavbarItems";
import { useEventSubscription } from "./safesim_hooks/useEventSubscription";
import React, { useCallback, useState } from "react";

export const SafesimEventNavbar = (props) => {
  const { children, onEventsRegistered, ...rest } = props;

  const [navBarActionItems, setNavBarActionItems] = useState([]);
  const navBarActionItemsChangedListener = useCallback(
    (evt) => {
      /// ensure the event included an id to denote the hook sender ///
      /// if content was included always show content ///
      /// if the evt.content is undefined, take that as a signal to accept the undefined to remove any components from the calling hook //
      if (evt.detail?.id) {
        const removeIndex = navBarActionItems?.findIndex((item) => item.key === evt.detail?.id);

        if (removeIndex > -1) navBarActionItems?.splice(removeIndex, 1);
        if (evt.detail?.content) {
          navBarActionItems?.push(evt.detail?.content);
        }
        setNavBarActionItems([...navBarActionItems]);
      }
    },
    [navBarActionItems, setNavBarActionItems]
  );

  useEventSubscription(NAVBAR_ACTION_ITEM_CHANGE_REQUESTED, navBarActionItemsChangedListener, onEventsRegistered);

  return (
    <SafesimNavbar {...rest}>
      {children}
      <SafesimErrorBoundary>{navBarActionItems}</SafesimErrorBoundary>
    </SafesimNavbar>
  );
};