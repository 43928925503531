/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";

import { BasewebKmTypography } from "../DesignSystem/BasewebKmTypography";
import { PageNotFound } from "../Utils/PageNotFound";
import CreateTestModels from "../Api/Gql/CreateTestModels.gql";

import { WidgetZoo } from "./WidgetZoo";
import { Button } from "baseui/button";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { SiteContext } from "../Utils/SiteProps";
import { AxiosContext } from "../Utils/AuthContext";
import { DatabaseTools } from "./DatabaseTools";
import { Input } from "baseui/input";
import { Radio, RadioGroup } from "baseui/radio";
import { HeadingXLarge, LabelLarge } from "baseui/typography";
import { notify } from "../Shared/Notify";
import { handleApolloError } from "../Shared/Errors";
import { FileSize, TestModelSpecInput } from "../Api/Api";
import { Spinner } from "baseui/spinner";
import { Accordion, Panel } from "baseui/accordion";
import { StyledLink } from "baseui/link";
import { HorizontalStack, VerticalStack } from "../DesignSystem/Containers";
import { ChangeKeywords } from "./ChangeKeyword";
import GetSecret from "../Api/Gql/GetSecret.gql";
import { TaskListIndex } from "../SmrScanner/TaskList/TaskListIndex";
import { SettingsPage } from "./SettingsPage";

const menuHeadingStyle: React.CSSProperties = {
  ...BasewebKmTypography.standard.heading1,
};

export function AdminPage(): JSX.Element {
  const match = useRouteMatch();
  const siteContext = React.useContext(SiteContext);
  const axiosContext = React.useContext(AxiosContext);

  // this is really the response message, not the secret
  const [secret, setSecret] = useState("");
  const getSecret = React.useCallback(() => {
    axiosContext
      .get("/authRequired")
      .then((authRequiredResponse) => {
        setSecret(authRequiredResponse.data);
      })
      .catch((error) => {
        setSecret(error.message);
      });
  }, [axiosContext]);

  const [getSecretGql, { loading, error, data }] = useLazyQuery(gql(GetSecret), {
    fetchPolicy: "network-only",
  });
  function SecretGql(): JSX.Element {
    function handleSecretGql(): void {
      getSecretGql();
    }
    return (
      <>
        <Button onClick={handleSecretGql}>Get secret gql</Button>
        {loading ? <div>Loading</div> : error ? "Error, auth required!" : data ? data.getSecret : ""}
      </>
    );
  }

  return (
    <VerticalStack>
      <div style={menuHeadingStyle}>Admin Tools</div>
      <HorizontalStack>
        <div style={{ marginTop: "1rem", width: "20rem" }}>
          <Accordion>
            <Panel title="Site Information">
              <VerticalStack>
                <Link to={`${match.url}/about`}>About</Link>
                <a href={`${siteContext.topUrl}/healthReport`}>Health Report</a>
                <Link to={`${match.url}/settings`}>Settings</Link>
              </VerticalStack>
            </Panel>
            <Panel title="Asset Manipulation">
              <VerticalStack>
                <Link to={`/modelFields`}>Model Fields</Link>
                <Link to={`${match.path}/changeKeywords`}>Change Keywords</Link>
              </VerticalStack>
            </Panel>
            <Panel title="Files">
              <VerticalStack>
                <Link to={`/files`}>Search Files</Link>
                <Link to={`${match.url}/generateTestFiles`}>Generate Test Files</Link>
              </VerticalStack>
            </Panel>
            <Panel title="Database">
              <VerticalStack>
                <StyledLink href={`${siteContext.topUrl}/playground`}>GraphQL playground</StyledLink>
                <Link to={`${match.url}/databaseTools`}>Database Tools</Link>
              </VerticalStack>
            </Panel>
            <Panel title="Debugging">
              <VerticalStack>
                {
                  // TODO: Candidate for delete
                }
                <Link to={`${match.url}/widgetzoo`}>Widgets</Link>
                <Link to={`${match.url}/restrictedEndpoint`}>Restricted REST Endpoint</Link>
                <Link to={`${match.url}/restrictedGQL`}>Restricted GQL query</Link>
                <Link to={"/restricted"}>Restricted page</Link>
              </VerticalStack>
            </Panel>
            <Panel title="SMR">
              <VerticalStack>
                <Link to={`${match.url}/taskList`}>Task List</Link>
                <Link to={`/scenarioWizard`}>Scenario Wizard</Link>
              </VerticalStack>
            </Panel>
          </Accordion>
        </div>
        {/* A <Switch> looks through its children <Route>s and
             renders the first one that matches the current URL. */}
        <div style={{ marginLeft: "0", marginRight: "auto" }}>
          <Switch>
            <Route path={`${match.path}/about`}>
              <About />
            </Route>
            <Route path={`${match.path}/generateTestFiles`}>
              <GenerateTestFiles />
            </Route>
            <Route path={`${match.path}/settings`}>
              <SettingsPage />
            </Route>
            <Route path={`${match.path}/databaseTools`}>
              <DatabaseTools />
            </Route>
            <Route path={`${match.path}/widgetzoo`}>
              <WidgetZoo />
            </Route>
            <Route path={`${match.path}/restrictedEndpoint`}>
              <>
                <Button onClick={getSecret}>Get secret</Button>
                {secret ? secret : "Click to reveal"}
              </>
            </Route>
            <Route path={`${match.path}/restrictedGQL`}>
              <SecretGql />
            </Route>
            <Route path={`${match.path}/changeKeywords`}>
              <ChangeKeywords />
            </Route>
            <Route path={`${match.path}/taskList`}>
              <TaskListIndex />
            </Route>
            {/* Must be the last path element to prevent it from matching
                 descendent paths */}
            <Route exact path={match.path}>
              <DevToolsHome />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </HorizontalStack>
    </VerticalStack>
  );
}

function DevToolsHome(): JSX.Element {
  return <HeadingXLarge>Dev Tools Home</HeadingXLarge>;
}

function About(): JSX.Element {
  const siteContext = React.useContext(SiteContext);
  return <HeadingXLarge>About: {siteContext.topUrl}</HeadingXLarge>;
}

function GenerateTestFiles(): JSX.Element {
  const [modelCount, setModelCount] = React.useState(10);
  const [fileSize, setFileSize] = React.useState<FileSize>("SMALL");

  const [createTestModelsMutation, { loading }] = useMutation(gql(CreateTestModels), {
    onCompleted: (data: { createTestModels: number }) => {
      notify.positive(`Created ${data.createTestModels} new models`);
    },
    onError: (error) => handleApolloError(error, "An error occured while generating models."),
  });

  const handleGenerate = React.useCallback(() => {
    const modelSpec: TestModelSpecInput = {
      modelCount: modelCount,
      fileSize: fileSize,
    };
    createTestModelsMutation({
      variables: { modelSpec: modelSpec },
    });
  }, [modelCount, fileSize, createTestModelsMutation]);

  if (loading) {
    return (
      <>
        <LabelLarge>{"Creating models..."}</LabelLarge>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <HeadingXLarge style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>Generate Test Files</HeadingXLarge>
      <div style={{ paddingLeft: "2rem" }}>
        <LabelLarge>Number of files</LabelLarge>
        <Input
          value={modelCount}
          type="number"
          min={1}
          onChange={(event) => setModelCount(parseInt(event.currentTarget.value))}
          placeholder="Number of models..."
          size="compact"
          clearable
          clearOnEscape
        />
        <LabelLarge $style={{ Top: "1rem" }}>File Sizes: </LabelLarge>
        <RadioGroup
          align="horizontal"
          name="horizontal"
          onChange={(e) => setFileSize(e.target.value as FileSize)}
          value={fileSize}
        >
          <Radio value="SMALL">Small</Radio>
          <Radio value="MEDIUM">Medium</Radio>
          <Radio value="LARGE">Large</Radio>
          <Radio value="EXTRA_LARGE">Extra Large</Radio>
        </RadioGroup>
        <Button $style={{ width: "10rem" }} onClick={handleGenerate}>
          Generate
        </Button>
      </div>
    </>
  );
}
