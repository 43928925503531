import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSafesimNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateCallback = useCallback(
    (path: string, searchParams?: string) => {
      if (searchParams !== null && searchParams !== undefined) {
        navigate({ pathname: path, search: searchParams });
      } else {
        navigate(path + location.search);
      }
    },
    [location, navigate]
  );
  return navigateCallback;
};
