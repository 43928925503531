/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useMutation, gql, useQuery } from "@apollo/client";
import { Button } from "baseui/button";
import { Select, Value } from "baseui/select";
import * as React from "react";
import UpdateKeyword from "../Api/Gql/UpdateKeyword.gql";
import GetKeywords from "../Api/Gql/GetKeywords.gql";
import { HorizontalStack } from "../DesignSystem/Containers";
import { notify } from "../Shared/Notify";
import { FormControl } from "baseui/form-control";
import { HeadingLarge } from "baseui/typography";
import { handleApolloError } from "../Shared/Errors";

export const ChangeKeywords = (): JSX.Element => {
  const [oldKeyword, setOldKeyword] = React.useState<string>("");
  const [newKeyword, setNewKeyword] = React.useState<string>("");
  const [oldKeywordValue, setOldKeywordValue] = React.useState<Value>([]);
  const [newKeywordValue, setNewKeywordValue] = React.useState<Value>([]);
  const [updateKeywordMutation] = useMutation(gql(UpdateKeyword), {
    variables: { oldKeyword: oldKeyword, newKeyword: newKeyword },
    onCompleted: () => {
      notify.positive("Keywords updated");
      setOldKeyword("");
      setNewKeyword("");
      setOldKeywordValue([]);
      setNewKeywordValue([]);
    },
    onError: (error) => handleApolloError(error, `Error updating keywords.`),
  });
  return (
    <>
      <HorizontalStack>
        <KeywordEditor
          title={"Old Keyword"}
          allowTagCreation={false}
          setKeyword={setOldKeyword}
          valueState={[oldKeywordValue, setOldKeywordValue]}
        />
        <HeadingLarge>{"=>"}</HeadingLarge>
        <KeywordEditor
          title={"New Keyword"}
          allowTagCreation={true}
          setKeyword={setNewKeyword}
          valueState={[newKeywordValue, setNewKeywordValue]}
        />
        <Button onClick={() => updateKeywordMutation()}>Submit</Button>
      </HorizontalStack>
    </>
  );
};

export const KeywordEditor = ({
  title,
  allowTagCreation,
  setKeyword,
  valueState,
}: {
  title: string;
  allowTagCreation?: boolean;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  valueState: [Value, React.Dispatch<React.SetStateAction<Value>>];
}): JSX.Element => {
  return (
    <FormControl label={() => title}>
      <KeywordSelectInput
        dataTestidBase={title + "test-id"}
        allowTagCreation={allowTagCreation}
        setKeyword={setKeyword}
        valueState={valueState}
      />
    </FormControl>
  );
};

const KeywordSelectInput = ({
  allowTagCreation,
  dataTestidBase,
  setKeyword,
  valueState: [value, setValue],
}: {
  allowTagCreation: boolean;
  dataTestidBase: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  valueState: [Value, React.Dispatch<React.SetStateAction<Value>>];
}): JSX.Element => {
  const { data: keywordsData, refetch: refetchKeywords } = useQuery(gql(GetKeywords), {
    variables: { keywordSubstring: "" },
  });

  const suggestedKeywords = React.useMemo(() => {
    const keywords = keywordsData?.getKeywords as string[];
    return keywords?.slice(0, 5)?.map((keyword) => {
      return { id: keyword, label: keyword };
    });
  }, [keywordsData?.getKeywords]);

  const handleTextChanged = React.useCallback(
    (event: React.SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const keyword = target.value;
      refetchKeywords({ keywordSubstring: keyword });
      setKeyword(target.value);
    },
    [refetchKeywords, setKeyword]
  );

  return (
    <>
      <Select
        creatable={allowTagCreation}
        options={suggestedKeywords}
        placeholder="Enter Keyword"
        onChange={({ value }) => {
          setValue(value);
          setKeyword(value[0].label.toString());
          handleTextChanged;
        }}
        onInputChange={handleTextChanged}
        value={value}
        overrides={{
          ValueContainer: {
            props: {
              "data-testid": `${dataTestidBase}-selector`,
            },
          },
          DropdownContainer: {
            props: {
              "data-testid": `${dataTestidBase}-dropdown`,
            },
          },
        }}
      />
    </>
  );
};
