/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import addScenario from "../Api/Gql/AddScenario.gql";
import { notify } from "../Shared/Notify";
import { AssetDetailsPage } from "../Asset/Editor/AssetDetailsPage";
import { newScenarioDetailsState, ScenarioDetailsReducer } from "./ScenarioDetailsState";
import { CreateAssetHeaderBar } from "../Asset/Editor/AssetDetailsHeaders";
import { useCopySettings } from "../Asset/Shared/CopyHelpers";
import { Scenario } from "Api";
import { handleApolloError } from "../Shared/Errors";
import getScenarios from "../Api/Gql/GetScenarios.gql";
import { AssetPane } from "../Asset/Shared/AssetPane";
import { ScenarioRelationPane } from "./ScenarioRelationPane";
import { ClassificationHeader } from "../Shared/ClassificationHeader";
import { initializeAssetCopy } from "../Api/ApiExtensions";
import { relationPaneStyle } from "../Asset/Shared/AssetPageStyles";

export const ScenarioDetailsCreatePage = (): JSX.Element => {
  const copySettings = useCopySettings();

  const [state, dispatch] = React.useReducer(
    ScenarioDetailsReducer,
    newScenarioDetailsState(undefined, copySettings.isInCopyMode)
  );

  const [addScenarioMutation] = useMutation(gql(addScenario), {
    variables: { scenario: state.edited.asset },
    onCompleted: () => {
      notify.positive("Scenario saved");
    },
    onError: (error) => handleApolloError(error, `Error creating scenario.`),
  });

  const handleAddScenario = React.useCallback((): void => {
    addScenarioMutation();
  }, [addScenarioMutation]);

  const [scenarioLoadError, setScenarioLoadError] = React.useState<string>();

  // TODO: Consider a useScenario hook to wrap getScenarios() and onComplete pass a single, nicely typed scenario
  const { loading: scenarioLoading } = useQuery(gql(getScenarios), {
    variables: { ids: [copySettings.assetId] },
    fetchPolicy: "network-only",
    skip: !copySettings.isInCopyMode,
    onCompleted: (data) => {
      const scenarios = data.getScenarios as Scenario[];
      if (scenarios[0]) {
        initializeAssetCopy(scenarios[0]);
        dispatch(["resetOriginalAndEditedScenario", scenarios[0]]);
      } else {
        setScenarioLoadError("Scenario not found; scenario does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting scenario data."),
  });

  const propertiesStyle = { minWidth: "80%" };

  return (
    <AssetDetailsPage
      headerActionButtons={
        <>
          <CreateAssetHeaderBar
            onAddAsset={handleAddScenario}
            typeString="Scenario"
            state={state}
            dispatch={dispatch}
          />
          <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
        </>
      }
      loading={scenarioLoading}
      loadError={scenarioLoadError}
    >
      <AssetPane state={state} dispatch={dispatch} propertiesStyle={propertiesStyle} />
      <div style={relationPaneStyle}>
        <ScenarioRelationPane dispatch={dispatch} relations={state.edited.relations} />
      </div>
    </AssetDetailsPage>
  );
};
