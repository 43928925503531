/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { ScenarioSpec, ScenarioWizardStep } from "./ScenarioSpec";
import { LabelLarge } from "baseui/typography";
import { Textarea } from "baseui/textarea";
import { isBlank } from "../../Utils/Strings";
import { StepBody } from "./StepBody";

export function DescribeMissionStep({
  scenarioSpec,
  onSpecUpdated,
  onStepChange,
}: {
  scenarioSpec?: ScenarioSpec;
  onSpecUpdated: (updatedSpec: ScenarioSpec) => void;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  function handleDescriptionUpdated(event: React.FormEvent<HTMLTextAreaElement>): void {
    const newSpec: ScenarioSpec = {
      missionDescription: event.currentTarget.value,
      // Clear out all other values, because changing the text invalidates the subsequent selections.
      tasks: undefined,
      location: undefined,
      measures: undefined,
      models: undefined,
    };
    onSpecUpdated(newSpec);
  }

  return (
    <StepBody
      stepName={`Describe Mission`}
      nextStep={"Define Outline"}
      nextDisabled={isBlank(scenarioSpec?.missionDescription)}
      onStepChange={onStepChange}
    >
      <LabelLarge>{``}</LabelLarge>
      <div style={{ padding: "1rem 3rem 1rem 2rem" }}>
        <Textarea value={scenarioSpec?.missionDescription ?? ""} onChange={handleDescriptionUpdated} />
      </div>
    </StepBody>
  );
}
