import React, { useRef, useCallback, useEffect } from 'react';
import { triggerEvent } from '../../utilities/events';
import { v4 as uuidv4 } from 'uuid';

/** Event type that is used to request content in the HomepageSidebarDrawer to be changed */
export const SIDEBAR_DRAWER_ACTION_ITEM_CHANGE_REQUESTED = 'SAFESIM/EVENTS/SIDEBAR_DRAWER_ACTION_ITEM_CHANGE_REQUESTED';

/**
 * Triggers a SIDEBAR_DRAWER_ACTION_ITEM_CHANGE_REQUESTED event
 * @param {React.Fragment} actionItems HomePageSidebarNavMenuItems wrapped in a React.Fragment to show in Action menu
 */
const requestSidebarActionItems = (actionItems) => {
  triggerEvent(SIDEBAR_DRAWER_ACTION_ITEM_CHANGE_REQUESTED, actionItems);
};

/**
 * React hook that provides a callback that, when executed, sends new sidebar action items to be rendered in the sidebar
 *
 * @returns A callback that takes a List of HomepageSidebarDrawer as an argument and triggers a request to add actions
 */
export const useSidebarActionItems = () => {
  /// id specific to this hook, this will be used to unload only when the ///
  /// content is from this hook, to prevent unloading another hooks content when this component unmounts ///
  const hookUUID = useRef(uuidv4());

  /// don't want to use initial content, instead force the caller to use the method in their own useEffect if they
  /// want to invoke items on their component load
  const hookCallback = useCallback((content) => {
    try {
      if (Array.isArray(content)) {
        requestSidebarActionItems({
          id: hookUUID.current,
          content: content,
        });
      }
    } catch (e) {}
  }, []);

  /// should be called when the component unloads to signal to remove items ///
  useEffect(() => {
    /// linter noted that the ref .current may have changed when the return unload method is called and to set the current to a local variable first ///
    const hookID = hookUUID.current;
    return () => requestSidebarActionItems({ id: hookID, content: undefined });
  }, []);

  return hookCallback;
};
