query {
  getMyUserProfile {
    id
    username
    person {
      id
      name {
        firstName
        lastName
        displayName
      }
      contactInfo {
        email
      }
    }
    preferences {
      topicsOfInterest
    }
    clearanceInfo
  }
}
