import { SafesimPopover, SafesimTag } from './allSafesimComponents';

type Props = {
  text: string;
  popoverContent: JSX.Element | undefined;
};

export const SafesimPopoverTag = (props: Props): JSX.Element => {
  const { text, popoverContent } = props;

  return (
    <SafesimPopover content={popoverContent} disabled={!popoverContent} minimal={true}>
      <SafesimTag minimal={true} style={popoverContent ? { cursor: 'pointer' } : undefined}>
        {text}
      </SafesimTag>
    </SafesimPopover>
  );
};
