import { useRef, useEffect } from 'react';

/**
 * React hook that can be used to keep track of the previous value for a variable being used in a component
 * @param {any} value The value to store
 * @returns The previous value
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};