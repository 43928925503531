/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { PlatformInput } from "Api";
import React from "react";
import { PlatformDetailsPageAction } from "./PlatformDetailsState";
import { PlatformRelationPane } from "./PlatformRelationPane";
import { WikiCardInfo } from "../Shared/WikiStaticTextBlock";
import { getRelativeLastModifiedTime, formattedDateTime } from "../Api/ApiSerialization";
import { AssetIconWithText } from "../Asset/Shared/AssetIcon";
import { AssetDetailsState } from "../Asset/Editor/AssetDetailsState";
import { AssetWikiDetails } from "../Asset/Editor/AssetWikiDetails";

export function PlatformDetails({
  state,
  dispatch,
}: {
  state: AssetDetailsState<PlatformInput>;
  dispatch: React.Dispatch<PlatformDetailsPageAction>;
}): JSX.Element {
  const wikiCardInfo = React.useMemo<WikiCardInfo[]>(
    () => [
      {
        field: "Resource Type",
        value: <AssetIconWithText type="PLATFORM" />,
      },
      { field: "Title", value: state.edited.asset.metadata.title },
      { field: "Description", value: state.edited.asset.metadata.description },
      { field: "Last Updated", value: getRelativeLastModifiedTime(state.creationDate, state.lastSaveDate) },
      { field: "Created On", value: formattedDateTime(state.creationDate) },
      { field: "Keywords", value: state.edited.asset.metadata.keywords?.toString() },
    ],
    [state.edited.asset.metadata, state.creationDate, state.lastSaveDate]
  );

  return (
    <AssetWikiDetails
      state={state}
      dispatch={dispatch}
      wikiCardInfo={wikiCardInfo}
      type="PLATFORM"
      assetPane={<PlatformRelationPane dispatch={dispatch} relations={state.edited.relations} />}
    />
  );
}
