query generateScenarioOutline($text: String!) {
    generateScenarioOutline(text: $text) {
        verbSuggestions {
            verb
            taskSuggestions {
                id
                category
                hierarchyNumber
                title
                priority
                djsApprovalDate
                description
                notes
                requiredCapabilities
                measures {
                    id
                    measureNumber
                    unit
                    description
                }
            }
        }
        conditions{
            locations
            timeOfDay
            weather
            visibility
        }
        standards{
            standard
        }
        outcomes{
            outcome
        }
    }
}
