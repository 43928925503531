/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Kobayashi Maru typography settings from the Air Force, v1.4.
//
// https://software.af.mil/softwarefactory/kobayashi-maru/

import * as React from "react";

const fontFamily = "Roboto";

//////////////////////////////////
// Display Headings (p.6)
//////////////////////////////////

const displayHeading1: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 300, // Light weight
  fontSize: "3.75rem", // ~60px
  letterSpacing: "-0.5px",
};

const displayHeading2: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 400, // Normal weight
  fontSize: "3rem", // ~48px
  letterSpacing: "0px",
};

//////////////////////////////////
// Headings (p.6)
//////////////////////////////////

const heading1: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 400, // Normal weight
  fontSize: "2.125rem", // ~34px
  letterSpacing: "0.25px",
};

const heading2: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 400, // Normal weight
  fontSize: "1.5rem", // ~24px
  letterSpacing: "0px",
};

const heading3: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 500, // Medium weight
  fontSize: "1.25rem", // ~20px
  letterSpacing: "0.15px",
};

//////////////////////////////////
// Body Text (p.7)
//////////////////////////////////

const bodyText1: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 400, // Normal weight
  fontSize: "1rem", // ~16px
  letterSpacing: "0.5px",
};

//////////////////////////////////
// Link Text (p.7)
//////////////////////////////////

const linkText1: React.CSSProperties = {
  fontFamily: fontFamily,
  fontWeight: 400, // Normal weight
  fontSize: "1rem", // ~16px
  letterSpacing: "0.5px",
};

//////////////////////////////////
// Table Text (p.27)
//////////////////////////////////

// Minimal size for table body text 13px (p.27)
const tableBodyTextSmall: React.CSSProperties = {
  fontFamily: fontFamily,
  fontSize: "0.81rem", // ~13px
};

export const KmTypography = {
  fontFamily: fontFamily,
  displayHeading1: displayHeading1,
  displayHeading2: displayHeading2,
  heading1: heading1,
  heading2: heading2,
  heading3: heading3,
  bodyText1: bodyText1,
  linkText1: linkText1,
  tableBodyTextSmall: tableBodyTextSmall,
};
