import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBlocker } from "./useBlocker";

/**
 * React hook that handles the state for a block-before-navigate prompt based on a specified condition.
 * @param {boolean} when A condition for which to block and prompt before naviation
 * @returns {[boolean, ()=>void, ()=>void]}
 */
export const useNavigationBlockedPrompt = (when) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (navTransition) => {
      if (
        !confirmedNavigation &&
        navTransition.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(navTransition);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};
