/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import { HeaderBar } from "./Header/HeaderBar";
import { PageNotFound } from "./Utils/PageNotFound";
import { ResourceSearchPage } from "./Search/AssetSearchPage";
import { UserPreferences } from "./Header/UserPreferences";
import { RestrictedPage } from "./Utils/RestrictedPage";
import { FilesIndex } from "./File/FilesIndex";
import ModelFields from "./CustomModelFields/ModelFields";
import { SecurityBanner } from "./SecurityBanner";
import { Dashboard } from "./Dashboard/Dashboard";
import { ModelsIndex } from "./Model/ModelsIndex";
import { SiteContext } from "./Utils/SiteProps";
import { ScenariosIndex } from "./Scenario/ScenariosIndex";
import { StudiesIndex } from "./Study/StudiesIndex";
import { AdminPage } from "./Admin/AdminPage";
import { Permissions } from "./Header/PermissionsPage";
import ErrorBoundary from "./Shared/ErrorBoundary";
import { NumericUnitSchema } from "Api";
import GetNumericUnitSchema from "./Api/Gql/GetNumericUnitSchema.gql";
import { gql, useQuery } from "@apollo/client";
import { NumericUnitContext } from "./Utils/NumericUnitContext";
import { PlatformsIndex } from "./Platform/PlatformsIndex";
import { KnowledgeManagementPortalPage } from "./KnowledgeManagementPortal/KnowledgeManagementPortalPage";
import { ScenarioWizardIndex } from "./SmrScanner/ScenarioWizard/ScenarioWizardIndex";

/**
 * The SIMOR application that has a user context
 */
export const UserApp = (): JSX.Element => {
  const siteProps = React.useContext(SiteContext);

  // TODO: When this block of shared states/callbacks grows
  // consider adding a state class with reduce/dispatch
  const searchTextState = React.useState(""),
    [_searchText, setSearchText] = searchTextState;

  const nextSearchTextState = React.useState("");
  const currentPageState = React.useState(1),
    [_currentPage, setCurrentPage] = currentPageState;

  const handleSearch = React.useCallback(
    (searchValue: string) => {
      setSearchText(searchValue);
      setCurrentPage(1);
    },
    [setCurrentPage, setSearchText]
  );

  const { data } = useQuery(gql(GetNumericUnitSchema));

  const numericUnitSchema = data?.getNumericUnitSchema as NumericUnitSchema;

  return (
    <ErrorBoundary>
      <NumericUnitContext.Provider value={numericUnitSchema}>
        <Router basename={siteProps.subUrl}>
          <SecurityBanner>
            <HeaderBar nextSearchTextState={nextSearchTextState} handleSearch={handleSearch} />
            <ToasterContainer placement={PLACEMENT.topRight}>
              {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
              <Switch>
                <Route path="/admin">
                  <AdminPage />
                </Route>
                <Route path="/files">
                  <FilesIndex />
                </Route>
                <Route path="/models">
                  <ModelsIndex />
                </Route>
                <Route exact path="/userPreferences">
                  <UserPreferences />
                </Route>
                <Route exact path="/permissionsPage">
                  <Permissions />
                </Route>
                <Route exact path="/modelFields">
                  <ModelFields />
                </Route>
                <Route exact path="/restricted">
                  <RestrictedPage />
                </Route>
                <Route exact path="/resources">
                  <ResourceSearchPage
                    searchTextState={searchTextState}
                    nextSearchTextState={nextSearchTextState}
                    currentPageState={currentPageState}
                  />
                </Route>
                <Route path="/kmPortal">
                  <KnowledgeManagementPortalPage />
                </Route>
                <Route path="/platforms">
                  <PlatformsIndex />
                </Route>
                <Route path="/scenarios">
                  <ScenariosIndex />
                </Route>
                <Route path="/studies">
                  <StudiesIndex />
                </Route>
                <Route path="/scenarioWizard">
                  <ScenarioWizardIndex />
                </Route>
                <Route exact path="/">
                  <Dashboard />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </ToasterContainer>
          </SecurityBanner>
        </Router>
      </NumericUnitContext.Provider>
    </ErrorBoundary>
  );
};
