/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { TitledBorder, VerticalStack } from "../../DesignSystem/Containers";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { DatePicker } from "baseui/datepicker";
import { DateTime } from "luxon";
import {
  AssetType,
  AssetTypeFilterInput,
  ClassificationFilterInput,
  ClassificationLevel,
  ComparisonOperator,
  DateFilterInput,
  NumericFilterInput,
  SearchSpecInput,
} from "Api";
import { toOffsetDateTimeFormat } from "../../Utils/Time";
import { deepClone } from "../../Utils/Common";
import { Input } from "baseui/input";
import { Combobox } from "baseui/combobox";
import { normalizeTextPropertyValue } from "./SimpleTextEditor";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classificationOptionsAsText } from "../../Api/ApiSerialization";
import { appConfigurationsVar } from "../../GlobalState";
import { useReactiveVar } from "@apollo/client";
import { toTitleCase } from "../../Utils/Strings";

/**
 * An individual selection of a date filter from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * components of Api/SearchSpecInput directly.
 */
export interface DateFilter {
  active: boolean;
  date: DateTime;
}

/**
 * An individual selection of a classification level from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * components of Api/SearchSpecInput directly.
 */
export interface ClassificationSelection {
  active: boolean;
  classificationLevel: ClassificationLevel;
}

/**
 * A full classification filter specification from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * components of Api/SearchSpecInput directly.
 */
export interface ClassificationFilter {
  classificationSelections: ClassificationSelection[];
  selectUnmarked: boolean;
}

/**
 * An individual selection of an asset type from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * components of Api/SearchSpecInput directly.
 */
export interface AssetTypeSelection {
  checked: boolean;
  assetType: AssetType;
}

/**
 * A full asset type filter specification from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * components of Api/SearchSpecInput directly.
 */
export interface AssetTypeFilter {
  selectedAssetTypes: AssetTypeSelection[];
}

/**
 * A full search specification from the AssetSearch page.
 *
 * This is intended to make it easier to maintain state in the limited UI the search page
 * provides. This structure should not be used outside of that page; instead, just use
 * Api/SearchSpecInput directly.
 */
export interface SearchSpec {
  beforeDate?: DateFilter;
  afterDate?: DateFilter;
  numericfilter?: NumericFilterInput;
  classificationFilter?: ClassificationFilter;
  assetTypeFilter?: AssetTypeFilter;
  onlyIncludeAssetsWithoutKeywords: boolean;
}

const toClassificationFilterInput = (filter: ClassificationFilter): ClassificationFilterInput | undefined =>
  filter.classificationSelections
    ? {
        classificationLevels: filter.classificationSelections
          .filter((selection) => selection.active)
          .map((selection) => selection.classificationLevel),
        includeUnmarked: filter.selectUnmarked,
      }
    : undefined;

const toDateFilterInputDate = (dateFilter: DateFilter): string | undefined =>
  dateFilter.active ? toOffsetDateTimeFormat(dateFilter.date) : undefined;

const toAssetTypeFilterInput = (filter: AssetTypeFilter): AssetTypeFilterInput | undefined =>
  filter.selectedAssetTypes
    ? {
        assetTypes: filter.selectedAssetTypes
          .filter((selection) => selection.checked)
          .map((selection) => selection.assetType),
      }
    : undefined;

function toDateFilterInput(fieldName: string, beforeDate?: DateFilter, afterDate?: DateFilter): DateFilterInput {
  const dateFilterInput: DateFilterInput = {
    fieldName,
  };

  if (beforeDate) {
    dateFilterInput.beforeDate = toDateFilterInputDate(beforeDate);
  }

  if (afterDate) {
    dateFilterInput.afterDate = toDateFilterInputDate(afterDate);
  }

  return dateFilterInput;
}

// TODO: Consider moving all conversion related functions into the search page. This way,
//  it would not need to be exported and would not creep throughout the application.

export const toSearchSpecInput = (searchSpec: SearchSpec): SearchSpecInput => {
  const searchSpecInput: SearchSpecInput = {
    numericFilters: searchSpec.numericfilter ? [searchSpec.numericfilter] : undefined,
    classificationFilter: searchSpec.classificationFilter
      ? toClassificationFilterInput(searchSpec.classificationFilter)
      : undefined,
    keywordFilter: {
      onlyIncludeResourcesWithoutKeywords: searchSpec.onlyIncludeAssetsWithoutKeywords,
    },
  };
  if (searchSpec.numericfilter) {
    searchSpecInput.numericFilters = [searchSpec.numericfilter];
  }

  if (searchSpec.classificationFilter) {
    searchSpecInput.classificationFilter = toClassificationFilterInput(searchSpec.classificationFilter);
  }

  if (searchSpec.beforeDate || searchSpec.afterDate) {
    const creationDateFilter = toDateFilterInput("creationDate", searchSpec.beforeDate, searchSpec.afterDate);
    searchSpecInput.dateFilters = [creationDateFilter];
  }

  if (searchSpec.assetTypeFilter) {
    searchSpecInput.assetTypeFilter = toAssetTypeFilterInput(searchSpec.assetTypeFilter);
  }

  return searchSpecInput;
};

export const AssetFilter = ({
  filter,
  onFilterChange,
}: {
  filter: SearchSpec;
  onFilterChange: (filter: SearchSpec) => void;
}): JSX.Element => {
  const onDateFilterChanged = React.useCallback(
    (dateFilter, prop) => {
      const newFilter = deepClone(filter);
      newFilter[prop] = dateFilter;
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  const onNumericFilterChanged = React.useCallback(
    (numericFilter) => {
      const newFilter = deepClone(filter);
      newFilter.numericfilter = numericFilter;
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  const onClassificationFilterChanged = React.useCallback(
    (classificationFilter: ClassificationFilter) => {
      const newFilter = deepClone(filter);
      newFilter.classificationFilter = classificationFilter;
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  const onAssetTypeFilterChanged = React.useCallback(
    (assetTypeFilter: AssetTypeFilter) => {
      const newFilter = deepClone(filter);
      newFilter.assetTypeFilter = assetTypeFilter;
      onFilterChange(newFilter);
    },
    [filter, onFilterChange]
  );

  return (
    <div>
      <TitledBorder
        title={"Type Filter "}
        hintElement={
          <FontAwesomeIcon
            icon={faCircleQuestion}
            size="1x"
            title={"Select the assets type(s) you would like to display. Selecting none will show all assets."}
          />
        }
      >
        <VerticalStack>
          <AssetTypeFilter assetTypeFilter={filter.assetTypeFilter} onFilterChange={onAssetTypeFilterChanged} />
        </VerticalStack>
      </TitledBorder>

      <TitledBorder
        title={"Date Filter "}
        hintElement={
          <FontAwesomeIcon
            icon={faCircleQuestion}
            size="1x"
            title={
              "Select the filter(s) you would like to use by checking the box to the left of to the Date Entry.\n" +
              "Type the value you wish to bound the data by, or select the date using the provided Calendar Widget"
            }
          />
        }
      >
        <VerticalStack>
          <DateFilter
            title={"Created After"}
            propertyName={"afterDate"}
            dateFilter={filter.afterDate}
            onFilterChange={onDateFilterChanged}
          />
          <DateFilter
            title={"Created Before"}
            propertyName={"beforeDate"}
            dateFilter={filter.beforeDate}
            onFilterChange={onDateFilterChanged}
          />
        </VerticalStack>
      </TitledBorder>
      <div style={{ width: "25%", paddingTop: "0.8rem" }}></div>
      <TitledBorder
        title={"Classification Filter "}
        hintElement={
          <FontAwesomeIcon
            icon={faCircleQuestion}
            size="1x"
            title={
              "Select the classification level(s) you would like to display.\n" + "Selecting none will show all assets."
            }
          />
        }
      >
        <VerticalStack>
          <ClassificationFilter
            classificationFilter={filter.classificationFilter}
            onFilterChange={onClassificationFilterChanged}
          />
        </VerticalStack>
      </TitledBorder>
      <div style={{ width: "25%", paddingTop: "0.8rem" }}></div>
      <TitledBorder
        title={"Numeric Filter "}
        hintElement={
          <FontAwesomeIcon
            icon={faCircleQuestion}
            size="1x"
            title={
              "To use this filter, check the box to the left of the text entry field\n" +
              "From left to right, Type the name of the <FIELD> you wish to filter by,\n" +
              "then select which operation youd like to use,\n" +
              "lastly type the numerical <VALUE> you wish to filter for within <FIELD>.\n" +
              "When you click out of the text edit box the filter will apply."
            }
          />
        }
      >
        <VerticalStack>
          <NumericFilter
            onNumericFilterChanged={onNumericFilterChanged}
            fieldPlaceholder={"<FIELD>"}
            numberPlaceholder={"<VALUE>"}
          />
        </VerticalStack>
      </TitledBorder>
    </div>
  );
};

type ComparatorOption = { label: string; id: ComparisonOperator };
const comparators: ComparatorOption[] = [
  { label: "=", id: "EQUAL" },
  { label: "!=", id: "NOT_EQUAL" },
  { label: "<", id: "LESS_THAN" },
  { label: "<=", id: "LESS_THAN_OR_EQUAL" },
  { label: ">", id: "GREATER_THAN" },
  { label: ">=", id: "GREATER_THAN_OR_EQUAL" },
];

export const NumericFilter = ({
  fieldPlaceholder,
  numberPlaceholder,
  onNumericFilterChanged,
}: {
  fieldPlaceholder?: string;
  numberPlaceholder?: string;
  onNumericFilterChanged: (filter?: NumericFilterInput) => void;
}): JSX.Element => {
  const [fieldName, setFieldName] = React.useState("");
  const [displayComparator, setDisplayComparator] = React.useState("=");
  const [comparator, setComparator] = React.useState<ComparisonOperator>("EQUAL");
  const [value, setValue] = React.useState("");
  const [filterActive, setFilterActive] = React.useState(false);

  const updateFilter = React.useCallback(
    (fieldName: string, comparator: ComparisonOperator, value: string, filterActive: boolean) => {
      if (fieldName.trim() !== "" && value && filterActive) {
        onNumericFilterChanged({
          fieldName: fieldName,
          comparator: comparator,
          value: Number(value),
        });
      }
    },
    [onNumericFilterChanged]
  );

  const setChecked = React.useCallback(
    (newActiveState) => {
      if (newActiveState) {
        updateFilter(fieldName, comparator, value, newActiveState);
      } else {
        onNumericFilterChanged(undefined);
      }
      setFilterActive(newActiveState);
    },
    [comparator, fieldName, onNumericFilterChanged, updateFilter, value]
  );

  const updateComparator = React.useCallback(
    (selectedComparator: ComparatorOption) => {
      setDisplayComparator(selectedComparator.label);
      setComparator(selectedComparator.id);
      updateFilter(fieldName, comparator, value, filterActive);
    },
    [comparator, fieldName, filterActive, updateFilter, value]
  );

  function handleNumberChange(event: React.FormEvent<HTMLInputElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value) || "";
    const numberPattern = /^([0-9]+.?[0-9]*|\.|\.[0-9]+|^$)$/g;
    if (numberPattern.test(newText)) {
      setValue(newText);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "0.8rem",
        alignItems: "center",
      }}
    >
      <Checkbox
        checked={filterActive}
        onChange={() => setChecked(!filterActive)}
        // Want the field name to line up with the "Before/After" labels
        overrides={{
          Root: {
            style: () => ({
              marginRight: "-0.8rem",
            }),
          },
        }}
      ></Checkbox>
      <Input
        value={fieldName}
        onChange={(event) => setFieldName(event.currentTarget.value)}
        onBlur={(_event) => updateFilter(fieldName, comparator, value, filterActive)}
        placeholder={fieldPlaceholder}
      />
      <Combobox
        value={displayComparator}
        onChange={(_stringValue: string, option: any) => updateComparator(option as ComparatorOption)}
        mapOptionToString={(option) => option.label}
        options={comparators}
        name="comparison-options"
      />
      <Input
        value={value}
        onChange={handleNumberChange}
        onBlur={(_event) => updateFilter(fieldName, comparator, value, filterActive)}
        placeholder={numberPlaceholder}
      />
    </div>
  );
};

export const DateFilter = ({
  title,
  propertyName,
  dateFilter,
  onFilterChange,
}: {
  title: string;
  propertyName: string;
  dateFilter: DateFilter;
  onFilterChange: (filter: DateFilter, prop: string) => void;
}): JSX.Element => {
  const setChecked = React.useCallback(
    (active) => {
      const newFilter = deepClone(dateFilter);
      newFilter.active = active;
      onFilterChange(newFilter, propertyName);
    },
    [dateFilter, onFilterChange, propertyName]
  );

  const setDate = React.useCallback(
    (newDate) => {
      const newFilter = deepClone(dateFilter);
      newFilter.date = newDate;
      //TODO: add this to a utility function
      if (Number.isNaN(new Date(newFilter.date.toString()).getTime())) newFilter.date = DateTime.now();
      onFilterChange(newFilter, propertyName);
    },
    [dateFilter, onFilterChange, propertyName]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "0.8rem",
      }}
    >
      <Checkbox
        checked={dateFilter.active}
        onChange={() => setChecked(!dateFilter.active)}
        labelPlacement={LABEL_PLACEMENT.right}
        overrides={{
          Checkmark: {
            style: () => ({
              alignSelf: "center",
            }),
          },
        }}
      >
        {title}
      </Checkbox>
      <DatePicker
        formatString="yyyy-MM-dd"
        mask="9999-99-99"
        value={dateFilter.date.toJSDate()}
        onChange={({ date }) => setDate(DateTime.fromJSDate(date as Date))}
      />
    </div>
  );
};

export const ClassificationFilter = ({
  classificationFilter,
  onFilterChange,
}: {
  classificationFilter: ClassificationFilter;
  onFilterChange: (filters: ClassificationFilter) => void;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const setChecked = React.useCallback(
    (filterToUpdate, active) => {
      const newFilter = {
        classificationSelections: classificationFilter.classificationSelections.map((filter) =>
          filter.classificationLevel === filterToUpdate.classificationLevel
            ? { ...filterToUpdate, active: active }
            : filter
        ),
        selectUnmarked: classificationFilter.selectUnmarked,
      };

      onFilterChange(newFilter);
    },
    [classificationFilter.classificationSelections, classificationFilter.selectUnmarked, onFilterChange]
  );

  const setUnmarkedChecked = React.useCallback(
    (active) => {
      const newFilter = { ...classificationFilter, selectUnmarked: active };
      onFilterChange(newFilter);
    },
    [classificationFilter, onFilterChange]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "0.8rem",
      }}
    >
      <VerticalStack>
        <Checkbox
          key={"Unmarked"}
          checked={classificationFilter.selectUnmarked}
          onChange={() => setUnmarkedChecked(!classificationFilter.selectUnmarked)}
          labelPlacement={LABEL_PLACEMENT.right}
          overrides={{
            Checkmark: {
              style: () => ({
                alignSelf: "center",
              }),
            },
          }}
        >
          {"Not Marked"}
        </Checkbox>
        {classificationFilter.classificationSelections.map((filter) => (
          <Checkbox
            key={filter.classificationLevel}
            checked={filter.active}
            onChange={() => setChecked(filter, !filter.active)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Checkmark: {
                style: () => ({
                  alignSelf: "center",
                }),
              },
            }}
          >
            {classificationOptionsAsText(filter.classificationLevel, isSensitiveSecurityContext)}
          </Checkbox>
        ))}
      </VerticalStack>
    </div>
  );
};

export const AssetTypeFilter = ({
  assetTypeFilter,
  onFilterChange,
}: {
  assetTypeFilter: AssetTypeFilter;
  onFilterChange: (filters: AssetTypeFilter) => void;
}): JSX.Element => {
  const setChecked = React.useCallback(
    (filterToUpdate, checked) => {
      const newFilter = {
        selectedAssetTypes: assetTypeFilter.selectedAssetTypes.map((filter) =>
          filter.assetType === filterToUpdate.assetType ? { ...filterToUpdate, checked: checked } : filter
        ),
      };

      onFilterChange(newFilter);
    },
    [assetTypeFilter.selectedAssetTypes, onFilterChange]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "0.8rem",
      }}
    >
      <VerticalStack>
        {assetTypeFilter.selectedAssetTypes.map((filter) => (
          <Checkbox
            key={filter.assetType}
            checked={filter.checked}
            onChange={() => setChecked(filter, !filter.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Checkmark: {
                style: () => ({
                  alignSelf: "center",
                }),
              },
            }}
          >
            {toTitleCase(filter.assetType)}
          </Checkbox>
        ))}
      </VerticalStack>
    </div>
  );
};
