/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { AssetType } from "Api";
import AssetIcon from "./AssetIcon";
import React from "react";

export function AssetIconCell({ assetType, styleClass }: { assetType: AssetType; styleClass: string }): JSX.Element {
  return (
    <div className={styleClass}>
      <AssetIcon type={assetType} />
    </div>
  );
}
