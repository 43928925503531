/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { useHistory, useLocation } from "react-router-dom";
import { Uuid } from "../Utils/Types";
import { useMutation, gql, useQuery } from "@apollo/client";
import { PlatformChangeSet, Platform } from "Api";
import React from "react";
import { handleApolloError } from "../Shared/Errors";
import { platformDetailsCreatePagePath } from "../Shared/NavigationLinks";
import { notify } from "../Shared/Notify";
import { PlatformDetailsReducer, newPlatformDetailsState } from "./PlatformDetailsState";
import updatePlatform from "../Api/Gql/UpdatePlatform.gql";
import getPlatforms from "../Api/Gql/GetPlatforms.gql";
import rescanPlatformFiles from "../Api/Gql/RescanPlatformFiles.gql";
import { AssetWikiEditPage } from "../Asset/Editor/AssetWikiEditPage";
import { PlatformDetails } from "./PlatformDetails";

interface PlatformDetailsQueryParams {
  platformId: Uuid;
}

function usePlatformDetailsParams(): PlatformDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  // TODO: Conditionally show an invalid uuid page if the Uuid fails to parse.
  const platformDetailsParams: PlatformDetailsQueryParams = {
    platformId: searchParams.get("platformId") as Uuid,
  };
  return platformDetailsParams;
}

export function PlatformDetailsEditPage(): JSX.Element {
  const platformDetailsParams = usePlatformDetailsParams();

  const [state, dispatch] = React.useReducer(
    PlatformDetailsReducer,
    newPlatformDetailsState(platformDetailsParams.platformId)
  );

  const [rescanPlatformFilesMutation] = useMutation(gql(rescanPlatformFiles), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const changeSet: PlatformChangeSet = data.rescanPlatformFiles;
      if (changeSet.hasChanged) {
        dispatch(["resetOriginalAndEditedPlatform", changeSet.updatedPlatform]);
        notify.positive("Platform metadata updated");
      } else {
        notify.positive("No changes to platform metadata");
      }
    },
  });

  const [rescanPressed, setRescanPressed] = React.useState<number>(0);
  const handleRescan = React.useCallback(() => {
    rescanPlatformFilesMutation({
      variables: { platformId: state.edited.asset.id },
    });
    setRescanPressed(rescanPressed + 1);
  }, [rescanPressed, rescanPlatformFilesMutation, state.edited?.asset.id]);

  const [updatePlatformMutation] = useMutation(gql(updatePlatform), {
    variables: { platform: state.edited.asset },
    onCompleted: (data) => {
      const updatedPlatform = data.updatePlatform as Platform;
      dispatch(["resetOriginalAndEditedPlatform", updatedPlatform]);
      notify.positive("Platform saved");
    },
    onError: (error) => handleApolloError(error, `Error updating platform.`),
  });

  const [platformLoadError, setPlatformLoadError] = React.useState<string>();
  const { loading: platformLoading } = useQuery(gql(getPlatforms), {
    variables: { ids: [state.edited.asset.id] },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const platforms = data.getPlatforms as Platform[];
      if (platforms[0]) {
        dispatch(["resetOriginalAndEditedPlatform", platforms[0]]);
      } else {
        setPlatformLoadError("Model not found; model does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting platform data."),
  });

  const history = useHistory();
  const handleCopy = React.useCallback(() => {
    history.push(platformDetailsCreatePagePath(state.edited.asset.id));
  }, [history, state.edited.asset.id]);

  return (
    <AssetWikiEditPage
      state={state}
      dispatch={dispatch}
      onSave={updatePlatformMutation}
      onRescan={handleRescan}
      onCopy={handleCopy}
      loading={platformLoading}
      loadError={platformLoadError}
    >
      <PlatformDetails state={state} dispatch={dispatch} />
    </AssetWikiEditPage>
  );
}
