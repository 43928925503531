/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { UserContext } from "../Utils/UserContext";
import { ParagraphMedium } from "baseui/typography";
import { Row } from "../DesignSystem/Containers";

export function Permissions(): JSX.Element {
  const userClearanceInfo = React.useContext(UserContext).clearanceInfo;

  return (
    <>
      <Row gap="2rem">
        <div style={{ width: "8rem", textAlign: "center" }}>
          <ParagraphMedium>User Clearance</ParagraphMedium>
          <pre style={{ margin: "auto", textAlign: "center", width: "50%" }}>
            {JSON.stringify(userClearanceInfo, null, 5)}
          </pre>
        </div>
      </Row>
    </>
  );
}
