# import "./fragments/fileFoldersData.gql"
# import "./fragments/securityMarkingsData.gql"
# import "./fragments/relatedModelData.gql"

query GetPlatforms($ids: [UUID!]!) {
  getPlatforms(ids: $ids) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      version
      description
      keywords
      notes
      subject
      customProps
      imageId
    }
    relatedModels {
      __typename
      ...relatedModelData
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
  }
}
