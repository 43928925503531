/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { Button } from "baseui/button";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from "baseui/modal";
import { Textarea } from "baseui/textarea";
import * as React from "react";
import { VerticalStack, RightAlignedRow } from "../DesignSystem/Containers";

export function ServerErrorToast({
  modalMessage,
  toastMessage = "There was a server error.",
}: {
  modalMessage: string;
  toastMessage?: string;
}): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  return (
    <VerticalStack>
      {toastMessage}
      <Button onClick={() => setModalIsOpen(true)}>{"Show Error"}</Button>
      <ServerErrorDetailsModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} errorMessage={modalMessage} />
    </VerticalStack>
  );
}

function ServerErrorDetailsModal({
  isOpen,
  onClose,
  errorMessage,
}: {
  isOpen: boolean;
  onClose: () => void;
  errorMessage: string;
}): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Server Error</ModalHeader>
      <ModalBody>
        <VerticalStack>
          <Textarea readOnly value={errorMessage} />
        </VerticalStack>
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton kind="tertiary" onClick={onClose}>
            Cancel
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
}
