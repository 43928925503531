import React, { useState, useMemo, useEffect } from 'react';
import { SafesimButton, SafesimText, SafesimNumericInput, SafesimPopover } from './allSafesimComponents';
import { SafesimCardCollapse } from './SafesimCardCollapse';
import { SafesimValueDisplay } from './SafesimValueDisplay';
import { NumberOnlyRegex } from '../utilities/stringModifiers';
import { SafesimControlGroup } from './SafesimControlGroup';

export const SafesimSeriesInput = (props) => {
  const {
    label,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    labelClassName,
    valueClassName,
    units,
    decimal,
    editDisabled = true,
    onCancel,
    unitPopoverContent,
  } = props;
  const [isEditDisabled, setIsEditDisabled] = useState(editDisabled);
  const [workingValue, setWorkingValue] = useState(
    value.map((v) => {
      return { number: v, string: String(v) };
    })
  );

  useEffect(() => {
    setIsEditDisabled(editDisabled);
  }, [editDisabled]);

  const collapsedLabel = useMemo(
    () => (
      <SafesimValueDisplay
        editAction={true}
        editOnClick={() => setIsEditDisabled(false)}
        label={<SafesimText ellipsize={true}>{label}</SafesimText>}
        value={editDisabledDisplayString ?? workingValue.map((x) => x.string).join(', ')}
        units={units}
        rightSideContent={labelContent}
      />
    ),
    [label, editDisabledDisplayString, workingValue, labelContent, units]
  );

  return (
    <>
      {isEditDisabled ? (
        collapsedLabel
      ) : (
        <SafesimCardCollapse header={label} headerContent={labelContent} isOpen={true} hideCaret={true}>
          {units !== undefined ? (
            <SafesimText className={`alignTextCenter ${labelClassName}`}>
              Units:{' '}
              <SafesimPopover content={!isEditDisabled && unitPopoverContent} disabled={!unitPopoverContent} minimal={true}>
                <SafesimButton>{units}</SafesimButton>
              </SafesimPopover>
            </SafesimText>
          ) : null}
          <SafesimControlGroup fill style={{ margin: '2px', width: '98%' }}>
            <SafesimButton
              icon={'tick'}
              style={{ marginRight: '1px' }}
              onClick={() => {
                setIsEditDisabled(true);
                onValueChange(workingValue.map((x) => x.number));
              }}
            />
            <SafesimButton
              icon={'cross'}
              style={{ marginLeft: '1px' }}
              minimal={true}
              outlined={true}
              onClick={() => {
                setIsEditDisabled(true);
                if (Array.isArray(value))
                  setWorkingValue(
                    value.map((v) => {
                      return { number: v, string: String(v) };
                    })
                  );
                else setWorkingValue([]);
                onCancel?.();
              }}
            />
          </SafesimControlGroup>
          {workingValue
            .map((x) => x.string)
            .map((val, index, array) => {
              return (
                <SafesimControlGroup key={index}>
                  <SafesimNumericInput
                    key={index}
                    value={val}
                    onValueChange={(num, str) => {
                      if ((str === '' || NumberOnlyRegex.test(str)) && (decimal || !str.includes('.'))) {
                        const newWorkingValue = [...workingValue];
                        newWorkingValue[index] = { number: num, string: str };
                        setWorkingValue(newWorkingValue);
                      }
                    }}
                    disabled={false}
                    fill={true}
                  />
                  <SafesimButton
                    icon={'minus'}
                    disabled={array.length === 1}
                    onClick={() => {
                      const newWorkingValue = [...workingValue];
                      newWorkingValue.splice(index, 1);
                      setWorkingValue(newWorkingValue);
                    }}
                  />
                </SafesimControlGroup>
              );
            })}
          <SafesimControlGroup fill style={{ margin: '2px', width: '98%' }}>
            <SafesimButton
              icon={'plus'}
              onClick={() => {
                setWorkingValue([...workingValue, { number: 0, string: '0' }]);
              }}
            />
          </SafesimControlGroup>
        </SafesimCardCollapse>
      )}
    </>
  );
};
