/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useQuery, gql } from "@apollo/client";
import { Value, OnChangeParams, Select } from "baseui/select";
import { Tag } from "baseui/tag";
import React from "react";
import { TextListQuerySpec, TagListFieldSpec } from "../Editor/FieldSpec";

export const TagListEditor = ({
  querySpec,
  field,
  value,
  disabled,
  onChange,
}: {
  querySpec: TextListQuerySpec;
  field: TagListFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  const [matchSubstring, setMatchSubstring] = React.useState("");

  const { data } = useQuery(gql(querySpec.matchValuesQuery), {
    variables: { [querySpec.queryParameterName]: matchSubstring },
    fetchPolicy: "network-only",
  });

  const selectedValues = React.useMemo<Value>(() => {
    const nonNullValues = !value ? [] : value;
    return nonNullValues?.map((value) => {
      return { id: value, label: value };
    });
  }, [value]);

  const matchesAsSelectValues = React.useMemo(() => {
    const matchingTags = data ? (data[querySpec.responseFieldName] as string[]) ?? [] : [];
    return matchingTags.slice(0, 5)?.map((match) => {
      return { id: match, label: match };
    });
  }, [data, querySpec.responseFieldName]);

  const handleSelectedKeywordUpdate = (changeParams: OnChangeParams): void => {
    if (onChange) {
      const updatedKeywords: string[] = changeParams.value.map((keywordValue) => {
        return keywordValue.id as string;
      });
      onChange(updatedKeywords);
    }
  };

  const handleTextChanged = React.useCallback((event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setMatchSubstring(target.value);
  }, []);

  const dataTestidBase = `model-${field.propertyName}`;

  return (
    <>
      {disabled ? (
        <>
          {value?.map((selected, index) => (
            <Tag key={index} closeable={false}>
              {selected}
            </Tag>
          ))}
        </>
      ) : (
        <Select
          multi={true}
          creatable={true}
          options={matchesAsSelectValues}
          value={selectedValues}
          onChange={handleSelectedKeywordUpdate}
          onInputChange={handleTextChanged}
          overrides={{
            ValueContainer: {
              props: {
                "data-testid": `${dataTestidBase}-selector`,
              },
            },
            DropdownContainer: {
              props: {
                "data-testid": `${dataTestidBase}-dropdown`,
              },
            },
            Tag: {
              props: {
                overrides: {
                  ActionIcon: {
                    props: {
                      "data-testid": `remove-${dataTestidBase}-tag`,
                    },
                  },
                },
              },
            },
          }}
        />
      )}
    </>
  );
};
