import { Classes } from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { SafesimInputGroup, SafesimLabel } from './allSafesimComponents';
import { debounceFunction, SafesimConfirmRejectPopover } from './SafesimConfirmRejectPopover';
import { SafesimControlGroup } from './SafesimControlGroup';

/**
 * Functional component that displays a string in a disabled input that can be edited.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} value Value to display in the disabled SafesimInputGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {boolean} disallowBlankValue boolean controlling if an empty string is an acceptable value.
 * @param {function} onValidation callback for input validation when value is changed
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {boolean} editDisabled the starting state of the input. True if editing is disabled at the start.
 * @param {function} onCancel callback for when the value is in edit mode, then cancelled
 * @returns Inline SafesimFormGroup containing a disabled input group, with a popover for the edit button.
 */
export const SafesimStringInput = (props) => {
  const {
    label,
    labelClassName,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    onValidation,
    valueClassName,
    disallowBlankValue,
    rightElement,
    editDisabled = true,
    onCancel,
    ...rest
  } = props;
  const [isEditDisabled, setIsEditDisabled] = useState(editDisabled);
  const [workingValue, setWorkingValue] = useState(value);
  const [isWorkingValueValid, setIsWorkingValueValid] = useState(true);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setIsEditDisabled(editDisabled);
  }, [editDisabled]);

  const validateWorkingValue = useCallback(
    (stringValue) => {
      setIsWorkingValueValid(onValidation?.(stringValue) ?? true);
    },
    [onValidation]
  );

  useEffect(() => {
    setWorkingValue(value);
    validateWorkingValue(value);
  }, [value, validateWorkingValue]);

  const onConfirmChanges = useCallback(() => {
    if (disallowBlankValue && !workingValue) {
      setWorkingValue(value);
      validateWorkingValue(value);
    } else {
      onValueChange?.(workingValue);
    }
    setIsEditDisabled(true);
  }, [disallowBlankValue, workingValue, value, validateWorkingValue, onValueChange]);

  const onRejectChanges = useCallback(() => {
    setIsEditDisabled(true);
    setWorkingValue(value);
    validateWorkingValue(value);
    onCancel?.();
  }, [validateWorkingValue, value, onCancel]);

  // when the input has focus, use enter and escape keys as alternatives to clicking the tick/cross buttons in the popover
  const handleKeyDown = useCallback(
    (event) => {
      // only check enter and escape keys. Let all others pass
      if (event.key.toLowerCase() === 'enter' && isWorkingValueValid) {
        onConfirmChanges();
      } else if (event.key.toLowerCase() === 'escape') {
        onRejectChanges();
      }
    },
    [onConfirmChanges, onRejectChanges, isWorkingValueValid]
  );

  const debounceOnMouseLeave = debounceFunction(() => setShowEdit(false));

  useEffect(() => {
    return () => debounceOnMouseLeave.cancel();
  }, [debounceOnMouseLeave]);

  return (
    <SafesimControlGroup
      style={{ padding: '5px' }}
      fill={true}
      onMouseEnter={() => {
        debounceOnMouseLeave.cancel();
        setShowEdit(true);
      }}
      onMouseLeave={debounceOnMouseLeave}
      {...rest}
    >
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={Classes.FIXED + ' ' + labelClassName} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimConfirmRejectPopover
        isOpen={!isEditDisabled || showEdit} // popover should be open on hover to show edit button or when actively editing
        isEdit={!isEditDisabled}
        onEditClicked={() => setIsEditDisabled(false)}
        onConfirmChanges={onConfirmChanges}
        onRejectChanges={onRejectChanges}
        confirmDisabled={!isWorkingValueValid}
      >
        <SafesimInputGroup
          className={valueClassName}
          value={isEditDisabled && editDisabledDisplayString ? editDisabledDisplayString : workingValue}
          placeholder={workingValue}
          disabled={isEditDisabled}
          fill={true}
          onChange={(e) => {
            if (!isEditDisabled) {
              setWorkingValue(e.target.value);
              validateWorkingValue(e.target.value);
            }
          }}
          onKeyDown={handleKeyDown}
          rightElement={rightElement}
        />
      </SafesimConfirmRejectPopover>
    </SafesimControlGroup>
  );
};
