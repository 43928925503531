/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { toaster } from "baseui/toast";
import * as React from "react";
import { ServerErrorToast } from "./ServerErrorToast";

export interface INotify {
  positive(message: string): void;
  warning(message: string): void;
  negative(message: string): void;
  serverError(modalMessage: string, toastMessage?: string): void;
  info(message: string): void;
}

const UNIMPORTANT_MESSAGE_DURATION_MS = 4000;

const UNIMPORTANT_MESSAGE_PROPS = {
  autoHideDuration: UNIMPORTANT_MESSAGE_DURATION_MS,
};

export const notify: INotify = {
  positive(message: string): void {
    toaster.positive(message, UNIMPORTANT_MESSAGE_PROPS);
  },
  warning(message: string): void {
    toaster.warning(message, {});
  },
  negative(message: string): void {
    toaster.negative(message, {});
  },
  serverError(modalMessage: string, toastMessage?: string): void {
    toaster.negative(<ServerErrorToast modalMessage={modalMessage} toastMessage={toastMessage} />, {});
  },
  info(message: string): void {
    toaster.info(message, UNIMPORTANT_MESSAGE_PROPS);
  },
};

// TODO: This logic is really complicated and we should figure out a way to test it.
export function notifyBatchResponse<TSuccess, TDuplicate, TFailed>(
  successfulElements: TSuccess[] | undefined,
  duplicateElements: TDuplicate[] | undefined,
  failedElements: TFailed[] | undefined,
  getAllFailedMessage: (failedElements?: TFailed[]) => string,
  getPartialSuccessMessage: (
    failedElements: TFailed[],
    successfulElements?: TSuccess[],
    duplicateElements?: TDuplicate[]
  ) => string,
  getSingleSuccessMessage: (successfulElement: TSuccess) => string,
  getFewSuccessMessage: (successfulElements: TSuccess[]) => string,
  getManySuccessMessage: (successfulElements: TSuccess[]) => string,
  getSuccessMessageManyDuplicate: (successfulElements: TSuccess[], duplicateElements: TDuplicate[]) => string,
  getSuccessMessageSingleDuplicate: (successfulElements: TSuccess[], duplicateElement: TDuplicate) => string,
  noDataError: string = "Something went wrong with this action; please contact the administrator."
): void {
  const numSuccessful = successfulElements ? successfulElements.length : 0;
  const numDuplicate = duplicateElements ? duplicateElements.length : 0;
  const numFailed = failedElements ? failedElements.length : 0;
  if (numFailed > 0) {
    if (numSuccessful > 0 || numDuplicate > 0) {
      const partialSuccess = getPartialSuccessMessage(failedElements, successfulElements, duplicateElements);
      notify.warning(partialSuccess);
    } else {
      const allFailedMessage = getAllFailedMessage(failedElements);
      notify.serverError(allFailedMessage, "There was a problem uploading the file(s)");
    }
  } else if (numDuplicate > 0) {
    let duplicateSuccessMessage = null;
    if (numDuplicate == 1) {
      duplicateSuccessMessage = getSuccessMessageSingleDuplicate(successfulElements, duplicateElements[0]);
    } else {
      duplicateSuccessMessage = getSuccessMessageManyDuplicate(successfulElements, duplicateElements);
    }
    notify.positive(duplicateSuccessMessage);
  } else if (numSuccessful > 0) {
    let successMessage = null;
    if (numSuccessful == 1) {
      successMessage = getSingleSuccessMessage(successfulElements[0]);
    } else if (numSuccessful == 2) {
      successMessage = getFewSuccessMessage(successfulElements);
    } else {
      successMessage = getManySuccessMessage(successfulElements);
    }
    notify.positive(successMessage);
  } else {
    notify.negative(noDataError);
  }
}
