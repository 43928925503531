import React, { ChangeEvent, useEffect, useState } from 'react';
import { Classes } from '@blueprintjs/core';
import { SafesimInputGroup, SafesimLabel } from './allSafesimComponents';
import { SafesimControlGroup, SafesimControlGroupProps } from './SafesimControlGroup';

interface Props extends SafesimControlGroupProps {
  label: string;
  labelClassName?: string;
  labelContent?: JSX.Element;
  value: string;
  editDisabledDisplayString?: string;
  onValueChange: (value: string) => void;
  validator?: (value: string) => boolean;
  valueClassName?: string;
  rightElement?: JSX.Element;
  editDisabled?: boolean;
  modified: boolean;
}

/**
 * Functional component that displays a string in a disabled input that can be edited.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} value Value to display in the disabled SafesimInputGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {function} validator callback for input validation when value is changed, expected to return true if value is valid
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {boolean} modified boolean indicating if the value has been changed and is valid but not yet saved
 * @returns Text input with label
 */
export const SafesimFormTextInput = (props: Props): JSX.Element => {
  const {
    label,
    labelClassName,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    validator,
    valueClassName,
    rightElement,
    editDisabled = false,
    modified,
    ...rest
  } = props;

  const [isValueValid, setIsValueValid] = useState(true);

  // if value loads or changes, verify validity
  useEffect(() => {
    setIsValueValid(validator?.(value) ?? true);
  }, [value, validator]);

  return (
    <SafesimControlGroup style={{ padding: '5px' }} fill={true} {...rest}>
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={`${Classes.FIXED} ${labelClassName}`} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimInputGroup
        className={`${valueClassName} ${!isValueValid ? 'makeRed' : modified ? 'make-green' : ''}`}
        value={editDisabled && editDisabledDisplayString ? editDisabledDisplayString : value}
        placeholder={value}
        disabled={editDisabled}
        fill={true}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!editDisabled) {
            onValueChange(e.target.value);
            setIsValueValid(validator?.(e.target.value) ?? true);
          }
        }}
        rightElement={rightElement}
      />
    </SafesimControlGroup>
  );
};
