/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { TwoButtonModal } from "../../Shared/TwoButtonModal";
import { FileSearchPage } from "../../File/FileSearchPage";
import { SelectableFileInfoItem } from "../../File/FileSearchResultTable";
import { AssetFileTreeAction, FolderNode } from "./AssetFileTreeState";

export function AddExistingFilesModal({
  isOpen,
  dispatch,
  onClose,
  folderNode,
}: {
  isOpen: boolean;
  dispatch: React.Dispatch<AssetFileTreeAction>;
  onClose: () => void;
  folderNode: FolderNode;
}): JSX.Element {
  const [selectedFileInfo, setSelectedFileInfo] = React.useState<SelectableFileInfoItem>();

  const handleChoosePressed = React.useCallback(() => {
    const fileToAdd = selectedFileInfo?.file;
    dispatch(["addExistingUploads", { filesToAdd: [fileToAdd], folderNode: folderNode }]);
    onClose();
  }, [dispatch, folderNode, onClose, selectedFileInfo?.file]);

  const handleHighlight = React.useCallback((fileInfo: SelectableFileInfoItem) => {
    setSelectedFileInfo(fileInfo);
  }, []);

  return (
    <TwoButtonModal
      title="Select Files to add"
      isOpen={isOpen}
      positiveButtonText="Choose"
      positiveButtonPressed={handleChoosePressed}
      isPositiveButtonDisabled={selectedFileInfo === undefined ? true : false}
      negativeButtonText="Cancel"
      negativeButtonPressed={onClose}
      modalOverrides={{
        Dialog: { style: { width: "80rem" } },
        Root: { props: { "data-testid": "Select_Files_to_add_modal" } },
      }}
    >
      <FileSearchPage selectedFileInfo={selectedFileInfo} handleHighlight={handleHighlight} componentHeight={"25rem"} />
    </TwoButtonModal>
  );
}
