/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { NeutralLink, ProminentLink } from "../DesignSystem/Links";
import { Uuid } from "../Utils/Types";

export function fileDetailsPagePath(fileId: Uuid): string {
  return `/files/details?fileId=${fileId}`;
}

export const FileDetailsLink = ({ fileId, fileName }: { fileId: Uuid; fileName: string }): JSX.Element => {
  return <ProminentLink to={fileDetailsPagePath(fileId)} displayName={fileName} openNewWindow={true} />;
};

export function modelDetailsEditPagePath(modelId: Uuid): string {
  return `/models/details/edit?modelId=${modelId}`;
}

export function modelDetailsCreatePagePath(modelId?: Uuid): string {
  if (modelId) {
    return `/models/details/create?assetId=${modelId}`;
  }

  return `/models/details/create`;
}

export function platformDetailsEditPagePath(platformId: Uuid): string {
  return `/platforms/details/edit?platformId=${platformId}`;
}

export function platformDetailsCreatePagePath(platformId?: Uuid): string {
  if (platformId) {
    return `/platforms/details/create?assetId=${platformId}`;
  }

  return `/platforms/details/create`;
}

export function scenarioDetailsCreatePagePath(scenarioId?: Uuid): string {
  if (scenarioId) {
    return `/scenarios/details/create?assetId=${scenarioId}`;
  }

  return `/scenarios/details/create`;
}

export function scenarioDetailsEditPagePath(scenarioId: Uuid): string {
  return `/scenarios/details/edit?scenarioId=${scenarioId}`;
}

export function studyDetailsCreatePagePath(studyId?: Uuid): string {
  if (studyId) {
    return `/studies/details/create?assetId=${studyId}`;
  }
  return `/studies/details/create`;
}

export function studyDetailsEditPagePath(studyId: Uuid): string {
  return `/studies/details/edit?studyId=${studyId}`;
}

export const ModelDetailsLink = ({ modelId, modelName }: { modelId: Uuid; modelName: string }): JSX.Element => {
  return <NeutralLink to={modelDetailsEditPagePath(modelId)} displayName={modelName} />;
};

export const PlatformDetailsLink = ({
  platformId,
  platformName,
}: {
  platformId: Uuid;
  platformName: string;
}): JSX.Element => {
  return <NeutralLink to={platformDetailsEditPagePath(platformId)} displayName={platformName} />;
};

export const ScenarioDetailsLink = ({
  scenarioId: scenarioId,
  scenarioName: scenarioName,
}: {
  scenarioId: Uuid;
  scenarioName: string;
}): JSX.Element => {
  return <NeutralLink to={scenarioDetailsEditPagePath(scenarioId)} displayName={scenarioName} />;
};

export const StudyDetailsLink = ({
  studyId: studyId,
  studyName: studyName,
}: {
  studyId: Uuid;
  studyName: string;
}): JSX.Element => {
  return <NeutralLink to={studyDetailsEditPagePath(studyId)} displayName={studyName} />;
};
