/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { useReactiveVar } from "@apollo/client";
import { SecurityMarkings } from "Api";
import { ThemeProvider, createTheme, lightThemePrimitives } from "baseui";
import { Button, ButtonOverrides } from "baseui/button";
import { LabelLarge } from "baseui/typography";
import * as React from "react";
import { AssetDetailsPageAction } from "../Asset/Editor/AssetDetailsState";
import { Row } from "../DesignSystem/Containers";
import { appConfigurationsVar } from "../GlobalState";
import { generateSecurityBannerText } from "./Security";
import { SecurityMarkingsModal } from "./SecurityMarkingsModal";

const markingButtonOverrides: ButtonOverrides = {
  BaseButton: {
    style: () => ({
      marginRight: "3rem",
    }),
  },
};

export function ClassificationHeader({
  securityMarkings,
  dispatch,
  onMarkingsChanged,
  readOnly = false,
}: {
  securityMarkings?: SecurityMarkings;
  dispatch?: React.Dispatch<AssetDetailsPageAction>;
  onMarkingsChanged?: (newSecurityMarking: SecurityMarkings) => void;
  readOnly?: boolean;
}): JSX.Element {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;
  const markingsRequired = appConfigurations.markingsRequired;

  const securityMarkingsText = generateSecurityBannerText(isSensitiveSecurityContext, securityMarkings);

  const [editorVisible, setEditorVisible] = React.useState(false);

  const handleMarkingsChanged = React.useCallback(
    (newSecurityMarking) => {
      if (onMarkingsChanged) {
        onMarkingsChanged(newSecurityMarking);
      } else {
        dispatch(["editMarkings", newSecurityMarking]);
      }
    },
    [dispatch, onMarkingsChanged]
  );

  return (
    <Row gap="1rem">
      {!markingsRequired || securityMarkingsText !== "Unspecified" ? (
        // TODO: commonize both blocks in this conditional
        <Row gap="0.5rem">
          <OverallClassificationComponent
            readOnly={readOnly}
            setEditorVisible={setEditorVisible}
            securityMarkingsText={securityMarkingsText}
          />
        </Row>
      ) : (
        <Row gap="0.5rem">
          <ThemeProvider
            theme={createTheme(lightThemePrimitives, {
              colors: { buttonTertiaryText: "#ff0000", buttonTertiaryFill: "#ffffff" },
            })}
          >
            <OverallClassificationComponent
              readOnly={readOnly}
              setEditorVisible={setEditorVisible}
              securityMarkingsText={securityMarkingsText}
            />
          </ThemeProvider>
        </Row>
      )}
      <SecurityMarkingsModal
        editorVisibleState={editorVisible}
        setEditorVisible={setEditorVisible}
        securityMarkings={securityMarkings}
        onMarkingsChanged={handleMarkingsChanged}
      ></SecurityMarkingsModal>
    </Row>
  );
}

function OverallClassificationComponent({
  readOnly,
  setEditorVisible,
  securityMarkingsText,
}: {
  readOnly: boolean;
  setEditorVisible: React.Dispatch<React.SetStateAction<boolean>>;
  securityMarkingsText: string;
}): JSX.Element {
  return (
    <>
      {readOnly ? (
        <LabelLarge style={{ marginTop: "0.75rem" }}>{`Overall Classification : `}</LabelLarge>
      ) : (
        <Button kind={"tertiary"} overrides={markingButtonOverrides} onClick={() => setEditorVisible(true)}>
          {`Overall Classification: `}
        </Button>
      )}
      <LabelLarge
        style={{ marginTop: "0.75rem", marginLeft: readOnly ? "" : "-3rem" }}
        overrides={{ Block: { props: { "data-testid": "classification-text" } } }}
      >{`${securityMarkingsText}`}</LabelLarge>
    </>
  );
}
