/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React from "react";
import { DetailsHeaderBar } from "../../Shared/DetailsPages";
import { AssetDetailsPageAction, AssetDetailsState } from "./AssetDetailsState";
import { AssetInput } from "../../Api/ApiExtensions";
import { useHistory } from "react-router-dom";
import { Row } from "../../DesignSystem/Containers";
import { Button } from "baseui/button";
import { useMarkingsDisableSave } from "../../Shared/ApiHooks";

export function CreateAssetHeaderBar({
  onAddAsset,
  typeString,
  state,
}: {
  onAddAsset: () => void;
  typeString: string;
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element {
  return (
    <DetailsHeaderBar
      title={typeString + " : " + (state.metadataValues.title ?? "")}
      creationDate={state.creationDate}
      lastSaveDate={state.lastSaveDate}
    >
      <CreateAssetHeaderButtons onAddAsset={onAddAsset} state={state} />
    </DetailsHeaderBar>
  );
}

export function CreateAssetHeaderButtons({
  onAddAsset,
  state,
}: {
  onAddAsset: () => void;
  state: AssetDetailsState<AssetInput>;
}): JSX.Element {
  const markingsDisableSave = useMarkingsDisableSave(state.edited.asset.securityMarkings);
  const isSaveDisabled = !state.dataChanged || markingsDisableSave;
  const history = useHistory();

  // TODO: think about globalizing this function
  function closeAddPage(): void {
    // The first page in your history is the "about:blank" page in the browser.
    if (history.length > 2) {
      history.goBack();
    } else {
      // There is no history; the user went directly to this page. return to the
      // site root.
      history.push("/");
    }
  }

  function handleCancel(): void {
    closeAddPage();
  }

  function handleSave(): void {
    onAddAsset();
    closeAddPage();
  }

  return (
    <Row gap="1rem">
      <Button
        kind={"tertiary"}
        onClick={handleCancel}
        overrides={{ BaseButton: { props: { "data-testid": `asset-cancel-button` } } }}
      >
        {"Cancel"}
      </Button>
      <Button
        kind={"primary"}
        disabled={isSaveDisabled}
        onClick={handleSave}
        overrides={{ BaseButton: { props: { "data-testid": `asset-save-button` } } }}
      >
        {"Save"}
      </Button>
    </Row>
  );
}

export function EditAssetHeaderBar({
  updateAssetMutation,
  handleRescan,
  handleCopy,
  typeString,
  state,
  dispatch,
}: {
  updateAssetMutation: () => void;
  handleRescan: () => void;
  handleCopy: () => void;
  typeString: string;
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element {
  return (
    <DetailsHeaderBar
      title={typeString + " : " + (state.metadataValues.title ?? "")}
      creationDate={state.creationDate}
      lastSaveDate={state.lastSaveDate}
    >
      <EditAssetHeaderButtons
        onUpdateAsset={updateAssetMutation}
        onRescan={handleRescan}
        onCopy={handleCopy}
        state={state}
        dispatch={dispatch}
      />
    </DetailsHeaderBar>
  );
}

export const classificationHeaderContainerStyle: React.CSSProperties = {
  marginTop: "0.75rem",
};

export function EditAssetHeaderButtons({
  onUpdateAsset,
  onRescan,
  onCopy,
  state,
  dispatch,
}: {
  onUpdateAsset: () => void;
  onRescan: () => void;
  onCopy: () => void;
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element {
  const markingsDisableSave = useMarkingsDisableSave(state.edited.asset.securityMarkings);

  const isSaveDisabled = !state.dataChanged || markingsDisableSave;

  return (
    <Row gap="1rem">
      <Button kind={"tertiary"} onClick={onCopy}>
        {"Duplicate"}
      </Button>
      <Button kind={"tertiary"} onClick={onRescan}>
        {"Rescan"}
      </Button>
      <Button kind={"tertiary"} disabled={!state.dataChanged} onClick={() => dispatch(["discardChanges"])}>
        {"Discard Changes"}
      </Button>
      <Button
        kind={"primary"}
        disabled={isSaveDisabled}
        onClick={() => {
          onUpdateAsset();
        }}
        overrides={{ BaseButton: { props: { "data-testid": `asset-save-button` } } }}
      >
        {"Save"}
      </Button>
    </Row>
  );
}
