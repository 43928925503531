/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { sortByDate, sortByNumber, sortByString } from "../Utils/Sort";
import { FileInfo, FileAttributes, NewFileInfo } from "./Api";
import { fileUploadDate } from "./ApiSerialization";

export const sortFileInfo = (files: FileInfo[], sortAscending: boolean, sortColumn: string): FileInfo[] => {
  const metadataProperty = sortColumn as keyof FileAttributes;
  switch (metadataProperty) {
    case "uploadDateUtc": {
      return sortByUploadDate(files, sortAscending);
    }
    case "sizeMbs": {
      return sortByFileSize(files, sortAscending);
    }
    case "name":
    default: {
      return sortByFileName(files, sortAscending);
    }
  }
};

export const sortByUploadDate = (files: FileInfo[], sortAscending: boolean): FileInfo[] =>
  sortByDate(files, sortAscending, (fileInfo) => fileUploadDate(fileInfo));

export const sortByFileSize = (files: FileInfo[], sortAscending: boolean): FileInfo[] =>
  sortByNumber(files, sortAscending, (fileInfo) => fileInfo.attributes["sizeMbs"]);

const sortByFileName = (files: FileInfo[], sortAscending: boolean): FileInfo[] =>
  sortByString(files, sortAscending, (fileInfo) => fileInfo.attributes["name"]);

const findByName = (name: string, files?: NewFileInfo[]): NewFileInfo | undefined =>
  files?.find((files) => files.name === name);

export const matchByName = (names?: string[], files?: NewFileInfo[]): NewFileInfo[] | undefined =>
  names?.map((name) => findByName(name, files));

export const getParentPath = (file: File): string => {
  const fullFilePath: string = file["path"];
  if (fullFilePath === undefined) {
    return undefined;
  }
  return removeFileFromPath(fullFilePath);
};

/**
 * Removes the leading "/" from the input string, if a "/" exists.
 */
const removeLeadingSlash = (filePath: string): string => {
  return filePath.startsWith("/") ? filePath.substring(1) : filePath;
};

// TODO: Think of a better name. It is not clear that the front slash is being removed.
export const removeFileFromPath = (fullFilePath: string): string => {
  const pathWithoutLeadingSlash = removeLeadingSlash(fullFilePath);
  const splitFilePath = pathWithoutLeadingSlash.split("/");

  if (splitFilePath === undefined || splitFilePath.length < 2) {
    return "";
  } else {
    const fileNameStartIndex = fullFilePath.lastIndexOf("/");
    if (fullFilePath.startsWith("/")) {
      return fullFilePath.substring(1, fileNameStartIndex);
    } else {
      return fullFilePath.substring(0, fileNameStartIndex);
    }
  }
};
