query GetNumericUnitSchema {
  getNumericUnitSchema {
    types {
      unitType 
      measureOptions {
        abbreviation
        descriptiveName
      }
    }
  }
}
