/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { BulkAddModelModal } from "./BulkAddModelModal";
import { PLACEMENT } from "baseui/popover";
import { OverflowActions } from "../Shared/OverflowMenu";
import { useHistory } from "react-router-dom";
import {
  modelDetailsCreatePagePath,
  platformDetailsCreatePagePath,
  scenarioDetailsCreatePagePath,
  studyDetailsCreatePagePath,
} from "../Shared/NavigationLinks";
import { BulkAddFileModal } from "./BulkAddFileModal";
import { ButtonDropdown } from "../Shared/Buttons";
import { BulkAddPlatformModal } from "./BulkAddPlatformModal";

export function AddDropdown(): JSX.Element {
  const history = useHistory();
  const [bulkAddFileModalIsOpen, setBulkAddFileModalIsOpen] = React.useState<boolean>(false);
  const itemSelectActions: OverflowActions[] = [
    {
      label: "File",
      onClick: () => {
        setBulkAddFileModalIsOpen(true);
      },
    },
    {
      label: "Model",
      onClick: () => history.push(modelDetailsCreatePagePath()),
    },
    {
      label: "Platform",
      onClick: () => history.push(platformDetailsCreatePagePath()),
    },
    {
      label: "Scenario",
      onClick: () => history.push(scenarioDetailsCreatePagePath()),
    },
    {
      label: "Study",
      onClick: () => history.push(studyDetailsCreatePagePath()),
    },
    { label: "Other", disabled: true },
  ];

  return (
    <>
      <ButtonDropdown
        buttonContent={"Add"}
        menuActions={itemSelectActions}
        popoverPlacement={PLACEMENT.bottomLeft}
        listProperties={{ height: "150px", width: "138px" }}
        dataTestId={"add-resource-button"}
      />
      <BulkAddFileModal isOpen={bulkAddFileModalIsOpen} onClose={() => setBulkAddFileModalIsOpen(false)} />
    </>
  );
}

export function BulkAddButton({ refetchQueryName }: { refetchQueryName: string }): JSX.Element {
  const [bulkAddFilesAsModelsIsOpen, setBulkAddFilesAsModelsIsOpen] = React.useState(false);
  const [bulkImportTa3DbExportIsOpen, setBulkImportTa3DbExportIsOpen] = React.useState(false);

  const itemSelectActions: OverflowActions[] = [
    {
      label: "Files as models",
      onClick: () => {
        setBulkAddFilesAsModelsIsOpen(true);
      },
    },
    {
      label: "TA 3 Db Export",
      onClick: () => {
        setBulkImportTa3DbExportIsOpen(true);
      },
    },
  ];

  return (
    <>
      <ButtonDropdown
        menuActions={itemSelectActions}
        popoverPlacement={PLACEMENT.bottomRight}
        buttonContent={"Bulk Add"}
        dataTestId={"bulk-add-button"}
      />
      <BulkAddModelModal
        isOpen={bulkAddFilesAsModelsIsOpen}
        dialogTitle="Bulk add models from files"
        dialogDescription="Choose files to upload. Each file will become a new model."
        endpointName="/bulkAddModels"
        refetchQueryName={refetchQueryName}
        onClose={() => setBulkAddFilesAsModelsIsOpen(false)}
      />
      <BulkAddPlatformModal
        isOpen={bulkImportTa3DbExportIsOpen}
        dialogTitle="Import TA 3 model DB export"
        dialogDescription="Choose a TA 3 database export .json file. Each platform in the export will become a new model."
        endpointName="/bulkAddTa3Db"
        refetchQueryName={refetchQueryName}
        allowMultipleFiles={false}
        fileTypeFilters={[".json"]}
        onClose={() => setBulkImportTa3DbExportIsOpen(false)}
      />
    </>
  );
}
