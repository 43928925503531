/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, KIND } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { HeadingSmall } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { Input } from "baseui/input";
import { useStyletron } from "baseui";
import { Card, StyledBody } from "baseui/card";
import addSelectMultipleField from "../Api/Gql/AddSelectMultipleField.gql";
import { MetadataSchema, PickListSchema, PickListValueOption } from "Api";
import { handleApolloError } from "../Shared/Errors";
import { notify } from "../Shared/Notify";
import { PickListSchemaOptionEditor } from "./PickListSchemaOptionEditor";
import { CommandBar } from "../DesignSystem/CommandBar";

import { ModelFieldImporterModal, ModelFieldImporterModal } from "./ModelFieldImporter";
import { EndAnchoredRow } from "../DesignSystem/Containers";
import { isExpandedSchema } from "../Asset/Editor/AssetMetadataFields";
import { MODEL_FIXED_SCHEMA_FIELDS } from "../Model/ModelDetailsState";

const emptyValueOption: PickListValueOption = {
  value: "",
  description: "",
};

export const newPickListSchema = (
  valueOptions = [emptyValueOption, emptyValueOption],
  attributeName = ""
): PickListSchema => {
  return JSON.parse(
    JSON.stringify({
      id: undefined,
      attributeName: attributeName,
      // Show two empty values to give the user an indication of multiplicity.
      valueOptions: valueOptions,
    })
  );
};

const PickListSchemaEditor = ({
  currentSchema,
  onSave,
}: {
  currentSchema: MetadataSchema;
  onSave: () => void;
}): JSX.Element => {
  const [css] = useStyletron();

  const [pickListSchema, setPickListSchema] = useState<PickListSchema>(newPickListSchema());

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const [addSelectMultipleFieldMutation] = useMutation(gql(addSelectMultipleField), {
    onCompleted: () => {
      notify.positive("Successfully saved new model field");
      setPickListSchema(newPickListSchema());
      onSave();
    },
    onError: (error) => handleApolloError(error, "Error saving new model field."),
  });

  const handleSave = (): void => {
    addSelectMultipleFieldMutation({
      variables: { pickListSchema: pickListSchema },
    });
  };

  const updateValueOption = (index: number, value?: string, description?: string): void => {
    const newPickListSchema = { ...pickListSchema };
    const updatedValue = value !== undefined ? value : newPickListSchema.valueOptions[index].value;
    const updatedDescription =
      description !== undefined ? description : newPickListSchema.valueOptions[index].description;

    newPickListSchema.valueOptions[index] = {
      value: updatedValue,
      description: updatedDescription,
    };
    setPickListSchema(newPickListSchema);
  };

  const addNewEmptyValueOption = (insertIndex: number): void => {
    const newPickListSchema = { ...pickListSchema };
    newPickListSchema.valueOptions.splice(insertIndex, 0, {
      value: "",
      description: "",
    });
    setPickListSchema(newPickListSchema);
  };

  const removeValueOption = (index: number): void => {
    if (index > -1) {
      const newPickListSchema = { ...pickListSchema };
      newPickListSchema.valueOptions.splice(index, 1);
      setPickListSchema(newPickListSchema);
    }
  };

  const setAttributeName = (name: string): void => {
    const newPickListSchema = { ...pickListSchema };
    newPickListSchema.attributeName = name;
    setPickListSchema(newPickListSchema);
  };

  const isNameInvalid = React.useMemo(() => {
    return isExpandedSchema(MODEL_FIXED_SCHEMA_FIELDS, pickListSchema.attributeName, currentSchema);
  }, [currentSchema, pickListSchema]);

  return (
    <>
      <ModelFieldImporterModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onUpload={setPickListSchema}
      />
      <Card
        overrides={{
          Root: { style: { width: "700px" } },
        }}
      >
        <EndAnchoredRow>
          <HeadingSmall marginTop="1rem" marginBottom="2.5rem">
            {"Custom dropdown field"}
          </HeadingSmall>
          <CommandBar>
            <Button kind={KIND.tertiary} onClick={() => setPickListSchema(newPickListSchema())}>
              Cancel
            </Button>
            <Button disabled={isNameInvalid} onClick={() => handleSave()}>
              Save
            </Button>
          </CommandBar>
        </EndAnchoredRow>
        <StyledBody>
          <div
            className={css({
              whiteSpace: "nowrap",
            })}
          >
            <FormControl
              label={() => "Field Label"}
              caption={() => (
                <StyledLink onClick={() => setModalIsOpen(true)} style={{ cursor: "pointer" }}>
                  {"Add options from file"}
                </StyledLink>
              )}
              error={isNameInvalid ? `That name is already used; please pick another.` : null}
            >
              <Input
                id="input-id"
                value={pickListSchema.attributeName}
                onChange={(event) => setAttributeName(event.currentTarget.value)}
                error={isNameInvalid}
              />
            </FormControl>
          </div>
          {pickListSchema.valueOptions.map((option, index) => {
            return (
              <React.Fragment key={index}>
                <PickListSchemaOptionEditor
                  position={index}
                  option={option}
                  onOptionUpdated={updateValueOption}
                  onAddNewOption={addNewEmptyValueOption}
                  onRemoveOption={removeValueOption}
                />
                <br />
              </React.Fragment>
            );
          })}
        </StyledBody>
      </Card>
    </>
  );
};

export default PickListSchemaEditor;
