/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { LabelLarge } from "baseui/typography";
import * as React from "react";
import { WizardNavigationMap } from "./WizardComponents";
import WizardSummaryDivider from "jsx:./wizard-summary-divider.svg";
import { ScenarioSpec, ScenarioWizardStep, toScenarioInput } from "./ScenarioSpec";
import { DescribeMissionStep } from "./DescribeMissionStep";
import { Textarea } from "baseui/textarea";
import { FormControl } from "baseui/form-control";
import { DefineOutlineStep } from "./DefineOutlineStep";
import { Input } from "baseui/input";
import { ChooseMeasuresStep } from "./ChooseMeasuresStep";
import { PickModelsStep } from "./PickModelsStep";
import { RefineScenarioStep } from "./RefineScenarioStep";

const orderedScenarioSteps: ScenarioWizardStep[] = [
  "Describe Mission",
  "Define Outline",
  "Choose Measures",
  "Pick Models",
  "Refine Scenario",
];

export function ScenarioWizard(): JSX.Element {
  const [scenarioSpec, setScenarioSpec] = React.useState(new ScenarioSpec());

  const [currentStep, setCurrentStep] = React.useState<ScenarioWizardStep>("Describe Mission");

  const handleSpecUpdated = React.useCallback((scenarioSpec: ScenarioSpec) => {
    const scenarioSpecWithInput: ScenarioSpec = {
      ...scenarioSpec,
      scenarioInput: toScenarioInput(scenarioSpec),
    };
    setScenarioSpec(scenarioSpecWithInput);
  }, []);

  const currentStepIndex = orderedScenarioSteps.indexOf(currentStep);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "3rem 3rem 3rem 3rem",
      }}
    >
      <WizardNavigationMap
        steps={orderedScenarioSteps}
        currentStep={currentStep}
        style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <div style={{ flexGrow: 1, padding: "3rem" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ScenarioStepContent
              currentStep={currentStep}
              scenarioSpec={scenarioSpec}
              onSpecUpdated={handleSpecUpdated}
              onStepChange={(newStep: ScenarioWizardStep) => setCurrentStep(newStep)}
            />
          </div>
        </div>
        <div style={{ width: "2rem" }}>
          <WizardSummaryDivider fill="gray" stroke="gray" height={"30rem"} />
        </div>
        <div style={{ width: "20vw" }}>
          <ScenarioSummary scenarioSpec={scenarioSpec} stepIndex={currentStepIndex} />
        </div>
      </div>
    </div>
  );
}

function ScenarioStepContent({
  currentStep,
  scenarioSpec,
  onSpecUpdated,
  onStepChange,
}: {
  currentStep: ScenarioWizardStep;
  scenarioSpec?: ScenarioSpec;
  onSpecUpdated: (newSpec: ScenarioSpec) => void;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  switch (currentStep) {
    case "Describe Mission":
      return (
        <DescribeMissionStep scenarioSpec={scenarioSpec} onSpecUpdated={onSpecUpdated} onStepChange={onStepChange} />
      );
    case "Define Outline":
      return (
        <DefineOutlineStep scenarioSpec={scenarioSpec} onSpecUpdated={onSpecUpdated} onStepChange={onStepChange} />
      );
    case "Choose Measures":
      return (
        <ChooseMeasuresStep scenarioSpec={scenarioSpec} onSpecUpdated={onSpecUpdated} onStepChange={onStepChange} />
      );
    case "Pick Models":
      return <PickModelsStep scenarioSpec={scenarioSpec} onSpecUpdated={onSpecUpdated} onStepChange={onStepChange} />;
    case "Refine Scenario":
      return <RefineScenarioStep scenarioSpec={scenarioSpec} onStepChange={onStepChange} />;
  }
}

function ScenarioSummary({ scenarioSpec, stepIndex }: { scenarioSpec: ScenarioSpec; stepIndex: number }): JSX.Element {
  const taskText = React.useMemo(
    () => scenarioSpec?.tasks?.map((task) => `- ${task.title}`).join("\n") ?? "",
    [scenarioSpec?.tasks]
  );

  const measureText = React.useMemo(
    () => scenarioSpec?.measures?.map((measure) => `- ${measure.description}`).join("\n") ?? "",
    [scenarioSpec?.measures]
  );

  if (stepIndex === 0) {
    return (
      <LabelLarge $style={{ paddingTop: "4rem" }}>
        {`SMR will give you suggestions for helping to define the scenario. Keeping your mission description concise and to the point will help give you the best suggestions.`}
      </LabelLarge>
    );
  }

  return (
    <>
      {stepIndex >= 1 && (
        <>
          <FormControl label={`Mission:`}>
            <Textarea disabled value={scenarioSpec.missionDescription ?? ""} />
          </FormControl>
        </>
      )}
      {stepIndex >= 2 && (
        <>
          <FormControl label={`Location:`}>
            <Input disabled value={scenarioSpec?.location ?? "Automatic"} />
          </FormControl>
          <FormControl label={`Task:`}>
            <Textarea disabled value={taskText} />
          </FormControl>
        </>
      )}
      {stepIndex >= 3 && (
        <>
          <FormControl label={`Measure:`}>
            <Textarea disabled value={measureText} />
          </FormControl>
        </>
      )}
    </>
  );
}
