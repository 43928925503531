# import "./fragments/fileFoldersData.gql"

mutation RescanScenarioFiles($scenarioId: UUID!) {
  rescanScenarioFiles(scenarioId: $scenarioId) {
    updatedScenario {
      id
      creationDate
      lastModifiedDate
      metadata {
        title
        version
        description
        keywords
        notes
        customProps
      }
      fileFolders {
        __typename
        ...fileFoldersData
      }
    }
    hasChanged
  }
}
