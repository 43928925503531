# import "./fragments/securityMarkingsData.gql"

query FindAssets($querySettings: QuerySettingsInput, $filter: SearchSpecInput) {
  findAssets(querySettings: $querySettings, filter: $filter) {
    pageCount
    results {
      id
      type
      title
      version
      description
      creationDate
      lastModifiedDate
      securityMarkings {
        __typename
        ...securityMarkingsData
      }
    }
  }
}
