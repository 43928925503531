/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ConfigureOptions, UseAxiosResult } from "axios-hooks";
import React from "react";
import { AxiosContext } from "./AuthContext";
import { HttpPostConfig, UseAxiosPost, useHttpGet, useHttpPost } from "./UseHttp";
import { handleAxiosError } from "../Shared/Errors";
import { AxiosRequestConfig } from "axios";

export interface ResponseHandler<TResponse = any> {
  onComplete: (response: TResponse) => void;
  errorToastTitle?: string;
}

export function useSimorGet<TResponse = any, TBody = any, TError = any>(
  url: string,
  requestConfig?: AxiosRequestConfig<TBody>
): UseAxiosResult<TResponse, TBody, TError> {
  const axiosConfig = useSimorAxiosConfig();

  return useHttpGet<TResponse, TBody, TError>(axiosConfig, url, requestConfig);
}

export function useSimorPost<TResponse = any, TBody = any>(
  url: string,
  responseHandler: ResponseHandler<TResponse>
): UseAxiosPost<TBody> {
  const axiosConfig = useSimorAxiosConfig();

  const errorToastTitle = responseHandler.errorToastTitle ?? "Error contacting server";

  const postConfig: HttpPostConfig<TResponse> = {
    onComplete: responseHandler.onComplete,
    onError: (reason: any) => handleAxiosError(reason, errorToastTitle),
  };

  return useHttpPost(axiosConfig, url, postConfig);
}

function useSimorAxiosConfig(): ConfigureOptions {
  const axiosContext = React.useContext(AxiosContext);
  return {
    axios: axiosContext,
  };
}
