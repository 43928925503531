import { debounce } from 'lodash';
import React from 'react';
import { SafesimPopover, SafesimButton } from './allSafesimComponents';
import { SafesimControlGroup } from './SafesimControlGroup';

export const debounceFunction = (func) => debounce(func, 1000);

/**
 * @param {boolean} isOpen Boolean determining the open state of the popover. true if open.
 * @param {boolean} isEdit Boolean for the edit state of the popover. If false, edit icon is shown. If true, tick/cross icons for accepting edits are shown
 * @param {string} placement the placement of the popover using Blueprint popover placement values
 * @param {function} onEditClicked callback for when the edit icon is clicked
 * @param {function} onConfirmChanges callback for when the tick (check) icon is clicked
 * @param {function} onRejectChanges callback for when the cross icon is clicked
 * @param {boolean} confirmDisabled whether the tick button should be disabled, usually used when something is invalid
 * @param {*} children The children of the popover. Does not need to be passed via props.
 * @returns Popover with tick and cross buttons for confirming/rejecting changes.
 */
export const SafesimConfirmRejectPopover = (props) => {
  const { isOpen, isEdit, placement = 'bottom-end', onEditClicked, onConfirmChanges, onRejectChanges, confirmDisabled, children, ...rest } = props;

  return (
    <SafesimPopover
      isOpen={isOpen}
      minimal={true}
      placement={placement}
      enforceFocus={false} // keep focus on the input/main item
      fill={false}
      content={
        <SafesimControlGroup>
          {!isEdit ? (
            <SafesimButton icon={'edit'} onClick={onEditClicked} />
          ) : (
            <>
              <SafesimButton icon={'tick'} onClick={onConfirmChanges} disabled={confirmDisabled} />
              <SafesimButton icon={'cross'} onClick={onRejectChanges} outlined={true} />
            </>
          )}
        </SafesimControlGroup>
      }
      {...rest}
    >
      {children}
    </SafesimPopover>
  );
};
