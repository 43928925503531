/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useState } from "react";
import { FileUploader } from "baseui/file-uploader";
import { notify, notifyBatchResponse } from "../Shared/Notify";
import { ClassificationSummary } from "../Shared/SecurityMarkingsEditor";
import MetadataPropsEditor from "../Asset/Editor/MetadataPropsEditor";
import {
  AssetType,
  BulkAddModelResponse,
  BulkModelUpload,
  ElementUploadFailure,
  ElementUploadSuccess,
  SecurityMarkings,
} from "../Api/Api";
import { useApolloClient } from "@apollo/client";
import { KEYWORDS_FIELD } from "../Asset/Editor/AssetMetadataFields";
import { useMarkingsDisableSave } from "../Shared/ApiHooks";
import { FileUploadPreview, UploadCancelModal } from "../Shared/UploadCancelModal";
import { useSimorPost } from "../Utils/UseSimor";

const getAllFailedMessage = (failedElements?: ElementUploadFailure[]): string => {
  const errorMessages = failedElements?.map((element) => element.failureMessage) ?? [];
  const uniqueErrorMessages = [...new Set(errorMessages)].join(", ");
  return `Failed to upload all files because of ${uniqueErrorMessages}.`;
};

const getPartialSuccessMessage = (
  failedElements: ElementUploadFailure[],
  successfulElements?: ElementUploadSuccess[],
  duplicateElements?: string[]
): string => {
  // TODO: make error messages use assetType to be more informative
  const failedFileNames = failedElements.map((element) => element.uploadedFileName).join(", ");

  const successfulAddCount = successfulElements?.length ?? 0;
  if (duplicateElements) {
    return `Added ${successfulAddCount} assets from files; ${duplicateElements.length} files were previously uploaded. Could not upload ${failedFileNames}.`;
  } else {
    return `Added ${successfulAddCount} assets from files. Could not upload ${failedFileNames}.`;
  }
};

const getSingleSuccessMessage = (successfulElement: ElementUploadSuccess): string => {
  return `Added new asset from ${successfulElement.uploadedFileName}`;
};

const getFewSuccessMessage = (successfulElements: ElementUploadSuccess[]): string => {
  const successfulFileNames = getUniqueSuccessfulFilenames(successfulElements);
  const lengthToReport = successfulElements.length == successfulFileNames.length ? "" : successfulElements.length + " ";
  return `Added ${lengthToReport} assets from ${successfulFileNames.join(", ")}`;
};

const getManySuccessMessage = (successfulElements: ElementUploadSuccess[]): string => {
  const successfulFileNames = getUniqueSuccessfulFilenames(successfulElements);
  const filenamesToReport = successfulFileNames.length < 3 ? successfulFileNames.join(", ") : "files";

  return `Added ${successfulElements.length} assets from ${filenamesToReport}.`;
};

const getSuccessMessageManyDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElements: string[]
): string => {
  return `Added ${successfulElements.length} asset(s) from files; ${duplicateElements.length} files were previously uploaded.`;
};

const getSuccessMessageSingleDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElement: string
): string => {
  return `Added ${successfulElements.length} asset(s) from files. ${duplicateElement} was previously uploaded.`;
};

const notifyBulkAddResponse = (bulkAddResponse: BulkAddModelResponse): void => {
  const { successfulUploads, failedUploads, operationError } = bulkAddResponse;

  const duplicateUploads = successfulUploads
    ?.filter((success) => success.isDuplicate)
    .map((success) => success.uploadedFileName);

  if (operationError) {
    notify.serverError(`Bulk upload could not start because ${operationError}`);
  } else {
    notifyBatchResponse(
      successfulUploads,
      duplicateUploads,
      failedUploads,
      getAllFailedMessage,
      getPartialSuccessMessage,
      getSingleSuccessMessage,
      getFewSuccessMessage,
      getManySuccessMessage,
      getSuccessMessageManyDuplicate,
      getSuccessMessageSingleDuplicate
    );
  }
};

export const BulkAddAssetModal = ({
  isOpen,
  dialogTitle,
  dialogDescription,
  endpointName,
  refetchQueryName,
  allowMultipleFiles = true,
  fileTypeFilters,
  onClose,
  assetType,
}: {
  isOpen: boolean;
  dialogTitle: string;
  dialogDescription: string;
  endpointName: string;
  refetchQueryName: string;
  allowMultipleFiles?: boolean;
  fileTypeFilters?: string[];
  onClose: () => void;
  assetType: AssetType;
}): JSX.Element => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [securityMarkings, setSecurityMarkings] = useState<SecurityMarkings>();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const apolloContext = useApolloClient();

  const clearSelectedFiles = React.useCallback(() => {
    setFilesToUpload([]);
  }, []);

  const closeModal = React.useCallback(() => {
    setKeywords([]);
    onClose();
  }, [onClose]);

  const [{ progress: _progress }, doBulkAdd, _cancelRequest] = useSimorPost<BulkAddModelResponse>(endpointName, {
    onComplete: (data) => {
      clearSelectedFiles();
      setSecurityMarkings(undefined);
      closeModal();
      notifyBulkAddResponse(data);
      apolloContext.refetchQueries({ include: [refetchQueryName] });
    },
    errorToastTitle: "Error creating models",
  });

  const startFileUpload = React.useCallback(() => {
    const formData = new FormData();

    const bulkUploadManifest: BulkModelUpload = {
      fileNames: filesToUpload.map((file) => file.name),
      metadata: { keywords: keywords },
      securityMarkings: securityMarkings,
    };

    const formName = (() => {
      switch (assetType) {
        case "MODEL":
          return "BulkModelUpload";
        case "PLATFORM":
          return "BulkPlatformUpload";
        default:
          return "";
      }
    })();

    formData.append(formName, JSON.stringify(bulkUploadManifest));
    filesToUpload.forEach((file, index) => {
      formData.append(`${index}`, file, file.name);
    });

    doBulkAdd({ data: formData });
  }, [filesToUpload, keywords, securityMarkings, assetType, doBulkAdd]);

  const isUploadDisabled = useMarkingsDisableSave(securityMarkings) || filesToUpload.length == 0;

  return (
    <UploadCancelModal
      title={dialogTitle}
      isOpen={isOpen}
      onClose={closeModal}
      isUploadDisabled={isUploadDisabled}
      startFileUpload={startFileUpload}
    >
      <MetadataPropsEditor
        fields={[KEYWORDS_FIELD]}
        metadataValues={{ keywords: keywords }}
        onValueChange={(_, value) => {
          setKeywords(value);
        }}
      />
      <div>
        <ClassificationSummary
          securityMarkings={securityMarkings}
          onMarkingsChanged={(newSecurityMarking) => setSecurityMarkings(newSecurityMarking)}
        />
      </div>
      {dialogDescription}
      {filesToUpload.length > 0 ? (
        <FileUploadPreview files={filesToUpload} onCancel={clearSelectedFiles} />
      ) : (
        <FileUploader
          multiple={allowMultipleFiles}
          accept={fileTypeFilters}
          onCancel={clearSelectedFiles}
          onDrop={async (acceptedFiles, _) => {
            setFilesToUpload(acceptedFiles);
          }}
        />
      )}
    </UploadCancelModal>
  );
};

function getUniqueSuccessfulFilenames(successfulElements: ElementUploadSuccess[]): string[] {
  return successfulElements
    .map((element) => element.uploadedFileName)
    .filter((fileName, index, arr) => arr.findIndex((name) => name == fileName) == index);
}
