/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { DatePicker } from "baseui/datepicker";
import React from "react";
import { utcTextToDate, dateToUtcText } from "../../Api/ApiSerialization";

const DATE_TIME_DISPLAY_FORMAT = "MM/dd/yyyy kk:mm:ss";

export const DateEditor = ({
  value,
  disabled,
  onChange,
}: {
  // Dates are referenced as formatted strings in the data and converted to objects
  // at the edges for editing and comparison
  value: string;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  const valueAsDateTime = utcTextToDate(value);

  function handleDateChanged(date: Date): void {
    if (onChange) {
      onChange(dateToUtcText(date));
    }
  }

  return (
    <DatePicker
      value={valueAsDateTime?.toJSDate()}
      formatString={DATE_TIME_DISPLAY_FORMAT}
      onChange={({ date }) => handleDateChanged(date as Date)}
      disabled={disabled}
      timeSelectStart
    />
  );
};
