/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";

import { PendingWorkSection } from "./PendingWorkSection";
import { SearchSpecInput } from "Api";

const assetsWithoutKeywordsFilter: SearchSpecInput = {
  keywordFilter: {
    onlyIncludeResourcesWithoutKeywords: true,
  },
};

export const AssetsMissingKeywords = (): JSX.Element => {
  return <PendingWorkSection filter={assetsWithoutKeywordsFilter} />;
};
