/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { FieldSpec } from "../Asset/Editor/FieldSpec";

export const FILE_FIXED_SCHEMA_FIELDS: FieldSpec[] = [];
