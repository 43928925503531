# import "./fragments/securityMarkingsData.gql"
# import "./fragments/relatedScenarioData.gql"
# import "./fragments/relatedModelData.gql"
# import "./fragments/fileFoldersData.gql"
# import "./fragments/relatedPlatformData.gql"

mutation UpdateModel($model: ModelInfoInput!) {
  updateModel(model: $model) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      description
      version
      subject
      keywords
      notes
      simulationFrameworks
      customProps
      imageId
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
    relatedModels {
      __typename
      ...relatedModelData
    }
    relatedScenarios {
      __typename
      ...relatedScenarioData
    }
    relatedPlatforms {
      __typename
      ...relatedPlatformData
    }
  }
}
