/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { TaskListSummary } from "Api";
import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import * as React from "react";
import { TextEntry } from "../../Utils/TableCells";
import { Uuid } from "../../Utils/Types";
import { EndAnchoredRow } from "../../DesignSystem/Containers";
import { OverflowMenu } from "../../Shared/OverflowMenu";

export function TaskListTable({
  taskLists,
  selectedId,
  onClick,
  onDelete,
}: {
  taskLists: TaskListSummary[];
  selectedId: Uuid | undefined;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, dataId: Uuid) => void;
  onDelete: (id: Uuid, title: string) => void;
}): JSX.Element {
  const styleOverrides = React.useMemo(() => {
    return {
      Table: {
        style: () => ({
          boxSizing: "content-box",
        }),
      },
      TableBodyRow: {
        style: ({ $theme, $rowIndex }: any) => ({
          backgroundColor:
            taskLists[$rowIndex].id === selectedId
              ? $theme.colors.selectionBackgroundPrimary
              : $theme.colors.backgroundPrimary,
          ":hover": {
            backgroundColor:
              taskLists[$rowIndex].id === selectedId
                ? $theme.colors.selectionBackgroundPrimaryDark
                : $theme.colors.backgroundTertiary,
          },
        }),
      },
      TableBodyCell: {
        style: () => ({
          // Baseweb adds padding automatically for aesthetics to the table data;
          // we need to rmeove it to make sure we're able to receive click events
          // for everything that appears to the user to be inside the table cell.
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "0",
          paddingLeft: "0",
        }),
      },
    };
  }, [taskLists, selectedId]);

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale3200,
    paddingBottom: theme.sizing.scale600,
  });

  // TODO: This would probably look nicer as a Baseweb List
  return (
    <TableBuilder data={taskLists} overrides={styleOverrides}>
      <TableBuilderColumn id="name" header="Name" sortable>
        {(row: TaskListSummary) => (
          <EndAnchoredRow>
            <TextEntry
              text={`${row.name} (${row.taskCount})`}
              styleClass={cellStyle}
              dataId={row.id}
              onClick={onClick}
            ></TextEntry>
            <OverflowMenu
              overflowActions={[
                {
                  label: "Delete Task List",
                  onClick: () => onDelete(row.id, row.name),
                },
              ]}
            />
          </EndAnchoredRow>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
}
