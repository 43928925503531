# import "./fragments/securityMarkingsData.gql"
# import "./fragments/userData.gql"

query GetFiles($ids: [UUID!]!) {
  getFiles(ids: $ids) {
    id
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    attributes {
      name
      uploadDateUtc
      sizeMbs
      uploadedBy {
        __typename
        ...userData
      }
    }
    metadataScans {
      id
      scanResult
      scanMetadata
      analyzedFileType
    }
  }
}
