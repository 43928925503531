/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Kobayashi Maru "Dark" theme from the Air Force, v1.4.
//
// Page 4 of the design standard states that both Light and Dark
// themed sites should use the same color and supporting colors
// from the Dark theme.
//
// https://software.af.mil/softwarefactory/kobayashi-maru/

import { topBarAppHeaderColor, KmDarkTheme } from "./KmDarkTheme";

// Note: Not all colors from a theme are defined here from a theme.
// This is meant to contain the colors that are likely to be used
// in a header.

export const KmTopAppBar = {
  // Foundational
  headerColor: topBarAppHeaderColor,
  defaultTextColor: KmDarkTheme.colors.defaultTextColor,
  secondaryTextColor: KmDarkTheme.colors.secondaryTextColor,

  // Primary Blue
  primaryBlue: KmDarkTheme.colors.primaryBlue,
  primaryLight: KmDarkTheme.colors.primaryLight,
  primaryDark: KmDarkTheme.colors.primaryDark,
  primaryElementFontColor: KmDarkTheme.colors.primaryElementFontColor,

  // Links
  linkColor: KmDarkTheme.colors.linkColor,

  // Buttons (p.14)
  buttonPrimaryHover: KmDarkTheme.colors.primaryLight,

  // Tabs (p.58)
  selectedTabColor: KmDarkTheme.colors.primaryBlue,
};
