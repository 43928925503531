/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { UploadStatus } from "./FileUpload";
import { NewFileInfo, ProtectedUrl } from "Api";
import { Alert } from "baseui/icon";
import { SIZE, Spinner } from "baseui/spinner";
import { OverflowActions, OverflowMenu } from "../../Shared/OverflowMenu";
import { RedactedText } from "../../Shared/RedactedElements";
import { TreeView, TreeLabelInteractable, TreeNodeData } from "baseui/tree-view";
import { EndAnchoredRow } from "../../DesignSystem/Containers";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { Input } from "baseui/input";
import {
  FileNode,
  FileTreeNode,
  FolderNode,
  isRootNode,
  AssetFileTreeAction,
  ROOT_FILE_TREE_NODE_ID,
} from "./AssetFileTreeState";
import AddFilesModal from "./AddFilesModal";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { BlockProps } from "baseui/block";
import { themedUseStyletron as useStyletron } from "../../DesignSystem/LightTheme";
import { fileDetailsPagePath } from "../../Shared/NavigationLinks";
import { useHistory } from "react-router-dom";
import { AxiosContext } from "../../Utils/AuthContext";
import { handleAxiosError } from "../../Shared/Errors";
import { SiteContext } from "../../Utils/SiteProps";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { AddExistingFilesModal } from "./AddExistingFilesModal";

const itemProps: BlockProps = {
  height: "scale1000",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
};

const narrowItemProps: BlockProps = {
  height: "scale1000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overrides: {
    Block: {
      style: {
        width: "3rem",
        flexGrow: 0,
      },
    },
  },
};

const FileUploadLabel = ({
  fileInfo,
  status,
  redacted,
}: {
  fileInfo: NewFileInfo;
  status?: UploadStatus;
  redacted: boolean;
}): JSX.Element => {
  return (
    <div style={{ display: "flex" }}>
      {status !== UploadStatus.SUCCESSFUL &&
        (status === UploadStatus.IN_PROGRESS ? <Spinner $size={SIZE.small} /> : <Alert color="red" size={30} />)}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <LabelMedium paddingLeft={"1rem"}>{redacted ? <RedactedText /> : fileInfo?.name}</LabelMedium>
      </div>
    </div>
  );
};

const FileRow = ({
  fileNode,
  dispatch,
}: {
  fileNode: FileNode;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element => {
  const file = fileNode.file;
  const history = useHistory();
  const siteProps = React.useContext(SiteContext);
  const axiosContext = React.useContext(AxiosContext);

  const handleClick = (): void => {
    if (!fileNode.isSelected && !file.redacted) {
      dispatch(["selectFileTreeNode", fileNode]);
    }
  };

  const handleDownload = React.useCallback(async () => {
    const fileId = file.info.id;

    try {
      const downloadFileTokenResponse = await axiosContext.get(`/downloadFileToken?fileId=${fileId}`);
      const protectedUrl: ProtectedUrl = downloadFileTokenResponse.data;
      window.location.href = `${siteProps.topUrl}${protectedUrl.urlFragment}`;
    } catch (error) {
      handleAxiosError(error, `Could not get secure download token for file ${fileId}`);
    }
  }, [axiosContext, file.info.id, siteProps.topUrl]);

  const fileOverflowActions = React.useMemo<OverflowActions[]>(() => {
    return [
      {
        label: "Edit details",
        onClick: () => history.push(fileDetailsPagePath(fileNode.file?.info.id)),
      },
      {
        label: "Remove",
        onClick: () => dispatch(["deleteFileTreeNode", fileNode]),
      },
      {
        label: "Download",
        onClick: () => handleDownload(),
      },
    ];
  }, [history, fileNode, dispatch, handleDownload]);

  // TODO: Make this a color constant. Add to theme.
  const backgroundColor = fileNode.isSelected ? "rgba(222, 236, 249, 1)" : undefined;

  // TODO: Componentize the flex grid and its contents if possible.
  return (
    <div style={{ backgroundColor: backgroundColor, width: "100%", overflow: "hidden" }}>
      <TreeLabelInteractable>
        <EndAnchoredRow style={{ alignItems: "center" }}>
          <StatefulTooltip
            placement={PLACEMENT.bottomRight}
            ignoreBoundary={true}
            onMouseEnterDelay={500}
            content={() => (
              <LabelXSmall maxWidth={"10rem"} color={"white"}>
                <div style={{ wordBreak: "break-all" }}>{file?.redacted ? <RedactedText /> : file?.info?.name}</div>
              </LabelXSmall>
            )}
          >
            <div
              style={{
                width: "100%",
                cursor: "pointer",
                overflow: "hidden",
              }}
              onClick={() => handleClick()}
            >
              <FlexGrid flexGridColumnCount={1} flexGridColumnGap="scale200" flexGridRowGap="scale800">
                <FlexGridItem {...itemProps}>
                  <FileUploadLabel redacted={file?.redacted} fileInfo={file?.info} status={file?.status} />
                </FlexGridItem>
              </FlexGrid>
            </div>
          </StatefulTooltip>
          <OverflowMenu overflowActions={fileOverflowActions} disabled={file?.redacted} />
        </EndAnchoredRow>
      </TreeLabelInteractable>
    </div>
  );
};

const FolderRow = ({
  folderNode,
  dispatch,
}: {
  folderNode: FolderNode;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element => {
  const [addFilesModalIsOpen, setAddFilesModalIsOpen] = React.useState(false);
  const [addExistingFilesModalIsOpen, setAddExistingFilesModalIsOpen] = React.useState(false);
  const axiosContext = React.useContext(AxiosContext);
  const isCreatingAsset = !window.location.href.endsWith("create");

  const folderOverflowActions = React.useMemo<OverflowActions[]>(() => {
    const folderActions = [
      {
        label: "Add folder",
        onClick: () => dispatch(["addFolder", folderNode]),
      },
      {
        label: "Add new files",
        onClick: () => setAddFilesModalIsOpen(true),
      },
      {
        label: "Add existing files",
        onClick: () => setAddExistingFilesModalIsOpen(true),
      },
    ];

    if (isCreatingAsset) {
      folderActions.push({
        label: "Download zip",
        onClick: () => {
          dispatch(["downloadZip", { folderToZip: folderNode, axiosContext: axiosContext }]);
        },
      });
    }

    if (folderNode.id != ROOT_FILE_TREE_NODE_ID) {
      folderActions.push({
        label: "Rename",
        onClick: () => dispatch(["startEditFolderName", folderNode]),
      });
      folderActions.push({
        label: "Remove",
        onClick: () => dispatch(["deleteFileTreeNode", folderNode]),
      });
    }
    return folderActions;
  }, [axiosContext, dispatch, folderNode, isCreatingAsset]);

  const handleFolderNameEdit = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch(["editFolderName", { editedFolder: folderNode, newTitle: event.currentTarget.value }]);
    },
    [dispatch, folderNode]
  );

  const handleKeyDown = React.useCallback(
    (keyEvent: React.KeyboardEvent): void => {
      if (keyEvent.key === "Enter") {
        dispatch(["commitFolderEdit", folderNode]);
      } else if (keyEvent.key === "Escape") {
        dispatch(["cancelFolderEdit", folderNode]);
      }
    },
    [dispatch, folderNode]
  );

  const handleClick = (): void => {
    if (!folderNode.isSelected) {
      dispatch(["selectFileTreeNode", folderNode]);
    }
  };

  const handleBlur = React.useCallback(() => {
    dispatch(["commitFolderEdit", folderNode]);
  }, [dispatch, folderNode]);

  const folderIcon = new URL("../../resources/images/Folder.svg", import.meta.url);

  const [css, theme] = useStyletron();

  // TODO: Componentize the flex grid and its contents if possible.
  return (
    <div
      className={css({
        borderColor: folderNode.isSelected ? theme.colors.selectionBackgroundPrimary : undefined,
        width: "100%",
      })}
    >
      <TreeLabelInteractable>
        <EndAnchoredRow style={{ alignItems: "center" }}>
          {folderNode.isEditing ? (
            <Input
              value={folderNode.newTitle}
              onChange={handleFolderNameEdit}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          ) : isRootNode(folderNode) ? (
            <div style={{ width: "100%", cursor: "pointer" }} onClick={() => handleClick()}>
              <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale800">
                <FlexGridItem {...narrowItemProps}>
                  <img style={{ width: "1.1rem" }} src={folderIcon.toString()} />
                </FlexGridItem>
                <FlexGridItem {...itemProps}>
                  <LabelMedium>{folderNode.title}</LabelMedium>
                </FlexGridItem>
              </FlexGrid>
            </div>
          ) : (
            <div style={{ width: "100%", cursor: "default" }}>
              <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale800">
                <FlexGridItem {...narrowItemProps}>
                  <img style={{ width: "1.1rem" }} src={folderIcon.toString()} />
                </FlexGridItem>
                <FlexGridItem {...itemProps}>
                  <LabelMedium>{folderNode.title}</LabelMedium>
                </FlexGridItem>
              </FlexGrid>
            </div>
          )}

          <OverflowMenu overflowActions={folderOverflowActions} btnTestId={`overflow_${folderNode.title}`} />
        </EndAnchoredRow>
        <AddExistingFilesModal
          isOpen={addExistingFilesModalIsOpen}
          dispatch={dispatch}
          onClose={() => setAddExistingFilesModalIsOpen(false)}
          folderNode={folderNode}
        />
        <AddFilesModal
          isOpen={addFilesModalIsOpen}
          folderNode={folderNode}
          dispatch={dispatch}
          onClose={() => setAddFilesModalIsOpen(false)}
        />
      </TreeLabelInteractable>
    </div>
  );
};

const TreeRow = ({
  node,
  dispatch,
}: {
  node: FileTreeNode;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element => {
  return node.type === "File" ? (
    <FileRow fileNode={node} dispatch={dispatch} />
  ) : (
    <FolderRow folderNode={node} dispatch={dispatch} />
  );
};

// TODO: Cleanup
function createBasewebNode(
  node: FileTreeNode,
  dispatch: React.Dispatch<AssetFileTreeAction>,
  parentPath?: string
): TreeNodeData<FileTreeNode> {
  let pathSoFar = "";
  if (node.type === "Folder" && node.id !== "ROOT_FILE_TREE_NODE") {
    pathSoFar = `${parentPath}/${node.title}`;
  }

  const value: TreeNodeData<FileTreeNode> = {
    ...node,
    info: node,
    // eslint-disable-next-line react/display-name
    label: (node) => <TreeRow node={node.info} dispatch={dispatch} />,
    children:
      node.type === "Folder" ? node.children?.map((child) => createBasewebNode(child, dispatch, pathSoFar)) : null,
  };

  if (value.info?.type === "Folder") {
    value.info.path = value.info.id === "ROOT_FILE_TREE_NODE" ? "/" : `${parentPath}/${value.info?.title}`;
  }

  return value;
}

export const AssetFileTree = ({
  root,
  dispatch,
}: {
  root?: FolderNode;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element => {
  const assetFileTreeData = React.useMemo((): TreeNodeData[] => {
    if (root) {
      const basewebRootNode = createBasewebNode(root, dispatch);
      return [basewebRootNode];
    } else {
      return [];
    }
  }, [root, dispatch]);

  return (
    <TreeView
      data={assetFileTreeData}
      onToggle={(node) => {
        if (node.info.type === "Folder") {
          const folderNode: FolderNode = node.info;
          dispatch(["setFolderExpanded", { folderToChange: folderNode, expanded: !folderNode.isExpanded }]);
        }
      }}
      overrides={{ Root: { props: { "data-testid": "AssetFileTree-testid" } } }}
    />
  );
};
