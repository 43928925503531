/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { AssetSummary, SecurityMarkings } from "Api";
import * as React from "react";
import { CommandBar } from "../DesignSystem/CommandBar";
import { AddDropdown, BulkAddButton } from "./AddButtons";
import { DeleteButton } from "../Shared/DeleteButton";
import { EditMarkingsButton } from "../Shared/EditMarkingsButton";
import "./AssetSearchCommandBar.css";

export const AssetSearchCommandBar = ({
  refetchQueryName,
  assetSelectionState: [selectedAssets = []],
  handleDelete,
  handleSecurityMarkingModalSave,
  children,
}: {
  refetchQueryName: string;
  assetSelectionState?: [AssetSummary[], React.Dispatch<React.SetStateAction<AssetSummary[]>>];
  handleDelete?: () => void;
  handleSecurityMarkingModalSave?: (securityMarkings: SecurityMarkings) => void;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <CommandBar>
      {
        // Centers the children property and left justifies the buttons
        // The first <li> is hidden with css and made the same width as the last.
      }
      <ul className="commandBarLayout">
        <li></li>
        <li>{children}</li>
        <li>
          <div style={{ display: "flex", flexDirection: "row", paddingTop: ".5rem", justifyContent: "flex-end" }}>
            <AddDropdown />
            {selectedAssets.length > 0 && (
              <>
                <DeleteButton handleDelete={handleDelete} />
                <EditMarkingsButton handleSecurityMarkingModalSave={handleSecurityMarkingModalSave} />
              </>
            )}
            <BulkAddButton refetchQueryName={refetchQueryName} />
          </div>
        </li>
      </ul>
    </CommandBar>
  );
};
