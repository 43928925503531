/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import * as React from "react";

export const CommandBar = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <FlexGrid flexDirection="row" flexGridColumnCount={React.Children.count(children)} flexGridColumnGap="scale700">
      {React.Children.map(children, (child, index) => {
        return <FlexGridItem key={index}>{child}</FlexGridItem>;
      })}
    </FlexGrid>
  );
};
