import { DateTime } from "luxon";

/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export const delay = (millis: number): Promise<any> => new Promise((res) => setTimeout(res, millis));

// This is the RFC3339 time format
export const toOffsetDateTimeFormat = (dateTime: DateTime): string =>
  dateTime.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true });
