/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ScenarioInput, Study, StudyInput, StudyRelations } from "Api";
import {
  AssetDetailsPageAction,
  AssetDetailsPageReducer,
  AssetDetailsState,
  resetOriginalAndEdited,
} from "../Asset/Editor/AssetDetailsState";
import { COMMON_FIXED_SCHEMA_FIELDS } from "../Asset/Editor/AssetMetadataFields";
import { FieldSpec } from "../Asset/Editor/FieldSpec";
import { Uuid, newId } from "../Utils/Types";
import { emptyStudyInput, toStudyMetadataInput } from "../Api/ApiExtensions";

// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
const STUDY_FIXED_SCHEMA_FIELDS: FieldSpec[] = [...COMMON_FIXED_SCHEMA_FIELDS];

export function newStudyDetailsState(studyId?: Uuid, copying?: boolean): AssetDetailsState<StudyInput, StudyRelations> {
  const newStudy = emptyStudyInput();
  newStudy.id = studyId ?? newId();
  return new AssetDetailsState<StudyInput, StudyRelations>("STUDY", STUDY_FIXED_SCHEMA_FIELDS, newStudy, {}, copying);
}

type ResetOriginalAndEditedStudyAction = ["resetOriginalAndEditedStudy", Study];

function resetOriginalAndEditedStudy(
  state: AssetDetailsState<StudyInput, StudyRelations>,
  study: Study
): AssetDetailsState<StudyInput, StudyRelations> {
  const studyInput: StudyInput = {
    id: study.id,
    metadata: toStudyMetadataInput(study.metadata),
    securityMarkings: study.securityMarkings,
    // Will not be used by resetOriginalAndEdited
    fileFolders: [],
  };

  return resetOriginalAndEdited<ScenarioInput, StudyRelations>(
    state,
    studyInput,
    study.creationDate,
    study.lastModifiedDate,
    study.fileFolders
  );
}

export type StudyDetailsPageAction = AssetDetailsPageAction | ResetOriginalAndEditedStudyAction;

export function StudyDetailsReducer(
  state: AssetDetailsState<StudyInput, StudyRelations>,
  action: StudyDetailsPageAction
): AssetDetailsState<StudyInput, StudyRelations> {
  const actionType = action[0];
  switch (actionType) {
    case "resetOriginalAndEditedStudy":
      return resetOriginalAndEditedStudy(state, action[1]);
    default:
      return AssetDetailsPageReducer(state, action);
  }
}
