import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { SafesimButton, SafesimNonIdealState, SafesimIcon } from './allSafesimComponents';
import { Collapse, Card } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { SafesimErrorBoundary } from '../utilities/SafesimErrorBoundaries';
import { SafesimControlGroup } from './SafesimControlGroup';

/**
 * Functional component that creates a titled card with content that can be collapsed. Essentially a SafesimCollapse with a Card wrapper
 * @param {string} className Applied to parent card of collapse
 * @param {string} buttonClassName Applied to the collapse button
 * @param {*} children Children used as the collapse content
 * @param {function} onChangeCollapsed Callback to handle opening/closing controlled collapses
 * @param {boolean} isOpen Whether the collapse is currently open, used for controlled collapses
 * @param {boolean} startOpen Whether the collapse starts open. Used for controlled or uncontrolled collapses. Default false (closed)
 * @param {string} header Title of card, displayed as collapse button or static header if caret hidden
 * @param {boolean} hideCaret Whether to hide the caret and block the ability to change the open state of the collapse
 * @param {boolean} outlined Whether the card is outlined. Default false.
 * @param {string} fallbackUITitle Optional. Title of error card when render errors occurs.
 * @param {string} fallbackUIDescription Optional. Message in error card when render error occurs.
 * @returns Card with title and collapsible content.
 */
export const SafesimCardCollapse = (props) => {
  const {
    className,
    buttonClassName,
    children,
    onChangeCollapsed,
    isOpen,
    header,
    headerContent,
    hideCaret,
    startOpen = false,
    outlined,
    fallbackUITitle = 'Card Error',
    fallbackUIDescription = 'Error displaying card!',
    ...other
  } = props;

  const [open, setOpen] = useState(startOpen);
  const openState = useMemo(() => isOpen ?? open, [isOpen, open]);
  const isControlled = isOpen !== undefined && isOpen !== null;
  const outlinedClass = outlined ? 'outlined' : '';

  const onClick = useCallback(() => {
    isControlled ? onChangeCollapsed?.(!isOpen) : setOpen((prev) => !prev);
  }, [isControlled, isOpen, onChangeCollapsed]);

  useEffect(() => {
    !isControlled && onChangeCollapsed?.(openState);
  }, [isControlled, openState, onChangeCollapsed]);

  return (
    <Card className={`titledCard kmSurfaceElement ${outlinedClass} ` + className} {...other}>
      <SafesimErrorBoundary
        fallbackUI={
          <div style={{ padding: '15px' }}>
            <SafesimNonIdealState icon={<SafesimIcon intent='danger' icon='error' size={60} />} title={fallbackUITitle} description={fallbackUIDescription} />
          </div>
        }
      >
        <SafesimControlGroup fill={true}>
          <SafesimButton
            alignText='left'
            fill={true}
            rightIcon={!hideCaret && (openState ? 'caret-up' : 'caret-down')}
            onClick={!hideCaret ? onClick : undefined}
            className={' collapseButton kmHeading3 cardTitle ' + buttonClassName}
            text={header}
            style={{ cursor: hideCaret ? 'default' : 'pointer' }}
          />
          {headerContent}
        </SafesimControlGroup>
        <Collapse className={'kmPrimaryText cardContent collapse' + (openState ? ' collapseOpen ' : '')} isOpen={openState}>
          {children}
        </Collapse>
      </SafesimErrorBoundary>
    </Card>
  );
};

SafesimCardCollapse.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  header: PropTypes.any.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  outlined: PropTypes.bool,
  onChangeCollapsed: PropTypes.func,
  isOpen: PropTypes.bool,
  hideCaret: PropTypes.bool,
  startOpen: PropTypes.bool,
  headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
