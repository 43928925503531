/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Uuid } from "../Utils/Types";
import { VerticalStack } from "../DesignSystem/Containers";
import { Button } from "baseui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation, faImage } from "@fortawesome/free-solid-svg-icons";
import { useSimorGet } from "../Utils/UseSimor";
import { RedactedImage } from "./RedactedElements";
import { AssetType, IdReference } from "Api";
import { isMarkedId, isRedaction } from "../Api/ApiExtensions";
import { isNull, isUndefined } from "lodash";
import AssetIcon from "../Asset/Shared/AssetIcon";

export function RedactableImage({
  idReference,
  onSetImagePressed,
  defaultImageType,
}: {
  idReference?: IdReference;
  onSetImagePressed?: () => void;
  defaultImageType?: AssetType;
}): JSX.Element {
  const authorizedToView = !isRedaction(idReference);

  const imageId = isMarkedId(idReference) ? idReference.contents : (idReference?.toString() as Uuid);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div style={{ width: "25rem", height: "14rem" }}>
        {!isNull(idReference) && !isUndefined(idReference) ? (
          authorizedToView ? (
            <PermittedImageIdPicture imageId={imageId} onSetImagePressed={onSetImagePressed} />
          ) : (
            <RedactedImage style={{ objectFit: "contain", height: "14rem", width: "auto" }} />
          )
        ) : (
          <ChangeablePicture
            image={
              isUndefined(defaultImageType) ? (
                <FontAwesomeIcon icon={faImage} size="10x" />
              ) : (
                <AssetIcon type={defaultImageType} size="10x" title="No image set" />
              )
            }
            onSetImagePressed={onSetImagePressed}
          ></ChangeablePicture>
        )}
      </div>
    </div>
  );
}

function PermittedImageIdPicture({
  imageId,
  onSetImagePressed,
}: {
  imageId?: IdReference;
  onSetImagePressed?: () => void;
}): JSX.Element {
  const [{ data: pictureBlob, error: _retrievalError }] = useSimorGet<Blob>(`/images/${imageId}`, {
    responseType: "blob",
  });

  const pictureLink = pictureBlob ? URL.createObjectURL(pictureBlob) : undefined;

  return (
    <ChangeablePicture
      image={
        isNull(_retrievalError) ? (
          <div data-testid="markable-image">
            <img src={pictureLink} style={{ objectFit: "contain", height: "12rem", width: "auto" }} />
          </div>
        ) : (
          <FontAwesomeIcon icon={faFileCircleExclamation} size="10x" title={"Image not found"} />
        )
      }
      onSetImagePressed={onSetImagePressed}
    ></ChangeablePicture>
  );
}

function ChangeablePicture({
  image,
  onSetImagePressed,
}: {
  image?: JSX.Element;
  onSetImagePressed?: () => void;
}): JSX.Element {
  return (
    <VerticalStack style={{ display: "flex", alignItems: "center" }}>
      {image}
      <Button onClick={onSetImagePressed} style={{ height: "fit-content", width: "fit-content", fontSize: "small" }}>
        Set Image
      </Button>
    </VerticalStack>
  );
}
