import React from 'react';
import { Classes } from '@blueprintjs/core';
import { SafesimLabel, SafesimInputGroup } from './allSafesimComponents';
import { SafesimControlGroup } from './SafesimControlGroup';

interface Props {
  label: string;
  labelClassName?: string;
  labelContent?: JSX.Element;
  value: boolean;
  editDisabledDisplayString?: string;
  onValueChange: (value: boolean) => void;
  valueClassName?: string;
  editDisabled?: boolean;
  modified: boolean;
}

/**
 * Functional component that displays a form group with a checkbox.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {boolean} value whether the checkbox is in a checked or unchecked state
 * @param {boolean} editDisabled the starting state of the input. True if editing is disabled at the start.
 * @returns Checkbox with a label.
 */
export const SafesimFormCheckbox = (props: Props): JSX.Element => {
  const { label, labelClassName, labelContent, value, editDisabledDisplayString, onValueChange, valueClassName, editDisabled = false, modified } = props;

  return (
    <SafesimControlGroup className='form-checkbox-bordered' fill={true}>
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={`${Classes.FIXED} checkbox-label ${labelClassName}`} title={label}>
        {label}
      </SafesimLabel>
      {editDisabledDisplayString && editDisabled ? (
        <SafesimInputGroup className={valueClassName} value={editDisabledDisplayString} disabled={true} fill={true} />
      ) : (
        // checkboxes are difficult, use a containing div to establish border-box, then apply border in lower div
        <div className='checkbox-container'>
          <div className={modified ? 'border-green' : undefined}>
            <input
              type='checkbox'
              className={'kmInterfaceElement ' + valueClassName}
              checked={value}
              disabled={editDisabled}
              onChange={(e: any) => onValueChange(e.target.checked)}
            />
          </div>
        </div>
      )}
    </SafesimControlGroup>
  );
};
