/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import React from "react";
import { RightAlignedRow } from "../../DesignSystem/Containers";
import { AssetFileTreeAction } from "./AssetFileTreeState";

const DownloadZipBlockedModal = ({
  isOpen,
  dispatch,
}: {
  isOpen: boolean;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element => {
  const onClose = React.useCallback(() => {
    dispatch(["cancelDownloadZip", null]);
  }, [dispatch]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // The unstable_ModalBackdropScroll will be removed in the next major baseui version.
      // It was recommended to set it to true to prepare for its removal.
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>Zip download blocked</ModalHeader>
      <ModalBody>Please save before attempting to download a file tree zip.</ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton kind="tertiary" onClick={onClose}>
            Cancel
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
};

export default DownloadZipBlockedModal;
