/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Breadcrumbs as BaseUIBreadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import * as React from "react";
import { useHistory } from "react-router-dom";

export const BackLink = (): JSX.Element => {
  const history = useHistory();
  return (
    <BaseUIBreadcrumbs>
      <StyledLink
        onClick={() => {
          history.goBack();
        }}
        style={{ cursor: "pointer" }}
      >
        {"< Back"}
      </StyledLink>
    </BaseUIBreadcrumbs>
  );
};
