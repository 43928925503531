/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useLazyQuery } from "@apollo/client";
import { DuplicateUploadInfo, FileInfo, NewFileInfo } from "Api";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import React from "react";
import { RightAlignedRow, VerticalStack } from "../../DesignSystem/Containers";
import { ObservableFileUploader } from "../../Shared/ObservableFileUploader";
import GetFiles from "../../Api/Gql/GetFiles.gql";
import { FolderNode } from "./AssetFileTreeState";
import { CancelTokenSource } from "axios";
import { FilePath } from "./FileUpload";

type UpdateUploadsAction = ["updateSuccessfulUploads", FileInfo[]];
type UpdateFailedUploadsAction = [
  "updateFailedUploads",
  { failedUploads: NewFileInfo[]; duplicateUploadInfos?: DuplicateUploadInfo[] }
];
type AddUploadsAction = [
  "addUploads",
  {
    cancelToken: CancelTokenSource;
    filePathsToUpload: FilePath[];
    folderNode?: FolderNode;
  }
];

type AddExistingUploadsAction = [
  "addExistingUploads",
  {
    filesToAdd: FileInfo[];
    folderNode?: FolderNode;
  }
];

export type UploadActions =
  | UpdateUploadsAction
  | UpdateFailedUploadsAction
  | AddUploadsAction
  | AddExistingUploadsAction;

const AddFilesModal = ({
  isOpen,
  folderNode,
  dispatch,
  onClose,
}: {
  isOpen: boolean;
  folderNode: FolderNode;
  dispatch: React.Dispatch<UploadActions>;
  onClose: () => void;
}): JSX.Element => {
  const [getFileInfos] = useLazyQuery(gql(GetFiles), {
    fetchPolicy: "network-only",
    onCompleted: (fileData) => {
      const fileInfos = fileData?.getFiles as FileInfo[];
      dispatch(["updateSuccessfulUploads", fileInfos]);
    },
  });

  const handleUploadCompleted = React.useCallback(
    (successfulUploads: NewFileInfo[], failedUploads: NewFileInfo[]) => {
      if (successfulUploads) {
        const succesfulUploadIds = successfulUploads.map((value) => value.id);
        getFileInfos({
          variables: { ids: succesfulUploadIds },
        });
      }
      if (failedUploads) {
        dispatch(["updateFailedUploads", { failedUploads }]);
      }
      onClose();
    },
    [dispatch, getFileInfos, onClose]
  );

  const headerText = folderNode?.title ? `Add files to '${folderNode.title}' folder` : "Add files to folder";

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody>
        <VerticalStack>
          {`Choose files to upload.`}
          <ObservableFileUploader
            showSecurityMarkingsControl={true}
            onUploadStart={(cancelToken, filePathsToUpload) =>
              dispatch(["addUploads", { cancelToken, filePathsToUpload, folderNode }])
            }
            onUploadComplete={handleUploadCompleted}
            testIdPrefix="add-files-modal"
          />
        </VerticalStack>
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton kind="tertiary" onClick={onClose}>
            Cancel
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
};

export default AddFilesModal;
