/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { KeycloakConfig } from "keycloak-js";
import { Uuid } from "../Utils/Types";
import { ClearanceInfo } from "./ClearedAccess";

// TODO: ApolloClient is receiving dates as scalars, but it is not
// doing anything to parse them. The apollo-link-scalars library should
// handle this problem so they can be referenced without parsing them.
//
// https://github.com/eturino/apollo-link-scalars
//
// This post had good guidance about how to get that working in an app:
// https://stackoverflow.com/questions/66085887/how-can-you-retrieve-a-date-field-in-apollo-client-from-a-query-as-a-date-and-no

export interface AuthenticationConfig {
  useKeycloak: boolean;
  keycloakConfig: KeycloakConfig;
}

export interface AppInfo {
  version: VersionInfo;
  // configurations field intentionally ommitted because it is deprecated.
  // configurations: AppConfigurations;
  schemaStatus: SchemaStatus;
  settings?: SimorAppSettings;
}

export interface SimorAppSettings {
  endpoints?: EndpointSettings;
  configurations?: AppConfigurations;
  fipsEnabled?: boolean;
  keycloak?: KeycloakConnectionSettings;
  jwt?: JwtSettings;
  security?: SecuritySettings;
}

export interface EndpointSettings {
  smrEnabled: boolean;
  smrBaseAddress: string;
}

export interface AppConfigurations {
  isSensitiveSecurityContext: boolean;
  bannerMode: BannerMode;
  bannerText: string;
  bannerTextColor: string;
  bannerColor: string;
  securityControlColors: SecurityControlColorConfig[];
  logoText: string;
  isLogoImageVisible: boolean;
  markingsRequired: boolean;
  iFrameDetectionEnabled: boolean;
  useSafeSimMarkingTool: boolean;
  disseminationControls: string[];
}

export enum BannerMode {
  STATIC_TEXT = "STATIC_TEXT",
  USER_CLEARANCE = "USER_CLEARANCE",
}

export interface SecurityControlColorConfig {
  securityControl: string;
  textColor: string;
  backgroundColor: string;
}

export interface KeycloakConnectionSettings {
  baseAddress?: string;
  realm?: string;
  clientId?: string;
  authAddress?: string;
  verifierAddress?: string;
  jwksAddress?: string;
  userInfoAddress?: string;
  cacheSize?: number;
  cacheEntryExpirationInSeconds?: number;
  rateLimitBucketSize?: number;
  rateLimitRefillRateInSeconds?: number;
  leewayInSeconds?: number;
}

export interface JwtSettings {
  /**
   * Secret will always be masked out on the client side.
   */
  secret?: string;
  issue?: string;
  audience?: string;
  myRealm?: string;
  userIdClaim?: string;
  userNameClaim?: string;
  authorizationSetClaim?: string;
  clearanceClaim?: string;
  digitalIdentifierClaim?: string;
}

export interface SecuritySettings {
  trustStorePath?: string;
}

export interface VersionInfo {
  abbreviatedCommitHash: string;
  commitHash: string;
  descriptor: string;
  buildDate: string;
}

export interface ExecutedMigration {
  __typename: "ExecutedMigration";
  changeId: string;
  timestamp: string;
}

export interface NoMigrationsExecuted {
  __typename: "NoMigrationsExecuted";
  message: string;
}

export interface ErrorGettingMigrations {
  __typename: "ErrorGettingMigrations";
  errorMessage: string;
}

export type MigrationExecutionState = ExecutedMigration | NoMigrationsExecuted | ErrorGettingMigrations;

export interface SchemaStatus {
  /**
   * The state of the data schema currently in the database.
   */
  lastExecutedMigration?: MigrationExecutionState;
  /**
   * The latest migration that should have been run on the data for the application to run with this version.
   */
  expectedMigrationId?: string;
}

export interface FileInfo extends Marked {
  id: Uuid;
  attributes?: FileAttributes;
  metadataScans: MetadataScan[];
}

export interface NewFileInfo {
  id: Uuid;
  name: string;
  securityMarkings?: SecurityMarkings;
}

export interface FileUploadManifest {
  fileInfo: NewFileInfo[];
  uploadDelayMillis?: number;
}

export interface FileAttributes {
  name: string;
  uploadDateUtc: string;
  uploadedBy: User;
  sizeMbs: number;
}

export interface Marked {
  // TODO: Consider just making this undefined.
  securityMarkings?: SecurityMarkings | null;
}

export interface SecurityMarkings {
  classificationLevel: ClassificationLevel;
  compartments?: CompartmentMarking[];
  disseminationControls?: string[];
  ownerProducer?: string[];
  geoPolitical?: GeoPolitical;
  classDeclass?: ClassDeclass;
  metadata?: SecurityMarkingMetadata;
}

export interface CompartmentMarking {
  classificationLevel: ClassificationLevel;
  // agencyToCompartments field intentionally ommitted because it is deprecated.
  // agencyToCompartments: Record<string, string[]>;
  categoryToCompartments: Record<string, string[]>;
}

export interface GeoPolitical {
  fgiSourceOpen: string[];
  fgiSourceProtected: string[];
  releasableTo: string[];
  displayOnlyTo: string[];
  joint: boolean;
}

export interface ClassDeclass {
  derivativeClassifiedBy: string;
  derivedFrom: string;
  declassDate: string;
  // TODO: It would not evident from the available MXS documentation what the data type of this should be.
  declassEvent: string;
  declassException: string[];
  classifiedBy: string;
  classificationReason: string;
}

export interface SecurityMarkingMetadata {
  desVersion: string;
  ismCatCesVersion: string;
  // TODO: It would not evident from the available MXS documentation what the data type of this should be.
  resourceElement: string;
  compliesWith: string;
  createDate: string;
  exemptFrom: string;
  noAggregation: boolean;
  // TODO: It would not evident from the available MXS documentation what the data type of this should be.
  externalNotice: string;
  noticeType: string;
  noticeDate: string;
  noticeReason: string;
  // TODO: It would not evident from the available MXS documentation what the data type of this should be.
  unregisteredNoticeType: string;
  pocType: string;
  hasApproximateMarkings: boolean;
  complicationReason: string;
  excludeFromRollup: boolean;
}

export enum ClassificationLevel {
  UNCLASSIFIED = "U",
  CONFIDENTIAL = "C",
  SECRET = "S",
  TOP_SECRET = "TS",
}

export interface Redaction {
  __typename: "Redaction";
  message: string;
}

export interface IgnorableValue {
  ignore: true;
}

export interface MarkedString {
  __typename: "MarkedString";
  contents: string;
  securityMarkings?: SecurityMarkings;
}

export type ClearedString = MarkedString | Redaction;

export type StringMaybeCleared = string | ClearedString;

export type MarkableString = StringMaybeCleared | IgnorableValue;

export interface MarkedId {
  __typename: "MarkedId";
  contents: Uuid;
  securityMarkings?: SecurityMarkings;
}

export type ClearedId = MarkedId | Redaction;

export type IdMaybeCleared = Uuid | ClearedId;

export type IdReference = IdMaybeCleared | IgnorableValue;

export type ClearedAssetFile = AssetFile | Redaction;

export interface AssetFile extends Marked {
  __typename: "AssetFile";
  fileId: Uuid;
  fileName: string;
  metadataScan?: MetadataScan;
}

export interface AssetFileFolder {
  path: string;
  files?: ClearedAssetFile[];
}

export interface ModelInfo extends Marked {
  id: Uuid;
  /**
   * The date the model was created. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  creationDate?: string;
  /**
   * The date the model was last modified. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  lastModifiedDate?: string;
  metadata?: ModelMetadata;
  fileFolders?: AssetFileFolder[];
  files?: ClearedAssetFile[];
  relatedScenarios?: Scenario[];
  relatedModels?: ModelInfo[];
  relatedPlatforms?: Platform[];
}

export interface ModelMetadata {
  title?: string;
  version?: string;
  description?: string;
  subject?: string;
  keywords?: string[];
  notes?: StringMaybeCleared;
  simulationFrameworks?: string[];
  customProps?: Record<string, ValueRecordMaybeCleared>;
  imageId?: IdMaybeCleared;
}

export interface ScenarioOutline {
  verbSuggestions: VerbTaskSuggestion[];
  conditions: Conditions;
  standards: Standards;
  outcomes: Outcomes;
}

export interface VerbTaskSuggestion {
  verb: string;
  taskSuggestions: TaskInfo[];
}

export interface Conditions {
  locations: string[];
  timeOfDay: string;
  weather: string;
  visibility: string;
}

export interface Standards {
  standard: string;
}

export interface Outcomes {
  outcome: string;
}

export interface AssetSummary extends Marked {
  id: Uuid;
  type: AssetType;
  title: string;
  version: string;
  description: string;
  securityMarkings?: SecurityMarkings;
  creationDate?: string;
  lastModifiedDate?: string;
}

export type AssetType = "MODEL" | "PLATFORM" | "SCENARIO" | "STUDY";

export interface AssetQueryResponse {
  pageCount: number;
  results: AssetSummary[];
}

export interface ModelQueryResponse {
  pageCount: number;
  results: ModelInfo[];
}

export interface C2SimQueryResponse {
  // NOTE: allEntities field is not used by SIMOR.
  // allEntities?: ModelQueryResponse;
  suggestions: C2SimScenarioSuggestions;
}

export interface C2SimScenarioSuggestions {
  entities: C2SimEntitySuggestions[];
}

export interface C2SimEntitySuggestions {
  definition: C2SimEntityDefinition;
  matches: ModelQueryResponse;
}

export interface C2SimEntityDefinition {
  name: string;
  type: string;
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
}

export interface Platform extends Marked {
  id: Uuid;
  /**
   * The date the platform was created. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  creationDate?: string;
  /**
   * The date the platform was last modified. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  lastModifiedDate?: string;
  metadata?: PlatformMetadata;
  fileFolders?: AssetFileFolder[];
  files?: ClearedAssetFile[];
  securityMarkings?: SecurityMarkings;
  relatedModels?: ModelInfo[];
}

export interface PlatformInput extends Marked {
  id: Uuid;
  metadata: PlatformMetadataInput;
  fileFolders?: FileIdFolder[] | null;
  relatedModelIds?: Uuid[] | null;
}

export interface PlatformMetadata {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: StringMaybeCleared;
  customProps?: Record<string, ValueRecordMaybeCleared>;
}

export interface PlatformMetadataInput {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: MarkableString;
  customProps?: Record<string, MarkableValueRecord>;
  imageId?: IdReference;
}

export interface ScenarioMetadata {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: StringMaybeCleared;
  // Date string as text, in Zulu time.
  startTime?: string;
  customProps?: Record<string, ValueRecordMaybeCleared>;
  geoLocation?: GeoLocation;
}

export interface ScenarioMetadataInput {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: MarkableString;
  // Date string as text, in Zulu time.
  startTime?: string;
  customProps?: Record<string, MarkableValueRecord>;
  geoLocation?: GeoLocation;
}

export interface Scenario extends Marked {
  id: Uuid;
  /**
   * The date the scenario was created. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  creationDate?: string;
  /**
   * The date the scenario was last modified. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  lastModifiedDate?: string;
  metadata?: ScenarioMetadata;
  fileFolders?: AssetFileFolder[];
  files?: ClearedAssetFile[];
  securityMarkings?: SecurityMarkings;
  relatedModels?: ModelInfo[];
}

export interface ScenarioInput extends Marked {
  id: Uuid;
  metadata: ScenarioMetadataInput;
  fileFolders?: FileIdFolder[] | null;
  relatedModelIds?: Uuid[] | null;
}

export interface StudyMetadata {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: StringMaybeCleared;
  customProps?: Record<string, ValueRecordMaybeCleared>;
}

export interface StudyMetadataInput {
  title?: string;
  version?: string;
  description?: string;
  keywords?: string[];
  notes?: MarkableString;
  customProps?: Record<string, MarkableValueRecord>;
}

export interface Study extends Marked {
  id: Uuid;
  /**
   * The date the study was created. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  creationDate?: string;
  /**
   * The date the study was last modified. This value will always be defined by the server, but
   * may be absent if not requested in the GQL query.
   */
  lastModifiedDate?: string;
  metadata?: StudyMetadata;
  fileFolders?: AssetFileFolder[];
  files?: ClearedAssetFile[];
  securityMarkings?: SecurityMarkings;
}

export interface StudyInput extends Marked {
  id: Uuid;
  metadata: StudyMetadataInput;
  fileFolders?: FileIdFolder[] | null;
}

/**
 * The settings that configure a query's response.
 */
export interface QuerySettingsInput {
  /**
   * The settings that configure the pagination of the query response.
   */
  paging?: PageSettingsInput;

  sort?: SortSettingsInput;
}

export interface SortSettingsInput {
  fieldName?: string;

  order?: SortOrder;
}

export type SortOrder = "ASCENDING" | "DESCENDING";

/**
 * The settings that define how to paginate data.
 */
export interface PageSettingsInput {
  /**
   * The size of each page. May be between 1 and 200. Values outside of this range will be clipped to
   * the lower or upper bound.
   */
  size?: number;

  /**
   * The index of the page. This is a 1-based index; the first page is 1.
   */
  index?: number;
}

export interface DateFilterInput {
  fieldName: string;
  beforeDate?: string;
  afterDate?: string;
}

export interface KeywordFilterInput {
  onlyIncludeResourcesWithoutKeywords: boolean;
}

// TODO: Move textSubstring search into SearchSpecInput
export interface SearchSpecInput {
  dateFilters?: DateFilterInput[];
  numericFilters?: NumericFilterInput[];
  classificationFilter?: ClassificationFilterInput;
  keywordFilter?: KeywordFilterInput;
  assetTypeFilter?: AssetTypeFilterInput;
}

export type ComparisonOperator =
  | "EQUAL"
  | "NOT_EQUAL"
  | "LESS_THAN"
  | "LESS_THAN_OR_EQUAL"
  | "GREATER_THAN"
  | "GREATER_THAN_OR_EQUAL";

export interface NumericFilterInput {
  fieldName: string;
  comparator: ComparisonOperator;
  value: number;
}

export interface ClassificationFilterInput {
  classificationLevels: ClassificationLevel[];
  includeUnmarked: boolean;
}

export interface AssetTypeFilterInput {
  assetTypes: AssetType[];
}

export interface FileIdFolder {
  path: string;
  fileIds: Uuid[];
}

export interface ModelInfoInput extends Marked {
  id: Uuid;
  metadata: ModelMetadataInput;
  fileFolders?: FileIdFolder[] | null;
  // the fileIds field is intentionally excluded here; it is included in
  //   the api for backwards compatibility purposes and is not needed to
  //   describe the folder structure.
  relatedScenarioIds?: Uuid[] | null;
  relatedModelIds?: Uuid[] | null;
  relatedPlatformIds?: Uuid[] | null;
}

export interface ModelMetadataInput {
  title?: string;
  version?: string;
  description?: string;
  subject?: string;
  keywords?: string[];
  notes?: MarkableString;
  simulationFrameworks?: string[];
  customProps?: Record<string, MarkableValueRecord>;
  imageId?: IdReference;
}

// Note: Changes in this enumeration require updating the graphql-dynamic-types.md docs.
// Note: DATE_TIME is not implemented as a dynamic property yet; it exists only as a
//  client side construction.
export type ValueRecordType = "NUMERIC" | "TEXT" | "TEXT_LIST" | "DATE_TIME";

// Note: Changes in this interface require updating the graphql-dynamic-types.md docs.
export interface ValueRecord {
  name: string;
  type: ValueRecordType;
  value?: any;
}

export interface MarkedValueRecord {
  contents: ValueRecord;
  securityMarkings?: SecurityMarkings;
}

export type ValueRecordMaybeCleared = ValueRecord | ClearedValueRecord;

export type ClearedValueRecord = MarkedValueRecord | Redaction;

export type MarkableValueRecord = ValueRecordMaybeCleared | IgnorableValue;

export interface ModelChangeSet {
  updatedModel: ModelInfo;
  hasChanged: boolean;
}

export interface PlatformChangeSet {
  updatedPlatform: Platform;
  hasChanged: boolean;
}

export interface ScenarioChangeSet {
  updatedScenario: Scenario;
  hasChanged: boolean;
}

export interface StudyChangeSet {
  updatedStudy: Study;
  hasChanged: boolean;
}

export interface MetadataScan {
  id: Uuid;
  // Legacy: This field is still present but was removed because it is deprecated.
  // metadata: ModelMetadata
  scanMetadata: Record<string, MarkedValueRecord>;
  scanResult: ScanResult;
  analyzedFileType: string;
}

export enum ScanResult {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  FILETYPE_NOT_SUPPORTED = "FILETYPE_NOT_SUPPORTED",
}

export interface LoginResponse {
  authToken: string;
  user: AuthenticatedUser;
  errors: string[];
}

export interface JwtRefreshResponse {
  authToken: string;
  errors: string[];
}

export interface ProtectedUrl {
  urlFragment: string;
}

export interface Name {
  firstName: string;
  lastName: string;
  displayName: string;
}

export interface ContactInfo {
  email: string;
}

export interface AuthenticatedUser {
  id: Uuid;
  username: string;
  person: Person;
  isAdmin: boolean;
  preferences?: UserPreferences;
}

export interface User {
  id: Uuid;
  username: string;
  person: Person;
  preferences?: UserPreferences;
}

export interface UserProfile {
  id: Uuid;
  username: string;
  person: Person;
  preferences?: UserPreferences;
  clearanceInfo?: ClearanceInfo;
}

export interface UserProfileInput {
  id: Uuid;
  person: Person;
  preferences?: UserPreferences;
}

export interface UserPreferences {
  topicsOfInterest?: string[];
}

export interface Person {
  name: Name;
  contactInfo: ContactInfo;
}

export interface AppInitializationStatus {
  initialized: boolean;
}

export interface AppInitializationResponse {
  message: string;
  status: AppInitializationStatus;
}

export interface LoginRequest {
  username: string;
}

export interface FileUploadResponse {
  successfulUploads: string[];
  duplicateUploads: string[];
  failedUploads: FileError[];
  successfulUploadInfos: NewFileInfo[];
  duplicateUploadInfos: DuplicateUploadInfo[];
}

// TODO: Can this be used instead of ElementUploadFailure or vice versa?
export interface FileError {
  fileName: string;
  uploadError: string;
}

export interface DuplicateUploadInfo {
  duplicateFile: NewFileInfo;
  originalFileAliasIds: Uuid[];
}

export interface BulkAddModelResponse {
  successfulUploads?: ElementUploadSuccess[];
  failedUploads?: ElementUploadFailure[];
  operationError?: string;
}

export interface BulkUploadModelMetadata {
  keywords: string[];
}

export interface BulkModelUpload {
  fileNames: string[];
  metadata: BulkUploadModelMetadata;
  // Deprecated. Commented out so we do not accidentally use it.
  //classificationLevel: ClassificationLevel;
  securityMarkings?: SecurityMarkings;
}

export interface ElementUploadSuccess {
  uploadedFileName: string;
  isDuplicate: boolean;
  newModelId: Uuid;
}

export interface ElementUploadFailure {
  uploadedFileName: string;
  failureMessage: string;
}

export interface FileInfoInput extends Marked {
  id: Uuid;
  fileNameNoExtension: string | null;
}

export interface PickListValueOption {
  value: string;
  description?: string | null;
}

// TODO: Create PickListSchemaInput interface
export interface PickListSchema {
  id: Uuid;
  attributeName: string;
  valueOptions: PickListValueOption[] | null;
}

export interface SchemaFields {
  selectMultiple: PickListSchema[] | null;
}

export interface MetadataSchema {
  id: Uuid;
  fields: SchemaFields;
}

export interface ModeledTopics {
  topics: string[];
}

export type FileSize = "SMALL" | "MEDIUM" | "LARGE" | "EXTRA_LARGE";

export interface TestModelSpecInput {
  modelCount: number;
  fileSize: FileSize;
}

export interface ClassificationStats {
  models: AssetClassificationStats;
  platforms: AssetClassificationStats;
  scenarios: AssetClassificationStats;
  studies: AssetClassificationStats;
  total: AssetClassificationStats;
}

export interface AssetClassificationStats {
  classification: ClassificationCount[];
  total: number;
}

export interface ClassificationCount {
  level?: ClassificationLevel;
  count: number;
}

export interface MostRecentTopicChanges {
  topics: TopicAssets[];
}

export interface TopicAssets {
  topicName: string;
  assets: AssetSummary[];
}

export interface UpdateSecurityMarkingsInput {
  securityMarkings: SecurityMarkings;
  assetIds: Uuid[];
}

export interface UpdateSecurityMarkingsResponse {
  numberUpdated: number;
}

export interface SearchEntryQueryResponse {
  results: string[];
}

//SMR INTERFACES
export interface UploadResponse<T> {
  data?: T;
  errors?: UploadError[];
}

export interface UploadError {
  message: string;
}

export interface TaskListMetadata {
  name: string;
}

export interface TaskListSummary {
  id: Uuid;
  name: string;
  taskCount: number;
}

export interface TaskList {
  id: Uuid;
  name: string;
  tasks: TaskInfo[];
}

export interface TaskInfo {
  id: Uuid;
  category: string;
  hierarchyNumber: string;
  title: string;
  priority: string;
  djsApprovalDate: string;
  description: string;
  notes: string;
  requiredCapabilities: string[];
  measures: TaskMeasure[];
}

export interface TaskMeasure {
  id: Uuid;
  measureNumber: string;
  unit: string;
  description: string;
}

export interface FileSummary {
  fileId: Uuid;
  securityMarkings: SecurityMarkings;
  fileName: string;
}

export interface FileReferenceReport {
  forFile: Uuid;
  references: FileSummary[];
}

export type UnitType = "Speed";

export interface NumericValue {
  value?: string | number;
  type?: UnitType;
  unitOfMeasure?: string;
}

export interface NumericUnitSchema {
  types: NumericTypeDescriptor[];
}

export interface NumericTypeDescriptor {
  unitType: UnitType;
  measureOptions: MeasureDescriptor[];
}

export interface MeasureDescriptor {
  abbreviation: string;
  descriptiveName: string;
}
