/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { FileUploadPreview, UploadCancelModal } from "./UploadCancelModal";
import { FileError, FileUploadManifest, FileUploadResponse, SecurityMarkings } from "Api";
import { AxiosContext } from "../Utils/AuthContext";
import axios from "axios";
import { FilePath } from "../Asset/Tree/FileUpload";
import { getParentPath } from "../Api/FileInfo";
import { newId } from "../Utils/Types";
import { notifyFileUploadResponse } from "./ObservableFileUploader";
import { notify } from "./Notify";
import { FileUploader } from "baseui/file-uploader";
import { ClassificationSummary } from "./SecurityMarkingsEditor";
import { useMarkingsDisableSave } from "./ApiHooks";

// TODO: Simplify this class; it is very dense.
export function BaseFileUploader({
  title,
  subTitle,
  isModalOpen,
  onClose,
  multiple,
  typeFilters,
  extraErrorInfo,
  onUploadComplete,
}: {
  title: string;
  subTitle: string;
  isModalOpen: boolean;
  onClose: () => void;
  multiple: boolean;
  typeFilters?: string[];
  extraErrorInfo?: string;
  onUploadComplete?: (data: FileUploadResponse) => void;
}): JSX.Element {
  const axiosContext = React.useContext(AxiosContext);
  const [securityMarkings, setSecurityMarkings] = React.useState<SecurityMarkings>();
  const [filesToUpload, setFilesToUpload] = React.useState<File[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const closeModal = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const clearSelectedFiles = React.useCallback(() => {
    setFilesToUpload([]);
  }, []);

  const handleUploadResponse = React.useCallback(
    (successfulUploads?: string[], duplicateUploads?: string[], failedUploads?: FileError[]) => {
      notifyFileUploadResponse({
        successfulUploads,
        duplicateUploads,
        failedUploads,
      });
    },
    []
  );

  const handleRetryClicked = React.useCallback(() => {
    setFilesToUpload([]);
    setErrorMessage("");
  }, []);

  const startFileUpload = React.useCallback(async (): Promise<void> => {
    // TODO: Commonize with ObservableFileUploader
    const postToken = axios.CancelToken.source();

    const formData = new FormData();

    const uploadedFilePaths: FilePath[] = filesToUpload.map((file) => {
      const filePath: FilePath = {
        path: getParentPath(file),
        file: {
          id: newId(),
          name: file.name,
          securityMarkings: securityMarkings,
        },
      };
      return filePath;
    });

    const uploadedFiles = uploadedFilePaths.map((path) => path.file);
    const uploadManifest: FileUploadManifest = {
      fileInfo: uploadedFiles,
    };

    formData.append("fileInfo", JSON.stringify(uploadManifest));
    filesToUpload.forEach((file, index) => {
      formData.append(`${index}`, file, file.name);
    });

    const config = {
      cancelToken: postToken.token,
    };

    const uploadPromise = axiosContext.post("/upload", formData, config);
    uploadPromise
      .then((response) => {
        const { successfulUploads, duplicateUploads, failedUploads } = response.data as FileUploadResponse;

        handleUploadResponse(successfulUploads, duplicateUploads, failedUploads);
        if (onUploadComplete) onUploadComplete(response.data as FileUploadResponse);
      })
      .catch((reason) => {
        if (axios.isCancel(reason)) {
          notify.info("Upload cancelled.");
          return;
        } else {
          notify.warning(reason?.message);
        }
      });

    clearSelectedFiles();
    setSecurityMarkings(undefined);
    closeModal();
  }, [
    axiosContext,
    clearSelectedFiles,
    closeModal,
    filesToUpload,
    handleUploadResponse,
    onUploadComplete,
    securityMarkings,
  ]);

  const isUploadDisabled = useMarkingsDisableSave(securityMarkings) || filesToUpload.length == 0;

  return (
    <UploadCancelModal
      title={title}
      isOpen={isModalOpen}
      onClose={onClose}
      isUploadDisabled={isUploadDisabled}
      startFileUpload={startFileUpload}
    >
      <div>
        <ClassificationSummary
          securityMarkings={securityMarkings}
          onMarkingsChanged={(newSecurityMarking) => setSecurityMarkings(newSecurityMarking)}
        />
      </div>
      {subTitle}
      {filesToUpload.length > 0 ? (
        <FileUploadPreview files={filesToUpload} onCancel={clearSelectedFiles} />
      ) : (
        <FileUploader
          errorMessage={errorMessage}
          multiple={multiple}
          accept={typeFilters}
          onCancel={clearSelectedFiles}
          onRetry={handleRetryClicked}
          onDrop={async (acceptedFiles, rejectedFiles) => {
            setFilesToUpload(acceptedFiles);
            if (rejectedFiles.length > 0) {
              setErrorMessage(`There was an error! ${extraErrorInfo}`);
            }
          }}
        />
      )}
    </UploadCancelModal>
  );
}
