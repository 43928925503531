/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React, { useCallback, useMemo, useState } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { LabelXSmall } from "baseui/typography";

import { AssetSummary, AssetType } from "Api";
import { Uuid } from "../Utils/Types";
import { themedUseStyletron as useStyletron } from "../DesignSystem/LightTheme";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { OverflowActions, OverflowMenu } from "../Shared/OverflowMenu";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import deleteModelMutation from "../Api/Gql/DeleteModel.gql";
import deletePlatformMutation from "../Api/Gql/DeletePlatform.gql";
import deleteScenarioMutation from "../Api/Gql/DeleteScenario.gql";
import deleteStudyMutation from "../Api/Gql/DeleteStudy.gql";
import {
  modelDetailsCreatePagePath,
  modelDetailsEditPagePath,
  platformDetailsCreatePagePath,
  platformDetailsEditPagePath,
  scenarioDetailsCreatePagePath,
  scenarioDetailsEditPagePath,
  studyDetailsCreatePagePath,
  studyDetailsEditPagePath,
} from "../Shared/NavigationLinks";
import { classificationOptionsAsText } from "../Api/ApiSerialization";
import { appConfigurationsVar } from "../GlobalState";
import { useHistory } from "react-router";
import { AssetIconCell } from "../Asset/Shared/AssetIconCell";
import { AssetLink } from "../Asset/Shared/AssetLink";
import { replaceIfUntitled, toTitleCase } from "../Utils/Strings";
import { TextEntry } from "../Utils/TableCells";

export interface SelectableResultItem {
  item: AssetSummary;
  isSelected: boolean;
}

const searchResultTableStyle: React.CSSProperties = {
  width: "70%",
  marginRight: "auto",
  // Disables the 2px cell border in FireFox when you ctrl-click on a table cell.
  MozUserSelect: "none",
  alignItems: "flex-start",
};

export default function AssetSearchResultTable({
  selectableResultItems,
  refetchQueryName,
  onSort,
  onClick,
}: {
  selectableResultItems: SelectableResultItem[];
  refetchQueryName: string;
  onSort: (fieldName: string) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, selectableResultItem: SelectableResultItem) => void;
}): JSX.Element {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, assetId: Uuid) => {
      const selectableItem = selectableResultItems.find((row) => row.item.id === assetId);
      if (selectableItem) {
        onClick(event, selectableItem);
      }
    },
    [onClick, selectableResultItems]
  );

  const styleOverrides = React.useMemo(() => {
    return {
      // TODO: Find a way to create a common table style, possibly using Styletron.
      Table: {
        style: () => ({
          boxSizing: "content-box",
        }),
      },
      TableBodyRow: {
        style: ({ $theme, $rowIndex }: any) => ({
          backgroundColor: selectableResultItems[$rowIndex].isSelected
            ? $theme.colors.selectionBackgroundPrimary
            : $theme.colors.backgroundPrimary,
          ":hover": {
            backgroundColor: selectableResultItems[$rowIndex].isSelected
              ? $theme.colors.selectionBackgroundPrimaryDark
              : $theme.colors.backgroundTertiary,
          },
        }),
      },
      TableBodyCell: {
        style: () => ({
          // Baseweb adds padding automatically for aesthetics to the table data;
          // we need to remove it to make sure we're able to receive click events
          // for everything that appears to the user to be inside the table cell.
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "1px",
          paddingLeft: "0",
        }),
      },
    };
  }, [selectableResultItems]);

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    overflow: "hidden",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  return (
    <div style={searchResultTableStyle}>
      <TableBuilder data={selectableResultItems} onSort={onSort} overrides={styleOverrides}>
        <TableBuilderColumn id="type" header="Type" sortable>
          {(row: SelectableResultItem) => (
            <AssetIconCell assetType={row.item?.type} styleClass={cellStyle}></AssetIconCell>
          )}
        </TableBuilderColumn>

        <TableBuilderColumn id="title" header="Title" sortable>
          {(row: SelectableResultItem) => (
            <AssetTitleCell
              title={replaceIfUntitled(row.item?.title)}
              type={row.item?.type}
              id={row.item.id}
              styleClass={cellStyle}
              refetchQueryName={refetchQueryName}
              onClick={handleClick}
            />
          )}
        </TableBuilderColumn>

        <TableBuilderColumn id="version" header="Version" sortable>
          {(row: SelectableResultItem) => (
            <TextEntry
              text={row.item?.version}
              styleClass={cellStyle}
              dataId={row.item.id}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="classificationLevel" header="Classification" sortable>
          {(row: SelectableResultItem) => (
            <TextEntry
              text={classificationOptionsAsText(
                row.item.securityMarkings?.classificationLevel,
                isSensitiveSecurityContext
              )}
              styleClass={cellStyle}
              dataId={row.item.id}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="description" header="Description" sortable>
          {(row: SelectableResultItem) => (
            <StatefulTooltip
              placement={PLACEMENT.bottom}
              content={() =>
                row.item?.description?.length > 0 ? (
                  <LabelXSmall maxWidth={"16rem"} color={"white"}>
                    {row.item?.description}
                  </LabelXSmall>
                ) : (
                  ""
                )
              }
            >
              <div>
                <TextEntry
                  text={row.item?.description}
                  styleClass={cellStyle}
                  dataId={row.item.id}
                  onClick={handleClick}
                ></TextEntry>
              </div>
            </StatefulTooltip>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}

const parsedDeleteModelGql = gql(deleteModelMutation);
const parsedDeletePlatformGql = gql(deletePlatformMutation);
const parsedDeleteScenarioGql = gql(deleteScenarioMutation);
const parsedDeleteStudyGql = gql(deleteStudyMutation);

const AssetTitleCell = ({
  title,
  id,
  type,
  styleClass,
  refetchQueryName,
  onClick,
}: {
  title: string;
  id: Uuid;
  type: AssetType;
  styleClass: string;
  refetchQueryName: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, modelId: Uuid) => void;
}): JSX.Element => {
  const [deletePromptIsOpen, setDeletePromptIsOpen] = useState(false);

  const history = useHistory();

  const [deleteModel] = useMutation(parsedDeleteModelGql, {
    refetchQueries: [refetchQueryName],
  });

  const [deletePlatform] = useMutation(parsedDeletePlatformGql, {
    refetchQueries: [refetchQueryName],
  });

  const [deleteScenario] = useMutation(parsedDeleteScenarioGql, {
    refetchQueries: [refetchQueryName],
  });

  const [deleteStudy] = useMutation(parsedDeleteStudyGql, {
    refetchQueries: [refetchQueryName],
  });

  const handleDelete = useCallback(() => {
    setDeletePromptIsOpen(false);

    switch (type) {
      case "MODEL":
        deleteModel({ variables: { modelId: id } });
        break;
      case "PLATFORM":
        deletePlatform({ variables: { platformId: id } });
        break;
      case "SCENARIO":
        deleteScenario({ variables: { scenarioId: id } });
        break;
      case "STUDY":
        deleteStudy({ variables: { studyId: id } });
        break;
      default:
        console.warn(`Attempted to delete type ${type} which is not handled.`);
        break;
    }
  }, [deleteModel, deletePlatform, deleteScenario, deleteStudy, id, type]);

  const overflowActions: OverflowActions[] = useMemo(() => {
    const overflowActionsModels = [
      {
        label: "Edit",
        onClick: () => history.push(modelDetailsEditPagePath(id)),
      },
      { label: "Delete", onClick: () => setDeletePromptIsOpen(true) },
      { label: "Duplicate & edit", onClick: () => history.push(modelDetailsCreatePagePath(id)) },
    ];

    const overflowActionsPlatforms = [
      {
        label: "Edit",
        onClick: () => history.push(platformDetailsEditPagePath(id)),
      },
      { label: "Delete", onClick: () => setDeletePromptIsOpen(true) },
      { label: "Duplicate & edit", onClick: () => history.push(platformDetailsCreatePagePath(id)) },
    ];

    const overflowActionsScenarios = [
      {
        label: "Edit",
        onClick: () => history.push(scenarioDetailsEditPagePath(id)),
      },
      { label: "Delete", onClick: () => setDeletePromptIsOpen(true) },
      { label: "Duplicate & edit", onClick: () => history.push(scenarioDetailsCreatePagePath(id)) },
    ];

    const overflowActionsStudies = [
      {
        label: "Edit",
        onClick: () => history.push(studyDetailsEditPagePath(id)),
      },
      { label: "Delete", onClick: () => setDeletePromptIsOpen(true) },
      { label: "Duplicate & edit", onClick: () => history.push(studyDetailsCreatePagePath(id)) },
    ];

    switch (type) {
      case "MODEL":
        return overflowActionsModels;
      case "PLATFORM":
        return overflowActionsPlatforms;
      case "SCENARIO":
        return overflowActionsScenarios;
      case "STUDY":
        return overflowActionsStudies;
      default:
        return [];
    }
  }, [history, id, type]);

  return (
    <>
      <div
        className={styleClass}
        onClick={(event) => {
          onClick(event, id);
        }}
      >
        <>
          <AssetLink id={id} title={title} assetType={type} />
          <OverflowMenu overflowActions={overflowActions} btnTestId={`overflow-${title?.trim()}`} />
        </>
        <DeleteConfirmationModal
          isOpen={deletePromptIsOpen}
          header={`Delete ${toTitleCase(type)}`}
          bodyText={`Are you sure you want to delete "${title}"?`}
          onDelete={handleDelete}
          onCancel={() => setDeletePromptIsOpen(false)}
        />
      </div>
    </>
  );
};
