/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import {
  FileInfo,
  MetadataSchema,
  NewFileInfo,
  AssetType,
  AssetFileFolder,
  SecurityMarkings,
  AssetSummary,
  DuplicateUploadInfo,
  MarkableValueRecord,
  ModelInfoInput,
  PlatformInput,
} from "Api";
import { AxiosInstance, CancelTokenSource } from "axios";
import { AssetInput, emptyMetadataSchema, isDataChanged } from "../../Api/ApiExtensions";
import { FieldSpec } from "./FieldSpec";
import { FilePath, toFileInfoUpload } from "../Tree/FileUpload";
import * as Tree from "../Tree/AssetFileTreeState";
import { convertToFileIdFolders, convertToFileTree } from "../Tree/AssetTreeHelpers";
import {
  addMetadataProperty,
  applyKeywords,
  updateMetadataProperty,
  deleteCustomProperty,
  flattenPresentMetadata,
  concatUnspecifiedFields,
  allMetadataFields,
  applyKeywordsAndFrameworks,
  getCustomPropFields,
} from "./AssetMetadataFields";
import { toTitleCase } from "../../Utils/Strings";
import { deepClone } from "../../Utils/Common";
import { handleAxiosError } from "../../Shared/Errors";
import { Uuid } from "../../Utils/Types";

export class EditorFields {
  customSchema: MetadataSchema = emptyMetadataSchema();
  all: FieldSpec[];
  customProps?: FieldSpec[];

  constructor(fixedSchemaFields: FieldSpec[]) {
    this.all = defineAllFields(fixedSchemaFields);
  }
}

function defineAllFields(
  fields: FieldSpec[],
  schema?: MetadataSchema,
  customProps?: Record<string, MarkableValueRecord>
): FieldSpec[] {
  const allSchemaFields = allMetadataFields(fields, schema);
  return concatUnspecifiedFields(allSchemaFields, customProps);
}

function toEditorFields(
  fields: FieldSpec[],
  schema: MetadataSchema,
  customProps?: Record<string, MarkableValueRecord>
): EditorFields {
  const allDefinedFields = defineAllFields(fields, schema, customProps);
  const customFields = getCustomPropFields(fields, customProps);

  return {
    customSchema: schema,
    all: allDefinedFields,
    customProps: customFields,
  };
}

export class AssetContent<TAssetInput extends AssetInput> {
  fileTreeRoot: Tree.FolderNode;
  asset: TAssetInput;
  type: AssetType;
  relations?: AssetSummary[];

  constructor(asset: TAssetInput, type: AssetType, relations?: AssetSummary[]) {
    this.asset = asset;
    this.type = type;
    this.fileTreeRoot = convertToFileTree(toTitleCase(type));
    this.relations = relations;
  }
}

export class AssetDetailsState<TAssetInput extends AssetInput> {
  // These fields could possibly be grouped together; they are related
  // to determining the values displayed in the fields.
  fixedSchemaFields: FieldSpec[];
  fields: EditorFields;
  schema: MetadataSchema;
  metadataValues: Record<string, any> = {};

  // These fields could possibly be grouped together; they are related
  // to determining of data is changed and the shape that is used
  // for submitting the change.
  original: AssetContent<TAssetInput>;
  edited: AssetContent<TAssetInput>;
  dataChanged: boolean = false;

  selectedFileTreeNode: Tree.FileTreeNode;

  creationDate?: string;
  lastSaveDate?: string;

  copying: boolean = false;
  downloadZipBlocked = false;

  constructor(
    type: AssetType,
    fixedSchemaFields: FieldSpec[],
    value: TAssetInput,
    relations: AssetSummary[],
    copying: boolean = false
  ) {
    this.fixedSchemaFields = fixedSchemaFields;
    this.fields = new EditorFields(fixedSchemaFields);

    this.original = new AssetContent<TAssetInput>(value, type, relations);

    this.metadataValues = this.original.asset.metadata;

    this.edited = {
      ...this.original,
    };

    this.copying = copying;
  }
}

function applyEdit<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  newEdit: AssetContent<TAssetInput>
): AssetDetailsState<TAssetInput> {
  return {
    ...state,
    edited: newEdit,
    dataChanged: isDataChanged(state.original.asset, newEdit.asset),
    metadataValues: flattenPresentMetadata(newEdit.asset.metadata),
  };
}

function addFolder<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  parent: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyInsertFolder(state.edited.fileTreeRoot, parent);
  return applyFileUploads(state, newFileTreeRoot);
}

function addUploads<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  cancelToken: CancelTokenSource,
  filePathsToUpload: FilePath[],
  folderNode?: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.addUploads(state.edited.fileTreeRoot, cancelToken, filePathsToUpload, folderNode);
  return applyFileUploads(state, newFileTreeRoot);
}

function addExistingUploads<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  filesToAdd: FileInfo[],
  folderNode?: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.addExistingUploads(state.edited.fileTreeRoot, filesToAdd, folderNode);
  return applyFileUploads(state, newFileTreeRoot);
}

function cancelFolderEdit<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  editedFolder: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyCancelFolderEdit(state.edited.fileTreeRoot, editedFolder);
  return applyFileUploads(state, newFileTreeRoot);
}

function commitFolderEdit<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  editedFolder: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyCommitFolderEdit(state.edited.fileTreeRoot, editedFolder);
  return applyFileUploads(state, newFileTreeRoot);
}

function deleteFileTreeNode<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  deletedNode: Tree.FileTreeNode
): AssetDetailsState<TAssetInput> {
  Tree.cancelIfUploading(deletedNode);
  const newFileTreeRoot = Tree.applyDelete(state.edited.fileTreeRoot, deletedNode);
  const stateAfterRemoval = applyFileUploads(state, newFileTreeRoot);

  if (
    state.selectedFileTreeNode?.type === "File" &&
    !Tree.findNodeById(newFileTreeRoot, state.selectedFileTreeNode?.file?.info.id)
  ) {
    return selectAssetMetadata(stateAfterRemoval);
  }
  return stateAfterRemoval;
}

type DiscardChangesAction = ["discardChanges"];

function discardChanges<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>
): AssetDetailsState<TAssetInput> {
  const metadataValues = flattenPresentMetadata(state.original.asset.metadata);
  const newState = selectAssetMetadata(state);
  return {
    ...newState,
    fields: toEditorFields(
      state.fixedSchemaFields,
      state.fields.customSchema,
      state.original.asset.metadata?.customProps
    ),
    metadataValues,
    edited: { ...state.original },
    dataChanged: false,
  };
}

function applyFileUploads<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  newFileTreeRoot: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const fileUploads = Tree.allUploadsIn(newFileTreeRoot);

  const fileFolders = convertToFileIdFolders(newFileTreeRoot);

  const editedAfterUpdate: AssetContent<TAssetInput> = {
    fileTreeRoot: newFileTreeRoot,
    asset: {
      ...state.edited.asset,
      fileFolders,
      metadata: applyKeywords(fileUploads, state.edited.asset.metadata),
    },
    type: state.edited.type,
    relations: state.edited.relations,
  };

  // TODO: Consider refactor to lift knowledge of Model out of AssetDetailsState.
  const modelEditedAfterUpdate: AssetContent<TAssetInput> = {
    fileTreeRoot: newFileTreeRoot,
    asset: {
      ...state.edited.asset,
      fileFolders,
      metadata: applyKeywordsAndFrameworks(fileUploads, state.edited.asset.metadata),
    },
    type: state.edited.type,
    relations: state.edited.relations,
  };

  if (state.edited.type === "MODEL") {
    return applyEdit(state, modelEditedAfterUpdate);
  } else {
    return applyEdit(state, editedAfterUpdate);
  }
}

function editFolderName<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  editedFolder: Tree.FolderNode,
  newTitle: string
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyEditFolderName(state.edited.fileTreeRoot, editedFolder, newTitle);
  // No change has been committed yet, so we don't need to rebuild the FileFolders.
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

type EditMarkingsAction = ["editMarkings", SecurityMarkings];

function editMarkings<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  newMarkings: SecurityMarkings
): AssetDetailsState<TAssetInput> {
  const editedAfterUpdate: AssetContent<TAssetInput> = {
    ...state.edited,
    asset: {
      ...state.edited.asset,
      securityMarkings: newMarkings,
    },
  };
  return applyEdit(state, editedAfterUpdate);
}

type AddPropertyAction = ["addProperty", FieldSpec];

function addProperty<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  newProperty: FieldSpec
): AssetDetailsState<TAssetInput> {
  const metadataWithNewProperty = addMetadataProperty(state.edited.asset.metadata, state.fields.all, newProperty);
  const assetWithNewProperty: AssetContent<TAssetInput> = {
    ...state.edited,
    asset: {
      ...state.edited.asset,
      metadata: metadataWithNewProperty,
    },
  };
  const stateAfterEdit = applyEdit(state, assetWithNewProperty);
  stateAfterEdit.fields = toEditorFields(
    state.fixedSchemaFields,
    state.fields.customSchema,
    stateAfterEdit.edited.asset.metadata.customProps
  );
  return stateAfterEdit;
}

type DeletePropertyAction = ["deleteProperty", string];

function deleteProperty<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  propertyToRemove: string
): AssetDetailsState<TAssetInput> {
  const customPropsWithoutProperty = deleteCustomProperty(state.edited.asset.metadata.customProps, propertyToRemove);

  const assetWithDeletedProperty: AssetContent<TAssetInput> = {
    ...state.edited,
    asset: {
      ...state.edited.asset,
      metadata: {
        ...state.edited.asset.metadata,
        customProps: customPropsWithoutProperty,
      },
    },
  };
  const stateAfterEdit = applyEdit(state, assetWithDeletedProperty);
  stateAfterEdit.fields = toEditorFields(
    state.fixedSchemaFields,
    state.fields.customSchema,
    stateAfterEdit.edited.asset.metadata.customProps
  );
  return stateAfterEdit;
}

type EditPropertyAction = ["editProperty", { propertyName: string; value: any }];

function editProperty<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  propertyName: string,
  value: any
): AssetDetailsState<TAssetInput> {
  const updatedMetadata = updateMetadataProperty(
    state.edited.asset.metadata,
    state.fixedSchemaFields,
    state.fields.all,
    propertyName,
    value
  );
  const editedAfterUpdate: AssetContent<TAssetInput> = {
    ...state.edited,
    asset: {
      ...state.edited.asset,
      metadata: updatedMetadata,
    },
  };
  const stateAfterEdit = applyEdit(state, editedAfterUpdate);
  return stateAfterEdit;
}

export function resetOriginalAndEdited<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  originalAsset: TAssetInput,
  creationDate?: string,
  lastModifiedDate?: string,
  fileFolders?: AssetFileFolder[],
  relations?: AssetSummary[]
): AssetDetailsState<TAssetInput> {
  const metadataValues = flattenPresentMetadata(originalAsset.metadata);

  const originalFileTreeRoot = convertToFileTree(toTitleCase(state.original.type), fileFolders);

  const originalFileFolders = convertToFileIdFolders(originalFileTreeRoot);

  const originalAssetContent: AssetContent<TAssetInput> = {
    fileTreeRoot: originalFileTreeRoot,
    asset: {
      ...originalAsset,
      fileFolders: originalFileFolders,
    },
    type: state.original.type,
    relations,
  };

  const newState: AssetDetailsState<TAssetInput> = {
    ...state,
    fields: toEditorFields(
      state.fixedSchemaFields,
      state.fields.customSchema,
      originalAssetContent.asset.metadata?.customProps
    ),
    metadataValues,
    original: originalAssetContent,
    edited: deepClone(originalAssetContent),
    selectedFileTreeNode: null,
    dataChanged: state.copying,
    creationDate,
    lastSaveDate: lastModifiedDate,
  };
  return selectAssetMetadata(newState);
}

function replaceDuplicateUpload<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  replacementFileInfo: FileInfo,
  aliasId: Uuid
): AssetDetailsState<TAssetInput> {
  if (state.selectedFileTreeNode.type === "File") {
    const newFileTreeRoot = Tree.replaceDuplicateUpload(
      state.edited.fileTreeRoot,
      state.selectedFileTreeNode,
      replacementFileInfo,
      aliasId
    );

    const fileUploads = Tree.allUploadsIn(newFileTreeRoot);
    const fileFolders = convertToFileIdFolders(newFileTreeRoot);

    return {
      ...state,
      edited: {
        ...state.edited,
        asset: {
          ...state.edited.asset,
          fileFolders,
          metadata: applyKeywords(fileUploads, state.edited.asset.metadata),
        },
        fileTreeRoot: newFileTreeRoot,
      },
    };
  } else return state;
}

function selectFileTreeNode<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  treeNode: Tree.FileTreeNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.selectFileTreeNode(state.edited.fileTreeRoot, state.selectedFileTreeNode, treeNode);

  return {
    ...state,
    selectedFileTreeNode: Tree.findNodeById(newFileTreeRoot, treeNode.id),
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

export function selectAssetMetadata<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>
): AssetDetailsState<TAssetInput> {
  return selectFileTreeNode(state, state.edited.fileTreeRoot);
}

function setFolderExpanded<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  folderToChange: Tree.FolderNode,
  expanded: boolean
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyExpanded(state.edited.fileTreeRoot, folderToChange, expanded);
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

type SetSchemaAction = ["setSchema", MetadataSchema];

function setSchema<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  newSchema: MetadataSchema
): AssetDetailsState<TAssetInput> {
  return {
    ...state,
    schema: newSchema,
    fields: toEditorFields(state.fixedSchemaFields, newSchema, state.edited.asset.metadata?.customProps),
  };
}

function startEditFolderName<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  folderToChange: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.applyStartEditFolderName(state.edited.fileTreeRoot, folderToChange);
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

function updateFailedUploads<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  failedUploads: NewFileInfo[],
  duplicateUploadInfos?: DuplicateUploadInfo[]
): AssetDetailsState<TAssetInput> {
  const newFileTreeRoot = Tree.updateFailedUploads(state.edited.fileTreeRoot, failedUploads, duplicateUploadInfos);

  return applyFileUploads(state, newFileTreeRoot);
}

// TODO: Refactor and move parts to AssetFileTreeState
//   shared with ModelDetailsState
function updateSuccessfulUploads<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  fileInfos: FileInfo[]
): AssetDetailsState<TAssetInput> {
  if (!fileInfos) {
    return;
  }

  const fileInfoAsUploads = fileInfos.map((fileInfo) => toFileInfoUpload(fileInfo));

  const newFileTreeRoot = Tree.applyReplaceFiles(state.edited.fileTreeRoot, fileInfoAsUploads);
  const newState = applyFileUploads(state, newFileTreeRoot);

  // TODO: This logic can probably be simplified.
  const newVersionOfSelectedFile = fileInfoAsUploads.find((fileUpload) => {
    if (state.selectedFileTreeNode?.type === "File") {
      return fileUpload.info.id === state.selectedFileTreeNode.file?.info.id;
    } else {
      return false;
    }
  });

  if (newVersionOfSelectedFile && state.selectedFileTreeNode?.type === "File") {
    const updatedFileTreeNode: Tree.FileNode = {
      ...state.selectedFileTreeNode,
      file: newVersionOfSelectedFile,
    };
    newState.selectedFileTreeNode = updatedFileTreeNode;
  }

  return newState;
}

export function downloadZip<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  axiosContext: AxiosInstance,
  folderToZip: Tree.FolderNode
): AssetDetailsState<TAssetInput> {
  if (state.dataChanged) {
    return {
      ...state,
      downloadZipBlocked: true,
    };
  }

  const assetId = state.edited.asset.id;
  const folderPath = !folderToZip ? "/" : folderToZip.path;

  axiosContext
    .get(`downloadAssetFileZip?assetId=${assetId}&folderPath=${folderPath}`, {
      responseType: "blob",
    })
    .then((response) => {
      const filename = response.headers["content-disposition"]?.replace(`attachment; filename="`, "").replace(`"`, "");
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      handleAxiosError(error, "An error occurred while downloading the zip.");
      console.error(error);
    });

  return state;
}

export function cancelDownloadZip<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>
): AssetDetailsState<TAssetInput> {
  return {
    ...state,
    downloadZipBlocked: false,
  };
}

export type AssetDetailsPageAction =
  | AddPropertyAction
  | DeletePropertyAction
  | DiscardChangesAction
  | EditMarkingsAction
  | EditPropertyAction
  | SetSchemaAction
  | Tree.AssetFileTreeAction;

export function AssetDetailsPageReducer<TAssetInput extends AssetInput>(
  state: AssetDetailsState<TAssetInput>,
  action: AssetDetailsPageAction
): AssetDetailsState<TAssetInput> {
  const actionType = action[0];
  switch (actionType) {
    case "addFolder":
      return addFolder(state, action[1]);
    case "addProperty":
      return addProperty(state, action[1]);
    case "addUploads":
      return addUploads(state, action[1].cancelToken, action[1].filePathsToUpload, action[1].folderNode);
    case "addExistingUploads":
      return addExistingUploads(state, action[1].filesToAdd, action[1].folderNode);
    case "cancelFolderEdit":
      return cancelFolderEdit(state, action[1]);
    case "cancelDownloadZip":
      return cancelDownloadZip(state);
    case "commitFolderEdit":
      return commitFolderEdit(state, action[1]);
    case "deleteFileTreeNode":
      return deleteFileTreeNode(state, action[1]);
    case "deleteProperty":
      return deleteProperty(state, action[1]);
    case "discardChanges":
      return discardChanges(state);
    case "downloadZip":
      return downloadZip(state, action[1].axiosContext, action[1].folderToZip);
    case "editFolderName":
      return editFolderName(state, action[1].editedFolder, action[1].newTitle);
    case "editMarkings":
      return editMarkings(state, action[1]);
    case "updateFailedUploads":
      return updateFailedUploads(state, action[1].failedUploads, action[1].duplicateUploadInfos);
    case "editProperty":
      return editProperty(state, action[1].propertyName, action[1].value);
    case "replaceDuplicateUpload":
      return replaceDuplicateUpload(state, action[1].fileInfo, action[1].aliasId);
    case "selectFileTreeNode":
      return selectFileTreeNode(state, action[1]);
    case "setFolderExpanded":
      return setFolderExpanded(state, action[1].folderToChange, action[1].expanded);
    case "setSchema":
      return setSchema(state, action[1]);
    case "startEditFolderName":
      return startEditFolderName(state, action[1]);
    case "updateSuccessfulUploads":
      return updateSuccessfulUploads(state, action[1]);
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////
// TODO: When all types are converted to having an imageId, this can be combined with the
//   details state above.

export type AssetWikiInput = ModelInfoInput | PlatformInput;

type AddImageIdAction = ["addImageId", Uuid];

function addImageId(state: AssetDetailsState<AssetWikiInput>, imageIdToAdd: Uuid): AssetDetailsState<AssetWikiInput> {
  const asset = { ...state.edited.asset };

  const editedAfterUpdate: AssetContent<AssetWikiInput> = {
    ...state.edited,
    asset: { ...asset, metadata: { ...asset.metadata, imageId: imageIdToAdd } },
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

export type AssetWikiPageAction = AssetDetailsPageAction | AddImageIdAction;

export function AssetWikiPageReducer(
  state: AssetDetailsState<AssetWikiInput>,
  action: AssetWikiPageAction
): AssetDetailsState<AssetWikiInput> {
  const actionType = action[0];
  switch (actionType) {
    case "addImageId":
      return addImageId(state, action[1]);
    default:
      return AssetDetailsPageReducer(state, action);
  }
}
