/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FieldSpec } from "./FieldSpec";
import { FieldEditor } from "../Shared/FieldEditor";
import { MarkableValueRecord } from "Api";
import { isMarkedValueRecord } from "../../Api/ApiExtensions";

const MetadataPropsEditor = ({
  fields,
  metadataValues,
  onValueChange,
  onPropertyDelete,
}: {
  fields: FieldSpec[];
  metadataValues: Record<string, MarkableValueRecord | any>;
  onValueChange?: (propertyName: string, value: any) => void;
  onPropertyDelete?: (propertyName: string) => void;
}): JSX.Element => {
  const editingDisabled = React.useMemo(() => {
    return onValueChange == null;
  }, [onValueChange]);

  return (
    <>
      {fields &&
        fields.map((field, index) => {
          // TODO: This was changed because of 9176, but it is not clear why field should have
          // a property name that's null. This likely indicates there is a bug (or at least
          // unexpected behavior in the caller), and this prevents and masks the issue. We should
          // look at root causing why this is happening because it may cause other issues.
          const propertyName = field.propertyName;
          if (!propertyName) return null;
          const rawValue = metadataValues ? metadataValues[propertyName] : undefined;
          const fieldValue = isMarkedValueRecord(rawValue)
            ? { contents: rawValue.contents.value, securityMarkings: rawValue.securityMarkings }
            : rawValue;

          return (
            <div key={propertyName + index} data-testid={`${propertyName}-div`}>
              <FieldEditor
                field={field}
                value={fieldValue}
                allowRemoval={field.removable && !editingDisabled}
                disabled={editingDisabled}
                onChange={(newValue) => onValueChange?.(propertyName, newValue)}
                onRemove={() => onPropertyDelete?.(field.propertyName)}
              />
            </div>
          );
        })}
    </>
  );
};

export default MetadataPropsEditor;
