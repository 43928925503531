/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useLazyQuery, gql } from "@apollo/client";
import { DuplicateUploadInfo, FileInfo, NewFileInfo } from "Api";
import * as React from "react";
import GetFiles from "../../Api/Gql/GetFiles.gql";
import { VerticalStack } from "../../DesignSystem/Containers";
import { ObservableFileUploader } from "../../Shared/ObservableFileUploader";
import { AssetFileTree } from "../Tree/AssetFileTree";
import { AssetFileTreeAction, FolderNode } from "../Tree/AssetFileTreeState";
import DownloadZipBlockedModal from "../Tree/DownloadZipBlockedModal";

export function AssetFileManager({
  root,
  downloadZipBlocked,
  dispatch,
}: {
  root?: FolderNode;
  downloadZipBlocked: boolean;
  dispatch: React.Dispatch<AssetFileTreeAction>;
}): JSX.Element {
  const [getFileInfos] = useLazyQuery(gql(GetFiles), {
    fetchPolicy: "network-only",
    onCompleted: (fileData) => {
      const fileInfos = fileData?.getFiles as FileInfo[];
      dispatch(["updateSuccessfulUploads", fileInfos]);
    },
  });

  const handleUploadCompleted = React.useCallback(
    (successfulUploads: NewFileInfo[], failedUploads: NewFileInfo[], duplicateUploadInfos: DuplicateUploadInfo[]) => {
      if (successfulUploads) {
        const succesfulUploadIds = successfulUploads.map((value) => value.id);
        getFileInfos({
          variables: { ids: succesfulUploadIds },
        });
      }
      if (failedUploads) {
        dispatch(["updateFailedUploads", { failedUploads, duplicateUploadInfos }]);
      }
    },
    [dispatch, getFileInfos]
  );

  return (
    <VerticalStack>
      <ObservableFileUploader
        showSecurityMarkingsControl={true}
        onUploadStart={(cancelToken, filePathsToUpload) => dispatch(["addUploads", { cancelToken, filePathsToUpload }])}
        onUploadComplete={handleUploadCompleted}
      />
      <AssetFileTree root={root} dispatch={dispatch} />
      <DownloadZipBlockedModal isOpen={downloadZipBlocked} dispatch={dispatch} />
    </VerticalStack>
  );
}
