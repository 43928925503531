/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { RelationPane } from "../Shared/RelationPane";
import { PlatformDetailsPageAction } from "./PlatformDetailsState";
import { AssetSummary, AssetTypeFilterInput } from "Api";

const validPlatformRelationTypeFilter: AssetTypeFilterInput = {
  assetTypes: ["MODEL"],
};

export function PlatformRelationPane({
  dispatch,
  relations,
}: {
  dispatch: React.Dispatch<PlatformDetailsPageAction>;
  relations?: AssetSummary[];
}): JSX.Element {
  return (
    <RelationPane
      relations={relations}
      inputEnabled={true}
      relationHeader="Related Models"
      typeFilter={validPlatformRelationTypeFilter}
      onAdd={(addedModel) => dispatch(["addRelatedModel", addedModel])}
      onRemove={(removedModel) => dispatch(["removeRelatedModel", removedModel])}
    />
  );
}
