export const CamelCaseToSpacedString = (str) => {
  if (typeof str !== 'string' || str.length <= 0) return '';

  let retString = str.charAt(0).toUpperCase();
  for (let i = 1; i < str.length; i++) {
    const char = str.charAt(i);
    const prevChar = str.charAt(i - 1);
    if (char === char.toUpperCase() && prevChar !== prevChar.toUpperCase()) retString += ' ';
    retString += char;
  }
  return retString;
};

export const NumberOnlyRegex = /(^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$)/;

export const AbsTimeToPrettyString = (absTime) => {
  const dateTime = new Date(
    absTime.year ?? 0,
    absTime.month ? absTime.month - 1 : 0,
    absTime.day ?? 0,
    absTime.hour ?? 0,
    absTime.minute ?? 0,
    absTime.second ?? 0,
    0 //milliseconds
  );

  return dateTime.toLocaleDateString('en-US') + ' ' + dateTime.toTimeString();
};
