import React, { useRef, useCallback, useEffect } from 'react';
import { wrappedLogger } from '../../utilities/wrappedLogger';
import { triggerEvent } from '../../utilities/events';
import { v4 as uuidv4 } from 'uuid';

/** Event type that is used to request content in the HomepageSidebarDrawer to be changed */
export const LEFT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED = 'SAFESIM/EVENTS/LEFT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED';
export const RIGHT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED = 'SAFESIM/EVENTS/RIGHT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED';
export const BOTTOM_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED = 'SAFESIM/EVENTS/BOTTOM_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED';
/** Event type that is used to clear content in the HomepageSidebarDrawer */
export const SIDEBAR_DRAWER_CONTENT_REMOVE_REQUESTED = 'SAFESIM/EVENTS/SIDEBAR_DRAWER_CONTENT_REMOVE_REQUESTED';

/**
 * Triggers a LEFT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED event
 * @param {React.ReactElement} drawerContent The content to render in the sidebar drawer
 */
const requestNewDrawerContentLeft = (drawerContent) => {
  wrappedLogger?.debug?.('LEFT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED');
  triggerEvent(LEFT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED, drawerContent);
};

/**
 * React hook that provides a callback that, when executed, requests new drawer content to be rendered in the sidebar drawer
 *
 * @returns A callback that takes a ReactElement as an argument and triggers a drawer content change request
 */
export const useLeftSidebarDrawerContentRequest = () => {
  /// id to denote the drawercontent specific to this hook, this will be used witin the drawer to unload only when the ///
  /// active drawer content is from this hook, to prevent unloading another hooks content when this component unmounts ///
  const hookUUID = useRef(uuidv4());

  /// don't want to use initial content, instead force the caller to use the method in their own useEffect if they
  /// want to invoke the drawer on their component load
  const hookCallback = useCallback((content) => {
    requestNewDrawerContentLeft({ ...content, id: hookUUID.current });
  }, []);

  /// should be called when the component unloads to signal to close the drawer if this hooks content is on display ///
  useEffect(() => {
    /// linter noted that the ref .current may have changed when the return unload method is called and to set the current to a local variable first ///
    const hookID = hookUUID.current;
    return () => requestNewDrawerContentLeft({ id: hookID, title: undefined });
  }, []);

  return hookCallback;
};

/**
 * Triggers a RIGHT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED event
 * @param {React.ReactElement} drawerContent The content to render in the sidebar drawer
 */
const requestNewDrawerContentRight = (drawerContent) => {
  wrappedLogger?.debug?.('RIGHT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED');
  triggerEvent(RIGHT_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED, drawerContent);
};

/**
 * React hook that provides a callback that, when executed, requests new drawer content to be rendered in the sidebar drawer
 *
 * @returns A callback that takes a ReactElement as an argument and triggers a drawer content change request
 */
export const useRightSidebarDrawerContentRequest = () => {
  /// id to denote the drawercontent specific to this hook, this will be used witin the drawer to unload only when the ///
  /// active drawer content is from this hook, to prevent unloading another hooks content when this component unmounts ///
  const hookUUID = useRef(uuidv4());

  /// don't want to use initial content, instead force the caller to use the method in their own useEffect if they
  /// want to invoke the drawer on their component load
  const hookCallback = useCallback((content) => {
    requestNewDrawerContentRight({ ...content, id: hookUUID.current });
  }, []);

  /// should be called when the component unloads to signal to close the drawer if this hooks content is on display ///
  useEffect(() => {
    /// linter noted that the ref .current may have changed when the return unload method is called and to set the current to a local variable first ///
    const hookID = hookUUID.current;
    return () => requestNewDrawerContentRight({ id: hookID, title: undefined });
  }, []);

  return hookCallback;
};

/**
 * Triggers a BOTTOM_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED event
 * @param {React.ReactElement} drawerContent The content to render in the sidebar drawer
 */
const requestNewDrawerContentBottom = (drawerContent) => {
  wrappedLogger?.debug?.('BOTTOM_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED');
  triggerEvent(BOTTOM_SIDEBAR_DRAWER_CONTENT_CHANGE_REQUESTED, drawerContent);
};

/**
 * React hook that provides a callback that, when executed, requests new drawer content to be rendered in the sidebar drawer
 *
 * @returns A callback that takes a ReactElement as an argument and triggers a drawer content change request
 */
export const useBottomSidebarDrawerContentRequest = () => {
  /// id to denote the drawercontent specific to this hook, this will be used witin the drawer to unload only when the ///
  /// active drawer content is from this hook, to prevent unloading another hooks content when this component unmounts ///
  const hookUUID = useRef(uuidv4());

  /// don't want to use initial content, instead force the caller to use the method in their own useEffect if they
  /// want to invoke the drawer on their component load
  const hookCallback = useCallback((content) => {
    requestNewDrawerContentBottom({ ...content, id: hookUUID.current });
  }, []);

  /// should be called when the component unloads to signal to close the drawer if this hooks content is on display ///
  useEffect(() => {
    /// linter noted that the ref .current may have changed when the return unload method is called and to set the current to a local variable first ///
    const hookID = hookUUID.current;
    return () => requestNewDrawerContentBottom({ id: hookID, title: undefined });
  }, []);

  return hookCallback;
};
