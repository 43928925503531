/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useQuery } from "@apollo/client";
import { AssetSummary, ClassificationStats, MostRecentTopicChanges } from "Api";
import { Spinner } from "baseui/spinner";
import * as React from "react";
import getClassificationStats from "../Api/Gql/GetClassificationStats.gql";
import getRecentlyModifiedAssetsInMyTopics from "../Api/Gql/GetRecentlyModifiedAssetsInMyTopics.gql";
import { TitledBorder } from "../DesignSystem/Containers";
import { handleApolloError } from "../Shared/Errors";
import { ClassificationChart } from "./ClassificationChart";
import { AssetSearchCommandBar } from "../Search/AssetSearchCommandBar";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { AssetsMissingKeywords } from "./AssetsMissingKeywords";
import { AssetsMissingSecurityMarkings } from "./AssetsMissingSecurityMarkings";
import { Button } from "baseui/button";
import { RecentActivity } from "./RecentActivity";
import { AssetSummaryTable } from "./AssetSummaryTable";
import { LabelLarge } from "baseui/typography";

const FIND_ASSETS_WITH_TEXT_QUERY_NAME = "FindAssetsWithText";

const dashboardColumnStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "40vw",
  height: "60vh",
  rowGap: "3rem",
};

export const Dashboard = (): JSX.Element => {
  const [selectedAssets, setSelectedAssets] = React.useState<AssetSummary[]>([]);

  const NEEDS_MARKINGS_KEY = "needsMarkings";
  const MISSING_KEYWORDS_KEY = "missingKeywords";
  const allKeys = [NEEDS_MARKINGS_KEY, MISSING_KEYWORDS_KEY];

  const [expanded, setExpanded] = React.useState<React.Key[]>(allKeys);

  const accordionButtons = (
    <div>
      <Button size="mini" kind="tertiary" onClick={() => setExpanded(allKeys)}>
        Expand All
      </Button>
      <Button size="mini" kind="tertiary" onClick={() => setExpanded([])}>
        Collapse All
      </Button>
    </div>
  );

  const { data } = useQuery(gql(getRecentlyModifiedAssetsInMyTopics), {
    fetchPolicy: "network-only",
    variables: {
      maxAssetsPerTopic: 3,
    },
    onError(error) {
      handleApolloError(error, "Error getting recently modified topic assets!");
    },
    onCompleted(data) {
      const topicChanges = data?.getRecentlyModifiedAssetsInMyTopics as MostRecentTopicChanges;
      const topicNames = topicChanges.topics.map((topicAssets) => topicAssets.topicName);
      const newExpandedKeys = [...expanded, ...topicNames];
      setExpanded(newExpandedKeys);
    },
  });

  const topicAssets = data?.getRecentlyModifiedAssetsInMyTopics as MostRecentTopicChanges;

  const populatedTopics = topicAssets?.topics?.filter((topic) => topic.assets?.length > 0) ?? [];

  const panelPaddingOverrides = {
    Content: {
      style: () => ({
        paddingTop: "0",
        paddingRight: "0",
        paddingBottom: "1rem",
        paddingLeft: "0",
      }),
    },
  };

  return (
    <>
      <AssetSearchCommandBar
        refetchQueryName={FIND_ASSETS_WITH_TEXT_QUERY_NAME}
        //TODO not necessary in this context. find a way to remove it
        assetSelectionState={[selectedAssets, setSelectedAssets]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "row",
          height: "80vh",
          columnGap: "5rem",
        }}
      >
        <div style={dashboardColumnStyle}>
          <TitledBorder title="Items of interest" buttonElement={accordionButtons}>
            <StatelessAccordion
              accordion={false}
              expanded={expanded}
              onChange={({ expanded }) => {
                setExpanded(expanded);
              }}
            >
              <Panel
                title="Resources that need security markings"
                key={NEEDS_MARKINGS_KEY}
                overrides={panelPaddingOverrides}
              >
                <AssetsMissingSecurityMarkings />
              </Panel>

              <Panel title="Resources without keywords" key={MISSING_KEYWORDS_KEY} overrides={panelPaddingOverrides}>
                <AssetsMissingKeywords />
              </Panel>
              {populatedTopics.map((populatedTopic) => (
                <Panel
                  title={`Because you subscribed to '${populatedTopic.topicName}'...`}
                  key={populatedTopic.topicName}
                  overrides={panelPaddingOverrides}
                >
                  <AssetSummaryTable assetList={populatedTopic?.assets} />
                </Panel>
              ))}
            </StatelessAccordion>
          </TitledBorder>
        </div>
        <div style={dashboardColumnStyle}>
          <SiteMetrics />
          <RecentActivity />
        </div>
      </div>
    </>
  );
};

const SiteMetrics = (): JSX.Element => {
  const { data, loading } = useQuery(gql(getClassificationStats), {
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, "Error retrieving recent activity"),
  });

  const classificationStats: ClassificationStats = data?.getClassificationStats;

  return (
    <TitledBorder title={"Metrics"}>
      {loading ? (
        <Spinner />
      ) : !classificationStats ? (
        <LabelLarge>{`Could not load classification stats.`}</LabelLarge>
      ) : (
        <ClassificationChart stats={classificationStats} />
      )}
    </TitledBorder>
  );
};
