/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import AssetSearchResultTable, { SelectableResultItem } from "./AssetSearchResultTable";
import { gql, useMutation } from "@apollo/client";
import { handleApolloError } from "../Shared/Errors";
import { AssetSearchCommandBar } from "./AssetSearchCommandBar";
import { Labels, Pagination } from "baseui/pagination";
import {
  AssetSummary,
  QuerySettingsInput,
  SortOrder,
  AssetType,
  SecurityMarkings,
  UpdateSecurityMarkingsInput,
  UpdateSecurityMarkingsResponse,
} from "Api";
import { boundWithinRange } from "../Utils/Common";
import { CenterControls, VerticalStack } from "../DesignSystem/Containers";
import { LoadingPlaceholder } from "../Shared/LoadingPlaceholder";
import { Uuid } from "../Utils/Types";
import { containsAny } from "../Utils/Array";
import {
  AssetFilter,
  AssetTypeFilter,
  ClassificationFilter,
  SearchSpec,
  toSearchSpecInput,
} from "../Asset/Shared/AssetFilter";
import { DateTime } from "luxon";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import deleteModelsMutation from "../Api/Gql/DeleteModels.gql";
import deletePlatformsMutation from "../Api/Gql/DeletePlatforms.gql";
import deleteScenariosMutation from "../Api/Gql/DeleteScenarios.gql";
import { notify } from "../Shared/Notify";
import { getUserClassificationLevels } from "../Shared/Security";
import deleteStudiesMutation from "../Api/Gql/DeleteStudies.gql";
import { UserContext } from "../Utils/UserContext";
import updateSecurityMarkings from "../Api/Gql/UpdateSecurityMarkings.gql";
import { useFindByTextQuery } from "../Shared/ApiHooks";

const FIND_ASSETS_WITH_TEXT_QUERY_NAME = "FindAssetsWithText";

const PAGE_LABELS: Labels = {
  prevButton: " ",
  nextButton: " ",
  preposition: "of",
};

const PAGE_SIZE = 50;

export const ResourceSearchPage = ({
  searchTextState: [searchText, _setSearchText],
  nextSearchTextState: [_nextSearchText, _setNextSearchText],
  currentPageState: [currentPage, setCurrentPage],
}: {
  searchTextState: [string, React.Dispatch<React.SetStateAction<string>>];
  nextSearchTextState: [string, React.Dispatch<React.SetStateAction<string>>];
  currentPageState: [number, React.Dispatch<React.SetStateAction<number>>];
}): JSX.Element => {
  const [sortField, setSortField] = React.useState<string | undefined>();

  const [sortOrder, setSortOrder] = React.useState<SortOrder | undefined>();

  const clearanceInfo = React.useContext(UserContext).clearanceInfo;

  const classificationFilter = React.useMemo<ClassificationFilter>(() => {
    return {
      classificationSelections: getUserClassificationLevels(clearanceInfo?.getMaxClearance()).map((level) => {
        return {
          active: false,
          classificationLevel: level,
        };
      }),
      selectUnmarked: false,
    };
  }, [clearanceInfo]);

  const assetTypeFilter = React.useMemo<AssetTypeFilter>(() => {
    return {
      selectedAssetTypes: [
        { assetType: "MODEL", checked: false },
        { assetType: "PLATFORM", checked: false },
        { assetType: "SCENARIO", checked: false },
        { assetType: "STUDY", checked: false },
      ],
    };
  }, []);

  const [filter, setFilter] = React.useState<SearchSpec>({
    beforeDate: { active: false, date: DateTime.now() },
    afterDate: { active: false, date: DateTime.now() },
    numericfilter: undefined,
    classificationFilter: classificationFilter,
    assetTypeFilter: assetTypeFilter,
    onlyIncludeAssetsWithoutKeywords: false,
  });

  const querySettings = React.useMemo<QuerySettingsInput>(() => {
    return {
      paging: {
        size: PAGE_SIZE,
        index: currentPage,
      },
      sort: {
        fieldName: sortField,
        order: sortOrder,
      },
    };
  }, [currentPage, sortField, sortOrder]);

  const searchSpecInput = React.useMemo(() => toSearchSpecInput(filter), [filter]);

  const { loading: findByTextLoading, response: textQueryResponse } = useFindByTextQuery(
    searchText,
    searchSpecInput,
    querySettings,
    "Error fetching resources!"
  );

  const modelTableData = React.useMemo(() => {
    return textQueryResponse?.results ?? [];
  }, [textQueryResponse?.results]);
  const pageCount = textQueryResponse?.pageCount ?? 1;

  const [selectedAssets, setSelectedAssets] = React.useState<AssetSummary[]>([]);

  const selectableAssetItems = React.useMemo<SelectableResultItem[]>(() => {
    return modelTableData.map((assetInfo) => {
      const isSelected = containsAny(
        selectedAssets.map(function (asset) {
          return asset.id;
        }),
        (selectedId) => selectedId === assetInfo.id
      );
      return {
        item: assetInfo,
        isSelected,
      };
    });
  }, [modelTableData, selectedAssets]);

  const [selectionRangeStart, setSelectionRangeStart] = React.useState<number>(0);

  const toggleSelection = React.useCallback(
    (selectableAsset: SelectableResultItem) => {
      if (selectableAsset.isSelected) {
        // It's selected, remove it from the selection
        //using assetInfo to try and grab asset type
        const newSelectedAssets = selectedAssets.filter((selectedId) => selectedId.id !== selectableAsset.item.id);
        selectableAsset.isSelected = false;
        setSelectedAssets(newSelectedAssets);
      } else {
        // It's not selected, add it to the selection
        //using assetInfo to try and grab asset type
        const newSelectedAssets = selectedAssets.concat(selectableAsset.item);
        selectableAsset.isSelected = true;
        setSelectedAssets(newSelectedAssets);
      }
    },
    [selectedAssets]
  );

  // TODO: commonize this for all tables
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, clickedItem: SelectableResultItem) => {
      const newSelectionIndex = selectableAssetItems.findIndex(
        (selectableItem) => selectableItem.item.id == clickedItem.item.id
      );

      // If we aren't holding shift, just track the click
      // as our start index.
      if (!event.shiftKey) {
        setSelectionRangeStart(newSelectionIndex);
      }

      if (event.ctrlKey) {
        toggleSelection(clickedItem);
      } else if (event.shiftKey) {
        const firstIndex = Math.min(selectionRangeStart, newSelectionIndex);
        const secondIndex = Math.max(selectionRangeStart, newSelectionIndex);

        const updatedAssetSelections = selectableAssetItems
          .map((selectableItem, index) => {
            if (index < firstIndex || index > secondIndex) {
              return { ...selectableItem, isSelected: false };
            } else {
              return { ...selectableItem, isSelected: true };
            }
          })
          .filter((selectableItem) => selectableItem.isSelected)
          .map((selectedItem) => selectedItem.item);

        setSelectedAssets(updatedAssetSelections);
      } else {
        // Change the selection to the selected item.
        setSelectedAssets([clickedItem.item]);
      }
    },
    [selectionRangeStart, selectableAssetItems, toggleSelection]
  );

  const handlePageChange = React.useCallback(
    ({ nextPage }) => {
      const pageToRequest = boundWithinRange(nextPage, 1, pageCount);
      setCurrentPage(pageToRequest);
    },
    [pageCount, setCurrentPage]
  );

  const toggleSortOrder = React.useCallback(() => {
    switch (sortOrder) {
      case "ASCENDING":
        setSortOrder("DESCENDING");
        break;
      case "DESCENDING":
        setSortOrder("ASCENDING");
        break;
      case undefined:
        setSortOrder("ASCENDING");
        break;
      default:
        const _exhaustiveCheck: never = sortOrder;
        return _exhaustiveCheck;
    }
  }, [sortOrder]);

  const handleSort = React.useCallback(
    (sortCol: string) => {
      if (sortCol === sortField) {
        toggleSortOrder();
      } else {
        setSortField(sortCol);
        setSortOrder("ASCENDING");
      }
    },
    [toggleSortOrder, sortField]
  );

  const handleFilter = React.useCallback(
    (newFilter: SearchSpec) => {
      setFilter(newFilter);
      setCurrentPage(1);
    },
    [setCurrentPage]
  );

  // TODO: The block of code / scope for delete is absolutely massive; consider moving to a separate component.

  const [deletePromptIsOpen, setDeletePromptIsOpen] = React.useState(false);

  const parsedDeleteModelsGql = gql(deleteModelsMutation);
  const parsedDeletePlatformsGql = gql(deletePlatformsMutation);
  const parsedDeleteScenariosGql = gql(deleteScenariosMutation);
  const parsedDeleteStudyGql = gql(deleteStudiesMutation);

  const [deleteModels] = useMutation(parsedDeleteModelsGql, {
    refetchQueries: [FIND_ASSETS_WITH_TEXT_QUERY_NAME],
  });

  const [deletePlatforms] = useMutation(parsedDeletePlatformsGql, {
    refetchQueries: [FIND_ASSETS_WITH_TEXT_QUERY_NAME],
  });

  const [deleteScenarios] = useMutation(parsedDeleteScenariosGql, {
    refetchQueries: [FIND_ASSETS_WITH_TEXT_QUERY_NAME],
  });

  const [deleteStudies] = useMutation(parsedDeleteStudyGql, {
    refetchQueries: [FIND_ASSETS_WITH_TEXT_QUERY_NAME],
  });

  function handleDeleteButtonClick(): void {
    setDeletePromptIsOpen(true);
  }

  function getDeleteCountMessage(count: number, assetType: AssetType): string {
    if (assetType === "STUDY" && count > 1) {
      return `${count} studies`;
    }

    let msg = `${count} ${assetType.toLowerCase()}`;
    return (msg += count > 1 ? "s" : "");
  }

  // handles deletion of multiple selections.
  async function handleDelete(): Promise<void> {
    // TODO: Consider moving this to a helper function that takes a list of assets
    //  and returns a map of type => asset
    const modelList: Uuid[] = [];
    const platformList: Uuid[] = [];
    const scenarioList: Uuid[] = [];
    const studyList: Uuid[] = [];

    selectedAssets.forEach((asset) => {
      switch (asset.type) {
        case "MODEL":
          modelList.push(asset.id);
          break;
        case "PLATFORM":
          platformList.push(asset.id);
          break;
        case "SCENARIO":
          scenarioList.push(asset.id);
          break;
        case "STUDY":
          studyList.push(asset.id);
          break;
      }
    });

    // TODO: Update to map of asset type => delete message
    let modelCountMsg = "";
    let platformCountMsg = "";
    let scenarioCountMsg = "";
    let studyCountMsg = "";

    if (modelList.length > 0) {
      await deleteModels({
        variables: { modelIds: modelList },
      }).then((response) => {
        const deletedCount = response.data?.deleteModels.deletedCount;
        modelCountMsg = getDeleteCountMessage(deletedCount, "MODEL");
      });
    }

    if (platformList.length > 0) {
      await deletePlatforms({
        variables: { platformIds: platformList },
      }).then((response) => {
        const deletedCount = response.data?.deletePlatforms.deletedCount;
        platformCountMsg = getDeleteCountMessage(deletedCount, "PLATFORM");
      });
    }

    if (scenarioList.length > 0) {
      await deleteScenarios({
        variables: { scenarioIds: scenarioList },
      }).then((response) => {
        const deletedCount = response.data?.deleteScenarios.deletedCount;
        scenarioCountMsg = getDeleteCountMessage(deletedCount, "SCENARIO");
      });
    }

    if (studyList.length > 0) {
      await deleteStudies({
        variables: { studyIds: studyList },
      }).then((response) => {
        const deletedCount = response.data?.deleteStudies.deletedCount;
        studyCountMsg = getDeleteCountMessage(deletedCount, "STUDY");
      });
    }

    notify.positive(getNotificationMessage(modelCountMsg, platformCountMsg, scenarioCountMsg, studyCountMsg));

    setSelectedAssets([]);
    setDeletePromptIsOpen(false);
  }

  const updateSecurityMarkingsGql = gql(updateSecurityMarkings);

  const [updateAssetSecurityMarkings] = useMutation(updateSecurityMarkingsGql, {
    refetchQueries: [FIND_ASSETS_WITH_TEXT_QUERY_NAME],
    onCompleted: (data) => {
      const changeSet: UpdateSecurityMarkingsResponse = data.updateSecurityMarkings;
      notify.positive(`Security markings updated for ${changeSet.numberUpdated} assets`);
    },
    onError: (error) => handleApolloError(error, "Error updating security markings."),
  });

  async function applySecurityMarkingsToSelected(securityMarkings: SecurityMarkings): Promise<void> {
    const updateMarkingsInput: UpdateSecurityMarkingsInput = {
      assetIds: selectedAssets.map((asset) => asset.id),
      securityMarkings: securityMarkings,
    };
    await updateAssetSecurityMarkings({
      variables: { markingsInput: updateMarkingsInput },
    });
  }

  const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1rem",
    justifyContent: "space-between",
    columnGap: "1rem",
  };

  const filterColumnStyle: React.CSSProperties = { width: "25%", paddingTop: "0.8rem" };
  const resultColumnStyle: React.CSSProperties = { width: "75%" };

  return (
    <>
      <VerticalStack style={{ flexGrow: 1 }}>
        <div style={rowStyle}>
          <div style={filterColumnStyle}></div>
          <div style={resultColumnStyle}>
            <AssetSearchCommandBar
              refetchQueryName={FIND_ASSETS_WITH_TEXT_QUERY_NAME}
              assetSelectionState={[selectedAssets, setSelectedAssets]}
              handleDelete={handleDeleteButtonClick}
              handleSecurityMarkingModalSave={applySecurityMarkingsToSelected}
            >
              <Pagination
                currentPage={currentPage}
                numPages={pageCount}
                onPageChange={handlePageChange}
                labels={PAGE_LABELS}
              />
            </AssetSearchCommandBar>
          </div>
        </div>

        <div style={rowStyle}>
          <div style={filterColumnStyle}>
            <AssetFilter filter={filter} onFilterChange={handleFilter} />
          </div>
          {findByTextLoading ? (
            <LoadingPlaceholder message={"Getting data..."} />
          ) : (
            <AssetSearchResultTable
              selectableResultItems={selectableAssetItems}
              refetchQueryName={FIND_ASSETS_WITH_TEXT_QUERY_NAME}
              onSort={handleSort}
              onClick={handleClick}
            />
          )}
        </div>
        <CenterControls>
          <Pagination
            currentPage={currentPage}
            numPages={pageCount}
            onPageChange={handlePageChange}
            labels={PAGE_LABELS}
          />
        </CenterControls>
      </VerticalStack>
      <DeleteConfirmationModal
        isOpen={deletePromptIsOpen}
        header={`Delete Assets`}
        // display title of asset to delete if only one selected
        // if many selected display the number of assets that will be deleted
        // make body text empty for initialization
        bodyText={`Are you sure you want to delete ${
          selectedAssets.length > 1
            ? selectedAssets.length.toString() + " assets"
            : selectedAssets.length > 0
            ? selectedAssets[0]?.title
            : ""
        }?`}
        onDelete={handleDelete}
        onCancel={() => setDeletePromptIsOpen(false)}
      />
    </>
  );
};

export function getNotificationMessage(
  modelCountMsg: string,
  platformCountMsg: string,
  scenarioCountMsg: string,
  studyCountMsg: string
): string {
  let msg = "";

  // TODO: Improve the 'flow' of the message for a human user. Consider a join
  if ((modelCountMsg + platformCountMsg + scenarioCountMsg + studyCountMsg).length <= 0) {
    msg = `Error resources were selected but not deleted.`;
  } else {
    msg = `Deleted: `;
    if (modelCountMsg.length) msg += `${modelCountMsg}, `;
    if (platformCountMsg.length) msg += `${platformCountMsg}, `;
    if (scenarioCountMsg.length) msg += `${scenarioCountMsg}, `;
    if (studyCountMsg.length) msg += `${studyCountMsg}`;
  }
  return msg;
}
