/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useReactiveVar } from "@apollo/client";
import * as React from "react";
import { simorAppSettingsVar } from "../GlobalState";

export function SettingsPage(): JSX.Element {
  const simorSettings = useReactiveVar(simorAppSettingsVar);

  return <pre>{JSON.stringify(simorSettings, null, 2)}</pre>;
}
