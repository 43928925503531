/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

/***********************************************************
 * Helper functions for converting between api representations
 * of the file tree and ones that are displayable in a tree.
 ***********************************************************/

import { AssetFileFolder, FileIdFolder } from "Api";
import { equalIgnoreCase } from "../../Utils/Sort";
import { Uuid } from "../../Utils/Types";
import * as Tree from "./AssetFileTreeState";
import { FileUpload, toClearedAssetFileUpload } from "./FileUpload";

export function convertToFileTree(rootName: string, fileFolders?: AssetFileFolder[]): Tree.FolderNode {
  const rootNode = Tree.newFolder(rootName, Tree.ROOT_FILE_TREE_NODE_ID);
  rootNode.isExpanded = true;

  if (fileFolders) {
    fileFolders.forEach((folder) => {
      const filesAsUploads = folder.files?.map((file) => toClearedAssetFileUpload(file)) ?? [];
      const pathWithoutLeadingSlash = folder.path.startsWith("/") ? folder.path.substring(1) : folder.path;
      insertPath(rootNode, pathWithoutLeadingSlash, filesAsUploads);
    });
  }

  return rootNode;
}

export function insertPath(subroot: Tree.FolderNode, path: string, fileUploads: FileUpload[]): void {
  if (!path || path === "") {
    const fileNodes = fileUploads.map((fileUpload) => Tree.newFile(fileUpload));
    subroot.children.push(...fileNodes);
    subroot.children.sort(Tree.compareFileTreeNodes);
    return;
  }

  const nextChildPath = Tree.childPath(path);
  const childFolder = acquireChildFolder(subroot, nextChildPath.child);
  insertPath(childFolder, nextChildPath.descendantPathAfterChild, fileUploads);
}

// NOTE: This function has a side-effect; it will modify the subroot's children.
export function acquireChildFolder(subroot: Tree.FolderNode, folderName: string): Tree.FolderNode {
  const firstFolderWithName = subroot.children.filter((child) => {
    return child.type === "Folder" && equalIgnoreCase(child.title, folderName);
  })[0];

  if (firstFolderWithName) {
    return firstFolderWithName as Tree.FolderNode;
  } else {
    const newFolder = Tree.newFolder(folderName);

    subroot.children.push(newFolder);
    subroot.children.sort(Tree.compareFileTreeNodes);
    return newFolder;
  }
}

export function toFileIdFolders(basePath: string, subroot: Tree.FolderNode): FileIdFolder[] {
  const filesInCurrentSubroot: Uuid[] = [];
  const allFolders: FileIdFolder[] = [];
  subroot.children?.forEach((child) => {
    switch (child.type) {
      case "File":
        // Only include files that aren't redacted; the server
        // will handle data from any redactions.
        if (child.file?.info?.id) {
          filesInCurrentSubroot.push(child.file.info.id);
        }
        break;
      case "Folder":
        const subfolderPath = `${basePath}/${child.title}`;
        const subfolders = toFileIdFolders(subfolderPath, child);
        allFolders.push(...subfolders);
        break;
      default:
        const _exhaustiveCheck: never = child;
        return _exhaustiveCheck;
    }
  });
  if (filesInCurrentSubroot.length > 0 || allFolders.length == 0) {
    const currentFolder: FileIdFolder = {
      path: basePath,
      fileIds: filesInCurrentSubroot,
    };
    allFolders.push(currentFolder);
  }
  return allFolders;
}

export function convertToFileIdFolders(root: Tree.FolderNode): FileIdFolder[] {
  return toFileIdFolders("", root);
}
