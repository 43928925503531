/**
 * Freezes an object and all its properties recursively, thereby making the object deeply immutable.
 * @param {object} object An object to freeze
 */
 export const deepFreeze = (object) => {
    Object.keys(object).forEach(property => {
      if (typeof object[property] === 'object' && !Object.isFrozen(object[property])) {
        deepFreeze(object[property]);
      }
    });
    return Object.freeze(object);
  };
