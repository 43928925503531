/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { LabelLarge } from "baseui/typography";
import * as React from "react";
import { themedUseStyletron as useStyletron } from "../DesignSystem/LightTheme";

/**
 * Centers the element's children both vertically and horizontally.
 */
export const CenteredContent = ({ height, children }: { height?: string; children?: React.ReactNode }): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        rowGap: "1rem",
        height: height ? height : "100vh",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const CenterControls = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const RightAlignedRow = ({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <Row
      style={{
        ...style,
        justifyContent: "flex-end",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </Row>
  );
};

// TODO: Eliminate duplication with RightAlignedRow
export const Row = ({
  children,
  style,
  gap,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  gap?: string | number;
}): JSX.Element => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        gap: gap,
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const VerticalStack = ({
  gap = "1.5rem",
  children,
  style,
}: {
  gap?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "column",
        gap: gap,
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};

// TODO: This looks similar to row see if there is anything we can do differently.
export const HorizontalStack = ({
  gap = "1.5rem",
  children,
  style,
}: {
  gap?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <div
      style={{
        ...style,
        paddingTop: "0.5rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        justifyItems: "center",
        gap: gap,
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};

export const VerticalSpacer = ({ height }: { height: string }): JSX.Element => {
  return <div style={{ height: height }}></div>;
};

// TODO: Remove left/right, add check for no more than 2 elements.
export const EndAnchoredRow = ({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  const childCount = React.Children.count(children);
  if (childCount > 2) {
    throw Error(`Cannot have more than 2 children for an EndAnchoredRow, but had ${childCount}`);
  }
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};

export const TitledBorder = ({
  title,
  children,
  buttonElement,
  hintElement,
}: {
  title?: string;
  hintElement?: JSX.Element;
  buttonElement?: JSX.Element;
  children?: React.ReactNode;
}): JSX.Element => {
  const [css, theme] = useStyletron();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "0.8rem",
        flexGrow: 1,
      }}
    >
      <HorizontalStack>
        <LabelLarge>
          {title ?? ""}
          {hintElement}
        </LabelLarge>
        {buttonElement}
      </HorizontalStack>

      <div
        className={css({
          marginLeft: "0.5rem",
          borderColor: theme.colors.accent100,
          borderStyle: "solid",
          borderWidth: "1px",
          padding: "1rem 2rem 1rem 2rem",
          flexGrow: 1,
        })}
      >
        {React.Children.toArray(children)}
      </div>
    </div>
  );
};
