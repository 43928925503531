import { Classes } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { NumberOnlyRegex } from '../utilities/stringModifiers';
import { SafesimNumericInput, SafesimLabel } from './allSafesimComponents';
import { SafesimPopoverTag } from './SafesimPopoverTag';
import { SafesimControlGroup, SafesimControlGroupProps } from './SafesimControlGroup';

interface Props extends SafesimControlGroupProps {
  label: string;
  labelContent?: JSX.Element;
  value: number;
  editDisabledDisplayString?: string;
  onValueChange: (num: number, str: string) => void;
  labelClassName?: string;
  valueClassName?: string;
  units?: string;
  decimal?: boolean;
  editDisabled: boolean;
  validator?: (num: number, str: string) => boolean;
  unitPopoverContent?: JSX.Element;
  rightSideContent?: JSX.Element;
  modified: boolean;
}

/**
 * Functional component that displays a string in a disabled input that can be edited.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string | number} value Value to display in the disabled SafesimInputGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {boolean} disallowBlankValue boolean controlling if an empty string is an acceptable value
 * @param {function} validator callback for input validation when value is changed
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {string} units unit to display next to value
 * @param {boolean} decimal whether decimal values are allowed. False if only integer values are allowed.
 * @param {boolean} editDisabled the starting state of the input. True if editing is disabled at the start.
 * @param {JSX.Element} unitPopoverContent element to show in popup when clicking unit tag
 * @param {JSX.Element} rightSideContent element displayed to the right of the config input
 * @returns Numeric input with label and units
 */
export const SafesimFormMeasurementInput = (props: Props): JSX.Element => {
  const {
    label,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    labelClassName,
    valueClassName,
    units,
    decimal,
    editDisabled = false,
    validator,
    unitPopoverContent,
    rightSideContent,
    modified,
    ...rest
  } = props;

  const [isValueValid, setIsValueValid] = useState(true);

  // if value loads or changes, verify validity
  useEffect(() => {
    setIsValueValid(validator?.(value, value?.toString()) ?? true);
  }, [value, validator]);

  return (
    <SafesimControlGroup style={{ padding: '5px' }} fill={true} {...rest}>
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={`${Classes.FIXED} ${labelClassName}`} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimNumericInput
        className={`${valueClassName} ${!isValueValid ? 'makeRed' : modified ? 'make-green' : ''}`}
        value={editDisabled ? editDisabledDisplayString ?? value : isNaN(value) ? '' : value}
        onValueChange={(num: number, str: string) => {
          if ((str === '' || str === '-' || str === '.' || str === '-.' || NumberOnlyRegex.test(str)) && (decimal || !str.includes('.'))) {
            onValueChange(num, str);
            setIsValueValid(validator?.(num, str) ?? true);
          }
        }}
        disabled={editDisabled}
        fill={true}
        rightElement={units && <SafesimPopoverTag popoverContent={!editDisabled ? unitPopoverContent : undefined} text={units} />}
      />
      {rightSideContent}
    </SafesimControlGroup>
  );
};
