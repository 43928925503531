/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { GeoLocation } from "Api";
import { Input } from "baseui/input";
import React, { useEffect } from "react";

export const GeoLocationEditor = ({
  value,
  disabled,
  label,
}: {
  value: any;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
  label?: string;
}): JSX.Element => {
  const [lat, setLat] = React.useState<number | string>();
  const [lon, setLon] = React.useState<number | string>();

  useEffect(() => {
    setLat((value as GeoLocation)?.latitude);
    setLon((value as GeoLocation)?.longitude);
  }, [value]);

  return (
    <>
      <Input
        value={lat || ""}
        onChange={(e) => setLat(e.currentTarget.value)}
        disabled={disabled}
        name={label}
        placeholder="Latitude"
        clearOnEscape
      />
      <Input
        value={lon || ""}
        onChange={(e) => setLat(e.currentTarget.value)}
        disabled={disabled}
        name={label}
        placeholder="Longitude"
        clearOnEscape
      />
    </>
  );
};
