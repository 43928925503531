import { wrappedLogger } from './wrappedLogger';
import { inRange } from 'lodash';

// Object holding schema fields and their min/max value
// used for string length validation and form helperText
export const schemaStringFields = {
  name: { minLength: 0, maxLength: 256 }, // NameType Schema
  description: { minLength: 0, maxLength: 1024 }, // DescriptionType Schema
};

/**
 * Function that verifies a string has a valid length
 * implements lodash inRange https://www.geeksforgeeks.org/lodash-_-inrange-method/
 * @param {number} value The number to be validated
 * @param {number} minLength The minimum valid length of value. If only minLength provided, checks value is between 0 and minLength
 * @param {number} maxLength The maximum valid length of value
 * @returns {boolean} Whether the provided value is of a valid length
 */
export const validateLength = (value: number, minLength: number, maxLength?: number) => {
  try {
    /// https://www.geeksforgeeks.org/lodash-_-inrange-method/ ///
    return inRange(value, minLength, maxLength ? maxLength + 1 : undefined); // +1 to include limit as valid value in range
  } catch (e) {
    wrappedLogger?.error?.(e);
    return false;
  }
};
