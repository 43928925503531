/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { Button, ButtonOverrides } from "baseui/button";

const modelCommandButtonStyle: ButtonOverrides = {
  BaseButton: {
    style: {
      width: "7rem",
    },
  },
};

export const DeleteButton = ({ handleDelete: handleDelete }: { handleDelete: () => void }): JSX.Element => {
  const handleDeleteClick = React.useCallback(() => {
    handleDelete();
  }, [handleDelete]);

  return (
    <>
      <Button kind="tertiary" overrides={modelCommandButtonStyle} onClick={handleDeleteClick}>
        {"Delete"}
      </Button>
    </>
  );
};
