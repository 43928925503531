import { createContext, useContext } from 'react';

/**
 * Context used to know if a popout window is open.
 */
export const PopoutContext = createContext({});

/**
 * React hook that can be used to keep track of if a popout window is open.
 * @returns true if popout is open and false otherwise
 */

export const usePortal = () => {
  const popoutOpen = useContext(PopoutContext);

  return popoutOpen;
};
