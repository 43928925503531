/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { createTheme, createThemedStyled, createThemedUseStyletron, createThemedWithStyle } from "baseui";
import { BasewebKmTypography } from "./BasewebKmTypography";
import { Colors, ThemePrimitives, Typography } from "baseui/theme";
import { KmLightTheme } from "./KobayashiMaru/KmLightTheme";
import { KmTypography } from "./KobayashiMaru/KmTypography";

import type { Theme } from "baseui";

// A list of all BaseWeb primitives:
// Visualization of all primitive defaults:
// https://baseweb.design/guides/theming#theme-properties
//
// Type definitions for primitives:
// https://github.com/uber/baseweb/blob/master/src/theme.ts

export interface SimorPrimitives {
  destructiveActionColor: string;
  subtleBackground: string;
  selectionBackgroundPrimary: string;
  selectionBackgroundPrimaryDark: string;
}

type SimorTheme = Theme & {
  colors: SimorPrimitives;
};

export const themedStyled = createThemedStyled<SimorTheme>();
export const themedWithStyle = createThemedWithStyle<SimorTheme>();
export const themedUseStyletron = createThemedUseStyletron<SimorTheme>();

export const primitives: ThemePrimitives & SimorPrimitives & any = {
  // // Primary Palette
  primary: KmLightTheme.colors.primaryBlue,
  primary50: "#DEEDF6",
  primary100: "#C4DEEE",
  primary200: "#96C5E2",
  primary300: "#58A2D0",
  primary400: KmLightTheme.colors.primaryLight,
  primary500: KmLightTheme.colors.primaryBlue,
  primary600: "#005482",
  primary700: KmLightTheme.colors.primaryDark,
  primaryFontFamily: KmTypography.fontFamily,
  backgroundPrimary: "#FFFFFF",

  // Non-standard override
  destructiveActionColor: "#d11a2a",
  subtleBackground: "#dddddd",
  selectionBackgroundPrimary: "#DEECF9",
  selectionBackgroundPrimaryDark: "#bbd8f2",
};

// List of all BaseWeb overrides:
// https://github.com/uber/baseweb/blob/master/src/themes/light-theme/color-component-tokens.js

// TODO: Look into casting overrides or at least give a link
const overrides = {
  colors: {
    fileUploaderBorderColorActive: KmLightTheme.fileUpload.dropAreaLineColorHover,
    fileUploaderBorderColorDefault: KmLightTheme.fileUpload.dropAreaLineColor,
    fileUploaderMessageColor: KmLightTheme.colors.defaultTextColor,

    buttonPrimaryText: KmLightTheme.colors.primaryElementFontColor,
  } as Colors,
  typography: BasewebKmTypography.extensions as Typography,
};

const BasicLightTheme = createTheme(primitives, overrides);

export const LightTheme = {
  ...BasicLightTheme,
  km: { ...KmLightTheme },
};
