import { SecurityMetadata } from '../types/SecurityMetadata';

/**
 *
 * @param {SecurityMetadata} label Security metadata object
 * @returns Comma-separated list of security markings as: classification, sci, sar, dissemination controls
 */
export const getSecurityMetadataStr = (label: SecurityMetadata): string => {
  const sci = label?.scicontrols?.join(' ');
  const sar = label?.saridentifier?.join(' ');
  const disseminationControls = label?.disseminationControls?.join(' ');

  let labelStr = label?.classification ?? '';
  if (sci) {
    labelStr += ', ' + sci;
  }
  if (sar) {
    labelStr += ', ' + sar;
  }
  if (disseminationControls) {
    labelStr += ', ' + disseminationControls;
  }
  return labelStr;
};
