/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { BaseFileUploader } from "../Shared/BaseFileUploader";

export function BulkAddFileModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element {
  return (
    <BaseFileUploader
      title={"Add File(s)"}
      subTitle={"Choose files to upload."}
      isModalOpen={isOpen}
      onClose={onClose}
      multiple={true}
    />
  );
}
