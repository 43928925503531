/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import SearchInput from "../Shared/SearchInput";
import SearchResultTable, { SelectableFileInfoItem } from "./FileSearchResultTable";
import { gql, useLazyQuery } from "@apollo/client";
import SearchFiles from "../Api/Gql/SearchFiles.gql";
import { handleApolloError } from "../Shared/Errors";
import { VerticalStack } from "../DesignSystem/Containers";
import { isUndefined } from "lodash";

export function FileSearchPage({
  selectedFileInfo,
  handleHighlight,
  componentHeight,
}: {
  selectedFileInfo?: SelectableFileInfoItem;
  handleHighlight?: (selectedFileInfo: SelectableFileInfoItem) => void;
  componentHeight?: string;
}): JSX.Element {
  const [nextSearchText, setNextSearchText] = React.useState("");

  const [searchFiles, { loading, data }] = useLazyQuery(gql(SearchFiles), {
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, "Error fetching files!"),
  });

  const handleSearch = React.useCallback(
    (searchValue: string) => {
      searchFiles({ variables: { nameSubstring: searchValue } });
    },
    [searchFiles]
  );

  React.useEffect(() => {
    handleSearch("");
  }, [handleSearch]);

  const handleSelection = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, clickedItem: SelectableFileInfoItem) => {
      if (handleHighlight) handleHighlight(clickedItem);
    },
    [handleHighlight]
  );

  return (
    <VerticalStack style={{ height: componentHeight ?? "100%" }}>
      <SearchInput
        searchValue={nextSearchText}
        placeholder="Search files"
        onSearchTextChange={(newSearchText) => setNextSearchText(newSearchText)}
        onSearch={handleSearch}
        style={{ paddingTop: "1rem", margin: "1rem", boxSizing: "content-box" }}
      />
      {loading ? (
        <div>Loading</div>
      ) : (
        <SearchResultTable
          fileSearchResults={data?.searchFiles || []}
          onClick={handleSelection}
          selectedFileInfo={selectedFileInfo}
          overflow={isUndefined(componentHeight) ? "hidden" : "scroll"}
        />
      )}
    </VerticalStack>
  );
}
