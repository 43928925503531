query {
  getClassificationStats {
    models {
      classification {
        count
        level
      }
      total
    }
    platforms {
      classification {
        count
        level
      }
      total
    }
    scenarios {
      classification {
        count
        level
      }
      total
    }
    studies {
      classification {
        count
        level
      }
      total
    }
    total {
      classification {
        count
        level
      }
      total
    }
  }
}