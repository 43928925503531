/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { BaseFileUploader } from "./BaseFileUploader";
import { FileUploadResponse } from "Api";

export function AssetPictureUploadModal({
  isModalOpen,
  onClose,
  onUploadComplete,
}: {
  isModalOpen: boolean;
  onClose: () => void;
  onUploadComplete: (data: FileUploadResponse) => void;
}): JSX.Element {
  const typeFilters = [".png", ".jpeg", ".jpg"];
  return (
    <BaseFileUploader
      title={"Upload a picture"}
      subTitle="Choose a picture to upload"
      isModalOpen={isModalOpen}
      onClose={onClose}
      multiple={false}
      typeFilters={typeFilters}
      extraErrorInfo={`please upload a file of the following types ${typeFilters}`}
      onUploadComplete={onUploadComplete}
    />
  );
}
