/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { VerticalStack } from "../../DesignSystem/Containers";
import { LabelLarge } from "baseui/typography";
import { WizardNavigationButtons } from "./WizardComponents";
import { ScenarioWizardStep } from "./ScenarioSpec";

export function StepBody({
  stepName,
  onStepChange,
  previousStep,
  previousDisabled,
  nextStep,
  nextLoading,
  nextDisabled,
  isFinalStep,
  children,
}: {
  stepName: ScenarioWizardStep;
  onStepChange: (newStep: ScenarioWizardStep) => void;
  previousStep?: ScenarioWizardStep | undefined;
  previousDisabled?: boolean;
  nextStep?: ScenarioWizardStep | undefined;
  nextLoading?: boolean;
  nextDisabled?: boolean;
  isFinalStep?: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <VerticalStack>
      <LabelLarge>{stepName}</LabelLarge>
      {React.Children.toArray(children)}
      <WizardNavigationButtons
        previousStep={previousStep}
        previousDisabled={previousDisabled}
        nextStep={nextStep}
        nextLoading={nextLoading}
        nextDisabled={nextDisabled}
        onStepChange={onStepChange}
        isFinalStep={isFinalStep}
      />
    </VerticalStack>
  );
}
