/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Button } from "baseui/button";
import { gql, useMutation, useQuery, FetchResult } from "@apollo/client";
import { UserProfileInput, UserPreferences, User } from "Api";
import getTopics from "../Api/Gql/GetTopics.gql";
import GetUserById from "../Api/Gql/GetUserById.gql";
import { notify } from "../Shared/Notify";
import { useEffect } from "react";
import { useStyletron } from "baseui";
import { ParagraphMedium, HeadingMedium } from "baseui/typography";
import { TopicSelector } from "./TopicSelector";
import { handleApolloError } from "../Shared/Errors";
import { arraysContainSameElements } from "../Utils/Array";
import updateUserProfile from "../Api/Gql/UpdateUserProfile.gql";
import { UserContext } from "../Utils/UserContext";

export function UserPreferences(): JSX.Element {
  const user = React.useContext(UserContext).profile;
  const { loading: topicsAreLoading, data: topicsData } = useQuery(gql(getTopics), {
    fetchPolicy: "network-only",
  });

  const { data: userPrefsData, refetch: refetchUserPrefsData } = useQuery(gql(GetUserById), {
    variables: { userId: user?.id },
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, "Error fetching preferences for user!"),
  });
  const [css] = useStyletron();

  const [selectedTopics, setSelectedTopics] = React.useState<string[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [updateUserProfileMutation] = useMutation(gql(updateUserProfile));
  function saveUserTopicSelections(): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> {
    const newPreferences = {
      topicsOfInterest: selectedTopics,
    } as UserPreferences;
    // TODO SECURITY: We do not want someone to be
    // able to change their username (just their display name)
    const updatedUserProfile = {
      id: user.id,
      person: user.person,
      preferences: newPreferences,
    } as UserProfileInput;
    return updateUserProfileMutation({
      variables: { userProfile: updatedUserProfile },
    });
  }

  function handleUserUpdateResult(result: FetchResult<any, Record<string, any>, Record<string, any>>): void {
    const data = result.data;
    if (data?.updateUserProfile?.id) {
      notify.positive("Successfully updated preferences");
    } else {
      notify.negative("Error updating preferences");
    }
    setIsLoading(false);
  }

  async function handleSave(): Promise<void> {
    try {
      const userUpdateResult = await saveUserTopicSelections();
      handleUserUpdateResult(userUpdateResult);
      refetchUserPrefsData();
    } catch (e) {
      notify.negative("Error updating preferences");
    }
  }

  const userHasUnchangedPreferences = React.useMemo((): boolean => {
    const user: User = userPrefsData?.getUserById as User;
    const persistedUserSelectedValues = (user?.preferences?.topicsOfInterest as string[]) ?? [];
    return arraysContainSameElements(persistedUserSelectedValues, selectedTopics);
  }, [selectedTopics, userPrefsData?.getUserById]);

  const resetPreferences = React.useCallback(() => {
    setSelectedTopics(userPrefsData?.getUserById?.preferences?.topicsOfInterest);
  }, [userPrefsData?.getUserById?.preferences?.topicsOfInterest]);

  useEffect(() => resetPreferences(), [resetPreferences]);

  return (
    <>
      {user ? (
        <div className={css({ margin: "auto", textAlign: "center", width: "50%" })}>
          <HeadingMedium>{`${user.username ? user.username : "User"}'s`} Preferences</HeadingMedium>
          <ParagraphMedium>
            Discover more of what matters to you by choosing the terms that interest you the most.
          </ParagraphMedium>
          <br />
          {topicsAreLoading ? (
            <div>Loading</div>
          ) : (
            <div>
              <TopicSelector
                topics={topicsData?.getTopics}
                selectedTopics={selectedTopics}
                onSelectionChange={setSelectedTopics}
              />
              <br /> <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ marginRight: 20 }}>
                  <Button disabled={userHasUnchangedPreferences} kind={"tertiary"} onClick={resetPreferences}>
                    Discard Changes
                  </Button>
                </div>
                <div>
                  <Button disabled={userHasUnchangedPreferences} isLoading={isLoading} onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={css({ margin: "auto", textAlign: "center", width: "50%" })}>
          <HeadingMedium>Login to view user preferences</HeadingMedium>
        </div>
      )}
    </>
  );
}
