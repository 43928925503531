/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import _ from "lodash";

export const deepClone = <T>(value: T): T => _.cloneDeep(value);

export function boundWithinRange(value: number, lowerLimit: number, upperLimit: number): number {
  return Math.min(Math.max(value, lowerLimit), upperLimit);
}
