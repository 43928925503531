/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { HeadingXXLarge, LabelSmall, HeadingXLarge } from "baseui/typography";
import * as React from "react";
import { CommandBar } from "../DesignSystem/CommandBar";
import { CenteredContent, EndAnchoredRow, VerticalSpacer } from "../DesignSystem/Containers";
import { StatefulTooltip } from "baseui/tooltip";
import { Block } from "baseui/block";
import { formattedDateTime, getRelativeLastModifiedTime } from "../Api/ApiSerialization";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { ContentPage } from "./ContentPage";

// TODO: There is now some commonality with the FileDetailsPage; in the future, the FileDetailsPage
//  could be simplified by using the loading / loadError properties of this component.
export const DetailsPage = ({
  loading,
  loadError,
  children,
}: {
  loading?: boolean;
  loadError?: string;
  children?: React.ReactNode;
}): JSX.Element => {
  if (loading) {
    return <LoadingPlaceholder message="Loading page details" />;
  }

  if (loadError) {
    return (
      <CenteredContent height="80vh">
        <HeadingXLarge>{loadError}</HeadingXLarge>
      </CenteredContent>
    );
  }

  return <ContentPage>{children}</ContentPage>;
};

export const DetailsHeaderBar = ({
  title,
  children,
  creationDate,
  lastSaveDate,
}: {
  title: string;
  children?: React.ReactNode;
  creationDate?: string;
  lastSaveDate?: string;
}): JSX.Element => {
  const relativeTime = React.useMemo(() => {
    return getRelativeLastModifiedTime(creationDate, lastSaveDate);
  }, [creationDate, lastSaveDate]);

  return (
    <>
      <EndAnchoredRow>
        <HeadingXXLarge>{title}</HeadingXXLarge>
        <CommandBar>{React.Children.toArray(children)}</CommandBar>
      </EndAnchoredRow>
      <VerticalSpacer height={"1rem"}></VerticalSpacer>
      <VerticalSpacer height={"1rem"}></VerticalSpacer>
      {creationDate && (
        <EndAnchoredRow>
          <LabelSmall>{`Created: ${formattedDateTime(creationDate)}`}</LabelSmall>

          <StatefulTooltip
            content={() => (
              <Block padding={"10px"}>{lastSaveDate && `Last Updated: ${formattedDateTime(lastSaveDate)}`}</Block>
            )}
            returnFocus
            autoFocus
          >
            {lastSaveDate && <LabelSmall>{`Last Updated: ${relativeTime}`}</LabelSmall>}
          </StatefulTooltip>
        </EndAnchoredRow>
      )}
      <VerticalSpacer height={"2rem"}></VerticalSpacer>
    </>
  );
};
