import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SafesimText, SafesimLabel, SafesimTooltip, SafesimButton, SafesimScrollableGrid, SafesimPopover, SafesimMenuItem, SafesimSpinner } from './allSafesimComponents';
import { SafesimSelect } from './SafesimSelect';
import { SafesimMultiSelect } from './SafesimMultiSelect';
import { SafesimControlGroup } from './SafesimControlGroup';
import PropTypes from 'prop-types';

/**
 * Master/Details component that displays a list of items and shows details of a selected item. Allows filtering the item list.
 *
 * @param {*} props
 * - `defaultElement` - default item to render when no elements are present
 * - `defaultFilter` - a single filter object with keys 'key', 'value' and 'tag'; key being what it's filtered on, value being the filter value, tag being what shows in the pane.
 * - `filterTags` - The list of tags which can be filtered on (e.g. Name, GUID)
 * - `itemArray` - list to bypass using axios if the caller wants to manually provide the items array
 * - `filterItem` - callback that takes an item and list of "property:value" parameters, and returns whether that item contains all of the matching values
 * - `itemRenderer` - callback used to take any item from the itemsArray and produce the renderable component
 * - `onItemsFiltered` - callback to notify container that the items have been filtered locally
 * - `onRefreshClicked` - callback to notify container that a data refresh has been triggered
 * - `onSelectedFiltersChanged` - callback to notify container that the selected filters have changed
 * - `quickFilterItems` - collection of quick filter items to show
 * - `renderedError` - Error element to display
 * - `showItemCount` - bool prop indicating if the top left count of items should be shown or hidden
 * - `showLoading` - display loading indicator if true
 * - `showRefresh` - bool prop indicating if the refresh list button should be shown or hidden
 * - `scrollDirection` - the scroll direction (vertical or horizontal) in which to display the results
 */
export const SafesimItemBrowse = ({
  className,
  defaultElement,
  defaultFilter,
  filterTags = [],
  itemArray,
  filterItem,
  itemRenderer,
  onItemsFiltered,
  onRefreshClicked,
  onSelectedFiltersChanged,
  quickFilterItems = [],
  renderedError,
  showItemCount,
  showLoading,
  showRefresh,
  scrollDirection,
  ...otherProps
}) => {
  // Initialize local state
  const [filteredItemArray, setFilteredItemArray] = useState(undefined);
  const [query, setQuery] = useState('');
  const [selectedProp, setSelectedProp] = useState(defaultFilter?.key && defaultFilter?.value && defaultFilter?.tag ? defaultFilter.key : undefined); // The keys (e.g. name, GUID) to filter on
  const [selectedFilters, setSelectedFilters] = useState(defaultFilter?.key && defaultFilter?.value && defaultFilter?.tag ? [defaultFilter] : []); // The values for a key (e.g. a name of a platform) to filter on

  const dataArray = useMemo(() => filteredItemArray, [filteredItemArray]);
  const onQueryChange = useCallback(
    (newQuery) => {
      setQuery(newQuery);
    },
    [setQuery]
  );
  const onRemoveFilter = useCallback(
    (item) => {
      const updatedFilters = selectedFilters.filter((value) => value !== item);
      setSelectedFilters(updatedFilters);

      if (onSelectedFiltersChanged) {
        onSelectedFiltersChanged(updatedFilters);
      }
    },
    [selectedFilters, setSelectedFilters, onSelectedFiltersChanged]
  );

  /// use effect for when data is filtered locally ///
  useEffect(() => {
    if (typeof filterItem === 'function' && selectedFilters?.length > 0) {
      const filteredItems = itemArray.filter((item) => filterItem(item, selectedFilters));
      if (onItemsFiltered) onItemsFiltered(filteredItems);
    } else {
      setFilteredItemArray(itemArray);
    }
  }, [itemArray, filterItem, selectedFilters, onItemsFiltered]);

  // We can change this to a useCallback if we just use the query value being passed in as an argument, rather than using the query state value as a dependency
  const createNewItemCallback = useCallback(
    (newQuery) => {
      if (!selectedProp || !newQuery || selectedFilters.includes(`${selectedProp}:${newQuery}`)) {
        return undefined;
      }
      return { key: selectedProp, value: newQuery, tag: `${selectedProp}:${newQuery}` };
    },
    [selectedProp, selectedFilters]
  );

  const onFiltersAdded = useCallback(
    (newFilter) => {
      if (!newFilter) return;
      const updatedFilters = selectedFilters.concat(newFilter);
      setSelectedFilters(updatedFilters);

      if (onSelectedFiltersChanged) {
        onSelectedFiltersChanged(updatedFilters);
      }
    },
    [selectedFilters, setSelectedFilters, onSelectedFiltersChanged]
  );

  const showQuickFilters = useMemo(
    () =>
      Array.isArray(quickFilterItems) &&
      Array.isArray(dataArray) &&
      quickFilterItems.filter((filterItem) => !selectedFilters?.map((sv) => sv.key).includes(filterItem))?.length > 0,
    [quickFilterItems, dataArray, selectedFilters]
  );

  const quickFilters = useMemo(() => {
    return quickFilterItems
      .filter((filterItem) => !selectedFilters?.map((sv) => sv.key).includes(filterItem))
      .map((filterItem) => {
        let possibleValues = new Set();
        dataArray?.forEach((x) => {
          if (x[filterItem]) possibleValues.add(x[filterItem]);
        });
        return <SafesimItemBrowseQuickFilterer key={filterItem} filter={filterItem} possibleValues={[...possibleValues]} onSelect={onFiltersAdded} />;
      });
  }, [quickFilterItems, dataArray, selectedFilters, onFiltersAdded]);

  return (
    <div className={`itemBrowseContainer ${className}`}>
      {filterTags?.length > 0 && (
        <SafesimControlGroup style={{ width: '100%' }}>
          <SafesimSelect
            className='itemBrowsePropSelect'
            fill={true}
            icon='filter'
            itemArray={filterTags}
            convertItemToString={(item) => item?.toString()}
            onSelectItem={(item) => setSelectedProp(item)}
            selectedItem={selectedProp}
          />
          <SafesimMultiSelect
            className='itemBrowseValueSelect'
            createNewItemFromQuery={createNewItemCallback}
            fill={true}
            items={[]}
            itemRenderer={itemRendererCallback}
            onItemSelect={onFiltersAdded}
            onQueryChange={onQueryChange}
            onRemove={onRemoveFilter}
            query={query}
            resetOnSelect={true}
            selectedItems={selectedFilters}
            tagInputProps={{ disabled: !selectedProp && !(selectedFilters?.length > 0) }}
            tagRenderer={tagRenderer}
          />
        </SafesimControlGroup>
      )}
      <div className={`itemBrowseGridContainer`} {...otherProps} style={{ width: '100%' }}>
        {showQuickFilters && (
          <div className={`itemBrowseQuickFilter`}>
            <SafesimText style={{ marginTop: '10px' }}>Quick Filters: </SafesimText>
            {quickFilters}
          </div>
        )}
        {showItemCount && !showLoading && !renderedError && <SafesimLabel className='itemBrowseItemCount'>{dataArray?.length ?? 0} Total</SafesimLabel>}
        {showRefresh && (
          <SafesimTooltip className='itemBrowseRefresh' minimal disabled={showLoading} content='Refresh'>
            <SafesimButton icon={'refresh'} onClick={onRefreshClicked} disabled={showLoading} />
          </SafesimTooltip>
        )}
        <div className={'itemBrowseContent' + (showLoading ? 'Loading' : '')}>
          {!showLoading && !!dataArray?.length && (
            <SafesimScrollableGrid direction={scrollDirection} className={`itemBrowseScrollableGrid ${scrollDirection}`} itemsArray={dataArray} itemRenderer={itemRenderer} />
          )}
          {renderedError}
          {showLoading && <SafesimSpinner />}
          {!renderedError && !showLoading && !dataArray?.length && defaultElement}
        </div>
      </div>
    </div>
  );
};

const SafesimItemBrowseQuickFilterer = (props) => {
  const { filter, possibleValues, onSelect, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  const filterOptions = useMemo(
    () =>
      possibleValues.map((pv) => {
        return (
          <SafesimButton
            key={pv}
            onClick={() => {
              onSelect?.({ key: filter, value: pv, tag: `${filter}:${pv}` });
              setIsOpen(false);
            }}
          >
            {pv}
          </SafesimButton>
        );
      }),
    [filter, possibleValues, onSelect, setIsOpen]
  );

  return (
    <SafesimPopover
      isOpen={isOpen}
      placement='right'
      onClose={() => {
        setIsOpen(false);
      }}
      content={<div className='quickFilterPopout'>{filterOptions}</div>}
      {...rest}
    >
      <SafesimButton onClick={() => setIsOpen(true)} text={filter} />
    </SafesimPopover>
  );
};

function itemRendererCallback(item) {
  return <SafesimMenuItem text={item} />;
}

function tagRenderer(tag) {
  // The tag is an object with the properties key, value, and tag
  // key is the property we're filtering on
  // value is the, uh, value that the key should contain
  // tag is 'key:value'
  return tag.tag;
}

SafesimItemBrowse.propTypes = {
  className: PropTypes.string,
  defaultElement: PropTypes.element,
  defaultFilter: PropTypes.object,
  filterTags: PropTypes.array,
  itemArray: PropTypes.array.isRequired,
  filterItem: PropTypes.func,
  itemRenderer: PropTypes.func,
  onItemsFiltered: PropTypes.func,
  onRefreshClicked: PropTypes.func,
  onSelectedFiltersChanged: PropTypes.func,
  quickFilterItems: PropTypes.array,
  renderedError: PropTypes.element,
  showItemCount: PropTypes.bool,
  showLoading: PropTypes.bool,
  showRefresh: PropTypes.bool,
  scrollDirection: PropTypes.oneOf(['vertical', 'horizonatal']),
};

SafesimItemBrowse.defaultProps = {
  itemRenderer: (item, index) => item,
  defaultElement: <SafesimText>No Results</SafesimText>,
  showItemCount: true,
  showRefresh: true,
  scrollDirection: 'vertical',
};
