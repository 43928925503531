/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ClassificationLevel, JwtRefreshResponse, LoginRequest, LoginResponse } from "../Api/Api";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { handleAxiosError } from "../Shared/Errors";
import KeycloakService, { minValidityMs } from "./KeycloakService";
import { ClearanceInfo, ClearedAccess } from "../Api/ClearedAccess";

type TokenState = "AboutToExpire" | "Valid" | "Expired" | "TokenNotSet";

export interface DecodedAuthToken extends ClearanceInfo {
  aud: string;
  exp: number;
  iss: string;
}

export function getNewAuthToken(refreshResponse?: JwtRefreshResponse): string {
  if (!refreshResponse) {
    throw Error("Refresh response was empty");
  }

  if (refreshResponse.errors) {
    throw Error(`Errors occurred on the server while refreshing; ${refreshResponse.errors.toString()}`);
  }
  return refreshResponse.authToken;
}

export function isPermittedForAllUsers(level: ClassificationLevel): boolean {
  return level === ClassificationLevel.UNCLASSIFIED;
}

export class SimorAuth {
  public constructor(
    private _useKeycloak: boolean,
    private _authToken: string,
    private _authorized: boolean,
    private onUpdateAuthToken?: (newAuthToken: string, expiresAtUtcMillis?: number) => void
  ) {
    this.setAuthToken(_authToken);
  }

  private _tokenExpMs = 0;

  private _clearanceInfo: ClearedAccess | null = null;

  public get clearanceInfo(): ClearedAccess | null {
    return this._clearanceInfo;
  }

  /**
   * @returns true if there's no user data in local storage.
   */
  public isAuthorized(): boolean {
    return this._useKeycloak ? KeycloakService.isLoggedIn() : this._authorized;
  }

  private setAuthToken(authToken: string): DecodedAuthToken | null {
    if (!authToken) {
      return null;
    }

    try {
      const decodedJwt = jwtDecode<DecodedAuthToken>(authToken);
      this._tokenExpMs = this.expInMs(decodedJwt);
      this._clearanceInfo = new ClearedAccess(decodedJwt);

      return decodedJwt;
    } catch (error) {
      this._clearanceInfo = null;
      this._tokenExpMs = 0;
      console.error("Error decoding the JWT.  The error was:", error);
      console.error("The raw JWT that was failed decoding:", authToken);
      return null;
    }
  }

  private expInMs(authToken?: DecodedAuthToken): number {
    return authToken ? authToken.exp * 1000 : 0;
  }

  private isTokenAboutToExpire(): TokenState {
    const msRemaining = this._tokenExpMs - Date.now();

    if (msRemaining > minValidityMs) {
      return "Valid";
    } else if (msRemaining <= minValidityMs && msRemaining > 0) {
      return "AboutToExpire";
    } else if (this._tokenExpMs === 0) {
      return "TokenNotSet";
    } else {
      return "Expired";
    }
  }

  public async tryRefreshAuthToken(isPreservingLogin: boolean = false): Promise<boolean> {
    if (this._useKeycloak) {
      return await KeycloakService.updateTokenIfExpired(async () => {
        this.handleNewToken(KeycloakService.authTokenRaw());
        return true;
      });
    } else {
      const authTokenStatus = this.isTokenAboutToExpire();
      if (authTokenStatus === "AboutToExpire" || (isPreservingLogin && authTokenStatus === "TokenNotSet")) {
        const cancelRefreshToken = axios.CancelToken.source();
        try {
          // No axiosContext is needed here; authorization read from
          // httpOnly cookie on the server side.
          const postResponse = await axios.get("/refresh_token", {
            cancelToken: cancelRefreshToken.token,
          });
          const newAuthToken = getNewAuthToken(postResponse?.data);
          this.handleNewToken(newAuthToken);
          return true;
        } catch (error: any) {
          handleAxiosError(error, "Refresh unsuccessful");
          return false;
        }
      } else if (authTokenStatus === "Expired") {
        // TODO: We should really check to see if the refresh token is still good but
        //  it isn't returned as part of the login.
        this.logoutFromServer();
        return false;
      } else {
        return true;
      }
    }
  }

  private handleNewToken(newAuthToken: string): void {
    this.setAuthToken(newAuthToken);

    if (this.onUpdateAuthToken) {
      this.onUpdateAuthToken(newAuthToken, this._tokenExpMs);
    }
  }

  /**
   * Log the user in via their username
   */
  public async loginUser(username: string): Promise<void> {
    const cancelLoginToken = axios.CancelToken.source();
    const loginRequest: LoginRequest = {
      username: username,
    };
    try {
      // No axiosContext is needed here; logging in does not require an
      // authorization header.
      const postResponse = await axios.post("/login", loginRequest, {
        cancelToken: cancelLoginToken.token,
      });
      const loginResponse: LoginResponse = postResponse?.data;
      this.handleNewToken(loginResponse?.authToken);
    } catch (error: any) {
      handleAxiosError(error, "Login unsuccessful.");
    }
  }

  /**
   * No local session state is cleared. Must be handled separately
   */
  public async logoutFromServer(): Promise<void> {
    if (this._useKeycloak) {
      KeycloakService.doLogout();
    } else {
      await axios.post("/logout").catch((error) => handleAxiosError(error, "Logout unsuccessful"));
    }

    if (this.handleNewToken) {
      this.handleNewToken("");
    }
  }
}
