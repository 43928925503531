import { isEqual } from "lodash";
import { useEffect, useState } from "react";

/**
 * React hook that accepts a value and only updates the returned value if the new value is not deeply equal to the previous value.
 * @template T
 * @param {T} value A value of reference type (i.e. an object)
 * @param {function(T,T):boolean} [equalityComparer] Optional. An equality comparer function for checking deep equality. Defaults to `lodash.isEqual`
 * @returns {T} A value that is deeply equal to the provided value (either the actual value or a previous value that is deeply equal to the current one)
 *
 * @example
 * // InnerInnerComponent should only receive a new value for `options` when the actual content of `options` changes, not just when a new reference is passed in
 * fuction InnerComponent(props) {
 *   const { options } = props;
 *   const memoizedOptions = useDeepCompareValue(options);
 *   return (
 *     <InnerInnerComponent options={memoizedOptions} />
 *   );
 * }
 *
 * // Because the `options` prop is created inline in the JSX, it would be recreated on every render unless we handle it internally, hence the use of useDeepCompareValue
 * function OuterComponent(props) {
 *   return (
 *     <InnerComponent options={{ val1: 'green', val2: true, val3: { val4: 15 } }} />
 *   );
 * }
 */
export const useDeepCompareValue = (value, equalityComparer = isEqual) => {
  const [retVal, setRetVal] = useState(value);

  useEffect(() => {
    setRetVal((prev) => equalityComparer(prev, value) ? prev : value);
  }, [value, equalityComparer]);

  return retVal;
};
