/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Study } from "Api";
import React from "react";
import { StudyChangeSet } from "Api";
import RescanStudyFiles from "../Api/Gql/RescanStudyFiles.gql";
import { notify, notify } from "../Shared/Notify";
import { useHistory, useLocation } from "react-router-dom";
import getStudies from "../Api/Gql/GetStudies.gql";
import updateStudy from "../Api/Gql/UpdateStudy.gql";
import { handleApolloError } from "../Shared/Errors";
import { UnsavedChangesPrompt } from "../Shared/UnsavedChangesPrompt";
import { Uuid } from "../Utils/Types";
import { AssetDetailsPage } from "../Asset/Editor/AssetDetailsPage";
import { newStudyDetailsState, StudyDetailsReducer } from "./StudyDetailsState";
import { EditAssetHeaderBar } from "../Asset/Editor/AssetDetailsHeaders";
import { studyDetailsCreatePagePath } from "../Shared/NavigationLinks";
import { AssetPane } from "../Asset/Shared/AssetPane";
import { ClassificationHeader } from "../Shared/ClassificationHeader";

interface StudyDetailsQueryParams {
  studyId: Uuid;
}

function useStudyDetailsParams(): StudyDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  // TODO: Conditionally show an invalid uuid page if the Uuid fails to parse.
  const studyDetailsParams: StudyDetailsQueryParams = {
    studyId: searchParams.get("studyId") as Uuid,
  };
  return studyDetailsParams;
}

export const StudyDetailsEditPage = (): JSX.Element => {
  const studyDetailsParams = useStudyDetailsParams();

  const [state, dispatch] = React.useReducer(StudyDetailsReducer, newStudyDetailsState(studyDetailsParams.studyId));

  const [rescanStudyFilesMutation] = useMutation(gql(RescanStudyFiles), {
    onCompleted: (data) => {
      const changeSet: StudyChangeSet = data.rescanStudyFiles;
      if (changeSet.hasChanged) {
        dispatch(["resetOriginalAndEditedStudy", changeSet.updatedStudy]);
        notify.positive("Study metadata updated");
      } else {
        notify.positive("No changes to study metadata");
      }
    },
    onError: (error) => handleApolloError(error, "Error re-scanning study."),
  });

  const [rescanPressed, setRescanPressed] = React.useState<number>(0);
  const handleRescan = React.useCallback(() => {
    rescanStudyFilesMutation({
      variables: { studyId: state.edited.asset.id },
    });
    //TODO is there a more elegant way to do this? rerendering whole StudyDetailsPage component
    setRescanPressed(rescanPressed + 1);
  }, [rescanPressed, rescanStudyFilesMutation, state.edited.asset.id]);

  // Should this be uppercase?
  const [updateStudyMutation] = useMutation(gql(updateStudy), {
    variables: { study: state.edited.asset },
    onCompleted: (data) => {
      dispatch(["resetOriginalAndEditedStudy", data.updateStudy as Study]);
      notify.positive("Study saved");
    },
    onError: (error) => handleApolloError(error, `Error uploading study.`),
  });

  const [studyLoadError, setStudyLoadError] = React.useState<string>();
  const { loading: studyLoading } = useQuery(gql(getStudies), {
    variables: { ids: [state.edited.asset.id] },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const studies = data.getStudies as Study[];
      if (studies[0]) {
        dispatch(["resetOriginalAndEditedStudy", studies[0]]);
      } else {
        setStudyLoadError("Study not found; study does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting study data."),
  });

  const history = useHistory();
  const handleCopy = React.useCallback(() => {
    history.push(studyDetailsCreatePagePath(state.edited.asset.id));
  }, [history, state.edited.asset.id]);

  const propertiesStyle = { minWidth: "100%" };

  return (
    <>
      <UnsavedChangesPrompt when={state.dataChanged} />
      <AssetDetailsPage
        headerActionButtons={
          <>
            <EditAssetHeaderBar
              updateAssetMutation={updateStudyMutation}
              handleRescan={handleRescan}
              handleCopy={handleCopy}
              typeString="Study"
              state={state}
              dispatch={dispatch}
            />
            <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
          </>
        }
        loading={studyLoading}
        loadError={studyLoadError}
      >
        <AssetPane state={state} dispatch={dispatch} propertiesStyle={propertiesStyle} />
      </AssetDetailsPage>
    </>
  );
};
