/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { AssetSummary, ModelInfo, ModelInfoInput } from "Api";
import {
  AssetContent,
  AssetDetailsState,
  AssetWikiPageAction,
  AssetWikiPageReducer,
  resetOriginalAndEdited,
} from "../Asset/Editor/AssetDetailsState";
import { emptyModelInfoInput, isDataChanged, toAssetSummaries, toModelMetadataInput } from "../Api/ApiExtensions";
import { Uuid, newId } from "../Utils/Types";
import { addToNewArray } from "../Utils/Array";
import { COMMON_FIXED_SCHEMA_FIELDS } from "../Asset/Editor/AssetMetadataFields";
import { FieldSpec, TextListQuerySpec, simpleTextFieldSpec, tagListFieldSpec } from "../Asset/Editor/FieldSpec";
import GetModelSimulationFrameworks from "../Api/Gql/GetModelSimulationFrameworks.gql";

const simFrameworkQuerySpec: TextListQuerySpec = {
  matchValuesQuery: GetModelSimulationFrameworks,
  queryParameterName: "simFrameworkSubstring",
  responseFieldName: "getModelSimulationFrameworks",
};

export const SUBJECT_FIELD = simpleTextFieldSpec("Subject");

export const SIMFRAMEWORK_FIELD = tagListFieldSpec(
  simFrameworkQuerySpec,
  "Simulation Frameworks",
  "simulationFrameworks"
);

// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
export const MODEL_FIXED_SCHEMA_FIELDS: FieldSpec[] = [
  ...COMMON_FIXED_SCHEMA_FIELDS,
  SUBJECT_FIELD,
  SIMFRAMEWORK_FIELD,
];

export function newModelDetailsState(modelId?: Uuid, copying?: boolean): AssetDetailsState<ModelInfoInput> {
  const newModel = emptyModelInfoInput();
  newModel.id = modelId ?? newId();
  return new AssetDetailsState<ModelInfoInput>("MODEL", MODEL_FIXED_SCHEMA_FIELDS, newModel, [], copying);
}

type ResetOriginalAndEditedModelAction = ["resetOriginalAndEditedModel", ModelInfo];

function resetOriginalAndEditedModel(
  state: AssetDetailsState<ModelInfoInput>,
  model: ModelInfo
): AssetDetailsState<ModelInfoInput> {
  const modelInput: ModelInfoInput = {
    id: model.id,
    metadata: toModelMetadataInput(model.metadata),
    securityMarkings: model.securityMarkings,

    relatedScenarioIds: model.relatedScenarios?.map((scenario) => scenario.id),
    relatedModelIds: model.relatedModels?.map((model) => model.id),
    relatedPlatformIds: model.relatedPlatforms?.map((platform) => platform.id),

    // Will not be used by resetOriginalAndEdited
    fileFolders: [],
  };

  const scenarioSummaries: AssetSummary[] = toAssetSummaries("SCENARIO", model.relatedScenarios);
  const modelSummaries: AssetSummary[] = toAssetSummaries("MODEL", model.relatedModels);
  const platformSummaries: AssetSummary[] = toAssetSummaries("PLATFORM", model.relatedPlatforms);
  const allRelations = [...scenarioSummaries, ...modelSummaries, ...platformSummaries];

  return resetOriginalAndEdited<ModelInfoInput>(
    state,
    modelInput,
    model.creationDate,
    model.lastModifiedDate,
    model.fileFolders,
    allRelations
  );
}

type AddRelatedAssetAction = ["addRelatedAsset", AssetSummary];

function addRelatedAsset(
  state: AssetDetailsState<ModelInfoInput>,
  resourceToAdd: AssetSummary
): AssetDetailsState<ModelInfoInput> {
  const asset = { ...state.edited.asset };
  const relations = addToNewArray(state.edited.relations, resourceToAdd);

  switch (resourceToAdd.type) {
    case "SCENARIO":
      asset.relatedScenarioIds = addToNewArray(state.edited.asset.relatedScenarioIds, resourceToAdd.id);
      break;
    case "MODEL":
      asset.relatedModelIds = addToNewArray(state.edited.asset.relatedModelIds, resourceToAdd.id);
      break;
    case "PLATFORM":
      asset.relatedPlatformIds = addToNewArray(state.edited.asset.relatedPlatformIds, resourceToAdd.id);
      break;
    default:
      console.error(`Unhandled assetType ${resourceToAdd.type}`);
      break;
  }

  const editedAfterUpdate: AssetContent<ModelInfoInput> = {
    ...state.edited,
    asset: asset,
    relations: relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

type RemoveRelatedAssetAction = ["removeRelatedAsset", AssetSummary];

function removeRelatedAsset(
  state: AssetDetailsState<ModelInfoInput>,
  assetToRemove: AssetSummary
): AssetDetailsState<ModelInfoInput> {
  const asset = { ...state.edited.asset };
  const relations = state.edited.relations?.filter((model) => model.id !== assetToRemove.id);

  switch (assetToRemove.type) {
    case "MODEL":
      asset.relatedModelIds = state.edited.asset.relatedModelIds?.filter((modelId) => modelId !== assetToRemove.id);
      break;
    case "SCENARIO":
      asset.relatedScenarioIds = state.edited.asset.relatedScenarioIds?.filter(
        (scenarioId) => scenarioId !== assetToRemove.id
      );
      break;
    case "PLATFORM":
      asset.relatedPlatformIds = state.edited.asset.relatedPlatformIds?.filter(
        (platformId) => platformId !== assetToRemove.id
      );
      break;
    default:
      console.error(`Could not delete relation ${assetToRemove} type not supported`);
      break;
  }

  const editedAfterUpdate: AssetContent<ModelInfoInput> = {
    ...state.edited,
    asset,
    relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

export type ModelDetailsPageAction =
  | AssetWikiPageAction
  | ResetOriginalAndEditedModelAction
  | AddRelatedAssetAction
  | RemoveRelatedAssetAction;

export function ModelDetailsReducer(
  state: AssetDetailsState<ModelInfoInput>,
  action: ModelDetailsPageAction
): AssetDetailsState<ModelInfoInput> {
  const actionType = action[0];
  switch (actionType) {
    case "resetOriginalAndEditedModel":
      return resetOriginalAndEditedModel(state, action[1]);
    case "addRelatedAsset":
      return addRelatedAsset(state, action[1]);
    case "removeRelatedAsset":
      return removeRelatedAsset(state, action[1]);
    default:
      return AssetWikiPageReducer(state, action);
  }
}
