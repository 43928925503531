import { isObjectLike } from 'lodash';

export const getDateStringFromURRCSDateObject = (urrcsDate?: any, includeTime = false) => {
  if (isObjectLike(urrcsDate)) {
    const { year, month, day, timezone, hour = 0, minute = 0, second = 0, fractionalSecond = 0 } = urrcsDate;

    if (year && month && day) {
      const dateObj = new Date(year, month - 1, day, ...(includeTime ? [hour, minute, second, fractionalSecond * 1000] : []));
      return getFormattedDateString(dateObj, includeTime);
    }
  } else if (typeof urrcsDate === 'string') {
    const dateObj = new Date(urrcsDate);
    if (!isNaN(Number(dateObj))) return getFormattedDateString(dateObj, includeTime);
  }

  return getFormattedDateString(new Date(), includeTime);
};

const getFormattedDateString = (date: Date, time = false) => {
  const isoString = date?.toISOString();
  if (time) return isoString;
  const [dateString] = isoString?.split('T');
  return dateString;
};
