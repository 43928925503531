/**
 * Subscribes a callback to a given event on a given DOM element
 * @param {Element} domElement A DOM element for which to subscribe to an event
 * @param {Symbol|string} eventType An event type symbol
 * @param {function} listener A callback to execute whenever the event is triggered\
 */
 export const subscribeElement = (domElement, eventType, listener) => domElement?.addEventListener(eventType.toString(), listener);

 /**
  * Subscribes a callback to a given event on the DOM document
  * @param {Symbol|string} eventType An event type symbol
  * @param {function} listener A callback to execute whenever the event is triggered\
  */
 export const subscribe = (eventType, listener) => subscribeElement(document, eventType, listener);
 
 /**
  * Unsubscribes a previously subscribed callback from an event on a given DOM element
  * @param {Element} domElement A DOM element for which to unsubscribe from an event
  * @param {Symbol|string} eventType An event type symbol
  * @param {function} listener A callback to unsubscribe from the event
  */
 export const unsubscribeElement = (domElement, eventType, listener) => domElement?.removeEventListener(eventType.toString(), listener);
 
 /**
  * Unsubscribes a previously subscribed callback from an event on the DOM document
  * @param {Symbol|string} eventType An event type symbol
  * @param {function} listener A callback to unsubscribe from the event
  */
 export const unsubscribe = (eventType, listener) => unsubscribeElement(document, eventType, listener);
 
 /**
  * Subscribes to a given event on a given DOM element, and then automatically unsubscribes from the event after the event is triggered once
  * @param {Element} domElement A DOM element for which to unsubscribe from an event
  * @param {Symbol|string} eventType An event type symbol
  * @param {function} listener A callback to execute whenever the event is triggered
  */
 export const subscribeElementOnce = (domElement, eventType, listener) => {
   subscribeElement(domElement, eventType, handleEventOnce);
 
   function handleEventOnce(event) {
     listener(event);
     unsubscribeElement(domElement, eventType, handleEventOnce);
   }
 };
 
 /**
  * Subscribes to a given event on the DOM document, and then automatically unsubscribes from the event after the event is triggered once
  * @param {Symbol|string} eventType An event type symbol
  * @param {function} listener A callback to execute whenever the event is triggered
  */
 export const subscribeOnce = (eventType, listener) => subscribeElementOnce(document, eventType, listener);
 
 /**
  * Triggers a custom event to be emitted for the specified event type
  * @param {Symbol|string} eventType An event type symbol
  * @param {*} data The data to provide to the event
  */
 export const triggerEvent = (eventType, data = undefined) => {
   const event = new CustomEvent(eventType.toString(), { detail: data });
   document.dispatchEvent(event);
 };