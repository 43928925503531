/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { TitledBorder } from "../DesignSystem/Containers";
import { BuilderOverrides, TableBuilder, TableBuilderColumn } from "baseui/table-semantic";

export interface WikiCardInfo {
  field: string;
  value: string | JSX.Element | undefined;
}

export function WikiStaticTextBlock({ wikiCardInfo }: { wikiCardInfo: WikiCardInfo[] }): JSX.Element {
  // Hide the header row since it is empty.  Otherwise it adds extra spacing.
  const headerRowOverrides: BuilderOverrides = {
    TableHeadRow: {
      style: () => ({
        display: "none",
      }),
    },
  };

  return (
    <TitledBorder>
      <TableBuilder data={wikiCardInfo} overrides={headerRowOverrides}>
        <TableBuilderColumn id="field">{(cardInfo: WikiCardInfo) => cardInfo.field}</TableBuilderColumn>
        <TableBuilderColumn id="field">{(cardInfo: WikiCardInfo) => cardInfo.value}</TableBuilderColumn>
      </TableBuilder>
    </TitledBorder>
  );
}
