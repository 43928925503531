/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React, { useState } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";

import { ModelInfo } from "Api";
import { Uuid } from "../../Utils/Types";
import { themedUseStyletron as useStyletron } from "../../DesignSystem/LightTheme";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import { OverflowActions, OverflowMenu } from "../../Shared/OverflowMenu";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import deleteModelMutation from "../../Api/Gql/DeleteModel.gql";
import { ModelDetailsLink, modelDetailsEditPagePath } from "../../Shared/NavigationLinks";
import { classificationOptionsAsText } from "../../Api/ApiSerialization";
import { appConfigurationsVar } from "../../GlobalState";
import { useHistory } from "react-router";
import { TextEntry } from "../../Utils/TableCells";

export interface SelectableResultItem {
  item: ModelInfo;
  isSelected: boolean;
}

const searchResultTableStyle: React.CSSProperties = {
  width: "70%",
  margin: "auto",
  // Disables the 2px cell border in FireFox when you ctrl-click on a table cell.
  MozUserSelect: "none",
};

export function ModelSearchResultTable({
  selectableResultItems,
  refetchQueryName,
  onSort,
  onClick,
}: {
  selectableResultItems: SelectableResultItem[];
  refetchQueryName: string;
  onSort: (fieldName: string) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, selectableResultItem: SelectableResultItem) => void;
}): JSX.Element {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, modelId: Uuid) => {
      const selectableItem = selectableResultItems.find((row) => row.item.id === modelId);
      if (selectableItem) {
        onClick(event, selectableItem);
      }
    },
    [onClick, selectableResultItems]
  );

  const styleOverrides = React.useMemo(() => {
    return {
      TableBodyRow: {
        style: ({ $theme, $rowIndex }: any) => ({
          backgroundColor: selectableResultItems[$rowIndex].isSelected
            ? $theme.colors.selectionBackgroundPrimary
            : $theme.colors.backgroundPrimary,
          ":hover": {
            backgroundColor: selectableResultItems[$rowIndex].isSelected
              ? $theme.colors.selectionBackgroundPrimaryDark
              : $theme.colors.backgroundTertiary,
          },
        }),
      },
      TableBodyCell: {
        style: () => ({
          // Baseweb adds padding automatically for aesthetics to the table data;
          // we need to rmeove it to make sure we're able to receive click events
          // for everything that appears to the user to be inside the table cell.
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "0",
          paddingLeft: "0",
        }),
      },
    };
  }, [selectableResultItems]);

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  return (
    <div style={searchResultTableStyle}>
      <TableBuilder data={selectableResultItems} onSort={onSort} overrides={styleOverrides}>
        <TableBuilderColumn id="title" header="Title" sortable>
          {(row: SelectableResultItem) => (
            <ModelTitleCell
              title={row.item.metadata?.title}
              modelId={row.item.id}
              styleClass={cellStyle}
              refetchQueryName={refetchQueryName}
              onClick={handleClick}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="version" header="Version" sortable>
          {(row: SelectableResultItem) => (
            <TextEntry
              text={row.item.metadata?.version}
              styleClass={cellStyle}
              dataId={row.item.id}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="classificationLevel" header="Classification" sortable>
          {(row: SelectableResultItem) => (
            <TextEntry
              text={classificationOptionsAsText(
                row.item.securityMarkings?.classificationLevel,
                isSensitiveSecurityContext
              )}
              styleClass={cellStyle}
              dataId={row.item.id}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="description" header="Description" sortable>
          {(row: SelectableResultItem) => (
            <TextEntry
              text={row.item.metadata?.description}
              styleClass={cellStyle}
              dataId={row.item.id}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}

const parsedDeleteModelGql = gql(deleteModelMutation);

const ModelTitleCell = ({
  title,
  modelId,
  styleClass,
  refetchQueryName,
  onClick,
}: {
  title: string;
  modelId: Uuid;
  styleClass: string;
  refetchQueryName: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, modelId: Uuid) => void;
}): JSX.Element => {
  const [deletePromptIsOpen, setDeletePromptIsOpen] = useState(false);

  const history = useHistory();

  const [deleteModel] = useMutation(parsedDeleteModelGql, {
    refetchQueries: [refetchQueryName],
  });

  const handleDelete = React.useCallback(() => {
    setDeletePromptIsOpen(false);
    deleteModel({ variables: { modelId: modelId } });
  }, [modelId, deleteModel]);

  const overflowActions: OverflowActions[] = [
    {
      label: "Edit",
      onClick: () => history.push(modelDetailsEditPagePath(modelId)),
    },
    { label: "Delete", onClick: () => setDeletePromptIsOpen(true) },
  ];

  return (
    <>
      <div
        className={styleClass}
        onClick={(event) => {
          onClick(event, modelId);
        }}
      >
        <ModelDetailsLink modelId={modelId} modelName={title} />
        <OverflowMenu overflowActions={overflowActions} />
        <DeleteConfirmationModal
          isOpen={deletePromptIsOpen}
          header={"Delete Model"}
          bodyText={`Are you sure you want to delete "${title}"?`}
          onDelete={handleDelete}
          onCancel={() => setDeletePromptIsOpen(false)}
        />
      </div>
    </>
  );
};
