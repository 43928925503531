/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { SecurityControlColorConfig, ClassificationLevel, FileInfo, Person } from "./Api";
import { DateTime } from "luxon";
import { ClassificationOption } from "../Shared/Security";

export function fileUploadDateAsText(fileInfo?: FileInfo): string | null {
  return formattedDateTime(fileInfo?.attributes?.uploadDateUtc);
}

export function fileUploadUserNameAsText(fileInfo: FileInfo): string {
  const uploadPerson = fileInfo.attributes?.uploadedBy?.person;
  const uploadUsername = fileInfo.attributes?.uploadedBy?.username ?? "";

  if (firstAndLastNamesAreNull(uploadPerson)) return uploadUsername;
  else return fullName(uploadPerson);
}

function firstAndLastNamesAreNull(person: Person | undefined): boolean {
  if (!person) return true;

  return !person.name.firstName && !person.name.lastName;
}

function fullName(person: Person | undefined): string {
  if (!person) return "Unknown user";

  return `${person.name?.firstName} ${person.name?.lastName}`;
}

function getRelativeTime(apiUTCDateTime: string): string {
  return utcToLocalDate(apiUTCDateTime).toRelative();
}

export function getRelativeLastModifiedTime(creationDate: string, lastModifiedDate: string): string {
  const utcDate = utcTextToDate(lastModifiedDate);
  const differenceInSeconds = lastModifiedDate ? DateTime.now().diff(utcDate, "seconds").seconds : 0;

  if (creationDate === lastModifiedDate) {
    return `Never`;
  } else if (0 < differenceInSeconds && differenceInSeconds < 60) {
    return `Less than a minute ago`;
  } else if (differenceInSeconds <= 0) {
    return `Just now`;
  } else {
    return getRelativeTime(lastModifiedDate);
  }
}

export function formattedDateTime(serializedDateTime: string): string | null {
  const localDate = utcToLocalDate(serializedDateTime);
  return localDate ? localDate.toLocaleString(DateTime.DATETIME_FULL) : null;
}

export function fileUploadDate(fileInfo?: FileInfo): DateTime | null {
  return utcToLocalDate(fileInfo?.attributes?.uploadDateUtc);
}

export function fileSizeAsText(fileInfo?: FileInfo): string | null {
  const size = fileInfo?.attributes?.sizeMbs;
  return mbsSizeAsText(size);
}

export function mbsSizeAsText(sizeMbs: number): string | null {
  if (sizeMbs < 0.001) {
    const sizeBytes = sizeMbs * 1000000;
    return `${Math.round(sizeBytes)} B`;
  }
  if (sizeMbs < 1) {
    const sizeKbs = sizeMbs * 1000;
    return `${Math.round(sizeKbs)} KB`;
  }
  if (sizeMbs < 1000) {
    return `${Math.round(sizeMbs)} MB`;
  }

  const sizeGbs = sizeMbs / 1000;
  return `${Math.round(sizeGbs)} GB`;
}

export function utcToLocalDate(utcDateText?: string): DateTime | null {
  const utcDate = utcTextToDate(utcDateText);
  return utcDate?.toLocal();
}

export function utcTextToDate(utcDateText?: string): DateTime | null {
  if (!utcDateText) {
    return null;
  }
  return DateTime.fromISO(utcDateText);
}

/**
 * Converts a date into a utc text string accepted by the API.
 * @param date The date to convert
 */
export function dateToUtcText(date: DateTime | Date | null): string {
  const dateTime = date instanceof Date ? DateTime.fromJSDate(date) : date instanceof DateTime ? date : null;
  return dateTime?.toString();
}

export function classificationLevelsAsPortionText(
  classificationLevel?: ClassificationLevel,
  isSensitiveSecurityContext: boolean = true
): string {
  switch (classificationLevel) {
    case ClassificationLevel.UNCLASSIFIED:
      return isSensitiveSecurityContext ? "U" : "C1";
    case ClassificationLevel.CONFIDENTIAL:
      return isSensitiveSecurityContext ? "C" : "P2";
    case ClassificationLevel.SECRET:
      return isSensitiveSecurityContext ? "S" : "P3";
    case ClassificationLevel.TOP_SECRET:
      return isSensitiveSecurityContext ? "TS" : "S4";
    default:
      return "";
  }
}

export function classificationOptionsAsText(
  classificationOption?: ClassificationOption,
  isSensitiveSecurityContext: boolean = true
): string {
  switch (classificationOption) {
    case ClassificationLevel.UNCLASSIFIED:
      return isSensitiveSecurityContext ? "UNCLASSIFIED" : "Cherry - 1";
    // TODO: Change FOUO / CUI to more identifiable names (as long as they are not mixed
    //   up with the "real" value).
    case "FOUO":
      return isSensitiveSecurityContext ? "FOUO" : "Cherry - 1a";
    case "CUI":
      return isSensitiveSecurityContext ? "CUI" : "Cherry - 1b";
    case ClassificationLevel.CONFIDENTIAL:
      return isSensitiveSecurityContext ? "CONFIDENTIAL" : "Pineapple - 2";
    case ClassificationLevel.SECRET:
      return isSensitiveSecurityContext ? "SECRET" : "Peach - 3";
    case ClassificationLevel.TOP_SECRET:
      return isSensitiveSecurityContext ? "TOP SECRET" : "Strawberry - 4";
    default:
      return "";
  }
}

export function classificationOptionsAsColors(
  classificationOption: ClassificationOption,
  securityControlColors: SecurityControlColorConfig[]
): SecurityControlColorConfig | undefined {
  let bannerMappedClassificationColor;
  securityControlColors.forEach((bannerColorOption) => {
    if (bannerColorOption.securityControl === classificationOption.toString()) {
      if (
        CSS.supports("color", bannerColorOption.backgroundColor) &&
        CSS.supports("color", bannerColorOption.textColor)
      )
        bannerMappedClassificationColor = bannerColorOption;
    }
  });
  if (bannerMappedClassificationColor) return bannerMappedClassificationColor;
  // If missing a color mapping for the chosen classification level, return the default color for that level
  console.error(
    `Failed to determine the banner color map for classification level ${classificationOption} . Attempting` +
      ` default banner colors for that classification.`
  );

  switch (classificationOption) {
    case "CUI":
      return { securityControl: classificationOption, backgroundColor: "#402B85", textColor: "#FFFFFF" };
    case "FOUO":
    case ClassificationLevel.UNCLASSIFIED:
      return {
        securityControl: classificationOption,
        backgroundColor: "#173517",
        textColor: "#FFFFFF",
      };
    case ClassificationLevel.CONFIDENTIAL:
      return {
        securityControl: classificationOption,
        backgroundColor: "#005A8F",
        textColor: "#FFFFFF",
      };
    case ClassificationLevel.SECRET:
      return {
        securityControl: classificationOption,
        backgroundColor: "#BF0000",
        textColor: "#FFFFFF",
      };
    case ClassificationLevel.TOP_SECRET:
      return {
        securityControl: classificationOption,
        backgroundColor: "#CA4D00",
        textColor: "#FFFFFF",
      };
    case "TS-SCI":
      return {
        securityControl: classificationOption,
        backgroundColor: "#FCE83A",
        textColor: "#000000",
      };
    default:
      return undefined;
  }
}
