/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { TaskInfo } from "Api";
import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import * as React from "react";
import { TextEntry } from "../../Utils/TableCells";
import { SelectedTableRowStyle } from "../SmrStyles";

export function TaskTable({
  tasks,
  selectedTasks,
  onClick,
}: {
  tasks: TaskInfo[];
  selectedTasks: TaskInfo[];
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, task: TaskInfo) => void;
}): JSX.Element {
  const styleOverrides = React.useMemo(
    () => SelectedTableRowStyle((rowIndex) => selectedTasks.includes(tasks[rowIndex])),
    [tasks, selectedTasks]
  );

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  return (
    <TableBuilder data={tasks} overrides={styleOverrides}>
      <TableBuilderColumn id="number" header="Number" sortable>
        {(row: TaskInfo) => (
          <TextEntry
            text={`${row.category} ${row.hierarchyNumber}`}
            styleClass={cellStyle}
            dataId={row.id}
            onClick={(event) => onClick(event, row)}
          ></TextEntry>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn id="title" header="Title" sortable>
        {(row: TaskInfo) => (
          <TextEntry
            text={row.title}
            styleClass={cellStyle}
            dataId={row.id}
            onClick={(event) => onClick(event, row)}
          ></TextEntry>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
}
