/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { MessageCard } from "baseui/message-card";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ContentPage } from "../Shared/ContentPage";
import { BulkAddModelModal } from "../Search/BulkAddModelModal";
import { modelDetailsCreatePagePath, scenarioDetailsCreatePagePath } from "../Shared/NavigationLinks";
import wizard from "./images/wizard.jpg";
import plus from "./images/plus.jpg";
import resources from "./images/catan+resources.jpg";
import curate from "./images/Curate.jpg";
import dataModels from "./images/dataModels.jpg";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";

export function KnowledgeManagementPortalPage(): JSX.Element {
  const fileItemProps = {
    overrides: {
      Block: {
        style: () => ({
          width: "30%",
          flexGrow: 0,
        }),
      },
    },
  };
  const FIND_ASSETS_WITH_TEXT_QUERY_NAME = "FindAssetsWithText";
  const [bulkAddModelModalIsOpen, setBulkAddModelModalIsOpen] = React.useState(false);
  const messageCards = GenerateCenteredMessageCards(setBulkAddModelModalIsOpen);

  return (
    <ContentPage>
      <FlexGrid flexGridColumnCount={[1, 2, 3, 4]} flexGridColumnGap={"scale800"} flexGridRowGap={"scale800"}>
        {messageCards.map((card, index) => {
          return (
            <FlexGridItem {...fileItemProps} key={index}>
              {card}
            </FlexGridItem>
          );
        })}
      </FlexGrid>
      <BulkAddModelModal
        isOpen={bulkAddModelModalIsOpen}
        refetchQueryName={FIND_ASSETS_WITH_TEXT_QUERY_NAME}
        onClose={() => setBulkAddModelModalIsOpen(false)}
      />
    </ContentPage>
  );
}

function CenteredMessageCard({
  heading,
  description,
  imagePath,
  onClick,
}: {
  heading: string;
  description: string;
  imagePath: string;
  onClick: () => unknown;
}): JSX.Element {
  return (
    <MessageCard
      heading={heading}
      paragraph={description}
      image={{ src: imagePath, ariaLabel: "" }}
      onClick={onClick}
      overrides={{ ContentContainer: { style: { textAlign: "center", minHeight: "10rem" } } }}
    />
  );
}

function GenerateCenteredMessageCards(
  setBulkAddModelModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
): JSX.Element[] {
  const history = useHistory();
  return [
    CenteredMessageCard({
      heading: "Resources",
      description: "View and filter assets",
      imagePath: resources,
      onClick: () => history.push("/resources"),
    }),
    CenteredMessageCard({
      heading: "Scenario Wizard",
      description: "Locate a Model based on specific criteria",
      imagePath: wizard,
      onClick: () => history.push("/scenarioWizard"),
    }),
    CenteredMessageCard({
      heading: "Curate Models",
      description: "View metrics about saved assets",
      imagePath: curate,
      onClick: () => history.push("/"),
    }),
    CenteredMessageCard({
      heading: "Bulk Add Models",
      description: "Add many models at the same time",
      imagePath: dataModels,
      onClick: () => setBulkAddModelModalIsOpen(true),
    }),
    CenteredMessageCard({
      heading: "Add a Model",
      description: "Create a new model",
      imagePath: plus,
      onClick: () => history.push(modelDetailsCreatePagePath()),
    }),
    CenteredMessageCard({
      heading: "Add a Scenario",
      description: "Create a new scenario",
      imagePath: plus,
      onClick: () => history.push(scenarioDetailsCreatePagePath()),
    }),
  ];
}
