/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { MeasureDescriptor, UnitType, NumericValue, NumericTypeDescriptor } from "Api";
import { Input } from "baseui/input";
import React from "react";
import { NumericUnitContext } from "../../Utils/NumericUnitContext";
import { NumericTextFieldSpec } from "../Editor/FieldSpec";
import { normalizeTextControlValue, normalizeTextPropertyValue } from "./SimpleTextEditor";
import { Select, Option } from "baseui/select";

interface MeasureOption extends MeasureDescriptor, Option {
  id: string;
  type?: UnitType;
}

const noUnitOption: MeasureOption = {
  label: "no unit",
  id: null,
  type: null,
  abbreviation: "",
  descriptiveName: "",
};

function isPrimitiveValue(value?: any): boolean {
  return (
    // String primitive values
    typeof value === "string" ||
    // String object values
    value instanceof String ||
    // Numeric primitive values
    typeof value === "number" ||
    // Numeric object values
    value instanceof Number
  );
}

function isTypedValue(value?: any): value is NumericValue {
  if (value == null) {
    return false;
  }

  return value.type || value.unitOfMeasure;
}

function getNumericTextFieldValue(value?: any): any {
  if (isPrimitiveValue(value)) {
    return `${value}`;
  }

  if (isTypedValue(value)) {
    return value.value;
  }

  return value;
}

function toMeasureOptions(numericTypeDescriptor: NumericTypeDescriptor): MeasureOption[] {
  return numericTypeDescriptor.measureOptions.map((measureDescriptor) => ({
    ...measureDescriptor,
    type: numericTypeDescriptor.unitType,
    label: measureDescriptor.abbreviation,
    id: measureDescriptor.abbreviation,
  }));
}

export const NumericTextEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: NumericTextFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  const numericUnitContext = React.useContext(NumericUnitContext);

  const valueAsText = React.useMemo(() => {
    const rawValue = getNumericTextFieldValue(value);
    return normalizeTextControlValue(rawValue);
  }, [value]);

  const measureOptions = React.useMemo(() => {
    const serverMeasureOptions = numericUnitContext?.types.flatMap((type) => toMeasureOptions(type)) ?? [];
    return [noUnitOption].concat(serverMeasureOptions);
  }, [numericUnitContext]);

  const unitOfMeasureId = isTypedValue(value) ? value.unitOfMeasure : null;
  const unitOfMeasureOption = measureOptions.filter((option) => option.id === unitOfMeasureId)[0] ?? noUnitOption;

  function handleTextChange(event: React.FormEvent<HTMLInputElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value) || "";
    const newNumber = Number(newText);

    const scalar = newNumber.toString() === newText ? newNumber : newText;

    let newValue: any;
    if (isTypedValue(value)) {
      newValue = {
        ...value,
        value: scalar,
      };
    } else {
      newValue = scalar;
    }
    onChange(newValue);
  }

  function handleUnitChange(newMeasureOptionId: string): void {
    const newMeasureOption = measureOptions.filter((option) => option.id === newMeasureOptionId)[0] ?? noUnitOption;

    let newValue: any;
    if (newMeasureOption.id === noUnitOption.id) {
      newValue = isTypedValue(value) ? value.value : value;
    } else {
      newValue = isTypedValue(value)
        ? {
            ...value,
            type: newMeasureOption.type,
            unitOfMeasure: newMeasureOption.abbreviation,
          }
        : {
            value: value,
            type: newMeasureOption.type,
            unitOfMeasure: newMeasureOption.abbreviation,
          };
    }
    onChange(newValue);
  }

  return (
    <Input
      value={valueAsText}
      onChange={handleTextChange}
      disabled={disabled}
      endEnhancer={
        <Select
          disabled={disabled}
          options={measureOptions}
          labelKey="label"
          valueKey="id"
          placeholder={noUnitOption.displayName}
          onChange={({ value }) => handleUnitChange(value[0]?.id as string)}
          value={[unitOfMeasureOption]}
          overrides={{
            ValueContainer: {
              props: {
                "data-testid": `${field.title}-unit-selector`,
              },
            },
            DropdownContainer: {
              props: {
                "data-testid": `${field.title}-unit-dropdown`,
              },
            },
          }}
        />
      }
      overrides={{ Input: { props: { "data-testid": `${field.title}-input` } } }}
    />
  );
};
