/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import { PickList } from "../../Shared/PickList";
import { PickListFieldSpec } from "../Editor/FieldSpec";

export const PickListEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: PickListFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  const values = React.useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((valueEntry) => {
        return {
          id: valueEntry,
        };
      });
    } else {
      return [];
    }
  }, [value]);

  const handlePickListChange = React.useCallback(
    (values) => {
      const propertyValues = values?.map((value) => value.id);
      onChange(propertyValues);
    },
    [onChange]
  );

  return (
    <PickList pickListData={field.schema} value={values} onChange={handlePickListChange} disabled={disabled} multi />
  );
};
