# import "./fragments/securityMarkingsData.gql"
# import "./fragments/fileFoldersData.gql"

query GetStudies($ids: [UUID!]!) {
  getStudies(ids: $ids) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      version
      description
      keywords
      notes
      customProps
    },
    securityMarkings {
      __typename
      ...securityMarkingsData
    },
    fileFolders {
      __typename
      ...fileFoldersData
    }
  },
},