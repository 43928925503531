/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { EndAnchoredRow, TitledBorder } from "../DesignSystem/Containers";
import { DuplicateFileTable, FileInfoWithAliasId, SelectableDuplicateFileInfo } from "./DuplicateFileTable";
import GetFiles from "../Api/Gql/GetFiles.gql";
import { FileInfo } from "Api";
import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "baseui/button";
import { LabelLarge } from "baseui/typography";
import { FileUpload } from "../Asset/Tree/FileUpload";
import { AssetDetailsPageAction } from "../Asset/Editor/AssetDetailsState";

export function DuplicateFilePane({
  duplicateFileUpload,
  dispatch,
}: {
  duplicateFileUpload?: FileUpload;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element {
  const [selectedFileWithAliasId, setSelectedFileWithAliasId] = React.useState<FileInfoWithAliasId>();

  const [duplicateFileInfoList, setDuplicateFileInfoList] = React.useState<FileInfoWithAliasId[]>([]);

  const [getFileInfos] = useLazyQuery(gql(GetFiles), {
    fetchPolicy: "network-only",
    onCompleted: (fileData) => {
      const fileInfos = fileData?.getFiles as FileInfo[];
      const fileInfosWithAliasIds: FileInfoWithAliasId[] = fileInfos.map((fileInfo, index) => {
        const aliasId = duplicateFileUpload?.duplicateAliasIds[index];
        const fileInfoWithAlias: FileInfoWithAliasId = { fileInfo, aliasId };
        return fileInfoWithAlias;
      });
      setDuplicateFileInfoList(fileInfosWithAliasIds ?? []);
    },
  });

  React.useMemo(() => {
    getFileInfos({
      variables: { ids: duplicateFileUpload?.duplicateAliasIds },
    });
  }, [duplicateFileUpload?.duplicateAliasIds, getFileInfos]);

  const duplicateFileItems = React.useMemo<SelectableDuplicateFileInfo[]>(() => {
    return duplicateFileInfoList.map((fileInfoWithAliasId) => {
      return {
        item: fileInfoWithAliasId,
        isSelected: selectedFileWithAliasId?.fileInfo.id === fileInfoWithAliasId.fileInfo.id,
      };
    });
  }, [duplicateFileInfoList, selectedFileWithAliasId?.fileInfo.id]);

  const handleSelection = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, clickedItem: SelectableDuplicateFileInfo) => {
      setSelectedFileWithAliasId(clickedItem.item);
    },
    []
  );

  const handleChoosePressed = React.useCallback(() => {
    const fileInfo = selectedFileWithAliasId?.fileInfo;
    const aliasId = selectedFileWithAliasId?.aliasId;
    dispatch(["replaceDuplicateUpload", { fileInfo, aliasId }]);
  }, [dispatch, selectedFileWithAliasId?.aliasId, selectedFileWithAliasId?.fileInfo]);

  return (
    <TitledBorder>
      <EndAnchoredRow>
        <LabelLarge>Select a File to Replace Duplicate</LabelLarge>
        <Button disabled={selectedFileWithAliasId === undefined ? true : false} onClick={handleChoosePressed}>
          Choose
        </Button>
      </EndAnchoredRow>
      <DuplicateFileTable
        selectableDuplicateFileInfos={duplicateFileItems}
        onClick={handleSelection}
      ></DuplicateFileTable>
    </TitledBorder>
  );
}
