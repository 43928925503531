/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Location } from "history";
import * as React from "react";
import { Prompt, useHistory } from "react-router-dom";

import { WarningDialog } from "./WarningDialog";

export const UnsavedChangesPrompt = ({ when }: { when?: boolean | undefined }): JSX.Element => {
  return (
    <RouteLeavingGuard
      when={when}
      titleText={`Leave without saving?`}
      contentText={`You have unsaved changes. Are you sure you want to leave this page and lose your changes?`}
      cancelButtonText={`Don't leave`}
      confirmButtonText={`Leave and lose changes`}
      shouldBlockNavigation={() => true}
    ></RouteLeavingGuard>
  );
};

const RouteLeavingGuard = ({
  when,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  shouldBlockNavigation,
}: {
  when?: boolean | undefined;
  titleText: string;
  contentText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  shouldBlockNavigation: (location: Location) => boolean;
}): JSX.Element => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const history = useHistory();

  const closeModal = (): void => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(`${lastLocation.pathname}${lastLocation.search}`);
    }
  }, [history, confirmedNavigation, lastLocation]);
  return (
    <>
      {/* Prompt is provided by react-router-dom and uses browser-native styling with no option
          for customization. Message can be a plain text string, or a function that returns
          true if the navigation should be allowed and false otherwise. This is used to
          intercept the navigation event and let us show our own custom dialog. */}
      <Prompt when={when} message={handleBlockedNavigation} />
      <WarningDialog
        isOpen={modalVisible}
        titleText={titleText}
        contentText={contentText}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};
