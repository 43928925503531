mutation UpdateTask($task: TaskInfoInput!) {
  updateTask(task: $task) {
    id
    category
    hierarchyNumber
    title
    priority
    djsApprovalDate
    description
    notes
    requiredCapabilities
    measures {
      id
      measureNumber
      unit
      description
    }
  }
}
