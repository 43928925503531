/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { TaskListMetadata, TaskListSummary, UploadResponse } from "Api";
import axios from "axios";
import { styled } from "baseui";
import { Button } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import { Upload } from "baseui/icon";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { Spinner } from "baseui/spinner";
import { LabelSmall } from "baseui/typography";
import React from "react";
import { RightAlignedRow, VerticalStack } from "../../DesignSystem/Containers";
import { simpleTextFieldSpec } from "../../Asset/Editor/FieldSpec";
import { notify } from "../../Shared/Notify";
import { AxiosContext } from "../../Utils/AuthContext";
import { SimpleTextEditor } from "../../Asset/Shared/SimpleTextEditor";

export const UploadTaskListModal = ({
  isOpen,
  onUploadComplete,
  onClose,
}: {
  isOpen: boolean;
  onUploadComplete: (taskList: TaskListSummary) => void;
  onClose: () => void;
}): JSX.Element => {
  const axiosContext = React.useContext(AxiosContext);

  const completeUpload = React.useCallback(
    (taskList: TaskListSummary) => {
      onUploadComplete(taskList);
      setLoading(false);
    },
    [onUploadComplete]
  );

  const closeModal = React.useCallback(() => {
    setTaskListName("");
    setUploadedFiles([]);
    onClose();
  }, [onClose]);
  const [errorMessage] = React.useState("");
  const [uploading, setLoading] = React.useState(false);
  const [taskListName, setTaskListName] = React.useState("");
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);

  const handleStartUpload = React.useCallback(() => {
    setLoading(true);
    const postToken = axios.CancelToken.source();

    const formData = new FormData();

    const taskListMetadata: TaskListMetadata = {
      name: taskListName,
    };
    formData.append("taskListMetadata", JSON.stringify(taskListMetadata));

    uploadedFiles.forEach((file, index) => {
      formData.append(`${index}`, file, file.name);
    });

    // TODO: This can be rewritten as async/await or possibly
    //    with an axios "use" hook.
    const uploadPromise = axiosContext.post("/ingestTaskList", formData, {
      cancelToken: postToken.token,
    });
    uploadPromise
      .then((response) => {
        const uploadResponse = response.data as UploadResponse<TaskListSummary>;
        if (uploadResponse.errors) {
          notify.serverError(
            `${uploadResponse.errors.length} error(s) occurred; ${uploadResponse.errors
              ?.map((error) => error.message)
              .join(", ")}`
          );
          completeUpload(null);
        } else {
          completeUpload(uploadResponse.data);
          setTaskListName("");
          closeModal();
        }
      })
      .catch((reason) => {
        if (axios.isCancel(reason)) {
          notify.info(reason?.message);
        } else {
          notify.warning(reason?.message);
        }
        completeUpload(undefined);
      });
  }, [axiosContext, closeModal, completeUpload, taskListName, uploadedFiles]);

  const handleFileDrop = React.useCallback(
    (acceptedFiles: File[], _rejectedFiles: File[]) => {
      setUploadedFiles(acceptedFiles);
    },
    [setUploadedFiles]
  );

  const allDataSpecified = taskListName?.length > 0 && uploadedFiles?.length > 0;

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      closeable={!uploading}
      // The unstable_ModalBackdropScroll will be removed in the next major baseui version.
      // It was recommended to set it to true to prepare for its removal.
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>{"Import task list"}</ModalHeader>
      <ModalBody>
        {uploading ? (
          <SelectedFileBody>
            <LabelSmall>{"Importing ..."}</LabelSmall>
            <Spinner />
          </SelectedFileBody>
        ) : (
          <VerticalStack>
            <SimpleTextEditor
              field={simpleTextFieldSpec("Name")}
              value={taskListName}
              onChange={(newName: string) => {
                setTaskListName(newName);
                setLoading(false);
              }}
            />
            {uploadedFiles?.length > 0 ? (
              <SelectedFileBody>
                <LabelSmall>{"Task source file"}</LabelSmall>
                <span>{uploadedFiles[0].name}</span>
                <Button kind="tertiary" onClick={() => setUploadedFiles([])}>
                  {"Change file"}
                </Button>
              </SelectedFileBody>
            ) : (
              <FileUploader multiple={false} onDrop={handleFileDrop} errorMessage={errorMessage} />
            )}
          </VerticalStack>
        )}
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton
            kind="tertiary"
            onClick={() => {
              setTaskListName("");
              closeModal();
            }}
            disabled={uploading}
          >
            {"Cancel"}
          </ModalButton>
          <ModalButton
            kind="primary"
            startEnhancer={Upload}
            onClick={handleStartUpload}
            disabled={uploading || !allDataSpecified}
          >
            {"Import"}
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
};

export const SelectedFileBody = styled("div", ({ $theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    rowGap: "1rem",

    paddingTop: $theme.sizing.scale900,
    paddingBottom: $theme.sizing.scale200,
    width: "100%",
  };
});
