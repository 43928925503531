import { useCallback, useRef, useEffect } from 'react';
import { triggerEvent } from '../../utilities/events';
import { v4 as uuidv4 } from 'uuid';

export const BREADCRUMB_OVERRIDE_ADDED = 'SAFESIM/EVENTS/BREADCRUMB_OVERRIDE_ADDED';

const requestBreadcrumbOverrideItems = (items) => {
  triggerEvent(BREADCRUMB_OVERRIDE_ADDED, items);
};

export const useBreadcrumbOverride = () => {
  const hookUUID = useRef(uuidv4());

  const callback = useCallback((overrides) => {
    requestBreadcrumbOverrideItems({ id: hookUUID.current, content: overrides });
  }, []);

  useEffect(() => {
    const hookID = hookUUID.current;
    return () => requestBreadcrumbOverrideItems({ id: hookID, content: undefined });
  }, []);

  return callback;
};
