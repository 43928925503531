// To avoid circular dependencies,
// all components that draw from allSafesimComponents and the ErrorBoundaries should go here

import { useSafesimDrawerHook, OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED } from './safesim_hooks/useSafesimDrawerHooks';
import { useEventSubscription } from './safesim_hooks/useEventSubscription';
import { SafesimErrorBoundary, SafesimPageComponentErrorBoundary } from '../utilities/SafesimErrorBoundaries';
import { SafesimDrawer, SafesimTab, SafesimButton, SafesimTabs } from './allSafesimComponents';
import React, { useMemo, useState, useCallback, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSafesimEventToaster } from './safesim_hooks/useSafesimEventToaster';
import NewWindow from 'react-new-window';
import { PopoutContext } from './safesim_hooks/usePortalHook';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';

export const SafesimEventDrawer = () => {
  const { content, setContent } = useSafesimDrawerHook();

  const contentChangedListener = useCallback(
    (evt) => {
      /// ensure the event included an id to denote the hook sender ///
      /// if content was included always show content ///
      /// if the evt.content is undefined, take that as a signal to accept the undefined (close) if the active drawer is from the calling hook //
      if (evt.detail?.id && (evt.detail?.content || evt.detail?.id === content?.id)) {
        setContent({ id: evt.detail?.id, content: evt.detail?.content });
      }
    },
    [content, setContent]
  );
  useEventSubscription(OVERLAY_DRAWER_CONTENT_CHANGE_REQUESTED, contentChangedListener);

  return (
    <SafesimErrorBoundary>
      <SafesimDrawer
        {...content?.content}
        isOpen={content?.content}
        onClose={() => {
          content?.content?.onClose?.();
          setContent(undefined);
        }}
      />
    </SafesimErrorBoundary>
  );
};

/**
 * This component wraps the NewWindow library to allow for elements to be passed in and presented within a popou window
 * all props other than content are passed directly to the NewWindow component
 * The contents presented in the window are wrapped within a div to provide the default styling on those contents
 * Contents also wrapped within an Error Boundary
 * @param {*} props
 * content - array of elements or single element to display within the new window
 * @returns
 */
export const SafesimPopOut = (props) => {
  const { content, ...rest } = props;

  const [popoutWindow, setPopoutWindow] = useState();
  const OnOpen = useCallback((window) => setPopoutWindow(window), []);

  return (
    <NewWindow onOpen={OnOpen} {...rest}>
      <PopoutContext.Provider value={{ isPoppedOut: true, window: popoutWindow }}>
        <div className={'Dark kmDefaultColor kmBackground homepageContentArea scrollOverflow fillParent'}>
          <SafesimPageComponentErrorBoundary>{content}</SafesimPageComponentErrorBoundary>
        </div>
      </PopoutContext.Provider>
    </NewWindow>
  );
};

/**
 * Component that is used to render a collapsible drawer that expands to the size of its content
 * Provides capability to display multiple content requests within labeled tabs and allow those tabs to be popped out
 * Provides functionality along with the useSafesimDrawerContentRequest to close any opened tabs / windows when a
 * component unloads
 * @param {*} props
 * - drawerName - used to uniquely identify the drawer itself, oh yes there may be multiple side bar drawers, not required
 * - `isCollapsed` - the collapsed state of the drawer to explicitly control the expansion if desired, not required
 * - `onCollapse` - a callback to execute when the close button is clicked in the drawer
 * - `contentClassName` - Additional class to apply to the inner content container for the drawer
 * - `contentStyle` - Additional styles to apply to the inner content container for the drawer
 */
const SafesimSidebarDrawer = ({ drawerName, isCollapsed, onCollapse, contentClassName, contentStyle, children, className, style, eventName, ...props }) => {
  ///////////////////////////
  /// Side Bar drawer related state management ///
  /// the tabs (note tabs plural, that contains the individual tab elements) itself requires an id, so if a drawername wasn't passed in, then use a uuid
  const tabsName = useMemo(() => drawerName ?? uuidv4(), [drawerName]);
  const eventToaster = useSafesimEventToaster();

  /// control the actively selected tab, to make it easier to display the lock icon correctly ///
  const [selectedTab, setSelectedTab] = useState(0);
  /// This is the callback used to update the drawer and popout window states based on menu options selected
  const drawerContentReducer = useCallback(
    (state, action) => {
      /// the action is the event object, passing in undefined or no id will clear all contents///
      let newState = {};

      /// check if the action was specific to a window, in this case popping out a tab to a window ///
      if (action?.window && action?.index > -1 && action?.index < state?.tabs?.length) {
        /// copy the existing state to start ///
        newState = { ...state };
        /// remove the tab to be popped out ///
        const removedTabs = newState.tabs?.splice(action.index, 1);
        /// confirm the tab was removed ///
        if (removedTabs && removedTabs.length > 0) {
          /// push on a new popout window, which includes the window and then the id in the parallel array ///
          newState.windows.push(
            <SafesimPopOut
              key={uuidv4()}
              title={removedTabs[0].title}
              content={removedTabs[0].panel}
              onBlock={() => {
                eventToaster({
                  topic: 'Pop-ups',
                  message: 'Failed to create pop-up window. Please check your browser settings to enable pop-ups for this site.',
                  intent: 'danger',
                });
              }}
            />
          );
          newState.windowIds.push({ id: removedTabs[0].id, title: removedTabs[0].title });
        }
      }
      /// check if it was a tab deletion ///
      else if (action?.delete && action?.index > -1 && action?.index < state?.tabs?.length) {
        newState = { ...state };
        newState.tabs?.splice(action.index, 1);
      }
      /// process adding / updating / tabs actions ///
      else if (action?.id) {
        /// the state is going to update so go ahead and copy the newState array ///
        newState = { ...state };
        /// check if a tab name was included ///
        if (action?.title) {
          /// check if tab's panel is undefined, which means that this title for the action's id should be removed ///
          if (action?.panel) {
            /// either an existing unlocked tab will be updated, or a new tab will be added ///
            /// check if it is just an update, and the index to update is valid ///
            if (action?.update && action?.index > -1 && action?.index < state?.tabs?.length) {
              const { update, index, ...tab } = action;
              /// update the item at index ///
              newState.tabs[index] = { ...tab };
              setSelectedTab(index);
            } else {
              newState.tabs = newState.tabs ?? [];
              /// tab name was included, find the first tab that is not locked that uses the tab name ///
              const unlockedMatchedName = newState.tabs?.findIndex((tab) => tab?.title === action.title && !tab?.isLocked);
              /// update the match, otherwise, push on the new tab ///
              if (unlockedMatchedName !== -1) {
                newState.tabs[unlockedMatchedName] = { ...action };
                setSelectedTab(unlockedMatchedName);
              } else {
                newState.tabs.push({ ...action, guid: uuidv4() });
                setSelectedTab(newState.tabs.length - 1);
              }
            }
          } else {
            /// keep everything that does not have the same id and the same tabName ///
            newState.tabs = state?.tabs?.filter((tab) => tab?.id !== action.id || tab?.title !== action.title);
            /// remove from the popout windows ///
            const tempWindows = [];
            const tempWindowIds = [];
            newState.windowIds?.forEach((value, index) => {
              if (value?.id !== action.id || value?.title !== action.title) {
                tempWindows.push(newState.windows[index]);
                tempWindowIds.push(value);
              }
            });
            newState.windows = tempWindows;
            newState.windowIds = tempWindowIds;
          }
        } else {
          /// no tab name was included, so take that to mean that all of the id's tabs should be removed ///
          /// filter to return all the tabs that don't match the id (remove all of the id's tabs) ///
          newState.tabs = state?.tabs?.filter((tab) => tab?.id !== action?.id);
          /// remove from the popout windows ///
          const tempWindows = [];
          const tempWindowIds = [];
          newState.windowIds?.forEach((value, index) => {
            if (value?.id !== action?.id) {
              tempWindows.push(newState.windows[index]);
              tempWindowIds.push(value);
            }
          });
          newState.windows = tempWindows;
          newState.windowIds = tempWindowIds;
        }
      }
      /// the action is the event object, passing in undefined will clear all contents, otherwise default state will be used ///
      return newState;
    },
    [eventToaster]
  );

  /// declare reducer and state for managing tabs and drawers ///
  const [drawerContent, setDrawerContent] = useReducer(drawerContentReducer, { tabs: [], windows: [], windowIds: [] });

  /// create a memoized version of the tabs ///
  const drawerTabs = useMemo(
    () =>
      drawerContent?.tabs?.map((tab, index) => {
        const { isLocked, panel, guid, ...rest } = tab;
        return <SafesimTab {...rest} id={index} key={index} panel={<SafesimPageComponentErrorBoundary>{panel}</SafesimPageComponentErrorBoundary>} />;
      }),
    [drawerContent]
  );

  /// it is the case that when a tab is deleted from the reducer above, the selectedTab may not be valid
  /// thus the activeTab will default to 0 if there is no tab to fall into at the selectedTab index ///
  const activeTab = useMemo(() => (selectedTab < drawerTabs?.length ? selectedTab : 0), [selectedTab, drawerTabs]);

  /// process new tabs being sent to the drawer ///
  const contentChangedListener = useCallback((evt) => setDrawerContent(evt?.detail), [setDrawerContent]);
  useEventSubscription(eventName, contentChangedListener);

  /// callbacks to attach to the close and pop out options ///
  const CloseActiveTab = useCallback(() => {
    setDrawerContent({ delete: true, index: selectedTab });
    setSelectedTab(selectedTab === 0 ? selectedTab : selectedTab - 1);
  }, [selectedTab]);
  const PopActiveTab = useCallback(() => {
    setDrawerContent({ window: true, index: selectedTab });
    setSelectedTab(selectedTab === 0 ? selectedTab : selectedTab - 1);
  }, [selectedTab]);
  ///////////////////////////

  /// based on the content the style className of the drawer will include collapsed or not ///
  const containerClassNames = `${className} sidebarDrawer ${
    drawerName === 'left' ? ' sidebarDrawerL ' : drawerName === 'right' ? ' sidebarDrawerR ' : drawerName === 'bottom' ? ' sidebarDrawerB ' : ''
  }  ${isCollapsed ?? !drawerTabs?.length > 0 ? 'collapsed' : ''}`;

  /// rendered items for the drawer, wrapped in a resizable , all content wrapped in an Error Boundary
  /// SafesimTabs element contains the tabs, the popout windows are then rendered outside of the resizeable

  return (
    <>
      <div className={isCollapsed ?? !drawerTabs?.length > 0 ? 'topBorderCollapsedSidebarDrawer' : drawerName === 'bottom' ? 'topBorderSidebarDrawer' : ''}></div>
      <Resizable
        enable={{
          top: drawerName === 'bottom',
          right: drawerName === 'left',
          bottom: false,
          left: drawerName === 'right',
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        className={containerClassNames}
        defaultSize={drawerName === 'bottom' ? { height: '300px' } : { width: 400 }}
        minWidth={drawerName === 'bottom' ? '100%' : drawerTabs?.length ? 250 : 0}
        minHeight={drawerName === 'bottom' ? 50 : '100%'}
        {...props}
      >
        <SafesimErrorBoundary>
          <div className={'sidebarTop'}>
            {drawerTabs?.length > 0 && drawerTabs?.length > activeTab && (
              <SafesimButton
                outlined={false}
                minimal
                icon={drawerContent?.tabs?.[activeTab]?.isLocked ? 'lock' : 'unlock'}
                className='sidebarDrawerCloseButton'
                onClick={() =>
                  setDrawerContent({ ...drawerContent?.tabs?.[activeTab], isLocked: !drawerContent?.tabs?.[activeTab]?.isLocked, update: true, index: activeTab })
                }
              />
            )}
            <SafesimButton outlined={false} minimal icon='duplicate' className='sidebarDrawerCloseButton' onClick={PopActiveTab} />
            <SafesimButton outlined={false} minimal icon='cross' className='sidebarDrawerCloseButton' onClick={CloseActiveTab} />
          </div>
          <div className={`sidebarDrawerContent ${contentClassName}`} style={{ ...contentStyle }}>
            <SafesimTabs
              className={'sidebarTabs'}
              style={{ width: '100%', height: '100%' }}
              id={tabsName}
              defaultSelectedTabId={0}
              selectedTabId={activeTab}
              onChange={(newTabId) => setSelectedTab(newTabId)}
            >
              {drawerTabs}
            </SafesimTabs>
            <div className={drawerName === 'bottom' ? '' : 'extraHeightBottom'}></div>
          </div>{' '}
        </SafesimErrorBoundary>
      </Resizable>
      {drawerContent.windows && [...drawerContent.windows]}
    </>
  );
};
SafesimSidebarDrawer.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
  className: PropTypes.string,
  drawerName: PropTypes.string,
  eventName: PropTypes.string,
};

export { SafesimSidebarDrawer };
