import { useCallback, useState } from 'react';
import { useDOMElementEventSubscription } from './useEventSubscription';

/**
 * React hook that listens for changes to the focus of a specified DOM element and returns the boolean focused state of the element
 * @param {Element} domElement The DOM element for which to determine the focus state
 * @returns {boolean} Whether the specified DOM element is currently focused
 */
export const useHasFocus = (domElement) => {
  const [isFocused, setIsFocused] = useState(
    document.hasFocus() &&
      !!domElement &&
      (document.activeElement === domElement || domElement === window)
  );

  useDOMElementEventSubscription(
    domElement,
    'focus',
    useCallback(() => setIsFocused(true), [])
  );

  useDOMElementEventSubscription(
    domElement,
    'blur',
    useCallback(() => setIsFocused(false), [])
  );

  return isFocused;
};