/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useStyletron } from "baseui";
import { Button, SHAPE } from "baseui/button";
import { Card } from "baseui/card";
import { FormControl } from "baseui/form-control";
import { CheckIndeterminate, Plus } from "baseui/icon";
import { Input } from "baseui/input";
import { SIZE, Textarea } from "baseui/textarea";
import { StatefulTooltip } from "baseui/tooltip";
import { PickListValueOption } from "Api";

export const PickListSchemaOptionEditor = ({
  option,
  position,
  onOptionUpdated,
  onAddNewOption,
  onRemoveOption,
}: {
  option: PickListValueOption;
  position: number;
  onOptionUpdated: (index: number, value?: string, description?: string) => void;
  onAddNewOption: (position: number) => void;
  onRemoveOption: (position: number) => void;
}): JSX.Element => {
  const [css] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
      })}
    >
      <div
        className={css({
          width: "95%",
        })}
      >
        <Card>
          <FormControl label={() => "Value"}>
            <Input
              id="input-id"
              value={option.value}
              onChange={(event) => onOptionUpdated(position, event.currentTarget.value, undefined)}
            />
          </FormControl>
          <FormControl label={() => "Description"}>
            <Textarea
              id="input-id"
              value={option.description}
              size={SIZE.compact}
              onChange={(event) => onOptionUpdated(position, undefined, event.currentTarget.value)}
            />
          </FormControl>
        </Card>
      </div>
      <div
        className={css({
          paddingLeft: "10px",
          paddingTop: "110px",
          display: "flex",
          width: "100px",
          justifyContent: "space-around",
        })}
      >
        <StatefulTooltip accessibilityType={"tooltip"} content="Add option">
          <Button
            onClick={() => onAddNewOption(position + 1)}
            shape={SHAPE.circle}
            size="mini"
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: "white",
                  outline: "grey solid 0.15rem",
                },
              },
            }}
          >
            <Plus color="grey" title="" />
          </Button>
        </StatefulTooltip>
        <StatefulTooltip accessibilityType={"tooltip"} content="Remove option">
          <Button
            onClick={() => onRemoveOption(position)}
            shape={SHAPE.circle}
            size="mini"
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: "white",
                  outline: "grey solid 0.15rem",
                },
              },
            }}
          >
            <CheckIndeterminate title="" color="grey" />
          </Button>
        </StatefulTooltip>
      </div>
    </div>
  );
};
