/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import Keycloak, { KeycloakConfig, KeycloakPromise, KeycloakTokenParsed } from "keycloak-js";

export const minValiditySec = 5;
export const minValidityMs = minValiditySec * 1000;

// Based on this example: https://github.com/dasniko/keycloak-reactjs-demo
let _keycloak: Keycloak = null;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (
  onAuthenticatedCallback: (useKeycloak: boolean) => void,
  config: KeycloakConfig
): Promise<void> => {
  // See https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter for Keycloak config
  _keycloak = new Keycloak({
    url: config.url,
    realm: config.realm,
    clientId: config.clientId,
  });

  _keycloak
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
    })
    .then(async (authenticated) => {
      if (!authenticated) {
        console.warn("user is not authenticated..!");
      } else {
        onAuthenticatedCallback(true);
      }
    })
    .catch(console.error);
};

const doLogin = (): KeycloakPromise<void, void> => _keycloak?.login();

const doLogout = (): KeycloakPromise<void, void> => _keycloak?.logout();

const authTokenRaw = (): string => _keycloak?.token;
const authToken = (): KeycloakTokenParsed => _keycloak?.tokenParsed;

const isLoggedIn = (): boolean => !!_keycloak?.token;

const updateTokenIfExpired = (successCallback: () => Promise<boolean>): Promise<boolean> =>
  _keycloak
    .updateToken(minValiditySec)
    .then((refreshed) => {
      if (refreshed) {
        successCallback();
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {
      doLogin();
      return false;
    });

const refreshTokenRaw = (): string => _keycloak?.refreshToken ?? "";
const refreshToken = (): KeycloakTokenParsed | null => _keycloak?.refreshTokenParsed ?? null;

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  authToken,
  authTokenRaw,
  updateTokenIfExpired,
  refreshToken,
  refreshTokenRaw,
};

export default KeycloakService;
