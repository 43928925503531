/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Alert } from "baseui/icon";
import { Input } from "baseui/input";
import { useStyletron } from "baseui";
import React from "react";

// Text controls don't deal well with null values. React will complain about
// having a mix of controlled and uncontrolled components. We get around this in
// the controls themselves by normalizing null to an empty string.
export function normalizeTextControlValue(text: string): string {
  return text ? `${text}` : "";
}

// Since text controls are rendering null as empty string, we need to
// be able to reverse that. We fold empty string into null to make sure
// the checks to see if an object is changed is as simple as possible.
// From a user's perspective, there is no difference between null and
// empty string anyway.
export function normalizeTextPropertyValue(text: string): string {
  return text === "" ? null : text;
}

export const SimpleTextEditor = ({
  value,
  disabled,
  onChange,
  label,
  shouldShowError,
}: {
  value: any;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
  label?: string;
  shouldShowError?: boolean;
}): JSX.Element => {
  const valueAsText = React.useMemo(() => {
    return normalizeTextControlValue(value);
  }, [value]);

  function handleTextChange(event: React.FormEvent<HTMLInputElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value) || "";
    onChange(newText);
  }

  function Negative(): JSX.Element {
    const [css, theme] = useStyletron();
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          paddingRight: theme.sizing.scale500,
          color: theme.colors.negative400,
        })}
      >
        <Alert size="18px" />
      </div>
    );
  }

  return (
    <Input
      value={valueAsText}
      onChange={handleTextChange}
      disabled={disabled}
      name={label}
      overrides={{ Input: { props: { "data-testid": `${label}-input` } }, After: shouldShowError ? Negative : {} }}
      error={shouldShowError}
    />
  );
};
