/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { AssetQueryResponse, AssetSummary, QuerySettingsInput, SearchSpecInput, SecurityMarkings } from "Api";
import findAssetsWithText from "../Api/Gql/FindAssetsWithText.gql";
import { handleApolloError } from "./Errors";
import findAssets from "../Api/Gql/FindAssets.gql";
import { appConfigurationsVar } from "../GlobalState";

export function useFindByTextQuery(
  searchText: string,
  filter?: SearchSpecInput,
  querySettings?: QuerySettingsInput,
  errorTextOnFailure?: string
): { loading: boolean; response: AssetQueryResponse; results: AssetSummary[]; refetch: any } {
  const errorMessage = errorTextOnFailure ?? `Error searching for ${searchText}`;

  const variables: any = {
    textSubstring: searchText,
  };

  if (filter) {
    variables.filter = filter;
  }

  if (querySettings) {
    variables.querySettings = querySettings;
  }

  const { loading, data, refetch } = useQuery(gql(findAssetsWithText), {
    fetchPolicy: "network-only",
    variables,
    onError: (error) => handleApolloError(error, errorMessage),
  });

  const response: AssetQueryResponse = data?.findAssetsWithText;
  const results = response?.results ?? [];
  return { loading, response, results, refetch };
}

export function useFindAssetsQuery(
  filter?: SearchSpecInput,
  querySettings?: QuerySettingsInput,
  errorTextOnFailure?: string
): { loading: boolean; response: AssetQueryResponse; results: AssetSummary[]; refetch: any } {
  const errorMessage = errorTextOnFailure ?? "Error fetching assets!";

  const variables: any = {};

  if (filter) {
    variables.filter = filter;
  }

  if (querySettings) {
    variables.querySettings = querySettings;
  }

  const { loading, data, refetch } = useQuery(gql(findAssets), {
    fetchPolicy: "network-only",
    variables,
    onError: (error) => handleApolloError(error, errorMessage),
  });

  const response: AssetQueryResponse = data?.findAssets;
  const results = response?.results ?? [];
  return { loading, response, results, refetch };
}

export function useMarkingsDisableSave(securityMarkings: SecurityMarkings | undefined | null): boolean {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const markingsRequired = appConfigurations.markingsRequired;

  const classificationLevel = securityMarkings?.classificationLevel;

  return markingsRequired && (classificationLevel === undefined || classificationLevel === null);
}
