// All common components. Should be in alphabetical order
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { wrappedLogger } from '../utilities/wrappedLogger';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import {
  Alert,
  Alignment,
  AnchorButton,
  Breadcrumb,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Callout,
  Card,
  Checkbox,
  Classes,
  Collapse,
  Intent,
  MultiSlider,
} from '@blueprintjs/core';
import { Dialog, Divider, Drawer, EditableText, FormGroup, Icon, InputGroup, Label, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { Navbar, NonIdealState, NumericInput, Overlay, ProgressBar, Radio, RadioGroup, Slider, Spinner, Switch } from '@blueprintjs/core';
import { Tab, Tabs, Tag, TagInput, Text, TextArea, Toast, Toaster, Tree, TreeNode } from '@blueprintjs/core';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu as SidebarMenu } from 'react-pro-sidebar';

import { Classes as Popover2Classes, ContextMenu2, Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';

import { useEventSubscription } from './safesim_hooks/useEventSubscription';
import { EVENT_TOASTER_NEW_TOAST, useSafesimEventToaster } from './safesim_hooks/useSafesimEventToaster';
import { BREADCRUMB_OVERRIDE_ADDED } from './safesim_hooks/useBreadcrumbOverride';
import { useSafesimNavigate } from './safesim_hooks/useSafesimNavigate';

import { usePrevious } from './safesim_hooks/usePrevious';
import { usePortal } from './safesim_hooks/usePortalHook';
import { AbsTimeToPrettyString, CamelCaseToSpacedString, NumberOnlyRegex } from '../utilities/stringModifiers';
import { SafesimValueDisplay } from './SafesimValueDisplay';
import { safesimDarkClass, safesimIntentClass } from '../utilities/aliasedClassNames';
import { SafesimErrorBoundary } from '../utilities/SafesimErrorBoundaries';
import { SafesimControlGroup } from './SafesimControlGroup';

export const SafesimAlignment = Alignment;
/**
 *
 * @param {*} props
 * header: can be a string or an element (like a SafesimCard or h1 tag)
 * onCollapsed: function called when collapse is expanded. Takes in isCollapsed prop.
 * @returns
 */
export const SafesimCollapse = (props) => {
  const { className, children, onChangeCollapsed, isOpen, disabled, header, ...other } = props;
  const [open, setOpen] = useState(false);
  const openState = useMemo(() => isOpen ?? open, [isOpen, open]);
  const isControlled = isOpen !== undefined && isOpen !== null;

  const onClick = useCallback(() => {
    isControlled ? onChangeCollapsed?.(!isOpen) : setOpen((prev) => !prev);
  }, [isControlled, isOpen, onChangeCollapsed]);

  useEffect(() => {
    !isControlled && onChangeCollapsed?.(openState);
  }, [isControlled, openState, onChangeCollapsed]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <SafesimButton
          alignText='left'
          fill={true}
          disabled={disabled}
          rightIcon={openState ? 'caret-up' : 'caret-down'}
          onClick={onClick}
          className={' kmHeading3 collapseButton ' + className}
          text={header}
        />
      </div>
      <Collapse className={'kmPrimaryText collapse' + (openState ? ' collapseOpen' : '')} isOpen={openState} {...other}>
        {children}
      </Collapse>
    </>
  );
};

export const SafesimAlert = Alert;

export const SafesimAnchorButton = (props) => {
  const { className, intent, outlined, onClick, minimal, ...other } = props;
  /// wrap the onClick in a try catch to ensure error handling ///
  const OnClick = useCallback(
    (event) => {
      try {
        onClick?.(event);
      } catch (e) {
        wrappedLogger?.error(e);
      }
    },
    [onClick]
  );

  // buttonVarType has a default that is regular but if buttonType is plain or has a border the value is changed accordingly
  // From the Blueprint demo, outlined button prop takes precedence over minimal button prop
  // if both outlined and minimal are true, the button will be outlined
  const buttonTypeVar = outlined ? 'kmInterfaceElementBorder' : minimal ? 'kmInterfaceElementPlain' : 'kmInterfaceElement';

  // don't pass down the minimal or outlined prop - use the KM styles
  // never pass intent prop. All buttons in the KM guide are blue, so use the base kmInterfaceElement styles.
  return <AnchorButton className={buttonTypeVar + ' kmAnchor button ' + safesimIntentClass + className} intent={undefined} outlined={outlined} onClick={OnClick} {...other} />;
};

export const SafesimBreadcrumb = (props) => {
  const { className, ...other } = props;

  return <Breadcrumb className={'kmPrimaryText ' + className} {...other} />;
};

export const SafesimBreadcrumbs = Breadcrumbs;

export const SafesimButton = (props) => {
  const { className, intent, outlined, onClick, minimal, tooltipText, tooltipProps, ...other } = props;
  /// wrap the onClick in a try catch to ensure error handling ///
  const OnClick = useCallback(
    (event) => {
      try {
        onClick?.(event);
      } catch (e) {
        wrappedLogger?.error(e);
      }
    },
    [onClick]
  );

  //buttonVarType has a default that is regular but if buttonType is plain or has a border the value is changed accordingly
  // From the Blueprint demo, outlined button prop takes precedence over minimal button prop
  // if both outlined and minimal are true, the button will be outlined
  const buttonTypeVar = outlined ? 'kmInterfaceElementBorder' : minimal ? 'kmInterfaceElementPlain' : 'kmInterfaceElement';

  // don't pass down the minimal or outlined prop - use the KM styles
  // never pass intent prop. All buttons in the KM guide are blue, so use the base kmInterfaceElement styles.
  return (
    <>
      {tooltipText ? (
        <SafesimTooltip content={tooltipText} openOnTargetFocus={false} {...tooltipProps}>
          <Button
            className={' kmPrimaryText ' + buttonTypeVar + ' button ' + safesimIntentClass + className}
            intent={undefined}
            outlined={outlined}
            onClick={OnClick}
            {...other}
          />
        </SafesimTooltip>
      ) : (
        <Button
          className={' kmPrimaryText ' + buttonTypeVar + ' button ' + safesimIntentClass + className}
          intent={undefined}
          outlined={outlined}
          onClick={OnClick}
          {...other}
        />
      )}
    </>
  );
};

//This is a minimally styled x button. Will be grey and does not changed themes. For example this is used for the multiselect input (which is white)
export const SafesimButtonCross = (props) => {
  const { className, intent, outlined, minimal, onClick, ...other } = props;

  const OnClick = useCallback(
    (event) => {
      try {
        onClick?.(event);
      } catch (e) {
        wrappedLogger?.error(e);
      }
    },
    [onClick]
  );

  return (
    <SafesimButton minimal={true} onClick={OnClick} {...other}>
      <SafesimIcon icon='cross' className={'kmSecondaryText'} />
    </SafesimButton>
  );
};
/**
 * Functional component that provides a ready made button to display a home icon
 * and auto navigate back to the home page on click
 * @param {*} props
 * @returns
 */
export const SafesimButtonHome = (props) => {
  const { onClick, icon, text, ...other } = props;
  const navigate = useNavigate();
  const OnClick = useCallback(
    (event) => {
      onClick?.(event);
      navigate(window.routepaths.home);
    },
    [navigate, onClick]
  );

  return <SafesimButton {...other} icon={icon || (!text && 'home')} text={text} onClick={OnClick} />;
};

/**
 * Functional component that provides a ready made button to display a "go back" icon
 * and auto navigate back to the previous page on click
 * @param {*} props
 * @returns
 */
export const SafesimButtonBack = (props) => {
  const { onClick, ...other } = props;
  const navigate = useNavigate();
  const OnClick = useCallback(
    (event) => {
      onClick?.(event);
      navigate(-1);
    },
    [navigate, onClick]
  );
  return <SafesimButton {...other} icon={'arrow-left'} onClick={OnClick} />;
};

/**
 * Functional components used to create a reusable button for linking to the help page
 * @returns
 */
export const SafesimButtonHelp = (props) => {
  const { onClick, minimal, ...other } = props;
  const navigate = useNavigate();
  const OnClick = useCallback(
    (event) => {
      onClick?.(event);
      navigate(window.routepaths.home + window.routepaths.userguide);
    },
    [navigate, onClick]
  );
  return <SafesimButton {...other} minimal={true} icon={'help'} onClick={OnClick} />;
};

export const SafesimButtonGroup = ButtonGroup;

export const SafesimCallout = (props) => {
  const { className, ...other } = props;

  return <Callout className={'kmInterfaceElementNoHover ' + className} {...other} />;
};

/**
 * Functional component that returns a card with content.
 * Wraps the BlueprintJS Card component, and uses all the BP props and those listed below.
 * Contains an error boundary to display render errors in the popover. Use fallbackUI props to display specific errors.
 * @param {string} fallbackUITitle Optional. Title of error card when render errors occurs.
 * @param {string} fallbackUIDescription Optional. Message in error card when render error occurs.
 */
export const SafesimCard = (props) => {
  const { className, outlined, children, fallbackUITitle = 'Card Error', fallbackUIDescription = 'Error displaying card!', ...other } = props;
  let outlinedClass = outlined ? 'outlined' : '';
  return (
    <Card className={`safesimCard kmSurfaceElement ${outlinedClass} ` + className} {...other}>
      <SafesimErrorBoundary
        fallbackUI={
          <div style={{ padding: '15px' }}>
            <SafesimNonIdealState icon={<SafesimIcon intent='danger' icon='error' size={60} />} title={fallbackUITitle} description={fallbackUIDescription} />
          </div>
        }
      >
        {children}
      </SafesimErrorBoundary>
    </Card>
  );
};

export const SafesimCheckbox = Checkbox;

export const SafesimClasses = Classes;

export const SafesimPopover2Classes = Popover2Classes;

export const SafesimContextMenu = ContextMenu2;

export const SafesimDialog = (props) => {
  const { className, themeName, preventClose, portalClassName, ...other } = props;

  let overrides = {};
  if (preventClose) {
    overrides = { ...overrides, canEscapeKeyClose: false, canOutsideClickClose: false, isCloseButtonShown: false };
  }

  return <Dialog className={'kmSurfaceElement ' + className} {...other} {...overrides} portalClassName={themeName + ' ' + portalClassName} />;
};

export const SafesimDivider = (props) => {
  const { className, ...other } = props;

  return <Divider className={'kmInterfaceElement ' + className} {...other} />;
};

export const SafesimDrawer = Drawer;

export const SafesimEditableText = EditableText;

export const SafesimFormGroup = (props) => {
  const { className, contentClassName, isInvalid, ...other } = props;
  const isDanger = isInvalid ? 'makeRed' : '';
  return (
    <FormGroup
      className={isDanger + ' kmPrimaryText formGroup ' + className} // include additional classnames change Dark to +props.theme
      contentClassName={'kmPrimaryText ' + contentClassName}
      {...other}
    />
  );
};
//Don't use this. It is hard to style instead use SafesimSelect
//export const SafesimHTMLSelect = HTMLSelect;

export const SafesimIcon = Icon;

/**
 * Functional component that provides an input group equipped with validation capabilities.
 * Validation method must be supplied by the user, and it must return true or false. The onValidate
 * method must also be supplied by the user and it determines what happens upon validation.
 * @param {*} props
 * intent - not required, developer may choose to manually set intent
 * onChange - required, captured here and used as callback
 * value - current value for the input group
 * validate - not required, true or false returning method supplied by developer
 * onValidate - method supplied by developer that determines what happens upon validation
 * @returns
 */
export const SafesimInputGroup = (props) => {
  const { className, intent, onChange, value, validate, onValidate, ...other } = props;

  //state boolean for managing whether the input was validated or not (if no validate method supplied, it is set to undefined)
  const [validatedValue, setValidatedValue] = useState(value);
  const [validated, setValidated] = useState(validate?.(value));

  //useMemo ensures that this is only updated when validate or intent is updated
  //newIntent- if not undefined, that means that a validation method was supplied. The boolean validate is then converted
  // to an intent, where true = success, and false = danger. If 'validate' is not defined, the newIntent is set to the original intent
  const isDanger = useMemo(() => (validated === false || intent === 'danger' ? 'makeRed' : ''), [intent, validated]);
  //If validation is undefined and not danger, used the intent.
  const newIntent = useMemo(() => (validated === undefined && intent !== 'danger' ? intent : undefined), [intent, validated]);

  useEffect(() => {
    setValidatedValue(value);
  }, [value]);

  useEffect(() => {
    const validatedResult = validate?.(validatedValue);
    onValidate?.(validatedResult);
    setValidated(validatedResult);
  }, [validatedValue, validate, onValidate]);

  //The onChange event handler is taken from the parent class, and validation checks are inserted here
  //before calling the actual 'onChange' in this callback. This new OnChange will be called anytime there
  //is a change to the input group.
  //The validatedResult will use the developers validation method to check the current input group value on this event
  //If a validatedResult is acheived onValidate is called and then the setValidated is called, which will result in the intent being updated

  const OnChange = useCallback(
    (event) => {
      if (value === undefined) {
        setValidatedValue(event.target.value);
      }
      onChange?.(event);
    },
    [value, onChange]
  );

  return <InputGroup className={className + ' ' + isDanger} intent={newIntent} onChange={OnChange} value={value} {...other} />;
};

export const SafesimLabel = Label;

export const SafesimMenu = (props) => {
  const { className, ...other } = props;

  return <Menu className={'menu kmLink kmSurfaceElement ' + className} {...other} />;
};

export const SafesimMenuDivider = (props) => {
  const { className, ...other } = props;

  return <MenuDivider className={'divider kmSurfaceElement ' + className} {...other} />;
};

export const SafesimMenuItem = (props) => {
  const { className, textClassName, intent, ...other } = props;
  const linkClassName = intent ? '' : 'kmLink ';
  const menuItemClassName = intent ? '' : 'kmSurfaceElementHover ';
  return <MenuItem className={menuItemClassName + className} intent={intent} textClassName={linkClassName + textClassName} {...other} />;
};

export const SafesimNavbar = Navbar;

export const SafesimNonIdealState = NonIdealState;

export const SafesimNumericInput = (props) => {
  const { className, intent, buttonPosition, ...other } = props;

  return <NumericInput className={'numericInput ' + className} buttonPosition={buttonPosition ?? 'none'} {...other} />;
};

const refetchIntervalLabelRenderer = (value, short = true) => {
  if (!value) return 'None';
  const useSeconds = value % (60 * 1000) > 0 && value < 2 * 60 * 1000;
  const formattedValue = value / ((useSeconds ? 1 : 60) * 1000);
  const plural = formattedValue > 1 ? 's' : '';
  const unit = useSeconds ? (short ? 'sec' : ` second${plural}`) : short ? 'min' : ` minute${plural}`;
  return `${formattedValue}${unit}`;
};

const SafesimDataSyncConfigItem = ({ selector, dataSyncConfig, onChange }) => {
  const { refetchInterval, retry } = dataSyncConfig;
  const [retryValue, setRetryValue] = useState(typeof retry !== 'boolean' ? retry : 0);
  const [isRetryChecked, setIsRetryChecked] = useState(!!retry);
  const prevDataSyncConfig = usePrevious(dataSyncConfig);

  useEffect(() => {
    setRetryValue(typeof retry !== 'boolean' ? retry : 0);
    setIsRetryChecked(!!retry);
  }, [retry]);

  useEffect(() => {
    const newRetry = isRetryChecked ? (retryValue === 0 ? true : retryValue) : false;
    if (prevDataSyncConfig === dataSyncConfig && newRetry !== dataSyncConfig.retry) {
      onChange?.({
        ...dataSyncConfig,
        retry: newRetry,
      });
    }
  }, [retryValue, isRetryChecked, prevDataSyncConfig, dataSyncConfig, onChange]);

  const refetchRateHelperText = refetchInterval
    ? `The data will be synchronized from the server every ${refetchIntervalLabelRenderer(refetchInterval, false)}`
    : 'The data will not be re-synchronized with the server automatically.';

  const retryHelperText =
    retry === true
      ? 'Data sync will retry infinitely after failure.'
      : retry === false
      ? 'Data sync will never retry after failure.'
      : `Data sync will retry ${retry} time${retry > 1 ? 's' : ''} after failure.`;

  return (
    <div className='dataSyncItem' key={selector}>
      <SafesimText className='kmHeading2 dataSyncItemTitle'>{selector}:</SafesimText>
      <SafesimFormGroup
        label={<SafesimText className='dsiFormLabel kmHeading3'>Refetch Rate:</SafesimText>}
        labelFor={`refetch-rate-slider-${selector}`}
        helperText={refetchRateHelperText}
        inline
        className='dsiFormControl'
        contentClassName='dsiFormInput'
        intent={refetchInterval ? Intent.PRIMARY : Intent.WARNING}
      >
        <SafesimSlider
          id={`refetch-rate-slider-${selector}`}
          value={refetchInterval || 0}
          onChange={(value) =>
            onChange?.({
              ...dataSyncConfig,
              refetchInterval: value || false,
            })
          }
          showTrackFill={false}
          labelRenderer={refetchIntervalLabelRenderer}
          labelStepSize={1 * 60 * 1000}
          stepSize={0.5 * 60 * 1000}
          min={0}
          max={5 * 60 * 1000}
        />
      </SafesimFormGroup>
      <SafesimFormGroup
        label={<SafesimText className='dsiFormLabel kmHeading3'>Retry:</SafesimText>}
        labelFor={`retry-${selector}`}
        helperText={retryHelperText}
        inline
        className='dsiFormControl'
        contentClassName='dsiFormInput'
        intent={isRetryChecked ? Intent.PRIMARY : Intent.WARNING}
      >
        <SafesimControlGroup id={`retry-${selector}`}>
          <SafesimSwitch checked={isRetryChecked} onChange={() => setIsRetryChecked(!isRetryChecked)} />
          <SafesimNumericInput
            style={{ width: 80 }}
            min={0}
            max={100}
            minorStepSize={1}
            stepSize={1}
            disabled={!isRetryChecked}
            value={retryValue}
            onValueChange={(value) => setRetryValue(value)}
          />
        </SafesimControlGroup>
      </SafesimFormGroup>
      <SafesimDivider vertical={false} />
    </div>
  );
};

export const SafesimOverlay = Overlay;

/**
 * Functional component that returns a popover with content.
 * Wraps the BlueprintJS Popover2 component, and uses all the BP props and those listed below.
 * Contains an error boundary to display render errors in the popover. Use fallbackUI props to display specific errors.
 * @param {string} fallbackUITitle Optional. Title of error card when render errors occurs.
 * @param {string} fallbackUIDescription Optional. Message in error card when render error occurs.
 */
export const SafesimPopover = (props) => {
  const { fallbackUITitle = 'Popover Error', fallbackUIDescription = 'Error displaying popover!', content, ...rest } = props;
  const enablePortal = usePortal();
  return (
    <Popover2
      portalContainer={enablePortal?.window?.document?.body}
      content={
        <SafesimErrorBoundary
          fallbackUI={
            <div style={{ padding: '15px' }}>
              <SafesimNonIdealState icon={<SafesimIcon intent='danger' icon='error' size={60} />} title={fallbackUITitle} description={fallbackUIDescription} />
            </div>
          }
        >
          {content}
        </SafesimErrorBoundary>
      }
      {...rest}
    />
  );
};

export const SafesimProgressBar = ProgressBar;

export const SafesimRadioButton = Radio;

export const SafesimRadioButtonGroup = RadioGroup;

export const SafesimRouteBreadcrumbs = (props) => {
  const location = useLocation();
  const navigate = useSafesimNavigate();

  const [overrideItems, setOverrideItems] = useState({});

  useEventSubscription(
    BREADCRUMB_OVERRIDE_ADDED,
    useCallback(
      (evt) => {
        if (evt?.detail?.id) {
          delete overrideItems[evt.detail.id];

          if (evt.detail.content) {
            overrideItems[evt.detail.id] = { ...evt.detail.content };
          }

          setOverrideItems({ ...overrideItems });
        }
      },
      [overrideItems, setOverrideItems]
    )
  );

  const breadcrumbs = useMemo(() => {
    let concatPath = '';
    const temp = [];
    location.pathname
      .split('/')
      .slice(1)
      .forEach((path) => {
        const pathOverrides = Object.values(overrideItems).find((x) => x[path]);
        const overrideText = pathOverrides && pathOverrides[path]?.text ? pathOverrides[path].text : path;

        // Ignore search params if it is null or undefined, but allow empty strings to overwrite current search params.
        const searchParams = pathOverrides && (pathOverrides[path]?.search || pathOverrides[path].search === '') ? pathOverrides[path].search : undefined;
        const newPath = (concatPath + '/' + path).toString();
        temp.push({
          text: overrideText,
          onClick: () => {
            navigate(newPath, searchParams);
          },
        });
        concatPath = newPath;
      });

    return temp;
  }, [navigate, overrideItems, location]);

  return (
    <SafesimBreadcrumbs
      items={breadcrumbs}
      breadcrumbRenderer={(breadcrumb) => {
        return <SafesimBreadcrumb className={'kmLink'} onClick={breadcrumb.onClick} text={breadcrumb.text} />;
      }}
      currentBreadcrumbRenderer={(breadcrumb) => {
        return <SafesimBreadcrumb className={'safesim-breadcrumb-current'} text={breadcrumb.text} />;
      }}
    />
  );
};

export const SafesimSimpleSelect = Select2;

export const SafesimSlider = (props) => {
  const { intent, ...other } = props;

  return <Slider intent={'km-interfaceElement'} {...other} />;
};

export const SafesimMultiSlider = MultiSlider;

export const SafesimSpinner = Spinner;

/**
 * Functional component that wraps child elements within a div that provides
 * the option for an overlay spinner or custom element to be rendered on top of the content
 * @param {*} props
 * isOpen prop denoting if the Overlay is visible or not visible
 * element prop allowing for a custom overlay item to be used instead of the default spinner
 * @returns
 */
function SafesimOverlaySpinner(props) {
  const { className, isOpen, element, children, ...rest } = props;

  return (
    <div className='makeRelative'>
      {isOpen && (
        <div className={className + ' overlaySpinner alignCenter '} {...rest}>
          {element || <SafesimSpinner className={className} />}
        </div>
      )}
      {children}
    </div>
  );
}
SafesimOverlaySpinner.propTypes = {
  isOpen: PropTypes.bool,
  element: PropTypes.element,
};
export { SafesimOverlaySpinner };

// must use context to set dark theme to override Blueprint styles
export const SafesimSwitch = (props) => {
  const { inNavbar, className, switchClassName, ...other } = props;

  const colorClass = inNavbar === true ? safesimDarkClass : '';

  return (
    <div className={colorClass + ' ' + (className ?? '')}>
      <Switch className={'switch ' + switchClassName} {...other} />
    </div>
  );
};

//for some reason wraping this component does not work. Doing so does not display the title. This was fixed by styling the tabs component
export const SafesimTabsExpander = Tabs.Expander;

export const SafesimTab = Tab;

export const SafesimTabs = (props) => {
  const { className, ...other } = props;

  return <Tabs className={'tabs ' + className} {...other} />;
};

export const SafesimTag = Tag;

export const SafesimTagInput = TagInput;

export const SafesimText = (props) => {
  const { className, ...other } = props;

  return <Text className={'kmPrimaryText ' + className} {...other} />;
};

/**
 * Functional component that provides an text area equipped with validation capabilities.
 * Validation method must be supplied by the user, and it must return true or false. The onValidate
 * method must also be supplied by the user and it determines what happens upon validation.
 * @param {*} props
 * intent - not required, developer may choose to manually set intent
 * onChange - required, captured here and used as callback
 * value - current value for the input group
 * validate - not required, true or false returning method supplied by developer
 * onValidate - method supplied by developer that determines what happens upon validation
 * @returns
 */
export const SafesimTextArea = (props) => {
  const { className, intent, onChange, value, validate, onValidate, ...other } = props;

  //state boolean for managing whether the input was validated or not (if no validate method supplied, it is set to undefined)
  const [validatedValue, setValidatedValue] = useState(value);
  const [validated, setValidated] = useState(validate?.(value));

  //useMemo ensures that this is only updated when validate or intent is updated
  //newIntent- if not undefined, that means that a validation method was supplied. The boolean validate is then converted
  // to an intent, where true = success, and false = danger. If 'validate' is not defined, the newIntent is set to the original intent
  const isDanger = useMemo(() => (validated === false || intent === 'danger' ? 'makeRed' : ''), [intent, validated]);
  //If validation is undefined and not danger, used the intent.
  const newIntent = useMemo(() => (validated === undefined && intent !== 'danger' ? intent : undefined), [intent, validated]);

  useEffect(() => {
    setValidatedValue(value);
  }, [value]);

  useEffect(() => {
    const validatedResult = validate?.(validatedValue);
    onValidate?.(validatedResult);
    setValidated(validatedResult);
  }, [validatedValue, validate, onValidate]);

  //The onChange event handler is taken from the parent class, and validation checks are inserted here
  //before calling the actual 'onChange' in this callback. This new OnChange will be called anytime there
  //is a change to the input group.
  //The validatedResult will use the developers validation method to check the current input group value on this event
  //If a validatedResult is acheived onValidate is called and then the setValidated is called, which will result in the intent being updated
  const OnChange = useCallback(
    (event) => {
      if (value === undefined) {
        setValidatedValue(event.target.value);
      }
      onChange?.(event);
    },
    [value, onChange]
  );

  return <TextArea className={className + ' ' + isDanger} intent={newIntent} onChange={OnChange} value={value} {...other} />;
};

export const SafesimToast = Toast;

export const SafesimToaster = Toaster;

export const SafesimEventToaster = ({ toaster }) => {
  const eventListener = useCallback((event) => toaster?.show(event.detail), [toaster]);
  useEventSubscription(EVENT_TOASTER_NEW_TOAST, eventListener);
  return <></>;
};

export const SafesimTooltip = (props) => {
  const { popoverClassName, ...other } = props;
  const enablePortal = usePortal();
  //This makes the tooltip a whiteish color in both themes. I thought this looked better then the darker color
  return <Tooltip2 popoverClassName={safesimDarkClass} portalContainer={enablePortal?.window?.document?.body} {...other} />;
};

export const SafesimTreeNode = TreeNode;

export const SafesimTree = Tree;
SafesimTree.forEachNode = (nodes, callback) => {
  if (nodes) {
    for (const node of nodes) {
      callback?.(node);
      SafesimTree.forEachNode(node?.childNodes, callback);
    }
  }
};
SafesimTree.findNodeById = (nodes, id) => {
  if (nodes) {
    for (const node of nodes) {
      if (node?.id === id) return node;
      const childNode = SafesimTree.findNodeById(node?.childNodes, id);
      if (childNode) return childNode;
    }
  }
};

/**
 * Component that provides a scrollable responsive flex grid in which to render an array of items either vertically or horizontally.
 *
 * @param {*} props
 * * `direction` - The direction in which to expand and scroll the grid. Default value is `vertical`
 * * `spaceBetween` - The number of pixels with which to pad the area in between each item in the grid. Default value is `10`
 * * `itemsArray` - The data array for which to populate the the grid
 * * `itemRenderer` - A render function [`(item: any, index: number, array: any[]) => ReactNode`] that will be used to render each element in the `itemsArray`. Note: The React component returned from this function should provide a `key` property.
 */
const SafesimScrollableGrid = (props) => {
  const { direction = 'vertical', spaceBetween = 10, itemsArray, itemRenderer, className, style, ...rest } = props;

  const renderedItems = useMemo(() => itemsArray?.map(itemRenderer), [itemsArray, itemRenderer]);

  const parentStyles = useMemo(
    () => ({
      gap: spaceBetween,
      padding: spaceBetween,
      ...(style ?? {}),
    }),
    [spaceBetween, style]
  );

  return (
    <div className={`scrollOverflow scrollableGridFlexParent ${direction} ${className ?? ''}`} style={parentStyles} {...rest}>
      {renderedItems}
    </div>
  );
};

SafesimScrollableGrid.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  spaceBetween: PropTypes.number,
  itemsArray: PropTypes.array.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
};

export { SafesimScrollableGrid };

export const SafesimSidebar = ProSidebar;
export const SafesimSidebarHeader = SidebarHeader;
export const SafesimSidebarFooter = SidebarFooter;
export const SafesimSidebarContent = SidebarContent;
export const SafesimSidebarMenu = SidebarMenu;

export const SafesimRedirect = (props) => {
  const { path, toast } = props;
  const navigate = useSafesimNavigate();
  const eventToaster = useSafesimEventToaster();
  useEffect(() => {
    toast && eventToaster && eventToaster(toast);
    path && navigate(path);
  }, [toast, path, eventToaster, navigate]);
  return <></>;
};

export const SafesimInputHeader = ({ label, labelClassName, labelContent }) => {
  return (
    <SafesimControlGroup>
      <SafesimText className={`alignTextLeft ${labelClassName}`} style={{ margin: '5px' }} ellipsize={true}>
        {label}
      </SafesimText>
      {labelContent}
    </SafesimControlGroup>
  );
};

export const SafesimCollapsibleElementRenderer = (childKey, child) => {
  if (childKey === 'absTime') {
    return <SafesimValueDisplay key={childKey ?? uuidv4()} label={CamelCaseToSpacedString(childKey)} value={AbsTimeToPrettyString(child)} />;
  }
  //if it's an array
  else if (Array.isArray(child)) {
    if (child.length <= 0) return null;
    //if it contains normal values display it
    else if (typeof child[0] !== 'object' && !Array.isArray(child[0])) {
      if (child.length === 1) {
        return <SafesimValueDisplay key={childKey ?? uuidv4()} label={CamelCaseToSpacedString(childKey)} value={String(child[0])} />;
      } else {
        return (
          <SafesimCollapse key={childKey ?? uuidv4()} header={CamelCaseToSpacedString(childKey ?? '')}>
            <div className='submissionWrapperChildren'>
              {child.map((element, index) => (
                <SafesimText key={childKey ? childKey + index : uuidv4()}>{String(element)}</SafesimText>
              ))}
            </div>
          </SafesimCollapse>
        );
      }
    }
    //if not, give it a collapsible and recurse
    else {
      return (
        <SafesimCollapse key={childKey ?? uuidv4()} header={CamelCaseToSpacedString(childKey ?? '')}>
          <div className='submissionWrapperChildren'>{child.map((element) => SafesimCollapsibleElementRenderer(null, element))}</div>
        </SafesimCollapse>
      );
    }
  }
  //if it's an object make a collapse for it and recurse
  else if (typeof child === 'object') {
    const name = childKey ?? child.label ?? child.name ?? child.id ?? child.guid ?? 'Item';
    if (Object.keys(child).length <= 0) {
      return null;
    }
    return (
      <SafesimCollapse key={childKey ?? uuidv4()} header={CamelCaseToSpacedString(name)}>
        <div className='submissionWrapperChildren'>{Object.keys(child).map((key) => SafesimCollapsibleElementRenderer(key, child[key]))}</div>
      </SafesimCollapse>
    );
  }
  //just a normal value
  else {
    return <SafesimValueDisplay key={childKey ?? uuidv4()} label={CamelCaseToSpacedString(childKey)} value={String(child)} />;
  }
};
