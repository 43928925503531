/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { BulkAddAssetModal } from "./BulkAddAssetModal";

export function BulkAddModelModal({
  isOpen,
  dialogTitle,
  dialogDescription,
  endpointName,
  refetchQueryName,
  allowMultipleFiles = true,
  fileTypeFilters,
  onClose,
}: {
  isOpen: boolean;
  dialogTitle: string;
  dialogDescription: string;
  endpointName: string;
  refetchQueryName: string;
  allowMultipleFiles?: boolean;
  fileTypeFilters?: string[];
  onClose: () => void;
}): JSX.Element {
  return (
    <BulkAddAssetModal
      isOpen={isOpen}
      dialogTitle={dialogTitle}
      dialogDescription={dialogDescription}
      endpointName={endpointName}
      refetchQueryName={refetchQueryName}
      allowMultipleFiles={allowMultipleFiles}
      fileTypeFilters={fileTypeFilters}
      onClose={onClose}
      assetType={"MODEL"}
    />
  );
}
