/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import * as Arrays from "../../Utils/Array";
import { ScenarioSpec, ScenarioWizardStep } from "./ScenarioSpec";
import { LabelLarge, LabelSmall } from "baseui/typography";
import { CenteredContent } from "../../DesignSystem/Containers";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useStyletron } from "baseui";
import { TextEntry } from "../../Utils/TableCells";
import { SelectedTableRowStyle } from "../SmrStyles";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { AssetSummary, SearchSpecInput } from "Api";
import { Spinner } from "baseui/spinner";
import { StepBody } from "./StepBody";
import { useFindByTextQuery } from "../../Shared/ApiHooks";

export function PickModelsStep({
  scenarioSpec,
  onSpecUpdated,
  onStepChange,
}: {
  scenarioSpec?: ScenarioSpec;
  onSpecUpdated: (updatedSpec: ScenarioSpec) => void;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  const capabilities = React.useMemo(() => {
    const allCapabilities = scenarioSpec?.tasks?.flatMap((task) => task.requiredCapabilities ?? []);
    return [...new Set(allCapabilities)];
  }, [scenarioSpec?.tasks]);

  const [expanded, setExpanded] = React.useState<React.Key[]>(capabilities);

  function handleModelSelected(_event: React.MouseEvent<HTMLDivElement, MouseEvent>, model: AssetSummary): void {
    const newModels = Arrays.toggleAdd(scenarioSpec?.models, model);
    const newSpec: ScenarioSpec = {
      ...scenarioSpec,
      models: newModels,
    };
    onSpecUpdated(newSpec);
  }

  const handleStepChange = React.useCallback(
    (newStep: ScenarioWizardStep) => {
      switch (newStep) {
        case "Refine Scenario":
          onStepChange(newStep);
          break;
        case "Choose Measures":
          onStepChange(newStep);
          break;
        default:
          throw Error(`Could not transition to ${newStep}; expected either 'Chooser Measures' or 'Refine Scenario'`);
      }
    },
    [onStepChange]
  );

  return (
    <StepBody
      stepName={`Pick Models`}
      previousStep={"Choose Measures"}
      previousDisabled={false}
      nextStep={"Refine Scenario"}
      nextDisabled={false}
      onStepChange={handleStepChange}
    >
      <div style={{ padding: "1rem 3rem 1rem 2rem" }}>
        {capabilities.length > 0 ? (
          <>
            <div style={{ padding: "1rem 0rem 1rem 0rem" }}>
              <LabelSmall>{`These models look like they are relevant to your scenario; select which ones should be included.`}</LabelSmall>
            </div>
            <StatelessAccordion
              accordion={false}
              expanded={expanded}
              onChange={({ expanded }) => {
                setExpanded(expanded);
              }}
            >
              {capabilities.map((capability) => (
                <Panel title={`Capability: ${capability}`} key={capability}>
                  <CapabilityModelList
                    key={capability}
                    capability={capability}
                    selectedModels={scenarioSpec?.models ?? []}
                    onModelSelected={handleModelSelected}
                  />
                </Panel>
              ))}
            </StatelessAccordion>
          </>
        ) : (
          <CenteredContent height="20rem">
            <LabelLarge>{`No models matched the task and measure criteria.`}</LabelLarge>
          </CenteredContent>
        )}
      </div>
    </StepBody>
  );
}

const modelFilter: SearchSpecInput = {
  assetTypeFilter: {
    assetTypes: ["MODEL"],
  },
};

function CapabilityModelList({
  selectedModels,
  capability,
  onModelSelected,
}: {
  selectedModels: AssetSummary[];
  capability: string;
  onModelSelected: (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, model: AssetSummary) => void;
}): JSX.Element {
  const { loading, results: modelsForCapability } = useFindByTextQuery(
    capability,
    modelFilter,
    undefined,
    `Error fetching models with capability ${capability}!`
  );

  const styleOverrides = React.useMemo(
    () => SelectedTableRowStyle((rowIndex) => selectedModels.includes(modelsForCapability[rowIndex])),
    [modelsForCapability, selectedModels]
  );

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "3rem",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <TableBuilder data={modelsForCapability} overrides={styleOverrides}>
            <TableBuilderColumn id="title" header="Name" sortable>
              {(row: AssetSummary) => (
                <TextEntry
                  text={row.title}
                  styleClass={cellStyle}
                  dataId={row.id}
                  onClick={(event) => onModelSelected(event, row)}
                />
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </div>
      </div>
    </>
  );
}
