/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { AssetSummary, ModelInfo, Scenario, ScenarioInput } from "Api";
import { AssetContent, AssetDetailsPageAction } from "../Asset/Editor/AssetDetailsState";
import { AssetDetailsPageReducer, AssetDetailsState, resetOriginalAndEdited } from "../Asset/Editor/AssetDetailsState";
import { COMMON_FIXED_SCHEMA_FIELDS } from "../Asset/Editor/AssetMetadataFields";
import { dateFieldSpec, FieldSpec, geoLocationFieldSpec } from "../Asset/Editor/FieldSpec";
import { Uuid, newId } from "../Utils/Types";
import { emptyScenarioInput, isDataChanged, toAssetSummaries, toScenarioMetadataInput } from "../Api/ApiExtensions";
import { addToNewArray } from "../Utils/Array";

export const START_TIME_FIELD = dateFieldSpec("Start Time", "startTime");

export const GEOLOCATION_FIELD = geoLocationFieldSpec("Location", "geoLocation");

// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
const SCENARIO_FIXED_SCHEMA_FIELDS: FieldSpec[] = [...COMMON_FIXED_SCHEMA_FIELDS, START_TIME_FIELD, GEOLOCATION_FIELD];

export interface ScenarioRelations {
  models?: ModelInfo[];
}

export function newScenarioDetailsState(scenarioId?: Uuid, copying?: boolean): AssetDetailsState<ScenarioInput> {
  const newScenario = emptyScenarioInput();
  newScenario.id = scenarioId ?? newId();
  return new AssetDetailsState("SCENARIO", SCENARIO_FIXED_SCHEMA_FIELDS, newScenario, [], copying);
}

type ResetOriginalAndEditedScenarioAction = ["resetOriginalAndEditedScenario", Scenario];

function resetOriginalAndEditedScenario(
  state: AssetDetailsState<ScenarioInput>,
  scenario: Scenario
): AssetDetailsState<ScenarioInput> {
  const scenarioInput: ScenarioInput = {
    id: scenario.id,
    metadata: toScenarioMetadataInput(scenario.metadata),
    securityMarkings: scenario.securityMarkings,

    relatedModelIds: scenario.relatedModels?.map((model) => model.id),

    // Will not be used by resetOriginalAndEdited
    fileFolders: [],
  };

  const modelSummaries: AssetSummary[] = toAssetSummaries("MODEL", scenario.relatedModels);

  return resetOriginalAndEdited<ScenarioInput>(
    state,
    scenarioInput,
    scenario.creationDate,
    scenario.lastModifiedDate,
    scenario.fileFolders,
    modelSummaries
  );
}

type AddRelatedModelAction = ["addRelatedModel", AssetSummary];

function addRelatedModel(
  state: AssetDetailsState<ScenarioInput>,
  resourceToAdd: AssetSummary
): AssetDetailsState<ScenarioInput> {
  const asset = { ...state.edited.asset };
  const relations = addToNewArray(state.edited.relations, resourceToAdd);

  switch (resourceToAdd.type) {
    case "MODEL":
      asset.relatedModelIds = addToNewArray(state.edited.asset.relatedModelIds, resourceToAdd.id);
      break;
    default:
      console.error(`Unhandled assetType ${resourceToAdd.type}`);
      break;
  }

  const editedAfterUpdate: AssetContent<ScenarioInput> = {
    ...state.edited,
    asset: asset,
    relations: relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

type RemoveRelatedModelAction = ["removeRelatedModel", AssetSummary];

function removeRelatedModel(
  state: AssetDetailsState<ScenarioInput>,
  assetToRemove: AssetSummary
): AssetDetailsState<ScenarioInput> {
  const asset = { ...state.edited.asset };
  const relations = state.edited.relations?.filter((model) => model.id !== assetToRemove.id);

  switch (assetToRemove.type) {
    case "MODEL":
      asset.relatedModelIds = state.edited.asset.relatedModelIds?.filter((modelId) => modelId !== assetToRemove.id);
      break;
    default:
      console.error(`Could not delete relation ${assetToRemove} type not supported`);
      break;
  }

  const editedAfterUpdate: AssetContent<ScenarioInput> = {
    ...state.edited,
    asset,
    relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

export type ScenarioDetailsPageAction =
  | AssetDetailsPageAction
  | ResetOriginalAndEditedScenarioAction
  | AddRelatedModelAction
  | RemoveRelatedModelAction;

export function ScenarioDetailsReducer(
  state: AssetDetailsState<ScenarioInput>,
  action: ScenarioDetailsPageAction
): AssetDetailsState<ScenarioInput> {
  const actionType = action[0];
  switch (actionType) {
    case "resetOriginalAndEditedScenario":
      return resetOriginalAndEditedScenario(state, action[1]);
    case "addRelatedModel":
      return addRelatedModel(state, action[1]);
    case "removeRelatedModel":
      return removeRelatedModel(state, action[1]);
    default:
      return AssetDetailsPageReducer(state, action);
  }
}
