/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Search } from "baseui/icon";
import { gql, useLazyQuery } from "@apollo/client";
import findSearchEntriesWithText from "../Api/Gql/FindSearchEntriesWithText.gql";
import { SearchEntryQueryResponse } from "Api";
import { Combobox } from "baseui/combobox";

const defaultSearchInputStyle: React.CSSProperties = {
  display: "flex",
  width: "50%",
  height: "3rem",
};

export default function SearchInput({
  searchValue,
  placeholder,
  style,
  onSearchTextChange,
  onSearch,
}: {
  searchValue?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onSearchTextChange: (newSearchText: string) => void;
  onSearch: (searchQuery: string) => void;
}): JSX.Element {
  const searchContainerStyle = React.useMemo(() => {
    return {
      ...defaultSearchInputStyle,
      ...style,
    };
  }, [style]);

  const [findSearchEntriesWithTextQuery, { data }] = useLazyQuery(gql(findSearchEntriesWithText), {
    fetchPolicy: "network-only",
    variables: { textSubstring: searchValue },
    // onError: (error) => handleApolloError(error, "Error fetching matches!"),
  });

  function handleOnChange(partialSearchText: string, selectedOption: string): void {
    // onChange only provides selectionOption when a combo box item is selected.
    if (selectedOption) {
      onSearch(selectedOption);
      return;
    }

    findSearchEntriesWithTextQuery();
    onSearchTextChange(partialSearchText);
    if (
      partialSearchText.length === searchSuggestionResponse?.results[0]?.length &&
      searchSuggestionResponse?.results?.length === 1
    ) {
      onSearch(partialSearchText);
    }
  }
  const searchSuggestionResponse = data?.findSearchEntriesWithText as SearchEntryQueryResponse;

  return (
    <div style={searchContainerStyle}>
      <Combobox
        value={searchValue}
        onChange={(nextValue, selectedOption) => handleOnChange(nextValue, selectedOption)}
        options={searchSuggestionResponse?.results ?? []}
        mapOptionToString={(option) => option}
        onSubmit={({ value }) => onSearch(value)}
        // A style is applied in HeaderBar which changes the color of all SVGs to be
        // white so they are visible against the blue background.  Make the color black
        // so it is not invisbile.
        overrides={{
          Input: {
            props: {
              placeholder: placeholder,
              startEnhancer: () => {
                <Search size="18px" color="black" />;
              },
              overrides: {
                // TODO: Possibly integrate this into theming so they
                // can be used across future search inputs
                Root: {
                  style: (props) => ({
                    boxSizing: "content-box",
                    borderLeftColor: props.$isFocused ? props.$theme.km.colors.primaryBlue : props.$theme.colors.border,
                    borderRightColor: props.$isFocused
                      ? props.$theme.km.colors.primaryBlue
                      : props.$theme.colors.border,
                    borderTopColor: props.$isFocused ? props.$theme.km.colors.primaryBlue : props.$theme.colors.border,
                    borderBottomColor: props.$isFocused
                      ? props.$theme.km.colors.primaryBlue
                      : props.$theme.colors.border,
                  }),
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
