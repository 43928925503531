/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { VerticalStack } from "../DesignSystem/Containers";
import { LabelMedium } from "baseui/typography";
import { Button } from "baseui/button";
import { TwoButtonModal } from "./TwoButtonModal";
import { filesize } from "filesize";

export function UploadCancelModal({
  children,
  title,
  isOpen,
  onClose,
  isUploadDisabled,
  startFileUpload,
}: {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  isUploadDisabled: boolean;
  startFileUpload: () => void;
}): JSX.Element {
  return (
    <TwoButtonModal
      title={title}
      isOpen={isOpen}
      positiveButtonText="Upload"
      positiveButtonPressed={startFileUpload}
      isPositiveButtonDisabled={isUploadDisabled}
      negativeButtonText="Cancel"
      negativeButtonPressed={onClose}
    >
      {React.Children.toArray(children)}
    </TwoButtonModal>
  );
}

export function FileUploadPreview({ files, onCancel }: { files: File[]; onCancel: () => void }): JSX.Element {
  const previewDescription =
    files.length > 1 ? `${files.length} files selected` : `${files[0].name} (${filesize(files[0].size)})`;

  return (
    <VerticalStack style={{ alignItems: "center" }}>
      <LabelMedium>{previewDescription}</LabelMedium>
      <Button kind="tertiary" onClick={onCancel}>
        {"Reset"}
      </Button>
    </VerticalStack>
  );
}
