import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { usePrevious } from './usePrevious';
import { wrappedLogger } from '../../utilities/wrappedLogger';

/**
 * React hook that keeps track of a specified URL search param and any time the URL changes, checks to ensure that the search param is still valid, and if not, removes the search param from the URL.
 * @param {string} searchParamName
 * @param {(location: import('react-router-dom').Location, prevLocation: import('react-router-dom').Location)=>boolean} isValidFn
 * @param {boolean} [removeOnUnmount]
 */
export const useSearchParamValidation = (searchParamName, isValidFn, removeOnUnmount = false) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [, setSearchParams] = useSearchParams();

  const locationRef = useRef(location);
  const searchParamNameRef = useRef(searchParamName);
  const setSearchParamsRef = useRef(setSearchParams);
  const removeOnUnmountRef = useRef(removeOnUnmount);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);
  useEffect(() => {
    searchParamNameRef.current = searchParamName;
  }, [searchParamName]);
  useEffect(() => {
    setSearchParamsRef.current = setSearchParams;
  }, [setSearchParams]);
  useEffect(() => {
    removeOnUnmountRef.current = removeOnUnmount;
  }, [removeOnUnmount]);

  // Whenever the URL changes, check if search param is still valid based on location, and remove search param is not valid
  useEffect(() => {
    if (location) {
      const { search } = location;
      const queryParams = new URLSearchParams(search);
      if (queryParams.has(searchParamNameRef.current) && !isValidFn?.(location, prevLocation)) {
        wrappedLogger?.error?.('is NOT valid');
        queryParams.delete(searchParamNameRef.current);
        setSearchParamsRef.current?.(queryParams);
      }
    }
  }, [location, prevLocation, isValidFn]);

  // If removeOnUnmount flag is true, remove the search param whenever the hook unmounts
  useEffect(() => {
    return () => {
      if (!removeOnUnmountRef.current) return;
      const { search } = locationRef.current;
      const queryParams = new URLSearchParams(search);
      if (queryParams.has(searchParamNameRef.current)) {
        queryParams.delete(searchParamNameRef.current);
        setSearchParamsRef.current?.(queryParams);
      }
    };
  }, []);
};
