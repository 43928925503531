query GetTasks($taskIds: [UUID!]!) {
  getTasks(taskIds: $taskIds) {
    id
    category
    hierarchyNumber
    title
    priority
    djsApprovalDate
    description
    notes
    requiredCapabilities
    measures {
      id
      measureNumber
      unit
      description
    }
  }
}
