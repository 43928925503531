/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React from "react";
import { VerticalSpacer } from "../../DesignSystem/Containers";
import { DetailsPage } from "../../Shared/DetailsPages";
import { UnsavedChangesPrompt } from "../../Shared/UnsavedChangesPrompt";
import { AssetInput } from "../../Api/ApiExtensions";
import { AssetDetailsState, AssetDetailsPageAction } from "./AssetDetailsState";
import { EndAnchoredRow } from "../../DesignSystem/Containers";
import { ClassificationHeader } from "../../Shared/ClassificationHeader";
import { EditAssetHeaderButtons, classificationHeaderContainerStyle } from "./AssetDetailsHeaders";

export function AssetWikiEditPage({
  state,
  dispatch,
  onSave,
  onRescan,
  onCopy,
  loading,
  loadError,
  children,
}: {
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
  onSave: () => void;
  onRescan: () => void;
  onCopy: () => void;
  loading: boolean;
  loadError?: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <UnsavedChangesPrompt when={state.dataChanged} />
      <DetailsPage loading={loading} loadError={loadError}>
        <EndAnchoredRow>
          <div style={classificationHeaderContainerStyle}>
            <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
          </div>

          <EditAssetHeaderButtons
            onUpdateAsset={onSave}
            onRescan={onRescan}
            onCopy={onCopy}
            state={state}
            dispatch={dispatch}
          />
        </EndAnchoredRow>
        <VerticalSpacer height="1.5rem" />
        {React.Children.toArray(children)}
      </DetailsPage>
    </>
  );
}
