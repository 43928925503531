/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Extensions for working with the data provided by the API in SIMOR.
import {
  ModelMetadata,
  ModelInfoInput,
  MetadataSchema,
  ScenarioInput,
  ScenarioMetadata,
  ValueRecordType,
  ModelQueryResponse,
  AssetQueryResponse,
  AssetSummary,
  ClassificationLevel,
  CompartmentMarking,
  StudyInput,
  StudyMetadata,
  Scenario,
  Study,
  ModelInfo,
  Platform,
  PlatformMetadata,
  PlatformInput,
  ModelMetadataInput,
  PlatformMetadataInput,
  StudyMetadataInput,
  ScenarioMetadataInput,
  StringMaybeCleared,
  Redaction,
  MarkedString,
  MarkableString,
  IgnorableValue,
  AssetType,
  SecurityMarkings,
  NumericValue,
  ValueRecord,
  MarkedValueRecord,
  MarkableValueRecord,
  MarkedId,
} from "./Api";
import { deepClone } from "../Utils/Common";
import { deepEquals } from "../Utils/Comparison";
import { EMPTY_UUID, newId } from "../Utils/Types";
import { canUserMarkCompartmentAtLevel } from "../Shared/Security";
import { isStringValue } from "../Utils/Strings";
import { startCase } from "lodash";

function toMarkableString(stringMaybeCleared: StringMaybeCleared | undefined): MarkableString | undefined {
  if (!stringMaybeCleared) {
    return undefined;
  }

  if (isStringValue(stringMaybeCleared)) {
    return stringMaybeCleared;
  }

  if (isRedaction(stringMaybeCleared)) {
    // Keep the shape of the redaction property, but add we want to ignore it.
    const ignoredField: IgnorableValue = {
      ...stringMaybeCleared,
      ignore: true,
    };
    return ignoredField;
  }

  if (isMarkedString(stringMaybeCleared)) {
    return stringMaybeCleared;
  }

  throw Error("Undefined data could not be converted to a MarkableString " + stringMaybeCleared);
}

export function getRecordType(record: MarkableValueRecord): ValueRecordType {
  if (isRedaction(record)) {
    // We have nothing interesting to show; consider it as a plain text field.
    return "TEXT";
  } else if (isValueRecord(record)) {
    return record.type;
  } else if (isMarkedValueRecord(record)) {
    return record.contents.type;
  } else {
    // It's a plain value.
    return "TEXT";
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isRedaction(value?: any): value is Redaction {
  return value?.__typename === "Redaction";
}

// TODO: figure out how to not manually check each type of the type union
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isValueRecord(obj: any): obj is ValueRecord {
  const valRecord = obj
    ? (obj as ValueRecord).type === "DATE_TIME" ||
      (obj as ValueRecord).type === "NUMERIC" ||
      (obj as ValueRecord).type === "TEXT" ||
      (obj as ValueRecord).type === "TEXT_LIST"
    : false;
  return valRecord;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isMarkedValueRecord(value: any): value is MarkedValueRecord {
  if (!value) {
    return false;
  }

  const containsTopLevelEntries =
    Object.keys(value).filter((key) => key === "contents" || key === "securityMarkings").length === 2;

  if (!containsTopLevelEntries) return false;

  return isValueRecord(value.contents);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isMarkedString(value?: any): value is MarkedString {
  return value?.__typename === "MarkedString";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isNumericValue(value: any): value is NumericValue {
  return Object.keys(value).filter((key) => key === "value" || key === "type" || key === "unitOfMeasure").length === 3;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isMarkedId(value: any): value is MarkedId {
  if (!value) {
    return false;
  }

  const containsTopLevelEntries =
    Object.keys(value).filter((key) => key === "contents" || key === "securityMarkings").length === 2;

  // TODO: Also check type names?
  return containsTopLevelEntries;
}

const EMPTY_MODEL_METADATA: ModelMetadata = {
  title: "",
  version: "",
  keywords: [],
};

function emptyModelMetadataInput(): ModelMetadataInput {
  return deepClone(EMPTY_MODEL_METADATA);
}

export function emptyModelInfoInput(): ModelInfoInput {
  return {
    id: EMPTY_UUID,
    securityMarkings: null,
    fileFolders: null,
    relatedScenarioIds: null,
    relatedModelIds: null,
    metadata: emptyModelMetadataInput(),
  };
}

export function toModelMetadataInput(metadataFromApi: ModelMetadata | undefined): ModelMetadataInput {
  const metaDataInput: ModelMetadataInput = {
    ...metadataFromApi,
    notes: toMarkableString(metadataFromApi?.notes),
  };
  return metaDataInput;
}

export function toPlatformMetadataInput(metadataFromApi: ModelMetadata | undefined): ModelMetadataInput {
  const metaDataInput: ModelMetadataInput = {
    ...metadataFromApi,
    notes: toMarkableString(metadataFromApi?.notes),
  };
  return metaDataInput;
}

export function toStudyMetadataInput(metadataFromApi: ModelMetadata | undefined): ModelMetadataInput {
  const metaDataInput: ModelMetadataInput = {
    ...metadataFromApi,
    notes: toMarkableString(metadataFromApi?.notes),
  };
  return metaDataInput;
}

export function toScenarioMetadataInput(metadataFromApi: ModelMetadata | undefined): ModelMetadataInput {
  const metaDataInput: ModelMetadataInput = {
    ...metadataFromApi,
    notes: toMarkableString(metadataFromApi?.notes),
  };
  return metaDataInput;
}

export function emptyMetadataSchema(): MetadataSchema {
  return {
    id: EMPTY_UUID,
    fields: {
      selectMultiple: null,
    },
  };
}

const EMPTY_PLATFORM_METADATA: PlatformMetadataInput = {
  title: "",
  version: "",
  keywords: [],
};

function emptyPlatformMetadataInput(): PlatformMetadataInput {
  return deepClone(EMPTY_PLATFORM_METADATA);
}

export function emptyPlatformInput(): PlatformInput {
  return {
    id: EMPTY_UUID,
    securityMarkings: null,
    fileFolders: null,
    metadata: emptyPlatformMetadataInput(),
  };
}

export type Asset = Scenario | Study | ModelInfo | Platform;
export type AssetMetadata = ScenarioMetadata | ModelMetadata | StudyMetadata | PlatformMetadata;
export type AssetMetadataInput =
  | ScenarioMetadataInput
  | ModelMetadataInput
  | StudyMetadataInput
  | PlatformMetadataInput;
export type AssetInput = ScenarioInput | StudyInput | ModelInfoInput | PlatformInput;

export function toAssetSummaries(type: AssetType, assets?: Asset[]): AssetSummary[] {
  return (
    assets?.map((asset) => {
      return {
        type,
        id: asset.id,
        title: asset.metadata?.title,
        version: asset.metadata?.version,
        description: asset.metadata?.description,
        securityMarkings: asset.securityMarkings,
        creationDate: asset.creationDate,
        lastModifiedDate: asset.lastModifiedDate,
      };
    }) ?? []
  );
}

export function prettyAssetType(type: AssetType): string {
  return startCase(type.toLowerCase());
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isDataChanged(original: any, edited: any): boolean {
  if (edited.id !== original.id) {
    return true;
  }

  return !deepEquals(edited, original);
}

const EMPTY_STUDY_METADATA: StudyMetadataInput = {
  title: "",
  version: "",
  keywords: [],
};

function emptyStudyMetadataInput(): StudyMetadataInput {
  return deepClone(EMPTY_STUDY_METADATA);
}

export function emptyStudyInput(): StudyInput {
  return {
    id: EMPTY_UUID,
    securityMarkings: null,
    fileFolders: null,
    metadata: emptyStudyMetadataInput(),
  };
}

const EMPTY_SCENARIO_METADATA: ScenarioMetadataInput = {
  title: "",
  version: "",
  keywords: [],
};

function emptyScenarioMetadataInput(): ScenarioMetadataInput {
  return deepClone(EMPTY_SCENARIO_METADATA);
}

export function emptyScenarioInput(): ScenarioInput {
  return {
    id: EMPTY_UUID,
    securityMarkings: null,
    fileFolders: null,
    metadata: emptyScenarioMetadataInput(),
  };
}

export function defaultMarkings(): SecurityMarkings {
  return {
    classificationLevel: null,
    compartments: undefined,
    disseminationControls: undefined,
    ownerProducer: undefined,
    geoPolitical: undefined,
    classDeclass: undefined,
    metadata: undefined,
  };
}

export function unknownValueType(type: ValueRecordType): never {
  throw new Error(`unknown ValueRecordType ${type}`);
}

export function convertToAssetQueryResponse(response: ModelQueryResponse): AssetQueryResponse {
  if (!response) return null;

  return {
    pageCount: response.pageCount,
    results: response.results.map((model): AssetSummary => {
      return {
        id: model.id,
        type: "MODEL",
        title: model.metadata?.title,
        version: model.metadata?.version,
        description: model.metadata?.description,
        securityMarkings: model.securityMarkings,
      };
    }),
  };
}

export function descendingCompartments(compartmentMarkings?: CompartmentMarking[]): CompartmentMarking[] {
  if (!compartmentMarkings) {
    return [];
  }

  const ascendingCompartments = [...compartmentMarkings].sort(compareCompartmentMarking);
  return ascendingCompartments.reverse();
}

function compareCompartmentMarking(marking1: CompartmentMarking, marking2: CompartmentMarking): number {
  return compareClearanceLevel(marking1.classificationLevel, marking2.classificationLevel);
}

function compareClearanceLevel(level1: ClassificationLevel, level2: ClassificationLevel): number {
  const numericLevel1 = numericLevel(level1);
  const numericLevel2 = numericLevel(level2);
  return numericLevel1 - numericLevel2;
}

export function numericLevel(level: ClassificationLevel): number {
  switch (level) {
    case ClassificationLevel.TOP_SECRET:
      return 4;
    case ClassificationLevel.SECRET:
      return 3;
    case ClassificationLevel.CONFIDENTIAL:
      return 2;
    case ClassificationLevel.UNCLASSIFIED:
      return 1;
    default:
      return 0;
  }
}

export function expandCompartments(compartmentMarkings?: CompartmentMarking[]): CompartmentMarking[] {
  const classificationValues = Object.values(ClassificationLevel);
  return classificationValues.map((classificationLevel) =>
    concatPermittedCompartments(classificationLevel, compartmentMarkings)
  );
}

type CategoryCompartmentSet = Record<string, Set<string>>;

function concatPermittedCompartments(
  userClearanceLevel: ClassificationLevel,
  allLevels?: CompartmentMarking[]
): CompartmentMarking {
  const allValidCategoryRecordsAtLevel: CategoryCompartmentSet = {};

  allLevels?.forEach((compartmentMarkings) => {
    if (canUserMarkCompartmentAtLevel(userClearanceLevel, compartmentMarkings)) {
      const addableEntries = compartmentMarkings.categoryToCompartments ?? {};
      Object.keys(addableEntries).forEach((category) => {
        if (!allValidCategoryRecordsAtLevel[category]) {
          allValidCategoryRecordsAtLevel[category] = new Set<string>();
        }
        const allValidCompartmentsForCategory = allValidCategoryRecordsAtLevel[category];

        if (addableEntries[category]) {
          addableEntries[category].forEach((compartmentName) => {
            allValidCompartmentsForCategory.add(compartmentName);
          });
        }
      });
    }
  });

  const categoryToEntryList = Object.entries(allValidCategoryRecordsAtLevel).map(([category, compartmentSet]) => [
    category,
    Array.from(compartmentSet),
  ]);
  const allPermittedCategoryToCompartment = Object.fromEntries(categoryToEntryList);

  const permittedCompartmentsAtLevel: CompartmentMarking = {
    classificationLevel: userClearanceLevel,
    categoryToCompartments: allPermittedCategoryToCompartment,
  };
  return permittedCompartmentsAtLevel;
}

export function initializeAssetCopy(asset: Asset): void {
  asset.id = newId();
  if (!asset.metadata) {
    asset.metadata = {};
  }
  asset.metadata.title = `${asset.metadata.title} - COPY`;
}
