/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { Select, TYPE, Value } from "baseui/select";
import { ListItem } from "baseui/list";
import React from "react";
import { AssetSummary, AssetTypeFilterInput, QuerySettingsInput, SearchSpecInput } from "Api";
import { useFindByTextQuery } from "./ApiHooks";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { HorizontalStack, TitledBorder, VerticalStack } from "../DesignSystem/Containers";
import { Delete } from "baseui/icon";
import { Button } from "baseui/button";
import AssetIcon from "../Asset/Shared/AssetIcon";
import { AssetLink } from "../Asset/Shared/AssetLink";
import { StatefulTooltip } from "baseui/tooltip";
import { PLACEMENT } from "baseui/toast";

const querySettings: QuerySettingsInput = {
  paging: {
    size: 10,
    index: 1,
  },
};

export function RelationPane({
  relations,
  inputEnabled,
  relationHeader,
  typeFilter,
  onAdd,
  onRemove,
}: {
  relations: AssetSummary[] | undefined;
  inputEnabled: boolean;
  relationHeader: string;
  typeFilter: AssetTypeFilterInput;
  onAdd?: (addedAsset: AssetSummary) => void;
  onRemove?: (removedAsset: AssetSummary) => void;
}): JSX.Element {
  const [value, setValue] = React.useState<Value>([]);

  const filter = React.useMemo<SearchSpecInput>(() => {
    return {
      assetTypeFilter: typeFilter,
    };
  }, [typeFilter]);

  const {
    loading,
    results,
    refetch: refetchFindRelatedAssets,
  } = useFindByTextQuery(value.toString(), filter, querySettings, "error loading resources");

  const handleTextChanged = React.useCallback(
    (event: React.SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const resource = target.value;
      refetchFindRelatedAssets({ textSubstring: resource?.toString(), querySettings, filter });
      setValue(value);
    },
    [refetchFindRelatedAssets, value, filter]
  );

  const handleSelectionChanged = React.useCallback(
    (options) => {
      if (options.type == "select") {
        if (onAdd) {
          onAdd(options.option?.assetSummary);
        }
      }
      refetchFindRelatedAssets({ textSubstring: "", querySettings, filter });
    },
    [onAdd, refetchFindRelatedAssets, filter]
  );

  const handleRemoveAsset = React.useCallback(
    (removedAsset) => {
      if (onRemove) {
        onRemove(removedAsset);
      }
    },
    [onRemove]
  );

  const suggestedResources = React.useMemo(() => {
    const assetSummaries =
      results
        .filter((summary) => {
          if (relations) {
            const alreadyHasResource = relations
              .map((existingSummary) => {
                return existingSummary.id;
              })
              .includes(summary.id);
            return !alreadyHasResource;
          } else {
            return true;
          }
        })
        .slice(0, 5) ?? [];
    return assetSummaries.map((summary) => {
      return { label: summary.title, id: summary.id, tag: summary.id, assetSummary: summary };
    });
  }, [results, relations]);

  const getResourceLabel = ({ option }: any): JSX.Element => {
    const iconStyle = { paddingRight: ".5rem" };
    return (
      <>
        <AssetIcon type={option.assetSummary.type} style={iconStyle} />
        <React.Fragment>{option.label}</React.Fragment>
      </>
    );
  };

  const removeAllFiltering = (option: any): any => option;

  return (
    <TitledBorder title={relationHeader}>
      <VerticalStack>
        {inputEnabled && (
          <Select
            options={suggestedResources}
            filterOptions={removeAllFiltering}
            valueKey="id"
            placeholder="Select a Resource"
            type={TYPE.search}
            onInputChange={handleTextChanged}
            onChange={handleSelectionChanged}
            getOptionLabel={getResourceLabel}
            getValueLabel={getResourceLabel}
            overrides={{
              ValueContainer: {
                props: {
                  "data-testid": "suggested-resource-selector",
                },
              },
              Input: {
                props: {
                  "data-testid": "suggested-resource-input",
                },
              },
              DropdownContainer: {
                props: {
                  "data-testid": `suggested-resource-dropdown`,
                },
              },
            }}
          />
        )}
        {loading ? (
          <LoadingPlaceholder message={"Getting data..."} />
        ) : (
          <VerticalStack gap={"0rem"}>
            {relations?.map((summary) => {
              return (
                <ListItem
                  key={summary.id}
                  endEnhancer={() =>
                    inputEnabled && (
                      <Button
                        data-testid={`delete-btn-${summary.title}`}
                        size="mini"
                        kind="tertiary"
                        shape="round"
                        onClick={() => handleRemoveAsset(summary)}
                      >
                        <Delete />
                      </Button>
                    )
                  }
                  overrides={{
                    ArtworkContainer: { style: () => ({ width: "0%" }) },
                    EndEnhancerContainer: { style: () => ({ width: "5%" }) },
                    Content: { style: () => ({ maxWidth: "90%" }) },
                  }}
                >
                  <StatefulTooltip placement={PLACEMENT.bottom} content={() => summary.title}>
                    <div style={{ maxWidth: "95%" }}>
                      <HorizontalStack>
                        <AssetIcon type={summary.type} />
                        <AssetLink id={summary.id} title={summary.title} assetType={summary.type} />
                      </HorizontalStack>
                    </div>
                  </StatefulTooltip>
                </ListItem>
              );
            })}
          </VerticalStack>
        )}
      </VerticalStack>
    </TitledBorder>
  );
}
