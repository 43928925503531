import { useEffect, useState } from 'react';
import { subscribeElement, unsubscribeElement } from '../../utilities/events';

/**
 * React hook that subscribes a component to a given event type and unsubscribes when the component is unmounted
 * @param {Element} domElement A DOM element for which to subscribe to an event
 * @param {Symbol|string} eventType An event type to listen for
 * @param {function} callback A callback function that is executed whenever the specified event is triggered
 */
export const useDOMElementEventSubscription = (domElement, eventType, callback, onComplete) => {
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    if (!callback || !domElement) return;

    subscribeElement(domElement, eventType, callback);
    setIsRegistered(true);

    return () => {
      unsubscribeElement(domElement, eventType, callback);
      setIsRegistered(false);
    };
  }, [domElement, eventType, callback]);

  useEffect(() => {
    isRegistered && onComplete?.();
  }, [isRegistered, onComplete]);
};

/**
 * React hook that subscribes a component to a given event type and unsubscribes when the component is unmounted
 * @param {Symbol|string} eventType An event type to listen for
 * @param {function} callback A callback function that is executed whenever the specified event is triggered
 */
export const useEventSubscription = (eventType, callback, onComplete) => useDOMElementEventSubscription(document, eventType, callback, onComplete);
