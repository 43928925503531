/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { AssetSummary } from "Api";
import { BuilderOverrides, TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import React from "react";
import { getRelativeLastModifiedTime } from "../Api/ApiSerialization";
import { AssetLink } from "../Asset/Shared/AssetLink";
import { AssetIconCell } from "../Asset/Shared/AssetIconCell";
import { replaceIfUntitled } from "../Utils/Strings";

export function AssetSummaryTable({ assetList }: { assetList: AssetSummary[] }): JSX.Element {
  // Hide the header row since it is empty.  Otherwise it adds extra spacing.
  const headerRowOverrides: BuilderOverrides = {
    TableHeadRow: {
      style: () => ({
        display: "none",
      }),
    },
  };

  return (
    <TableBuilder data={assetList} overrides={headerRowOverrides}>
      <TableBuilderColumn id="type">
        {(asset: AssetSummary) => <AssetIconCell styleClass={null} assetType={asset.type} />}
      </TableBuilderColumn>
      <TableBuilderColumn id="title">
        {(asset: AssetSummary) => (
          <AssetLink id={asset.id} title={replaceIfUntitled(asset.title)} assetType={asset.type} />
        )}
      </TableBuilderColumn>
      <TableBuilderColumn id="lastModified">
        {(asset: AssetSummary) => (
          <label title={asset.lastModifiedDate}>
            {getRelativeLastModifiedTime(asset.creationDate, asset.lastModifiedDate)}
          </label>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
}
