/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { ClassificationLevel, CompartmentMarking, SecurityMarkings } from "../Api/Api";
import { join } from "lodash";
import { SecurityMetadata } from "@radiance/safesim_component_library";
import { classificationOptionsAsText } from "../Api/ApiSerialization";
import { PidFormat, getPidFormat } from "../Shared/Security";

export function toSimorSecurityMarkings(
  safeSimSecurityMetadata: SecurityMetadata,
  rawSarCompartmentStrings: string[]
): SecurityMarkings {
  const consolidatedCompartments = consolidateCompartmentMarkings(
    safeSimSarToCompartmentMarking(safeSimSecurityMetadata.saridentifier, rawSarCompartmentStrings).concat(
      safeSimSciToCompartmentMarking(safeSimSecurityMetadata.scicontrols, safeSimSecurityMetadata.classification) ?? [],
      safeSimDisseminationToCompartmentMarking(safeSimSecurityMetadata.disseminationControls) ?? []
    )
  );
  return {
    classificationLevel: toClassificationLevel(safeSimSecurityMetadata.classification),
    compartments: consolidatedCompartments,
    disseminationControls: safeSimSecurityMetadata.disseminationControls,
  };
}

export function toSafeSimSecurityMetadata(
  securityMarkings?: SecurityMarkings,
  isSensitiveSecurityContext: boolean = true
): SecurityMetadata {
  return {
    classification: classificationOptionsAsText(securityMarkings?.classificationLevel, isSensitiveSecurityContext),
    saridentifier: sarCompartmentMarkingsToSafeSimFormat(
      securityMarkings?.compartments ?? [],
      isSensitiveSecurityContext
    ),
    scicontrols: sciCompartmentMarkingsToSafeSimFormat(securityMarkings?.compartments ?? []),
    disseminationControls: [...(securityMarkings?.disseminationControls ?? [])],
  };
}

function consolidateCompartmentMarkings(compartmentMarkings: CompartmentMarking[]): CompartmentMarking[] {
  const consolidatedMarkingsMap = new Map<ClassificationLevel, Record<string, string[]>>();
  compartmentMarkings.forEach((compartment) => {
    if (consolidatedMarkingsMap.has(compartment.classificationLevel)) {
      consolidatedMarkingsMap.set(compartment.classificationLevel, {
        ...consolidatedMarkingsMap.get(compartment.classificationLevel),
        ...compartment.categoryToCompartments,
      });
    } else {
      consolidatedMarkingsMap.set(compartment.classificationLevel, { ...compartment.categoryToCompartments });
    }
  });
  const consolidatedMarkings: CompartmentMarking[] = [];
  // eslint-disable-next-line no-loops/no-loops
  for (const [key, value] of consolidatedMarkingsMap) {
    consolidatedMarkings.push({ classificationLevel: key, categoryToCompartments: value });
  }
  return consolidatedMarkings;
}

export function getOriginalCompartmentString(
  rawSarCompartmentStrings: string[],
  sarCompartment: string,
  pidFormat: PidFormat
): string {
  switch (pidFormat) {
    case PidFormat.PID_AND_NUMBER:
      const pidNumRegex = RegExp(`^${sarCompartment}:\\d+$`);
      return tryGetUniqueSarMatch(rawSarCompartmentStrings, sarCompartment, pidNumRegex);
    case PidFormat.AGENCY_AND_PID:
      const agencyPidRegEx = RegExp(`^.+:${sarCompartment}$`);
      return tryGetUniqueSarMatch(rawSarCompartmentStrings, sarCompartment, agencyPidRegEx);
    case PidFormat.AGENCY_AND_PID_AND_NUMBER:
      const agencyPidNumRegEx = RegExp(`^.+:${sarCompartment}:\\d+$`);
      return tryGetUniqueSarMatch(rawSarCompartmentStrings, sarCompartment, agencyPidNumRegEx);
    case PidFormat.PID_OR_UNKNOWN:
    default:
      return sarCompartment;
  }
}

function tryGetUniqueSarMatch(rawSarCompartmentStrings: string[], sarCompartment: string, regEx: RegExp): string {
  const matches = rawSarCompartmentStrings.filter((rawCompartment) => regEx.test(rawCompartment));

  if (matches.length === 1) return matches[0];

  if (matches.length === 0) {
    const allCompartmentsGiven = rawSarCompartmentStrings.toString();
    console.error(
      `The compartment ${sarCompartment} could not be found in the given ` +
        `compartments. The available compartments were ${allCompartmentsGiven}`
    );
  }

  if (matches.length > 1) {
    console.error(
      `The compartment ${sarCompartment} was present multiple times in the given compartments. The matching
      compartments were ${matches}`
    );
  }

  return sarCompartment;
}

export function safeSimSarToCompartmentMarking(
  sarList: string[],
  rawSarCompartmentStrings: string[]
): CompartmentMarking[] {
  const compartmentMarkings: CompartmentMarking[] = [];
  const pidFormat = getPidFormat(rawSarCompartmentStrings[0]);

  sarList.map((sarEntry) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [category, classification] = sarEntry.split(":");

    // Value from safesim controls follows this format agency:classification:compartment
    const safesimSarParts = sarEntry.split(":");
    const compartment = safesimSarParts.slice(2).join(":");
    const compartmentFromUserJwt = getOriginalCompartmentString(rawSarCompartmentStrings, compartment, pidFormat);

    const classificationLevel = toClassificationLevel(classification);
    const classificationIndex = compartmentMarkings.filter((value) => {
      return value.classificationLevel === classificationLevel;
    })[0];
    if (!classificationIndex) {
      compartmentMarkings.push({
        classificationLevel: classificationLevel,
        categoryToCompartments: { SAR: [compartmentFromUserJwt] },
      });
    } else {
      classificationIndex.categoryToCompartments["SAR"].push(compartmentFromUserJwt);
    }
  });
  return compartmentMarkings;
}

function safeSimSciToCompartmentMarking(sciList: string[], classification: string): CompartmentMarking | undefined {
  const classificationLevel = toClassificationLevel(classification);
  if (sciList.length > 0) {
    return {
      classificationLevel: classificationLevel,
      categoryToCompartments: { SCI: sciList },
    };
  }
}

function safeSimDisseminationToCompartmentMarking(
  disseminationControls: string[] = []
): CompartmentMarking | undefined {
  if (disseminationControls.includes("CUI")) {
    return {
      classificationLevel: ClassificationLevel.UNCLASSIFIED,
      categoryToCompartments: { CUI: [] },
    };
  } else if (disseminationControls.includes("FOUO")) {
    return {
      classificationLevel: ClassificationLevel.UNCLASSIFIED,
      categoryToCompartments: { FOUO: [] },
    };
  }
}

function toClassificationLevel(classification: string): ClassificationLevel {
  switch (classification) {
    case "UNCLASSIFIED": {
      return ClassificationLevel.UNCLASSIFIED;
    }
    case "Cherry - 1": {
      return ClassificationLevel.UNCLASSIFIED;
    }
    case "CONFIDENTIAL": {
      return ClassificationLevel.CONFIDENTIAL;
    }
    case "Pineapple - 2": {
      return ClassificationLevel.CONFIDENTIAL;
    }
    case "SECRET": {
      return ClassificationLevel.SECRET;
    }
    case "Peach - 3": {
      return ClassificationLevel.SECRET;
    }
    case "TOP SECRET": {
      return ClassificationLevel.TOP_SECRET;
    }
    case "Strawberry - 4": {
      return ClassificationLevel.TOP_SECRET;
    }
    default: {
      console.warn(`unable to process clearance ${classification}`);
      return ClassificationLevel.UNCLASSIFIED;
    }
  }
}

function compartmentsInCategory(compartmentMarking: CompartmentMarking, category: string): string[] | undefined {
  return compartmentMarking.categoryToCompartments ? compartmentMarking.categoryToCompartments[category] : undefined;
}

function compartmentMarkingsToSafeSimFormat(
  compartmentName: string,
  compartmentMarkings: CompartmentMarking[],
  isSensitiveSecurityContext: boolean = true
): string[] {
  let compartmentsAsStrings: string[] = [];

  const filteredComparmtmentMarkings: CompartmentMarking[] = compartmentMarkings
    .map((marking) => ({
      classificationLevel: marking.classificationLevel,
      categoryToCompartments: { [compartmentName]: compartmentsInCategory(marking, compartmentName) },
    }))
    .filter((marking) => {
      return marking.categoryToCompartments[compartmentName] ? true : false;
    }) as CompartmentMarking[];

  filteredComparmtmentMarkings.forEach((compartment) => {
    const classification = classificationOptionsAsText(compartment.classificationLevel, isSensitiveSecurityContext);
    if (compartmentName === "SAR") {
      compartmentsAsStrings = compartmentsAsStrings.concat(
        compartment.categoryToCompartments[compartmentName].map((value) => {
          // Format example DOD:TOP SECRET:A
          return join(["DOD", classification, value], ":");
        })
      );
    } else {
      compartmentsAsStrings = compartmentsAsStrings.concat(compartment.categoryToCompartments[compartmentName]);
    }
  });
  return compartmentsAsStrings;
}

export function sarCompartmentMarkingsToSafeSimFormat(
  compartmentMarkings: CompartmentMarking[],
  isSensitiveSecurityContext: boolean
): string[] {
  return compartmentMarkingsToSafeSimFormat("SAR", compartmentMarkings, isSensitiveSecurityContext);
}

export function sciCompartmentMarkingsToSafeSimFormat(compartmentMarkings: CompartmentMarking[]): string[] {
  return compartmentMarkingsToSafeSimFormat("SCI", compartmentMarkings);
}
