/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { useReactiveVar } from "@apollo/client";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, SIZE } from "baseui/modal";
import React from "react";
import "@radiance/safesim_component_library/src/css/safesimComponents.scss";
import { RightAlignedRow, Row } from "../DesignSystem/Containers";
import { appConfigurationsVar } from "../GlobalState";
import { SecurityMarkings } from "../Api/Api";
import { SecurityMarkingsEditor } from "./SecurityMarkingsEditor";
import { SafeSimMarkingControl } from "../Utils/SafeSimMarkingControl";

export const SecurityMarkingsModal = ({
  editorVisibleState,
  securityMarkings,
  showSaveButton,
  setEditorVisible,
  onMarkingsChanged,
  onSaveClicked,
}: {
  editorVisibleState: boolean;
  securityMarkings?: SecurityMarkings;
  showSaveButton?: boolean;
  setEditorVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onMarkingsChanged?: (newMarkings: SecurityMarkings) => any;
  onSaveClicked?: (newMarkings: SecurityMarkings) => any;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);

  // TODO: look into changing wrapping for conditional render for safesim tool
  return (
    <Modal isOpen={editorVisibleState} onClose={() => setEditorVisible(false)} size={SIZE.auto}>
      <ModalHeader>{"Security Markings"}</ModalHeader>
      <ModalBody>
        <Row gap="3rem">
          {appConfigurations.useSafeSimMarkingTool ? (
            <SafeSimMarkingControl
              securityMarkings={securityMarkings}
              isSensitiveSecurityContext={appConfigurations.isSensitiveSecurityContext}
              disseminationControlOptions={appConfigurations.disseminationControls}
              onMarkingsChanged={onMarkingsChanged}
              onSaveClicked={onSaveClicked}
              closeEditor={() => setEditorVisible(false)}
            />
          ) : (
            <SecurityMarkingsEditor
              securityMarkings={securityMarkings}
              onMarkingsChanged={(newSecurityMarking) => {
                if (onMarkingsChanged) {
                  onMarkingsChanged(newSecurityMarking);
                }
              }}
            />
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          {showSaveButton && !appConfigurations.useSafeSimMarkingTool && (
            <ModalButton
              kind="tertiary"
              onClick={() => {
                if (onSaveClicked) {
                  onSaveClicked(securityMarkings);
                }
                setEditorVisible(false);
              }}
            >
              {`Save`}
            </ModalButton>
          )}

          {!appConfigurations.useSafeSimMarkingTool && (
            <ModalButton
              kind="tertiary"
              onClick={() => setEditorVisible(false)}
              overrides={{
                Root: {
                  props: {
                    "data-testid": "SecurityMarkingsModalCloseButton",
                  },
                },
              }}
            >
              {`Close`}
            </ModalButton>
          )}
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
};
