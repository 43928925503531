import React, { ChangeEvent } from 'react';
import { Classes } from '@blueprintjs/core';
import { SafesimInputGroup, SafesimTag, SafesimLabel, SafesimButton } from './allSafesimComponents';
import { SafesimControlGroup, SafesimControlGroupProps } from './SafesimControlGroup';

interface Props extends SafesimControlGroupProps {
  label: string;
  labelClassName?: string;
  labelContent?: JSX.Element;
  value: string;
  valueClassName?: string;
  units?: string;
  editAction?: boolean;
  editDisabled?: boolean;
  onClickEdit?: (event: ChangeEvent<HTMLElement>) => void;
  rightSideContent?: JSX.Element;
  modified: boolean;
}

/**
 * Functional component that displays a value in a disabled input with an optional edit action.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} value Value to display in the disabled SafesimInputGroup
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {string} units unit to display next to value
 * @param {boolean} editAction boolean controlling if there is an edit action available. Used to display the edit button
 * @param {boolean} editDisabled boolean controlling if edit is enabled - used to show/hide edit button
 * @param {function} onClickEdit callback used when clicking the edit button
 * @param {JSX.Element} rightSideContent element displayed to the right of the config input
 * @param {boolean} modified boolean used to flag the value as changed but unsaved.
 * @returns Label with disabled text input. Optional edit button to open dialogs or trigger other action
 */
export const SafesimFormValueDisplay = (props: Props): JSX.Element => {
  const { label, labelClassName, labelContent, value, valueClassName, units, editAction, editDisabled = true, onClickEdit, rightSideContent, modified, ...rest } = props;

  return (
    // only show edit if an edit option exists
    <SafesimControlGroup style={{ padding: '5px' }} fill={true} {...rest}>
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={`${Classes.FIXED} ${labelClassName}`} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimInputGroup
        className={`${valueClassName} ${modified ? 'make-green' : ''}`}
        value={value}
        disabled={true}
        fill={true}
        rightElement={units && <SafesimTag minimal={true}>{units}</SafesimTag>}
      />
      {editAction && !editDisabled && <SafesimButton icon='edit' onClick={onClickEdit} disabled={editDisabled} />}
      {rightSideContent}
    </SafesimControlGroup>
  );
};
