/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { QuerySettingsInput, SearchSpecInput } from "Api";
import { Spinner } from "baseui/spinner";
import { LabelSmall, LabelMedium } from "baseui/typography";
import React from "react";
import { CenteredContent } from "../DesignSystem/Containers";
import { AssetSummaryTable } from "./AssetSummaryTable";
import { useFindAssetsQuery } from "../Shared/ApiHooks";

export const PendingWorkSection = ({ filter }: { filter: SearchSpecInput }): JSX.Element => {
  const querySettings: QuerySettingsInput = {
    paging: {
      size: 3,
      index: 0,
    },
    sort: {
      fieldName: "lastModifiedDate",
      order: "DESCENDING",
    },
  };

  const { loading, results: assets } = useFindAssetsQuery(filter, querySettings);

  return (
    <>
      {loading ? (
        <>
          <LabelSmall>{"Loading..."}</LabelSmall>
          <Spinner />
        </>
      ) : (
        <>
          {assets.length < 1 ? (
            <CenteredContent height="3rem">
              <LabelMedium>{"You're all caught up!"}</LabelMedium>
            </CenteredContent>
          ) : (
            <AssetSummaryTable assetList={assets} />
          )}
        </>
      )}
    </>
  );
};
