/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { HeadingXXLarge, LabelLarge } from "baseui/typography";
import { Value } from "baseui/select";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import PickListSchemaEditor from "./PickListSchemaEditor";
import getModelUserDefinedSchema from "../Api/Gql/GetModelUserDefinedSchema.gql";
import { MetadataSchema, PickListSchema, SchemaFields } from "Api";
import { PickList } from "../Shared/PickList";
import { VerticalSpacer } from "../DesignSystem/Containers";
import { FormControl } from "baseui/form-control";
import { Button } from "baseui/button";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { notify } from "../Shared/Notify";
import deleteModelPickListSchema from "../Api/Gql/DeleteModelPickListSchema.gql";
import { Delete } from "baseui/icon";

const GET_MODEL_USER_DEFINED_SCHEMA_QUERY_NAME = "GetModelUserDefinedSchema";

const ModelFields = (): JSX.Element => {
  const { data, refetch } = useQuery(gql(getModelUserDefinedSchema));
  const pickListSchema = data?.getModelUserDefinedSchema as MetadataSchema;
  return (
    <div style={{ margin: "1em 1em 1em 1em" }}>
      <div style={{ marginBottom: "2em" }}>
        <HeadingXXLarge>Model Fields</HeadingXXLarge>
      </div>
      <FlexGrid flexDirection="row" flexGridColumnCount={2}>
        <FlexGridItem>
          <LabelLarge>{`Customize the "Add a Model" form by creating your own form fields.`}</LabelLarge>
          <VerticalSpacer height="2rem" />
          <PickListSchemaEditor currentSchema={pickListSchema} onSave={refetch} />
        </FlexGridItem>
        <FlexGridItem>
          <DemoFieldList schemaFields={pickListSchema?.fields} />
        </FlexGridItem>
      </FlexGrid>
    </div>
  );
};

const DemoFieldList = ({ schemaFields }: { schemaFields: SchemaFields | null }): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <LabelLarge>{`How it will look in the "Add a Model" form`}</LabelLarge>
      <VerticalSpacer height="2rem" />
      <FlexGrid flexDirection="column" width="400px">
        {schemaFields?.selectMultiple &&
          schemaFields?.selectMultiple?.map((pickListData) => (
            <FlexGridItem key={pickListData.id}>
              <DemoPickList pickListData={pickListData} />
            </FlexGridItem>
          ))}
      </FlexGrid>
    </div>
  );
};

const DemoPickList = ({ pickListData }: { pickListData: PickListSchema }): JSX.Element => {
  const [value, setValue] = React.useState<Value>([]);
  const [deletePromptIsOpen, setDeletePromptIsOpen] = React.useState(false);

  const [deleteSchemaField] = useMutation(gql(deleteModelPickListSchema), {
    refetchQueries: [GET_MODEL_USER_DEFINED_SCHEMA_QUERY_NAME],
  });

  const handleDelete = React.useCallback(() => {
    setDeletePromptIsOpen(false);
    deleteSchemaField({ variables: { pickListSchemaId: pickListData.id } });
    notify.positive("Model field deleted successfully");
  }, [pickListData.id, deleteSchemaField]);

  return (
    <>
      <FormControl label={() => pickListData.attributeName}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PickList value={value} onChange={setValue} pickListData={pickListData} multi />

          <Button onClick={() => setDeletePromptIsOpen(true)} kind={"tertiary"}>
            <Delete size={24} />
          </Button>

          <DeleteConfirmationModal
            isOpen={deletePromptIsOpen}
            header={"Delete Model Field"}
            bodyText={`Are you sure you want to delete "${pickListData.attributeName}"? Doing so will remove the custom property from all existing models.`}
            onCancel={() => setDeletePromptIsOpen(false)}
            onDelete={handleDelete}
          />
        </div>
      </FormControl>
    </>
  );
};

export default ModelFields;
