/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { EndAnchoredRow, Row } from "../../DesignSystem/Containers";
import { LabelLarge, LabelMedium } from "baseui/typography";
import WizardLine from "jsx:./wizard-step-connector-line.svg";
import { Button } from "baseui/button";

const completeLineColor = "#68c062";
const incompleteLineColor = "#bebebe";

export function WizardNavigationMap({
  steps,
  currentStep,
  style,
}: {
  steps: string[];
  currentStep: string;
  style?: React.CSSProperties;
}): JSX.Element {
  if (!steps || steps.length < 1) {
    throw new Error("Must have at least 1 step defined.");
  }

  const stepsAfterFirst = steps.slice(1);
  const currentStepIndex = steps.indexOf(currentStep);

  return (
    <Row style={style}>
      <StepLabel stepName={steps[0]} isCurrent={currentStep === steps[0]} />
      {stepsAfterFirst.map((step) => {
        const lineColor = steps.indexOf(step) <= currentStepIndex ? completeLineColor : incompleteLineColor;
        return (
          <React.Fragment key={step}>
            <WizardLine fill={lineColor} stroke={lineColor} />
            <StepLabel stepName={step} isCurrent={currentStep === step} />
          </React.Fragment>
        );
      })}
    </Row>
  );
}

function StepLabel({ stepName, isCurrent }: { stepName: string; isCurrent: boolean }): JSX.Element {
  return isCurrent ? <LabelLarge>{stepName} </LabelLarge> : <LabelMedium>{stepName}</LabelMedium>;
}

export function WizardNavigationButtons<T extends string>({
  previousStep,
  previousDisabled,
  nextStep,
  nextLoading,
  nextDisabled,
  onStepChange,
  isFinalStep,
}: {
  previousStep?: T;
  previousDisabled?: boolean;
  nextStep?: T;
  nextLoading?: boolean;
  nextDisabled?: boolean;
  onStepChange: (newStep: T) => void;
  isFinalStep?: boolean;
}): JSX.Element {
  const nextStepText = isFinalStep ? nextStep : `${nextStep} >`;

  return (
    <EndAnchoredRow>
      {previousStep ? (
        <Button kind={"tertiary"} disabled={previousDisabled} onClick={() => onStepChange(previousStep)}>
          {`< ${previousStep}`}
        </Button>
      ) : (
        <div />
      )}

      {nextStep ? (
        <Button kind={"primary"} disabled={nextDisabled || nextLoading} onClick={() => onStepChange(nextStep)}>
          {nextStepText}
        </Button>
      ) : (
        <div />
      )}
    </EndAnchoredRow>
  );
}
