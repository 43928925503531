import React from 'react';
import { ControlGroup, ControlGroupProps } from '@blueprintjs/core';

export interface SafesimControlGroupProps extends ControlGroupProps {}

export const SafesimControlGroup = (props: SafesimControlGroupProps) => {
  const { className, ...other } = props;

  return <ControlGroup className={'control-group ' + className} {...other} />;
};
