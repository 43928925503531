/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import AppInfoQuery from "../Api/Gql/AppInfo.gql";
import { HeadingXXLarge, HeadingLarge, LabelLarge, LabelSmall } from "baseui/typography";
import { EndAnchoredRow, VerticalStack } from "../DesignSystem/Containers";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { AppInfo, MigrationExecutionState } from "Api";
import { formattedDateTime } from "../Api/ApiSerialization";
import { Spinner } from "baseui/spinner";

export const DatabaseTools = (): JSX.Element => {
  const { loading, error, data } = useQuery(gql(AppInfoQuery), {
    fetchPolicy: "network-only",
  });
  const appInfoValues = data?.appInfo as AppInfo;

  if (loading) {
    return (
      <>
        <LabelLarge>{"Loading migration status..."}</LabelLarge>
        <Spinner />
      </>
    );
  }

  if (error) {
    return (
      <>
        <LabelLarge>{`Couldn't load migration status; ${error.message}`}</LabelLarge>
      </>
    );
  }

  return (
    <VerticalStack gap="1rem" style={{ padding: "1rem 1rem 1rem 1rem", width: "50rem" }}>
      <HeadingXXLarge $style={{ paddingBottom: "1rem" }}>{"Database Tools"}</HeadingXXLarge>
      <EndAnchoredRow>
        <HeadingLarge $style={{ paddingBottom: "0.5rem" }}>{"Migration Status"}</HeadingLarge>
      </EndAnchoredRow>

      <LabelSmall>{"Last executed migration"}</LabelSmall>
      <div style={{ paddingLeft: "2rem" }}>
        <MigrationStatus migrationState={appInfoValues?.schemaStatus.lastExecutedMigration} />
      </div>
      <FormControl label={() => `Expected migration for SIMOR ${appInfoValues?.version?.descriptor}`}>
        <Input value={appInfoValues?.schemaStatus?.expectedMigrationId} disabled />
      </FormControl>
    </VerticalStack>
  );
};

const MigrationStatus = ({ migrationState }: { migrationState: MigrationExecutionState }): JSX.Element => {
  switch (migrationState.__typename) {
    case "ExecutedMigration":
      return (
        <>
          <FormControl label={() => "id"}>
            <Input value={migrationState.changeId} disabled />
          </FormControl>
          <FormControl label={() => "Executed at"}>
            <Input value={formattedDateTime(migrationState.timestamp) ?? "N/A"} disabled />
          </FormControl>
        </>
      );
    case "NoMigrationsExecuted":
      return <LabelSmall>{migrationState.message}</LabelSmall>;
    case "ErrorGettingMigrations":
      return <LabelSmall>{`Couldn't get migrations because ${migrationState.errorMessage}`}</LabelSmall>;
    default:
      const _exhaustiveCheck: never = migrationState;
      return _exhaustiveCheck;
  }
};
