/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Spinner } from "baseui/spinner";
import { HeadingXLarge } from "baseui/typography";
import * as React from "react";
import { CenteredContent } from "../DesignSystem/Containers";

export const LoadingPlaceholder = ({ message }: { message: string }): JSX.Element => {
  return (
    <CenteredContent>
      <HeadingXLarge>{message}</HeadingXLarge>
      <Spinner />
    </CenteredContent>
  );
};
