/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { RelationPane } from "../Shared/RelationPane";
import { ModelDetailsPageAction } from "./ModelDetailsState";
import { AssetSummary, AssetTypeFilterInput } from "Api";

const validModelRelationTypeFilter: AssetTypeFilterInput = {
  assetTypes: ["SCENARIO", "MODEL", "PLATFORM"],
};

export function ModelRelationPane({
  dispatch,
  relations,
}: {
  dispatch: React.Dispatch<ModelDetailsPageAction>;
  relations?: AssetSummary[];
}): JSX.Element {
  return (
    <RelationPane
      relations={relations}
      inputEnabled={true}
      relationHeader="Related Resources"
      typeFilter={validModelRelationTypeFilter}
      onAdd={(addedAsset) => dispatch(["addRelatedAsset", addedAsset])}
      onRemove={(removedAsset) => dispatch(["removeRelatedAsset", removedAsset])}
    />
  );
}
