import React from 'react';
import { MultiSelect2, MultiSelect2Props } from '@blueprintjs/select';
import { usePortal } from './safesim_hooks/usePortalHook';

interface Props<T> extends MultiSelect2Props<T> {}

/**
 * Functional component that provides a drop down selection of items to render as tags..
 * Wraps BlueprintJS MultiSelect2.
 * Requires several props to define the list of items,
 * function to convert the items to menu item for rendering, and function to call on selection,
 * as well as function to convert item to tag for rendering
 * @param {*} props
 * @returns a drop down selection of items to render as tags.
 */
export function SafesimMultiSelect<T>(props: Props<T>): JSX.Element {
  const { className, popoverProps, ...other } = props;
  const enablePortal: any = usePortal();

  return (
    <MultiSelect2
      className={'kmPrimaryText kmSurfaceElement ' + className}
      popoverProps={{ minimal: true, portalContainer: enablePortal?.window?.document?.body, ...popoverProps }}
      {...other}
    />
  );
}
