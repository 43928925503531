/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useQuery } from "@apollo/client";
import { LabelSmall, LabelMedium, LabelXSmall } from "baseui/typography";
import * as React from "react";
import { FileUpload } from "./FileUpload";
import findC2SimEntityOptions from "../../Api/Gql/FindC2SimEntityOptions.gql";
import { handleApolloError } from "../../Shared/Errors";
import { CenteredContent, VerticalStack } from "../../DesignSystem/Containers";
import { Spinner } from "baseui/spinner";
import { C2SimEntityDefinition, C2SimQueryResponse, ModelQueryResponse } from "Api";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { ModelSearchResultTable, SelectableResultItem } from "./ModelSearchResultTable";

export function C2SimFileQuery({ fileUpload }: { fileUpload: FileUpload }): JSX.Element {
  const [expanded, setExpanded] = React.useState<React.Key[]>([]);

  const { data, loading } = useQuery(gql(findC2SimEntityOptions), {
    fetchPolicy: "network-only",
    variables: {
      fileId: fileUpload.info.id,
    },
    onError: (error) => handleApolloError(error, "Error retrieving recent activity"),
  });

  const queryResponse = data?.findC2SimEntityOptions as C2SimQueryResponse;

  if (loading) {
    return (
      <CenteredContent>
        <LabelSmall>{`Searching for models that match the C2 Sim Scenario file`}</LabelSmall>
        <Spinner />
      </CenteredContent>
    );
  }

  return (
    <StatelessAccordion
      accordion={false}
      expanded={expanded}
      onChange={({ expanded }) => {
        setExpanded(expanded);
      }}
    >
      {queryResponse?.suggestions.entities.map((entity, index) => (
        <Panel title={<EntityHeader entity={entity.definition} />} key={index}>
          <C2SimEntityMatchResults matches={entity.matches} />
        </Panel>
      ))}
    </StatelessAccordion>
  );
}

function EntityHeader({ entity }: { entity: C2SimEntityDefinition }): JSX.Element {
  return (
    <VerticalStack gap={"0.1rem"}>
      <LabelMedium>{entity.name}</LabelMedium>
      <LabelXSmall
        $style={{
          // TODO: Use an appropriate value from the Baseweb theme (or add it to the custom theme).
          color: "#c0c0c0",
        }}
      >
        <i>{entity.type}</i>
      </LabelXSmall>
    </VerticalStack>
  );
}

function C2SimEntityMatchResults({ matches }: { matches: ModelQueryResponse }): JSX.Element {
  const selectableModels = React.useMemo(
    () =>
      matches.results.map((model) => {
        const selectableModel: SelectableResultItem = {
          item: model,
          isSelected: false,
        };
        return selectableModel;
      }),
    [matches.results]
  );

  return (
    <ModelSearchResultTable
      selectableResultItems={selectableModels}
      refetchQueryName={"NA"}
      onClick={doNothing}
      onSort={doNothing}
    />
  );
}

function doNothing(): void {}
