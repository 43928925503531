/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { Accordion, Panel } from "baseui/accordion";
import { Button } from "baseui/button";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import { HeadingXXLarge, LabelLarge } from "baseui/typography";

import { gql, useLazyQuery } from "@apollo/client";

import appInfo from "./Api/Gql/AppInfo.gql";
import { AppInfo, SchemaStatus } from "Api";

import { SimorAuthContext } from "./Utils/AuthContext";
import { CenteredContent, Row } from "./DesignSystem/Containers";
import { UserApp } from "./UserApp";
import { handleApolloError } from "./Shared/Errors";
import { setAppConfigurationsVar, setSimorAppSettingsVar } from "./GlobalState";
import { Spinner } from "baseui/spinner";
import { UserContext } from "./Utils/UserContext";

export const SimorApp = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);

  return simorAuth.isAuthorized() ? (
    <CheckAppReady />
  ) : (
    <ToasterContainer placement={PLACEMENT.topRight}>
      <ChooseUser />
    </ToasterContainer>
  );
};

const CheckAppReady = (): JSX.Element => {
  const [migrationInProgress, setMigrationInProgress] = React.useState(false);

  const [appInfoQuery, { loading, refetch }] = useLazyQuery(gql(appInfo), {
    onCompleted: (data: { appInfo: AppInfo }) => {
      const settings = data?.appInfo?.settings;
      if (settings) {
        setSimorAppSettingsVar(settings);
      }
      const configurations = settings?.configurations;
      if (configurations) {
        setAppConfigurationsVar(configurations);
      }
      setMigrationInProgress(isMigrationInProgress(data?.appInfo.schemaStatus));
    },
    onError: (error) => handleApolloError(error, "An error occured while getting server configuration."),
  });
  const currentUser = React.useContext(UserContext);

  React.useEffect(() => {
    appInfoQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || currentUser == null) {
    return (
      <CenteredContent>
        <LabelLarge>{"Loading SIMOR..."}</LabelLarge>
        <Spinner />
      </CenteredContent>
    );
  }

  return !migrationInProgress ? <UserApp /> : <MigrationInProgressNotice onRefresh={() => refetch()} />;
};

const isMigrationInProgress = (schemaStatus: SchemaStatus): boolean => {
  switch (schemaStatus.lastExecutedMigration.__typename) {
    case "ExecutedMigration":
      return schemaStatus.lastExecutedMigration.changeId !== schemaStatus.expectedMigrationId;
    case "NoMigrationsExecuted":
      return schemaStatus.lastExecutedMigration.message !== schemaStatus.expectedMigrationId;
    default:
      return false;
  }
};

const MigrationInProgressNotice = ({ onRefresh }: { onRefresh: () => void }): JSX.Element => {
  return (
    <CenteredContent>
      <LabelLarge>{`A database migration is in progress.  Please wait a minute and then click the refresh button.`}</LabelLarge>
      <Row>
        <Button onClick={() => onRefresh()} kind={"primary"}>
          {`Refresh`}
        </Button>
      </Row>
    </CenteredContent>
  );
};

const ChooseUser = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);

  return (
    <CenteredContent>
      <HeadingXXLarge>{"Welcome to SIMOR!"}</HeadingXXLarge>
      <LabelLarge>{"Choose a user to get started"}</LabelLarge>
      <Button onClick={() => simorAuth.loginUser("APTUser")} kind={"primary"}>
        {"APTUser"}
      </Button>
      <div style={{ paddingTop: "2rem", width: "20rem" }}>
        <Accordion>
          <Panel title="Other Test Users">
            <Button onClick={() => simorAuth.loginUser("Bob Smith")} kind={"tertiary"}>
              {"Access Restricted User"}
            </Button>
            <Button onClick={() => simorAuth.loginUser("Juliet Devereux")} kind={"tertiary"}>
              {"Power User"}
            </Button>
          </Panel>
        </Accordion>
      </div>
    </CenteredContent>
  );
};
