/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React from "react";
import { LabelSmall } from "baseui/typography";
import { EndAnchoredRow, Row } from "../../DesignSystem/Containers";
import { SecurityMarkings } from "Api";
import { generatePortionMarkingsText } from "../../Shared/Security";
import { IconButton } from "../../Shared/Buttons";
import { Icon } from "@blueprintjs/core";
import { SecurityMarkingsModal } from "../../Shared/SecurityMarkingsModal";
import { useReactiveVar } from "@apollo/client";
import { appConfigurationsVar } from "../../GlobalState";
import { Delete } from "baseui/icon";

export function FieldHeader({
  fieldTitle,
  portionMarking,
  isMarkable,
  allowMarkingEdits,
  allowRemoval,
  onMarkingsChange,
  onRemove,
}: {
  fieldTitle: string;
  portionMarking?: SecurityMarkings;
  isMarkable?: boolean;
  allowMarkingEdits?: boolean;
  allowRemoval?: boolean;
  onMarkingsChange?: (newValue: SecurityMarkings) => void;
  onRemove?: () => void;
}): JSX.Element {
  const [editorVisible, setEditorVisible] = React.useState(false);

  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  return (
    <>
      <EndAnchoredRow>
        <LabelSmall>{fieldTitle}</LabelSmall>
        <Row>
          {isMarkable && (
            <Row gap="0.5rem">
              <LabelSmall>{generatePortionMarkingsText(isSensitiveSecurityContext, portionMarking)}</LabelSmall>
              {allowMarkingEdits && (
                <IconButton
                  dataTestId={`Portion-mark-${fieldTitle}`}
                  onClick={() => setEditorVisible(true)}
                  style={{ marginTop: "-0.15rem" }}
                >
                  <Icon
                    icon="take-action"
                    size={9}
                    title={"Edit the security classification markings of the relative portion of the asset."}
                  />
                </IconButton>
              )}
            </Row>
          )}
          {allowRemoval && (
            <IconButton dataTestId={`${fieldTitle}-Remove-Button`} onClick={() => onRemove && onRemove()}>
              <Delete title="Delete field" />
            </IconButton>
          )}
        </Row>
      </EndAnchoredRow>
      <SecurityMarkingsModal
        editorVisibleState={editorVisible}
        setEditorVisible={setEditorVisible}
        securityMarkings={portionMarking}
        onMarkingsChanged={onMarkingsChange}
      ></SecurityMarkingsModal>
    </>
  );
}
