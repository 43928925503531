# import "./fragments/securityMarkingsData.gql"

query FindC2SimEntityOptions($fileId: UUID!) {
  findC2SimEntityOptions(fileId: $fileId) {
    suggestions {
      entities {
        definition {
          name
          type
        }
        matches {
          pageCount
          results {
            id
            creationDate
            lastModifiedDate
            metadata {
              title
              version
              description
              subject
              keywords
              notes
              customProps
            }
            securityMarkings {
              __typename
              ...securityMarkingsData
            }
          }
        }
      }
    }
  }
}