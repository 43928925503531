/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FileUploader } from "baseui/file-uploader";
import axios, { CancelTokenSource } from "axios";
import { notify, notifyBatchResponse } from "./Notify";
import {
  DuplicateUploadInfo,
  FileError,
  FileUploadManifest,
  FileUploadResponse,
  NewFileInfo,
  SecurityMarkings,
} from "Api";
import { getParentPath, matchByName } from "../Api/FileInfo";
import { AxiosContext } from "../Utils/AuthContext";
import { FilePath } from "../Asset/Tree/FileUpload";
import { useState } from "react";
import { ClassificationSummary } from "./SecurityMarkingsEditor";
import { CenteredContent, RightAlignedRow, VerticalStack } from "../DesignSystem/Containers";
import { Button } from "baseui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { newId } from "../Utils/Types";
import { useReactiveVar } from "@apollo/client";
import { appConfigurationsVar } from "../GlobalState";
import { useMarkingsDisableSave } from "./ApiHooks";

export interface ObservableFileUploaderProps {
  showSecurityMarkingsControl: boolean;
  onUploadStart?: (cancelToken: CancelTokenSource, uploadedFilePaths: FilePath[]) => void;
  onUploadComplete: (
    successfulUploads: NewFileInfo[],
    failedUploads: NewFileInfo[],
    duplicateUploadInfos: DuplicateUploadInfo[]
  ) => void;
  testIdPrefix?: string;
}
const getAllFailedMessage = (failedElements?: FileError[]): string => {
  const errorMessages = failedElements.map((element) => element.uploadError);
  const uniqueErrorMessages = [...new Set(errorMessages)].join(", ");
  return `Failed to upload all files because of ${uniqueErrorMessages}.`;
};

const getPartialSuccessMessage = (
  failedElements: FileError[],
  successfulElements?: string[],
  duplicateElements?: string[]
): string => {
  const failedFileNames = failedElements.map((element) => element.fileName).join(", ");

  if (duplicateElements) {
    return `Added ${successfulElements.length} files; ${duplicateElements.length} were previously uploaded. Could not upload ${failedFileNames}.`;
  } else {
    return `Added ${successfulElements.length} files. Could not upload ${failedFileNames}.`;
  }
};

const getSingleSuccessMessage = (successfulFile: string): string => {
  return `Added new file ${successfulFile}.`;
};

const getFewSuccessMessage = (successfulElements: string[]): string => {
  const successfulFileNames = successfulElements.map((element) => element).join(", ");
  return `Added new files ${successfulFileNames}.`;
};

const getManySuccessMessage = (successfulFiles: string[]): string => {
  return `Added ${successfulFiles.length} files.`;
};

const getSuccessMessageManyDuplicate = (successfulElements: string[], duplicateElements: string[]): string => {
  return `Added ${successfulElements.length} files(s); ${duplicateElements.length} were previously uploaded.`;
};

const getSuccessMessageSingleDuplicate = (successfulElements: string[], duplicateElement: string): string => {
  return `Added ${successfulElements.length} files(s). ${duplicateElement} was previously uploaded.`;
};

export const notifyFileUploadResponse = (fileUploadResponse: FileUploadResponse): void => {
  const { successfulUploads, failedUploads, duplicateUploads } = fileUploadResponse;

  notifyBatchResponse(
    successfulUploads,
    duplicateUploads,
    failedUploads,
    getAllFailedMessage,
    getPartialSuccessMessage,
    getSingleSuccessMessage,
    getFewSuccessMessage,
    getManySuccessMessage,
    getSuccessMessageManyDuplicate,
    getSuccessMessageSingleDuplicate
  );
};

const concatAllSuccessfulUploads = (successfulUploads: string[], duplicateUploads: string[]): string[] => {
  let allSuccessfulUploads = [];
  if (successfulUploads) {
    allSuccessfulUploads = [...allSuccessfulUploads, ...successfulUploads];
  }
  if (duplicateUploads) {
    allSuccessfulUploads = [...allSuccessfulUploads, ...duplicateUploads];
  }
  return allSuccessfulUploads;
};

export function ObservableFileUploader({
  showSecurityMarkingsControl,
  onUploadStart,
  onUploadComplete,
  testIdPrefix,
}: ObservableFileUploaderProps): JSX.Element {
  const axiosContext = React.useContext(AxiosContext);

  const [securityMarkings, setSecurityMarkings] = useState<SecurityMarkings>();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  const [percentageComplete, setPercentageComplete] = React.useState<number>(null);

  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource>(null);

  const [fileName, setFileName] = React.useState<string>(null);

  const handleUploadResponse = React.useCallback(
    (
      successfulUploads?: string[],
      duplicateUploads?: string[],
      failedUploads?: FileError[],
      duplicateUploadInfos?: DuplicateUploadInfo[]
    ) => {
      notifyFileUploadResponse({
        successfulUploads,
        duplicateUploads,
        failedUploads,
        duplicateUploadInfos,
      });
    },
    []
  );

  const handleUpload = React.useCallback(
    (files: File[], markings: SecurityMarkings) => {
      setFileName(files[0].name);

      const formData = new FormData();

      const uploadedFilePaths: FilePath[] = files.map((file) => {
        const filePath: FilePath = {
          path: getParentPath(file),
          file: {
            id: newId(),
            name: file.name,
            securityMarkings: markings,
          },
        };
        return filePath;
      });

      const uploadedFiles = uploadedFilePaths.map((path) => path.file);
      // To add a delay before file uploading is complete (to simulate large files), add property:
      // uploadDelayMillis: 5000,
      const uploadManifest: FileUploadManifest = {
        fileInfo: uploadedFiles,
      };

      formData.append("fileInfo", JSON.stringify(uploadManifest));
      files.forEach((file, index) => {
        formData.append(`${index}`, file, file.name);
      });

      const localCancelToken = axios.CancelToken.source(); // Relative to each upload
      setCancelToken(localCancelToken);
      if (onUploadStart) {
        onUploadStart(localCancelToken, uploadedFilePaths);
      }

      const config = {
        cancelToken: localCancelToken.token,
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setPercentageComplete(percentCompleted === 100 ? null : percentCompleted);
        },
      };

      // TODO: This can be rewritten as async/await or possibly
      //    with an axios "use" hook.
      const uploadPromise = axiosContext.post("/upload", formData, config);
      uploadPromise
        .then((response) => {
          const { successfulUploads, duplicateUploads, failedUploads, duplicateUploadInfos } =
            response.data as FileUploadResponse;

          handleUploadResponse(successfulUploads, duplicateUploads, failedUploads);

          const allSuccessfulUploads = concatAllSuccessfulUploads(successfulUploads, duplicateUploads);
          const successfulFiles = matchByName(allSuccessfulUploads, uploadedFiles);
          const failedFiles = matchByName(
            failedUploads?.map((fileError) => fileError.fileName),
            uploadedFiles
          );

          setPercentageComplete(null);
          onUploadComplete(successfulFiles, failedFiles, duplicateUploadInfos);
        })
        .catch((reason) => {
          if (axios.isCancel(reason)) {
            notify.info("Upload cancelled.");
          } else {
            notify.warning(reason?.message);
          }
          setPercentageComplete(null);

          onUploadComplete([], uploadedFiles);
        });

      setFilesToUpload([]);
    },
    [axiosContext, handleUploadResponse, onUploadComplete, onUploadStart]
  );

  const handleDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (rejectedFiles && rejectedFiles.length > 1) {
        notify.negative("Only one file upload is currently supported");
      } else {
        if (!showSecurityMarkingsControl) {
          // Control is configured to not show the markings control. Go straight to upload.
          handleUpload(acceptedFiles, null);
        } else {
          setFilesToUpload(acceptedFiles);
        }
      }
    },
    [handleUpload, showSecurityMarkingsControl]
  );

  const fileCountMsg =
    filesToUpload?.length == 1 ? "1 file selected for upload" : `${filesToUpload.length} files selected for upload`;

  const [errorMessage, setErrorMessage] = React.useState("");

  const markingsRequired = useReactiveVar(appConfigurationsVar).markingsRequired;

  const classificationSummaryTitle = markingsRequired
    ? "The security markings selected here will be applied to all uploaded files. This value is required."
    : "The security markings selected here will be applied to all uploaded files. This value is not required.";

  const isSaveDisabled = useMarkingsDisableSave(securityMarkings);

  return (
    <>
      {showSecurityMarkingsControl && filesToUpload?.length > 0 ? (
        <VerticalStack
          gap="0"
          style={{
            borderColor: "rgb(81, 85, 91)",
            borderRadius: "12px",
            borderStyle: "dashed",
            borderWidth: "2px",
            height: "10rem",
            padding: ".75rem .75rem 0 .75rem",
            boxSizing: "content-box",
          }}
        >
          <CenteredContent>
            <span style={{ fontSize: "1.2rem" }}>{fileCountMsg}</span>
          </CenteredContent>

          <div>
            <ClassificationSummary
              label={
                <div>
                  <span style={{ marginRight: ".5rem" }}>Classification</span>
                  <FontAwesomeIcon icon={faCircleQuestion} size="1x" title={classificationSummaryTitle} />
                </div>
              }
              securityMarkings={securityMarkings}
              onMarkingsChanged={(newSecurityMarking) => setSecurityMarkings(newSecurityMarking)}
            />
          </div>

          <RightAlignedRow>
            <Button
              kind={"tertiary"}
              size="compact"
              disabled={isSaveDisabled}
              onClick={() => {
                handleUpload(filesToUpload, securityMarkings);
              }}
            >
              Upload
            </Button>
            <Button
              kind={"tertiary"}
              size="compact"
              onClick={() => {
                setSecurityMarkings(null);
                setFilesToUpload([]);
              }}
            >
              Cancel
            </Button>
          </RightAlignedRow>
        </VerticalStack>
      ) : (
        <FileUploader
          multiple={true}
          onDrop={handleDrop}
          onCancel={() => {
            cancelToken.cancel();
            setPercentageComplete(null);
            setErrorMessage("Upload Cancelled");
            setCancelToken(null);
          }}
          onRetry={() => {
            setPercentageComplete(null);
            setErrorMessage(null);
            setCancelToken(null);
          }}
          errorMessage={errorMessage}
          progressAmount={percentageComplete}
          progressMessage={percentageComplete ? `Uploading ${fileName} ${percentageComplete}% Complete` : ""}
          overrides={{
            ButtonComponent: {
              props: { overrides: { BaseButton: { props: { "data-testid": `${testIdPrefix}-browse-files` } } } },
            },
          }}
        />
      )}
    </>
  );
}
