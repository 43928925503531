# import "./fragments/securityMarkingsData.gql"
# import "./fragments/relatedScenarioData.gql"
# import "./fragments/relatedPlatformData.gql"
# import "./fragments/relatedModelData.gql"
# import "./fragments/fileFoldersData.gql"

query GetModels($ids: [UUID!]!) {
  getModels(ids: $ids) {
    id
    creationDate
    lastModifiedDate
    relatedScenarios {
      __typename
      ...relatedScenarioData
    }
    relatedModels {
      __typename
      ...relatedModelData
    }
    relatedPlatforms {
      __typename
      ...relatedPlatformData
    }
    metadata {
      title
      version
      description
      keywords
      subject
      notes
      simulationFrameworks
      customProps
      imageId
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
  }
}
