/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { HeadingSmall } from "baseui/typography";

export function TopicSelector({
  topics,
  selectedTopics,
  onSelectionChange,
}: {
  topics: string[];
  selectedTopics: string[];
  onSelectionChange: (selected: string[]) => void;
}): JSX.Element {
  const safeSelectedTopics = React.useMemo(() => selectedTopics ?? [], [selectedTopics]);
  const selectedIndices = React.useMemo(
    () => safeSelectedTopics?.map((selectedTopic) => topics.indexOf(selectedTopic)),
    [safeSelectedTopics, topics]
  );

  const toggleSelection = React.useCallback(
    (_event, index) => {
      const topicForIndex = topics[index];
      if (!selectedIndices.includes(index)) {
        onSelectionChange([...safeSelectedTopics, topicForIndex]);
      } else {
        onSelectionChange(safeSelectedTopics.filter((value) => value !== topicForIndex));
      }
    },
    [topics, safeSelectedTopics, selectedIndices, onSelectionChange]
  );

  return (
    <div>
      {topics !== undefined && topics.length != 0 ? (
        <ButtonGroup
          overrides={{
            Root: {
              style: () => ({
                flexWrap: "wrap",
                columnGap: "1rem",
                rowGap: "1rem",
              }),
            },
          }}
          mode="checkbox"
          selected={selectedIndices}
          onClick={toggleSelection}
        >
          {topics?.map((topic, index) => (
            <Button data-testid={`${topic}-button`} key={index}>
              {topic}
            </Button>
          ))}
        </ButtonGroup>
      ) : (
        <HeadingSmall
          data-testid="no-topics-message"
          overrides={{
            Block: {
              style: () => ({
                opacity: 0.25,
              }),
            },
          }}
        >
          No topics present
        </HeadingSmall>
      )}
    </div>
  );
}
