import React, { useCallback, useState } from 'react';
import { SafesimControlGroup } from './SafesimControlGroup';
import { SafesimMultiSelect } from './SafesimMultiSelect';
import { SafesimSelect } from './SafesimSelect';
import {
  SafesimAlert,
  SafesimButton,
  SafesimButtonCross,
  SafesimButtonGroup,
  SafesimCard,
  SafesimFormGroup,
  SafesimInputGroup,
  SafesimMenuItem,
} from './allSafesimComponents';
import { SecurityMetadata } from '../types/SecurityMetadata';
import { ItemRendererProps } from '@blueprintjs/select';

interface Props {
  securityMetadataObject: SecurityMetadata;
  onSubmit: (label: SecurityMetadata) => void;
  onCancel: () => void;
  classificationList: string[];
  sciList: string[];
  sarList: string[];
  disseminationControlsList: string[];
}

/**
 * Component that displays a form that is used to set security metadata.
 */
export const SecurityMetadataSelector = (props: Props): JSX.Element => {
  const { onCancel, onSubmit, securityMetadataObject, classificationList, sciList, sarList, disseminationControlsList } = props;
  const initialFormState = {
    classification: '',
    scicontrols: [],
    saridentifier: [],
    disseminationControls: [],
  } as SecurityMetadata;

  const [securityMetadata, setSecurityMetadata] = useState<SecurityMetadata>(securityMetadataObject || initialFormState);
  const [sarAgencyDisabled, setSarAgencyDisabled] = useState(false);
  const [sarClassDisabled, setSarClassDisabled] = useState(true);
  const [sarMarkDisabled, setSarMarkDisabled] = useState(true);

  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<JSX.Element>();

  const changeAlertState = useCallback(() => {
    setAlertIsOpen(!alertIsOpen);
  }, [alertIsOpen]);

  const valid = useCallback(
    (classifications: string[], securityMetadata: SecurityMetadata) => {
      if (!securityMetadata.classification) {
        setAlertContent(
          <p>
            Classification is required.
            <br />
            <br />
            Please select a value for classification...
          </p>
        );
        changeAlertState();
        return false;
      }
      if (securityMetadata.saridentifier) {
        const regExpStr = '(' + classifications.join('|') + '):[A-Za-z0-9._:-]+$';
        const regExp = new RegExp(regExpStr);
        for (const s of securityMetadata.saridentifier) {
          if (!regExp.test(s)) {
            setAlertContent(
              <p>
                Invalid SAR Marking (one or more selections missing). <br />
                <br />
                SAR must have one selection from each category: <br />
                Agency, Classification, Marking.
                <br />
                <br />
                Example: DOD:P:XYZ1
              </p>
            );
            changeAlertState();
            return;
          }
        }
      }

      return true;
    },
    [changeAlertState]
  );

  const handleSubmit = useCallback(() => {
    if (valid(classificationList, securityMetadata)) {
      onSubmit(securityMetadata);
    }
  }, [onSubmit, securityMetadata, classificationList, valid]);

  /// function used to handle changes to sci selections ///
  const handleSCIChange = (selection: string) => {
    if (!securityMetadata.scicontrols.includes(selection)) {
      securityMetadata.scicontrols.push(selection);
      setSecurityMetadata({
        ...securityMetadata,
        scicontrols: securityMetadata.scicontrols,
      });
    }
  };

  /// function used to handle removals of sci selections ///
  const handleSCIRemove = (_tag: any, index: number) => {
    securityMetadata.scicontrols.splice(index, 1);
    setSecurityMetadata({
      ...securityMetadata,
      scicontrols: securityMetadata.scicontrols,
    });
  };

  /// function used to handle the clearing of all sci selections ///
  const handleSCIClear = () => {
    setSecurityMetadata({
      ...securityMetadata,
      scicontrols: [],
    });
  };

  const handleSARAgencyChange = (val: string) => {
    const arr = securityMetadata.saridentifier || [];
    arr.push(val + ':');
    setSecurityMetadata({ ...securityMetadata, saridentifier: arr });

    setSarAgencyDisabled(true);
    setSarClassDisabled(false);
    setSarMarkDisabled(true);
  };

  const handleSARClassificationChange = (val: string) => {
    const arr = securityMetadata.saridentifier || [];
    const sar = arr[arr.length - 1];
    arr[arr.length - 1] = sar + val + ':';
    setSecurityMetadata({ ...securityMetadata, saridentifier: arr });

    setSarAgencyDisabled(true);
    setSarClassDisabled(true);
    setSarMarkDisabled(false);
  };

  const handleSARMarkingChange = (val: string) => {
    const arr = securityMetadata.saridentifier || [];
    const sar = arr[arr.length - 1];
    arr[arr.length - 1] = sar + val;
    setSecurityMetadata({ ...securityMetadata, saridentifier: arr });

    setSarAgencyDisabled(false);
    setSarClassDisabled(true);
    setSarMarkDisabled(true);
  };

  /// function used to handle changes to dissemination control selections ///
  const handleDisseminationControlChange = (selection: string) => {
    if (!securityMetadata.disseminationControls?.includes(selection)) {
      securityMetadata.disseminationControls?.push(selection);
      setSecurityMetadata({
        ...securityMetadata,
        disseminationControls: securityMetadata.disseminationControls,
      });
    }
  };

  /// function used to handle removals of dissemination control selections ///
  const handleDisseminationControlRemove = (_tag: any, index: number) => {
    securityMetadata.disseminationControls?.splice(index, 1);
    setSecurityMetadata({
      ...securityMetadata,
      disseminationControls: securityMetadata.disseminationControls,
    });
  };

  /// function used to handle the clearing of all dissemination control selections ///
  const handleDisseminationControlClear = () => {
    setSecurityMetadata({
      ...securityMetadata,
      disseminationControls: [],
    });
  };

  return (
    <div>
      <SafesimCard className={'submissionWrapper '} style={{ width: '600px' }}>
        <h3 className='alignCenter formTitle'>Security Metadata</h3>
        <div className='submissionWrapperChildren' style={{ padding: '10px 0px' }}>
          <SafesimFormGroup label='Classification' labelInfo='*' inline={true} isInvalid={!securityMetadata.classification}>
            <SafesimSelect
              fill={true}
              round={true}
              disabled={false}
              selectOnBlur={false}
              itemArray={classificationList}
              selectedItem={securityMetadata.classification}
              onSelectItem={(item: string) => setSecurityMetadata({ ...securityMetadata, classification: item })}
              intent={securityMetadata.classification ? '' : 'danger'}
            />
            <SafesimAlert onConfirm={changeAlertState} confirmButtonText='Okay' onClose={changeAlertState} canOutsideClickCancel={true} loading={false} isOpen={alertIsOpen}>
              {alertContent}
            </SafesimAlert>
          </SafesimFormGroup>
          <SafesimFormGroup label='SCI' labelInfo='(optional)' inline={true}>
            <SafesimMultiSelect
              fill={true}
              itemRenderer={(item: string, { modifiers, handleClick }: ItemRendererProps) => {
                if (!modifiers.matchesPredicate) {
                  return null;
                }
                return (
                  <SafesimMenuItem
                    icon={securityMetadata.scicontrols.includes(item) ? 'tick' : 'blank'}
                    key={item}
                    onClick={handleClick}
                    text={item}
                    shouldDismissPopover={true}
                  />
                );
              }}
              items={sciList || []}
              itemPredicate={(predicate: string, item: string) => {
                return item?.toLowerCase()?.includes(predicate.toLowerCase());
              }}
              noResults={<SafesimMenuItem disabled={true} text='No results...' />}
              onItemSelect={handleSCIChange}
              popoverProps={{ minimal: true }}
              tagRenderer={(selection: string) => {
                return selection;
              }}
              tagInputProps={{
                onRemove: handleSCIRemove,
                rightElement: <SafesimButtonCross onClick={handleSCIClear} />,
              }}
              selectedItems={securityMetadata.scicontrols}
              resetOnSelect={true}
            />
          </SafesimFormGroup>
          <SafesimFormGroup label='Dissemination Controls' labelInfo='(optional)' inline={true}>
            <SafesimMultiSelect
              fill={true}
              itemRenderer={(item: string, { modifiers, handleClick }: ItemRendererProps) => {
                if (!modifiers.matchesPredicate) {
                  return null;
                }
                return (
                  <SafesimMenuItem
                    icon={securityMetadata.disseminationControls?.includes(item) ? 'tick' : 'blank'}
                    key={item}
                    onClick={handleClick}
                    text={item}
                    shouldDismissPopover={true}
                  />
                );
              }}
              items={disseminationControlsList}
              itemPredicate={(predicate: string, item: string) => {
                return item?.toLowerCase()?.includes(predicate.toLowerCase());
              }}
              noResults={<SafesimMenuItem disabled={true} text='No results...' />}
              onItemSelect={handleDisseminationControlChange}
              popoverProps={{ minimal: true }}
              tagRenderer={(selection: string) => {
                return selection;
              }}
              tagInputProps={{
                onRemove: handleDisseminationControlRemove,
                rightElement: <SafesimButtonCross onClick={handleDisseminationControlClear} />,
              }}
              selectedItems={securityMetadata.disseminationControls ?? []}
              resetOnSelect={true}
            />
          </SafesimFormGroup>
          {/* SAR FORM GRID*/}
          <SafesimFormGroup label='SAR' labelInfo='(optional)' inline={true}>
            <div className='form-sar-group'>
              <SafesimControlGroup fill={true}>
                <SafesimFormGroup className='pad-right' label='Agency' inline={false}>
                  <SafesimSelect
                    fill={true}
                    round={true}
                    disabled={sarAgencyDisabled}
                    selectOnBlur={false}
                    itemArray={['DOD']}
                    onSelectItem={(item: string) => {
                      handleSARAgencyChange(item);
                    }}
                  />
                </SafesimFormGroup>
                <SafesimFormGroup className='pad-right' label='Classification' inline={false}>
                  <SafesimSelect
                    fill={true}
                    round={true}
                    disabled={sarClassDisabled}
                    selectOnBlur={false}
                    itemArray={classificationList}
                    onSelectItem={(item: string) => {
                      handleSARClassificationChange(item);
                    }}
                  />
                </SafesimFormGroup>
                <SafesimFormGroup label='Marking' inline={false}>
                  <SafesimSelect
                    fill={true}
                    round={true}
                    disabled={sarMarkDisabled}
                    selectOnBlur={false}
                    itemArray={sarList}
                    onSelectItem={(item: string) => {
                      handleSARMarkingChange(item);
                    }}
                  />
                </SafesimFormGroup>
              </SafesimControlGroup>
              <SafesimFormGroup
                label='SAR'
                inline={false}
                helperText={
                  <>
                    {`Select: Agency -> Classification -> Marking`}
                    <br />
                    (Repeat the process to add additional SAR Markings)
                    <br />
                    To remove or change, click the "x" icon and make new selections
                  </>
                }
              >
                <SafesimInputGroup
                  placeholder='SAR'
                  rightElement={
                    <SafesimButtonCross
                      onClick={() => {
                        setSecurityMetadata({ ...securityMetadata, saridentifier: [] });
                        setSarAgencyDisabled(false);
                        setSarClassDisabled(true);
                        setSarMarkDisabled(true);
                      }}
                    />
                  }
                  type='text'
                  value={securityMetadata.saridentifier || []}
                />
              </SafesimFormGroup>
            </div>
          </SafesimFormGroup>
        </div>
        <SafesimButtonGroup className='alignRight'>
          <SafesimButton onClick={handleSubmit}>Save</SafesimButton>
          <SafesimButton onClick={onCancel}>Cancel</SafesimButton>
        </SafesimButtonGroup>
      </SafesimCard>
    </div>
  );
};
