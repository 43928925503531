/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, ModalOverrides } from "baseui/modal";
import * as React from "react";
import { VerticalStack, RightAlignedRow } from "../DesignSystem/Containers";

export function TwoButtonModal({
  children,
  title,
  isOpen,
  positiveButtonText,
  positiveButtonPressed,
  isPositiveButtonDisabled,
  negativeButtonText,
  negativeButtonPressed,
  modalOverrides,
}: {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  positiveButtonText: string;
  positiveButtonPressed: () => void;
  isPositiveButtonDisabled: boolean;
  negativeButtonText: string;
  negativeButtonPressed: () => void;
  modalOverrides?: ModalOverrides;
}): JSX.Element {
  return (
    <Modal isOpen={isOpen} onClose={negativeButtonPressed} overrides={modalOverrides}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <VerticalStack>{React.Children.toArray(children)}</VerticalStack>
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton kind="tertiary" onClick={negativeButtonPressed}>
            {negativeButtonText}
          </ModalButton>
          <ModalButton kind={"primary"} disabled={isPositiveButtonDisabled} onClick={positiveButtonPressed}>
            {positiveButtonText}
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
}
