/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { Button, ButtonOverrides } from "baseui/button";
import { SecurityMarkingsModal } from "./SecurityMarkingsModal";
import { SecurityMarkings } from "Api";

const styleOverrides: ButtonOverrides = {
  BaseButton: {
    style: {
      width: "9rem",
    },
  },
};

export const EditMarkingsButton = ({
  handleSecurityMarkingModalSave,
}: {
  handleSecurityMarkingModalSave: (securityMarkings: SecurityMarkings) => void;
}): JSX.Element => {
  const [securityMarkings, setSecurityMarkings] = React.useState<SecurityMarkings>(null);

  const handleEditMarkingsClick = React.useCallback(() => {
    setEditMarkingsIsOpen(true);
  }, []);

  const [editMarkingsIsOpen, setEditMarkingsIsOpen] = React.useState(false);

  return (
    <>
      <Button kind="tertiary" overrides={styleOverrides} onClick={handleEditMarkingsClick}>
        {"Edit markings"}
      </Button>
      <SecurityMarkingsModal
        securityMarkings={securityMarkings}
        editorVisibleState={editMarkingsIsOpen}
        setEditorVisible={setEditMarkingsIsOpen}
        onMarkingsChanged={(newSecurityMarkings) => setSecurityMarkings(newSecurityMarkings)}
        onSaveClicked={(newSecurityMarkings) => {
          handleSecurityMarkingModalSave(newSecurityMarkings);
          setSecurityMarkings(null);
        }}
        showSaveButton={true}
      ></SecurityMarkingsModal>
    </>
  );
};
