import { useEffect, useRef } from 'react';

/**
 * React hook that executes the provided callbacks on component mount/unmount events
 * @param {{ onMounted: (()=>void)|undefined, onUnmount: (()=>void)|undefined }} options
 */
export const useMountEvents = ({ onMounted, onUnmount } = {}) => {
  const onMountedRef = useRef(onMounted);
  const onUnmountRef = useRef(onUnmount);

  useEffect(() => {
    onMountedRef.current = onMounted;
  }, [onMounted]);

  useEffect(() => {
    onUnmountRef.current = onUnmount;
  }, [onUnmount]);

  useEffect(() => {
    onMountedRef.current?.();
    return () => {
      onUnmountRef.current?.();
    };
  }, []);
};