/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FormControl } from "baseui/form-control";
import { Select, Option } from "baseui/select";
import { ClassificationLevel, CompartmentMarking, SecurityMarkings } from "../Api/Api";
import { classificationOptionsAsText } from "../Api/ApiSerialization";
import { EndAnchoredRow, Row, VerticalStack } from "../DesignSystem/Containers";
import { LabelMedium, LabelSmall } from "baseui/typography";
import { Button } from "baseui/button";
import { removeBy, replaceBy } from "../Utils/Array";
import { Delete } from "baseui/icon";
import { KmButtons } from "../DesignSystem/KmButtons";
import { primitives } from "../DesignSystem/LightTheme";
import { useReactiveVar } from "@apollo/client";
import { appConfigurationsVar } from "../GlobalState";
import {
  ClassificationComboOption,
  ClassificationOption,
  getClassificationComboOptions,
  getClassificationOptionFromMarkings,
  getUnusedCompartmentOptions,
} from "./Security";
import { Icon, IconSize } from "@blueprintjs/core";
import { Input } from "baseui/input";
import { isRecordEmpty } from "../Utils/Record";
import { descendingCompartments } from "../Api/ApiExtensions";
import { UserContext } from "../Utils/UserContext";
import { isPermittedForAllUsers } from "../Utils/Auth";
import { SecurityMarkingsModal } from "./SecurityMarkingsModal";
import { useMarkingsDisableSave } from "./ApiHooks";

// TODO: This file is pretty big; consider refactor to simplify.
export const ClassificationSummary = ({
  securityMarkings,
  label,
  onMarkingsChanged,
}: {
  securityMarkings?: SecurityMarkings;
  label?: React.ReactNode;
  onMarkingsChanged?: (newValue: SecurityMarkings) => void;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const [editorVisible, setEditorVisible] = React.useState(false);

  const clearanceLevelText = classificationOptionsAsText(
    securityMarkings?.classificationLevel,
    isSensitiveSecurityContext
  );

  const hasCompartments = securityMarkings?.compartments && securityMarkings?.compartments.length > 0;
  const summaryText = hasCompartments ? `${clearanceLevelText} (compartmented)` : clearanceLevelText;

  // If the user of the component didnt set a label default it to classification.
  const labelElement = label ?? "Classification";

  const markingsDisableSave = useMarkingsDisableSave(securityMarkings);

  // TODO: implement colors from theme
  const securityEditIconColor = markingsDisableSave ? "#ff0000" : "blue";

  return (
    <>
      <FormControl label={labelElement}>
        <Row gap="1rem">
          <Input value={summaryText ?? ""} disabled />
          <Button
            size="large"
            kind={"tertiary"}
            overrides={
              (KmButtons.style.icon, { Root: { props: { "data-testid": "open-security-markings-modal-button" } } })
            }
            onClick={() => setEditorVisible(true)}
          >
            <Icon
              icon="take-action"
              size={IconSize.LARGE}
              title={"Edit security markings"}
              style={{ marginLeft: "0.7rem", marginRight: "0.6rem" }}
              color={securityEditIconColor}
            />
          </Button>
        </Row>
      </FormControl>
      <SecurityMarkingsModal
        editorVisibleState={editorVisible}
        setEditorVisible={setEditorVisible}
        securityMarkings={securityMarkings}
        onMarkingsChanged={onMarkingsChanged}
      ></SecurityMarkingsModal>
    </>
  );
};

export const SecurityMarkingsEditor = ({
  securityMarkings,
  onMarkingsChanged,
}: {
  securityMarkings: SecurityMarkings;
  onMarkingsChanged?: (newMarkings: SecurityMarkings) => any;
}): JSX.Element => {
  const userClearanceLevel = React.useContext(UserContext).clearanceInfo.getMaxClearance();

  const selectedClassificationOption = getClassificationOptionFromMarkings(securityMarkings);

  const handleMarkingsChanged = React.useCallback(
    (propertyName, newValue) => {
      if (!onMarkingsChanged) {
        return;
      }
      const newMarkings: SecurityMarkings = {
        ...securityMarkings,
      };
      if (newValue) {
        if (propertyName === "classificationLevel") {
          if (newValue == "U") {
            newMarkings[propertyName] = newValue;
            newMarkings["compartments"] = [];
          } else if (newValue === "FOUO" || newValue === "CUI") {
            const unclassCompartment: CompartmentMarking = {
              classificationLevel: ClassificationLevel.UNCLASSIFIED,
              categoryToCompartments: { [newValue]: [] },
            };
            newMarkings["compartments"] = [unclassCompartment];
            newMarkings["classificationLevel"] = ClassificationLevel.UNCLASSIFIED;
          } else {
            newMarkings["classificationLevel"] = newValue;
            newMarkings["compartments"] = newMarkings["compartments"]?.filter(
              (compartment) => compartment?.classificationLevel != ClassificationLevel.UNCLASSIFIED
            );
          }
        } else {
          newMarkings[propertyName] = newValue;
        }
      } else {
        delete newMarkings[propertyName];
        if (propertyName === "classificationLevel") {
          if (newMarkings["disseminationControls"]) {
            delete newMarkings.disseminationControls;
          }
        }
      }
      onMarkingsChanged(newMarkings);
    },
    [onMarkingsChanged, securityMarkings]
  );

  const orderedCompartments = descendingCompartments(securityMarkings?.compartments);

  return (
    <VerticalStack>
      <ClassificationLevelEditor
        selectedClassificationOption={selectedClassificationOption}
        onClassificationChanged={(value) => handleMarkingsChanged("classificationLevel", value)}
      />
      {securityMarkings?.classificationLevel && (
        <DisseminationControlsEditor
          selectedOptions={securityMarkings?.disseminationControls}
          onSelectionChanged={(newSelection) => handleMarkingsChanged("disseminationControls", newSelection)}
        />
      )}
      {userClearanceLevel != ClassificationLevel.UNCLASSIFIED &&
        securityMarkings?.classificationLevel &&
        !isPermittedForAllUsers(securityMarkings?.classificationLevel) && (
          <CompartmentListEditor
            compartments={orderedCompartments}
            onCompartmentsChanged={(value) => {
              handleMarkingsChanged("compartments", value);
            }}
          />
        )}
    </VerticalStack>
  );
};

function DisseminationControlsEditor({
  selectedOptions,
  onSelectionChanged,
}: {
  selectedOptions?: string[];
  onSelectionChanged: (newSelection: string[]) => void;
}): JSX.Element {
  const appConfigurations = useReactiveVar(appConfigurationsVar);

  const allDisseminationControlsOption = appConfigurations.disseminationControls ?? [];

  const comboOptions = allDisseminationControlsOption.map((disseminationOption) => {
    return {
      id: disseminationOption,
      label: disseminationOption,
    };
  });

  const selectedDisseminationControls = selectedOptions ?? [];
  const comboSelections = comboOptions.filter((option) => selectedDisseminationControls.includes(option.id));

  return (
    <FormControl label={"Dissemination Controls"}>
      <Select
        options={comboOptions}
        onChange={(params) => onSelectionChanged(params.value.map((comboItem) => comboItem.id?.toString() ?? ""))}
        value={comboSelections}
        multi
        overrides={{
          ControlContainer: {
            style: {
              minWidth: "40rem",
            },
          },
          ValueContainer: {
            props: {
              "data-testid": "dissemination-controls-selector",
            },
          },
          DropdownContainer: {
            props: {
              "data-testid": `dissemination-controls-dropdown`,
            },
          },
        }}
      />
    </FormControl>
  );
}

export const ClassificationLevelEditor = ({
  selectedClassificationOption,
  onClassificationChanged,
}: {
  selectedClassificationOption: ClassificationOption;
  onClassificationChanged?: (newValue: ClassificationOption) => void;
}): JSX.Element => {
  const userClearanceLevel = React.useContext(UserContext).clearanceInfo.getMaxClearance();

  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;
  const markingsRequired = appConfigurations.markingsRequired;

  const classificationOption = React.useMemo(() => {
    return getClassificationComboOptions(isSensitiveSecurityContext, userClearanceLevel);
  }, [isSensitiveSecurityContext, userClearanceLevel]);

  // TODO: This feels like a lot of code for what it's doing.
  // There is probably a simpler way.
  const classificationOptionMatch = classificationOption.find((option) => option.id === selectedClassificationOption);
  const classificationOptionSelected: Option[] = classificationOptionMatch ? [classificationOptionMatch] : [];

  return (
    <FormControl label={"Classification"}>
      <Select
        options={classificationOption}
        labelKey="label"
        valueKey="id"
        onChange={(value) => onClassificationChanged(value?.option?.id as ClassificationOption)}
        value={classificationOptionSelected}
        required={markingsRequired}
        overrides={{
          ControlContainer: {
            style: {
              minWidth: "40rem",
            },
          },
          ValueContainer: {
            props: {
              "data-testid": "classification-level-selector",
            },
          },
          DropdownContainer: {
            props: {
              "data-testid": `classification-level-dropdown`,
            },
          },
        }}
      />
    </FormControl>
  );
};

const CONTROLLED_LEVELS = [
  ClassificationLevel.CONFIDENTIAL,
  ClassificationLevel.SECRET,
  ClassificationLevel.TOP_SECRET,
];

const CompartmentListEditor = ({
  compartments,
  onCompartmentsChanged,
}: {
  compartments: CompartmentMarking[];
  onCompartmentsChanged: (newCompartments: CompartmentMarking[]) => void;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;
  const clearanceInfo = React.useContext(UserContext).clearanceInfo;
  const userClearanceLevel = clearanceInfo.getMaxClearance();

  const missingClassificationOptions = React.useMemo<ClassificationComboOption[]>(() => {
    const assignedCompartments =
      compartments.map((value) => value.classificationLevel).filter((value) => CONTROLLED_LEVELS.includes(value)) ?? [];

    return getUnusedCompartmentOptions(assignedCompartments, isSensitiveSecurityContext, userClearanceLevel);
  }, [compartments, isSensitiveSecurityContext, userClearanceLevel]);

  const initialSelection = React.useMemo<ClassificationComboOption[]>(() => {
    return missingClassificationOptions?.length > 0 ? [missingClassificationOptions[0]] : [];
  }, [missingClassificationOptions]);

  const [selectedClassification, setSelectedClassification] =
    React.useState<ClassificationComboOption[]>(initialSelection);

  const handleAddCompartment = React.useCallback(() => {
    const addedOption = selectedClassification[0];
    const updatedCompartments = compartments ? [...compartments] : [];
    const newCompartmentMarking = clearanceInfo.getDefaultCompartment(addedOption.classificationOption);

    updatedCompartments.push(newCompartmentMarking);
    onCompartmentsChanged(updatedCompartments);

    const remainingOptions = removeBy(
      missingClassificationOptions,
      [addedOption],
      (option) => option.classificationOption
    );
    const newSelection = remainingOptions?.length > 0 ? [remainingOptions[0]] : [];
    setSelectedClassification(newSelection);
  }, [
    selectedClassification,
    clearanceInfo,
    compartments,
    onCompartmentsChanged,
    setSelectedClassification,
    missingClassificationOptions,
  ]);

  const handleCompartmentChange = React.useCallback(
    (isDelete, compartment) => {
      const updatedCompartments = isDelete
        ? removeBy(compartments, [compartment], (compartment) => compartment.classificationLevel)
        : replaceBy(compartments, [compartment], (compartment) => compartment.classificationLevel);
      onCompartmentsChanged(updatedCompartments);
    },
    [compartments, onCompartmentsChanged]
  );

  return (
    <VerticalStack>
      <EndAnchoredRow style={{ width: "45rem" }}>
        <LabelSmall $style={{ paddingTop: "1rem" }}>{"Compartments"}</LabelSmall>
        <Row>
          <div style={{ width: "12rem" }}>
            <Select
              options={missingClassificationOptions.filter((element) => element.classificationOption)}
              multi={false}
              labelKey="label"
              valueKey="id"
              onChange={(change) => setSelectedClassification(change.value as ClassificationComboOption[])}
              value={selectedClassification}
              overrides={{
                ValueContainer: {
                  props: {
                    "data-testid": "compartment-level-selector",
                  },
                },
                DropdownContainer: {
                  props: {
                    "data-testid": `compartment-level-dropdown`,
                  },
                },
              }}
            />
          </div>
          <Button kind="tertiary" disabled={selectedClassification?.length == 0} onClick={handleAddCompartment}>
            {"Add"}
          </Button>
        </Row>
      </EndAnchoredRow>
      {compartments?.map((compartment) => (
        <ClassifiedCategories
          key={compartment.classificationLevel}
          onChange={handleCompartmentChange}
          compartment={compartment}
          categoryCompartmentOptions={clearanceInfo.getCompartmentsAtLevel(compartment.classificationLevel)}
        />
      ))}
    </VerticalStack>
  );
};

function applyNewMarkings(
  existingCompartment: CompartmentMarking,
  oldCategoryName: string,
  newCategoryName: string,
  newCategoryCompartments: string[]
): CompartmentMarking {
  const categoryToCompartments: Record<string, string[]> = {
    ...existingCompartment?.categoryToCompartments,
  };

  if (oldCategoryName !== newCategoryName) {
    delete categoryToCompartments[oldCategoryName];
  }

  if (newCategoryName) {
    categoryToCompartments[newCategoryName] = newCategoryCompartments;
  }

  const newCompartment: CompartmentMarking = {
    ...existingCompartment,
    categoryToCompartments: categoryToCompartments,
  };
  return newCompartment;
}

const ClassifiedCategories = ({
  compartment,
  categoryCompartmentOptions,
  onChange,
}: {
  compartment: CompartmentMarking;
  categoryCompartmentOptions: Record<string, string[]>;
  onChange: (isDelete: boolean, compartment?: CompartmentMarking) => void;
}): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const handleDelete = React.useCallback(() => {
    onChange(true, compartment);
  }, [compartment, onChange]);

  const handleCategoryCompartmentsChanged = React.useCallback(
    (oldCategoryName: string, newCategoryName: string, newCategoryCompartments: string[]) => {
      const newCompartment = applyNewMarkings(compartment, oldCategoryName, newCategoryName, newCategoryCompartments);
      const newCategories = Object.keys(newCompartment.categoryToCompartments);
      const isDelete = !Array.isArray(newCategories) || !newCategories.length;
      onChange(isDelete, newCompartment);
    },
    [compartment, onChange]
  );

  const existingCategories = Object.keys(compartment.categoryToCompartments ?? {});
  const missingCategories = Object.keys(categoryCompartmentOptions).filter(
    (categoryOption) => !existingCategories.includes(categoryOption)
  );

  const handleAddCategory = React.useCallback(() => {
    const newCategoryName = missingCategories[0];

    const newCompartment = applyNewMarkings(compartment, null, newCategoryName, []);
    onChange(false, newCompartment);
  }, [compartment, missingCategories, onChange]);

  return (
    <VerticalStack>
      <EndAnchoredRow
        style={{
          height: "2.5rem",
          backgroundColor: primitives.subtleBackground,
        }}
      >
        <LabelSmall
          $style={{
            padding: "0.75rem 0rem 0rem 0.75rem",
          }}
        >
          {classificationOptionsAsText(compartment.classificationLevel, isSensitiveSecurityContext)}
        </LabelSmall>
        <Button kind="tertiary" onClick={handleDelete} overrides={KmButtons.style.subtle}>
          <Delete size={24} />
        </Button>
      </EndAnchoredRow>
      {isRecordEmpty(categoryCompartmentOptions) ? (
        <LabelMedium>{"No compartment options available."}</LabelMedium>
      ) : (
        <>
          {existingCategories.map((existingCategory) => (
            <CategoryCompartments
              key={existingCategory}
              categoryOptions={missingCategories.concat(existingCategory)}
              selectedCategory={existingCategory}
              compartmentOptions={categoryCompartmentOptions[existingCategory]}
              compartmentSelections={compartment.categoryToCompartments[existingCategory]}
              onCategoryCompartmentsChanged={handleCategoryCompartmentsChanged}
            />
          ))}
          {missingCategories.length > 0 && (
            <Row>
              <Button kind="tertiary" onClick={handleAddCategory}>
                {"+ Add Compartment"}
              </Button>
            </Row>
          )}
        </>
      )}
    </VerticalStack>
  );
};

// TODO: Look into using this logic elsewhere.
function toOptions(values: string[]): Option[] {
  return (values ?? []).map((value) => {
    const valueOption: Option = {
      id: value,
      label: value,
    };
    return valueOption;
  });
}

const CategoryCompartments = ({
  categoryOptions,
  selectedCategory,
  compartmentOptions,
  compartmentSelections,
  onCategoryCompartmentsChanged,
}: {
  categoryOptions: string[];
  selectedCategory: string;
  compartmentOptions?: string[];
  compartmentSelections?: string[];
  onCategoryCompartmentsChanged: (
    oldCategoryName: string,
    newCategoryName: string,
    newCategoryCompartments: string[]
  ) => void;
}): JSX.Element => {
  const categoryComboOptions = toOptions(categoryOptions);
  const selectedCategoryOptions = categoryComboOptions.filter(
    (categoryOption) => categoryOption.id === selectedCategory
  );

  const handleCategorySelected = React.useCallback(
    (categorySelections) => {
      const newCategory = categorySelections.map((item) => item.id)[0];
      onCategoryCompartmentsChanged(selectedCategory, newCategory, []);
    },
    [selectedCategory, onCategoryCompartmentsChanged]
  );

  const compartmentNameComboOptions = toOptions(compartmentOptions);
  const selectedCompartmentOptions = compartmentSelections
    ? compartmentNameComboOptions.filter((option) => compartmentSelections.includes(option.id.toString()))
    : [];

  const handleCompartmentNameSelected = React.useCallback(
    (compartmentNameSelections) => {
      const selectedCompartments = compartmentNameSelections.map((item) => item.id);
      onCategoryCompartmentsChanged(selectedCategory, selectedCategory, selectedCompartments);
    },
    [selectedCategory, onCategoryCompartmentsChanged]
  );

  return (
    <Row gap="2rem">
      <div style={{ width: "8rem" }}>
        <Select
          options={categoryComboOptions}
          multi={false}
          labelKey="label"
          valueKey="id"
          onChange={(changeParams) => handleCategorySelected(changeParams.value)}
          value={selectedCategoryOptions}
          overrides={{
            ValueContainer: {
              props: {
                "data-testid": "category-selector",
              },
            },
            DropdownContainer: {
              props: {
                "data-testid": `category-dropdown`,
              },
            },
          }}
        />
      </div>
      <div style={{ width: "35rem" }}>
        <Select
          options={compartmentNameComboOptions}
          multi={true}
          labelKey="label"
          valueKey="id"
          onChange={(changeParams) => handleCompartmentNameSelected(changeParams.value)}
          value={selectedCompartmentOptions}
          overrides={{
            ValueContainer: {
              props: {
                "data-testid": "category-name-selector",
              },
            },
            DropdownContainer: {
              props: {
                "data-testid": `category-name-dropdown`,
              },
            },
          }}
        />
      </div>
    </Row>
  );
};
