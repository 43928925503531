/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { HeadingLarge } from "baseui/typography";
import React from "react";
import { useLocation } from "react-router-dom";

export function PageNotFound(): JSX.Element {
  const location = useLocation();

  return (
    <>
      <HeadingLarge>
        Couldn&apos;t find the page <code>{location.pathname}</code>
      </HeadingLarge>
    </>
  );
}
