/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";

export function ContentPage({ children }: { children?: React.ReactNode }): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "1rem 1rem 1rem 1rem",
        overflow: "auto",
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
}
