import React from 'react';
import PropTypes from 'prop-types';
import { SafesimCard, SafesimButton, SafesimButtonGroup, SafesimOverlaySpinner } from './allSafesimComponents';

/**
 * functional component that wraps children elements in a card.
 * Submit and Cancel button (configurable) are also provided by default as well as method to invoke when the cancel button is pressed
 * @param {*} props
 * submitButtonText - string to use as the submit button text
 * cancelButtonText - string to use as the cancel button text
 * onSubmit - (data)=>{} function invoked when the user clicks the submit button
 * onCancel - ()=>{} function invoked when the cancel button is clicked
 * @returns JSX Element that wraps a child form in a card with submit & cancel buttons
 */
function SafesimSubmissionWrapper(props) {
  const {
    showSpinner,
    spinnerElement,
    submitButtonText,
    submitButtonDisabled,
    submitButtonHidden,
    submitButtonTooltipText,
    submitButtonTooltipProps,
    cancelButtonText,
    cancelButtonDisabled,
    cancelButtonHidden,
    cancelButtonTooltipText,
    cancelButtonTooltipProps,
    onSubmit,
    onCancel,
    children,
    className,
    title,
    ...rest
  } = props;

  return (
    <SafesimOverlaySpinner isOpen={showSpinner} element={spinnerElement} className={className} {...rest}>
      <SafesimCard className={'submissionWrapper ' + className} {...rest}>
        <h3 className='formTitle'>{title}</h3>
        <div className='submissionWrapperChildren'>{children}</div>
        <div className={className + ' alignRight '}>
          <SafesimButtonGroup>
            {!cancelButtonHidden && (
              <SafesimButton
                id='SafesimSubmissionWrapper-submit-cancel'
                tooltipText={cancelButtonTooltipText}
                tooltipProps={cancelButtonTooltipProps}
                name='cancel'
                disabled={cancelButtonDisabled}
                minimal={true}
                onClick={onCancel}
                text={cancelButtonText}
              />
            )}
            {!submitButtonHidden && (
              <SafesimButton
                id='SafesimSubmissionWrapper-submit-button'
                tooltipText={submitButtonTooltipText}
                tooltipProps={submitButtonTooltipProps}
                name='submit'
                disabled={submitButtonDisabled}
                onClick={onSubmit}
                text={submitButtonText}
              />
            )}
          </SafesimButtonGroup>
        </div>
      </SafesimCard>
    </SafesimOverlaySpinner>
  );
}
SafesimSubmissionWrapper.propTypes = {
  submitButtonText: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  submitButtonHidden: PropTypes.bool,
  submitButtonTooltipText: PropTypes.bool,
  submitButtonTooltipProps: PropTypes.any,
  cancelButtonText: PropTypes.string,
  cancelButtonDisabled: PropTypes.bool,
  cancelButtonHidden: PropTypes.bool,
  cancelButtonTooltipText: PropTypes.string,
  cancelButtonTooltipProps: PropTypes.any,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.any,
  showSpinner: PropTypes.bool,
};
export { SafesimSubmissionWrapper };
