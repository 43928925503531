/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { SecurityMetadataSelector } from "@radiance/safesim_component_library";
import React from "react";
import { deepEquals } from "./Comparison";
import { toSafeSimSecurityMetadata, toSimorSecurityMarkings } from "./SafeSimDataHelpers";
import { SecurityMarkings } from "Api";
import { UserContext } from "../Utils/UserContext";
import { getPid, getPidFormat } from "../Shared/Security";

export function SafeSimMarkingControl({
  securityMarkings,
  isSensitiveSecurityContext,
  disseminationControlOptions,
  onMarkingsChanged,
  onSaveClicked,
  closeEditor,
}: {
  securityMarkings: SecurityMarkings;
  isSensitiveSecurityContext: boolean;
  disseminationControlOptions: string[];
  onMarkingsChanged?: (newMarkings: SecurityMarkings) => any;
  onSaveClicked?: (newMarkings: SecurityMarkings) => any;
  closeEditor: () => void;
}): JSX.Element {
  const userClearance = React.useContext(UserContext).clearanceInfo;

  const sarCompartmentStrings = userClearance.getSpecificCompartmentsAsString("SAR");
  const pidFormat = getPidFormat(sarCompartmentStrings[0]);
  const sarPidStrings = sarCompartmentStrings.map((compartment: string) => getPid(compartment, pidFormat));

  const sortedDisseminationControls = [...disseminationControlOptions].sort((a, b) => a.localeCompare(b));

  return (
    <div aria-label="SafeSimMarkingDiv">
      <SecurityMetadataSelector
        securityMetadataObject={toSafeSimSecurityMetadata(securityMarkings, isSensitiveSecurityContext)}
        onSubmit={(securityMetadata) => {
          const markingsChanged = !deepEquals(securityMetadata, toSafeSimSecurityMetadata(securityMarkings));
          if (onMarkingsChanged && markingsChanged) {
            securityMarkings = toSimorSecurityMarkings(securityMetadata, sarCompartmentStrings);
            onMarkingsChanged(securityMarkings);
            if (onSaveClicked) {
              onSaveClicked(securityMarkings);
            }
            closeEditor();
          }
        }}
        onCancel={closeEditor}
        classificationList={userClearance.getHumanReadableClearance(isSensitiveSecurityContext)}
        sciList={userClearance.getSpecificCompartmentsAsString("SCI")}
        sarList={sarPidStrings}
        disseminationControlsList={sortedDisseminationControls}
      />
    </div>
  );
}
