# import "./fragments/fileFoldersData.gql"

mutation RescanPlatformFiles($platformId: UUID!) {
  rescanPlatformFiles(platformId: $platformId) {
    updatedPlatform {
      id
      creationDate
      lastModifiedDate
      metadata {
        title
        version
        description
        keywords
        subject
        notes
        customProps
      }
      fileFolders {
        __typename
        ...fileFoldersData
      }
    }
    hasChanged
  }
}
