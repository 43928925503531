# import "./fragments/securityMarkingsData.gql"
# import "./fragments/fileFoldersData.gql"

mutation UpdateStudy($study: StudyInput!) {
  updateStudy(study: $study) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      description
      version
      keywords
      notes
      customProps
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
  }
}
