import { BPVersion } from './BPVersion';

// Assign Blueprint ClassNames to a safesim alias.
// If the BP className changes, we should only need to update it here

// ClassNames are used as space-delimited strings, so always surround alias with spaces

export const safesimIntentClass = ` ${BPVersion}-intent-safesim `;
export const safesimInputGroupClass = ` ${BPVersion}-input-group `;
export const safesimFillClass = ` ${BPVersion}-fill `;
export const safesimDarkClass = ` ${BPVersion}-dark `;
export const safesimPopoverContentSizing = ` ${BPVersion}-popover2-content-sizing `;
