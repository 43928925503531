import { useState, useCallback } from 'react';

/**
 * React hook that provides state management for a submission dialog
 *
 * @param {{
 *  onSubmitError: (error:Error,...args:any)=>(boolean|void),
 *  onSubmitSuccess: (data:any,...args:any)=>(boolean|void),
 *  onCancel: ()=>(boolean|void) }} options
 * @returns {{
 *  showDialog: function():void,
 *  isOpen: boolean,
 *  setIsOpen: function(boolean):void,
 *  dialogProps: {
 *    isOpen: boolean,
 *    onCancel: ()=>void,
 *    onSubmitSuccess: (data:any,...args:any)=>void
 *    onSubmitError: (error:Error,...args:any)=>void,
 *  }
 * }}
 */
export const useSubmissionFormDialogState = ({ onSubmitError: onSubmitErrorExt, onSubmitSuccess: onSubmitSuccessExt, onCancel: onCancelExt } = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const showDialog = useCallback(() => setIsOpen(true), []);
  const closeDialog = useCallback(() => setIsOpen(false), []);

  const onCancel = useCallback((...args) => onCancelExt?.(...args) !== false && closeDialog(), [onCancelExt, closeDialog]);
  const onSubmitError = useCallback((...args) => onSubmitErrorExt?.(...args), [onSubmitErrorExt]);
  const onSubmitSuccess = useCallback((...args) => onSubmitSuccessExt?.(...args) !== false && closeDialog(), [onSubmitSuccessExt, closeDialog]);

  return { showDialog, isOpen, setIsOpen, dialogProps: { isOpen, onCancel, onSubmitSuccess, onSubmitError } };
};