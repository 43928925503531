/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { createContext, PropsWithChildren, useMemo } from "react";
import { SimorAuth } from "./Auth";
import axios from "axios";
import { SiteContext } from "./SiteProps";
import { cache } from "../GlobalState";
import KeycloakService from "./KeycloakService";
import { UserProvider } from "./UserContext";
import { setContext } from "@apollo/client/link/context";

export const SimorAuthContext = createContext(new SimorAuth(false, null, false));

export const AxiosContext = createContext(axios.create());

export function AuthProvider({ children }: PropsWithChildren<any>): JSX.Element {
  const siteProps = React.useContext(SiteContext);
  const wasPreviouslyAuthorized = isAuthValid(siteProps.useKeycloak);
  const initialToken = siteProps.useKeycloak ? KeycloakService.authTokenRaw() : "";

  const [authToken, setAuthToken] = React.useState<string>(initialToken);
  const handleUpdateAuthToken = React.useCallback(
    (newAuthToken: string, expiresAtUtcMillis?: number) => {
      setAuthToken(newAuthToken);
      setStorageAuthExpirationTime(expiresAtUtcMillis);
    },
    [setAuthToken]
  );

  const managedAuth = useMemo(() => {
    return new SimorAuth(siteProps.useKeycloak, authToken, authToken ? true : false, handleUpdateAuthToken);
  }, [siteProps.useKeycloak, authToken, handleUpdateAuthToken]);

  const managedAxios = useMemo(() => {
    const authHeaders = {
      Authorization: `Bearer ${authToken}`,
    };

    const axiosInstance = axios.create({
      headers: authHeaders,
    });

    // TODO: This causes problems with the file upload.  It may still be needed
    // to keep the token alive when doing downloads.
    // axiosInstance.interceptors.request.use(
    //   async (config) => {
    //     // TODO: If this works we may need to do something with the fallback
    //     // auth since it does not check the expiration before doing a refresh.
    //     const tokenRefreshed = await managedAuth.tryRefreshAuthToken();

    //     // If the token changed use the new one.
    //     if (tokenRefreshed) {
    //       config.headers["Authorization"] = `Bearer ${KeycloakService.authTokenRaw}`;
    //     }

    //     return config;
    //   },
    //   (error) => {
    //     Promise.reject(error);
    //   }
    // );

    return axiosInstance;
  }, [authToken]);

  React.useEffect(() => {
    if (wasPreviouslyAuthorized) {
      managedAuth.tryRefreshAuthToken(true);
    }
    // Run with an empty dependencies array to ensure this only runs once
    // to load the expected state from local storage (if auth should be valid).

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const client = useMemo(() => {
    const batchHttpLink = new BatchHttpLink({
      uri: `${siteProps.topUrl}/graphql`,
      batchMax: 5, // No more than 5 operations per batch
      batchInterval: 20, // Wait no more than 20ms after first batched operation
      credentials: "include",
    });

    const authMiddleware = setContext(async (req, { headers }) => {
      await managedAuth.tryRefreshAuthToken(true);

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`,
        },
      };
    });

    const typenameMiddleware = new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = removeTypeName(operation.variables);
      }
      return forward(operation);
    });

    const sanitizedAppLink = ApolloLink.from([typenameMiddleware, authMiddleware, batchHttpLink]);

    return new ApolloClient({
      link: sanitizedAppLink,
      cache: cache,
    });
  }, [authToken, managedAuth, siteProps.topUrl]);

  return (
    <SimorAuthContext.Provider value={managedAuth}>
      <AxiosContext.Provider value={managedAxios}>
        <ApolloProvider client={client}>
          <UserProvider>{children}</UserProvider>
        </ApolloProvider>
      </AxiosContext.Provider>
    </SimorAuthContext.Provider>
  );
}

// Auth is not stored in local storage; it set in an http only cookie.
// Local storage is used to give you a reasonable guess about
// whether or not you should expect to be able to re-establish
// an authenticated session.
function isAuthValid(useKeycloak: boolean): boolean {
  const authExpirationTime = getStorageAuthExpirationTime();

  if (!authExpirationTime) {
    return false;
  }

  if (useKeycloak) {
    // Keycloak JWT expiration is in Unix epoch time.  Convert to a regular date.
    return new Date(authExpirationTime * 1000).getTime() - Date.now() > 0;
  } else {
    return authExpirationTime - Date.now() > 0;
  }
}

function setStorageAuthExpirationTime(expiresAtUtcMillis?: number): void {
  if (expiresAtUtcMillis) {
    localStorage.setItem("authExpirationTime", expiresAtUtcMillis.toString());
  } else {
    localStorage.removeItem("authExpirationTime");
  }
}

function getStorageAuthExpirationTime(): number | undefined {
  const authExpirationTimeText = localStorage.getItem("authExpirationTime");
  if (!authExpirationTimeText) {
    return undefined;
  }
  const sessionExpirationTime = parseInt(authExpirationTimeText);

  if (Number.isNaN(sessionExpirationTime)) {
    return undefined;
  }

  return sessionExpirationTime;
}

// List of object types we want to skip sanitization. This mostly will be
// Union type objects, as the __typename field is the main avenue for
export const typesToSkip = ["AssetFileFolder"];

// code came from https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-1363589267
// removing __typename from queries seems to be a hot topic on the apollo blog. this thread has
// a fair amount of useful information about the shape of the apollo query/mutation objects as well.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeTypeName(objectToSanitize: any): any {
  if (!objectToSanitize || typeof objectToSanitize !== "object") return objectToSanitize;

  const newObject = {};

  // iterate through objects attributes
  Object.keys(objectToSanitize).map((key) => {
    //add attribute to new object if key contains object type to be skipped
    if (!typesToSkip.includes(key)) {
      if (typeof objectToSanitize[key] === "object") {
        //recursive call if attribute is another object
        newObject[key] = removeTypeName(objectToSanitize[key]);
      }

      //check array of objects and remove typename from any objects within the array
      if (Array.isArray(objectToSanitize[key])) {
        newObject[key] = [];
        objectToSanitize[key].map((item) => {
          if (typeof item === "object") {
            newObject[key].push(removeTypeName(item));
          } else {
            newObject[key].push(item);
          }
          return item;
        });
      }

      //if type is primitive, not an array, and is not __typename, write to new data object
      if (typeof objectToSanitize[key] !== "object" && !Array.isArray(objectToSanitize[key]) && key !== "__typename") {
        newObject[key] = objectToSanitize[key];
      }
    } else {
      newObject[key] = objectToSanitize[key];
    }
  });

  return newObject;
}
