/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { AssetInput } from "../../Api/ApiExtensions";
import { OperationOutcome, failureOutcome, successOutcome } from "../../Shared/OperationOutcome";
import { isBlank } from "../../Utils/Strings";
import { AssetDetailsState, AssetDetailsPageAction } from "./AssetDetailsState";
import { FieldAdder } from "./FieldAdder";
import { FieldSpec } from "./FieldSpec";
import MetadataPropsEditor from "./MetadataPropsEditor";

// TODO: Is there a better name for this?
export const MetadataFieldsAddEditor = ({
  state,
  dispatch,
}: {
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element => {
  const verifyCanAddField = React.useCallback(
    (newField: FieldSpec): OperationOutcome => {
      if (isBlank(newField.propertyName)) {
        return failureOutcome("Field name may not be blank or only whitespace");
      }

      const lowerCaseNewFieldName = newField.propertyName.toLowerCase();

      const existingFieldsWithName = state.fields.all
        .map((field) => field.propertyName.toLowerCase())
        .filter((lowerCaseFieldName) => lowerCaseFieldName === lowerCaseNewFieldName);

      return existingFieldsWithName.length > 0
        ? failureOutcome(`There is already a field named '${newField.propertyName}'`)
        : successOutcome();
    },
    [state.fields.all]
  );

  const handleAddField = React.useCallback(
    (newField: FieldSpec) => {
      const addOutcome = verifyCanAddField(newField);
      if (addOutcome.successful) {
        dispatch(["addProperty", newField]);
      }
      return addOutcome;
    },
    [verifyCanAddField, dispatch]
  );
  return (
    <>
      <MetadataPropsEditor
        fields={state.fields.all}
        metadataValues={state.metadataValues}
        onValueChange={(propertyName, value) => dispatch(["editProperty", { propertyName, value }])}
        onPropertyDelete={(propertyName) => dispatch(["deleteProperty", propertyName])}
      />
      <FieldAdder canAddField={verifyCanAddField} onAddField={handleAddField} />
    </>
  );
};
