import { useCallback } from 'react';
import { triggerEvent } from '../../utilities/events';

/** Event type that is used to request content in the HomepageSidebarDrawer to be changed */
export const EVENT_TOASTER_NEW_TOAST = 'SAFESIM/EVENTS/EVENT_TOASTER_NEW_TOAST';

/**
 * Triggers a EVENT_TOASTER_NEW_TOAST event
 * @param {[{toast}]]} actionItems toast objects wrapped in an array
 */
const requestNavbarActionItems = (actionItems) => {
  triggerEvent(EVENT_TOASTER_NEW_TOAST, actionItems);
};

export const useSafesimEventToaster = () => {
  const sendEventToast = useCallback((data) => {
    try {
      if (data && (data?.topic || data?.message || data.intent)) requestNavbarActionItems(data);
    } catch (e) {}
  }, []);

  return sendEventToast;
};