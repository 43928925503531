/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useQuery } from "@apollo/client";
import * as React from "react";
import { createContext, PropsWithChildren } from "react";
import { UserProfile } from "../Api/Api";
import GetMyUserProfile from "../Api/Gql/GetMyUserProfile.gql";
import { ClearedAccess } from "../Api/ClearedAccess";
import { SimorAuthContext } from "./AuthContext";

export class LoggedInUser {
  constructor(profile: UserProfile, clearanceInfo: ClearedAccess) {
    this.profile = profile;
    this.clearanceInfo = clearanceInfo;
  }

  profile: UserProfile;
  clearanceInfo: ClearedAccess;
}

export const UserContext = createContext<LoggedInUser>(null);

export function UserProvider({ children }: PropsWithChildren<any>): JSX.Element {
  const simorAuth = React.useContext(SimorAuthContext);
  const { data } = useQuery(gql(GetMyUserProfile), {});

  const currentUser = data?.getMyUserProfile as UserProfile;

  const clearanceInfo = simorAuth.clearanceInfo?.initializedWithValidSource()
    ? simorAuth.clearanceInfo
    : new ClearedAccess(currentUser?.clearanceInfo);

  const loggedInUser = new LoggedInUser(currentUser, clearanceInfo);

  return <UserContext.Provider value={loggedInUser}>{children}</UserContext.Provider>;
}
