# import "./fragments/securityMarkingsData.gql"

query FindAssetsWithText($textSubstring: String!, $querySettings: QuerySettingsInput, $filter: SearchSpecInput) {
    findAssetsWithText (textSubstring: $textSubstring, querySettings: $querySettings, filter: $filter) {
        pageCount,
        results {
            id
            type
            title
            version
            description
            securityMarkings {
                __typename
                ...securityMarkingsData
            }
        }
    },
}