import React, { useEffect, useState } from 'react';
import { SecurityMetadataSelector } from './SecurityMetadataSelector';
import { SafesimIcon, SafesimPopover, SafesimTooltip } from './allSafesimComponents';
import { SecurityMetadata } from '../types/SecurityMetadata';
import { getSecurityMetadataStr } from '../utilities/securityMarkingUtilities';

interface Props {
  securityMetadataObject: SecurityMetadata;
  onSubmit?: (label: SecurityMetadata) => void;
  disabled?: boolean;
  classificationList: string[];
  sciList: string[];
  sarList: string[];
  disseminationControlsList: string[];
}

export const SafesimSecurityMetadataPopover = (props: Props): JSX.Element => {
  const { securityMetadataObject, onSubmit, disabled = false, classificationList, sciList, sarList, disseminationControlsList } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [securityMetadataStr, setSecurityMetadataStr] = useState(getSecurityMetadataStr(securityMetadataObject));

  useEffect(() => {
    setSecurityMetadataStr(getSecurityMetadataStr(securityMetadataObject));
  }, [securityMetadataObject]);

  return (
    <SafesimPopover
      isOpen={isPopoverOpen}
      content={
        <SecurityMetadataSelector
          securityMetadataObject={securityMetadataObject}
          onCancel={() => setIsPopoverOpen(false)}
          onSubmit={(obj) => {
            setIsPopoverOpen(false);
            onSubmit?.(obj);
          }}
          classificationList={classificationList}
          sciList={sciList}
          sarList={sarList}
          disseminationControlsList={disseminationControlsList}
        />
      }
    >
      <div>
        <SafesimTooltip disabled={securityMetadataStr === null || securityMetadataStr === ''} content={<p>{securityMetadataStr}</p>}>
          <SafesimIcon
            size={32}
            icon={'take-action'}
            onClick={() => {
              setIsPopoverOpen(!disabled && !isPopoverOpen);
            }}
          />
        </SafesimTooltip>
      </div>
    </SafesimPopover>
  );
};
