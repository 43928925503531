/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Spinner } from "baseui/spinner";
import { Tab, Tabs } from "baseui/tabs-motion";
import { LabelSmall } from "baseui/typography";
import * as React from "react";
import { CenteredContent, HorizontalStack } from "../../DesignSystem/Containers";
import { FileDetails } from "../../File/FileDetailsPage";
import { C2SimFileQuery } from "./C2SimFileQuery";
import { FileUpload, UploadStatus } from "./FileUpload";
import { DuplicateFilePane } from "../../File/DuplicateFilePane";
import { AssetDetailsPageAction } from "../Editor/AssetDetailsState";

export function FileUploadDetails({
  fileUpload,
  dispatch,
}: {
  fileUpload: FileUpload;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
}): JSX.Element {
  const [activeKey, setActiveKey] = React.useState<React.Key>(
    fileUpload?.metadataScan?.analyzedFileType === "C2SIM" ? 1 : 0
  );

  React.useEffect(() => {
    if (fileUpload?.metadataScan?.analyzedFileType != "C2SIM") {
      setActiveKey(0);
    }
  }, [fileUpload]);

  if (fileUpload.status === UploadStatus.FAILED) {
    return (
      <>
        {fileUpload.duplicateAliasIds?.length > 0 ? (
          <HorizontalStack>
            <DuplicateFilePane duplicateFileUpload={fileUpload} dispatch={dispatch}></DuplicateFilePane>
          </HorizontalStack>
        ) : (
          <CenteredContent>
            <LabelSmall>{"An unexpected error occured"}</LabelSmall>
          </CenteredContent>
        )}
      </>
    );
  }

  if (fileUpload.status == UploadStatus.IN_PROGRESS) {
    return (
      <CenteredContent>
        <LabelSmall>{"Analyzing file..."}</LabelSmall>
        <Spinner />
      </CenteredContent>
    );
  }

  return (
    <Tabs
      activeKey={activeKey}
      onChange={({ activeKey }) => {
        setActiveKey(activeKey);
      }}
      activateOnFocus
    >
      <Tab title="File Details">
        <FileDetails fileId={fileUpload.info.id} readonly={true} />
      </Tab>
      {fileUpload?.metadataScan?.analyzedFileType === "C2SIM" && (
        <Tab title="C2 Sim Scenario Candidates">
          <C2SimFileQuery fileUpload={fileUpload} />
        </Tab>
      )}
    </Tabs>
  );
}
