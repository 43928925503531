/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Scenario } from "Api";
import React from "react";
import { ScenarioChangeSet } from "Api";
import RescanScenarioFiles from "../Api/Gql/RescanScenarioFiles.gql";
import { notify } from "../Shared/Notify";
import { useHistory, useLocation } from "react-router-dom";
import getScenarios from "../Api/Gql/GetScenarios.gql";
import updateScenario from "../Api/Gql/UpdateScenario.gql";
import { handleApolloError } from "../Shared/Errors";
import { UnsavedChangesPrompt } from "../Shared/UnsavedChangesPrompt";
import { Uuid } from "../Utils/Types";
import { AssetDetailsPage } from "../Asset/Editor/AssetDetailsPage";
import { newScenarioDetailsState, ScenarioDetailsReducer } from "./ScenarioDetailsState";
import { EditAssetHeaderBar } from "../Asset/Editor/AssetDetailsHeaders";
import { scenarioDetailsCreatePagePath } from "../Shared/NavigationLinks";
import { AssetPane } from "../Asset/Shared/AssetPane";
import { ScenarioRelationPane } from "./ScenarioRelationPane";
import { ClassificationHeader } from "../Shared/ClassificationHeader";
import { relationPaneStyle } from "../Asset/Shared/AssetPageStyles";

interface ScenarioDetailsQueryParams {
  scenarioId: Uuid;
}

function useScenarioDetailsParams(): ScenarioDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  // TODO: Conditionally show an invalid uuid page if the Uuid fails to parse.
  const scenarioDetailsParams: ScenarioDetailsQueryParams = {
    scenarioId: searchParams.get("scenarioId") as Uuid,
  };
  return scenarioDetailsParams;
}

export const ScenarioDetailsEditPage = (): JSX.Element => {
  const scenarioDetailsParams = useScenarioDetailsParams();

  const [state, dispatch] = React.useReducer(
    ScenarioDetailsReducer,
    newScenarioDetailsState(scenarioDetailsParams.scenarioId)
  );

  const [rescanScenarioFilesMutation] = useMutation(gql(RescanScenarioFiles), {
    onCompleted: (data) => {
      const changeSet: ScenarioChangeSet = data.rescanScenarioFiles;
      if (changeSet.hasChanged) {
        dispatch(["resetOriginalAndEditedScenario", changeSet.updatedScenario]);
        notify.positive("Scenario metadata updated");
      } else {
        notify.positive("No changes to scenario metadata");
      }
    },
    onError: (error) => handleApolloError(error, "Error re-scanning scenario."),
  });

  const [rescanPressed, setRescanPressed] = React.useState<number>(0);
  const handleRescan = React.useCallback(() => {
    rescanScenarioFilesMutation({
      variables: { scenarioId: state.edited.asset.id },
    });
    //TODO is there a more elegant way to do this? rerendering whole ScenarioDetailsPage component
    setRescanPressed(rescanPressed + 1);
  }, [rescanPressed, rescanScenarioFilesMutation, state.edited.asset.id]);

  // Should this be uppercase?
  const [updateScenarioMutation] = useMutation(gql(updateScenario), {
    variables: { scenario: state.edited.asset },
    onCompleted: (data) => {
      dispatch(["resetOriginalAndEditedScenario", data.updateScenario as Scenario]);
      notify.positive("Scenario saved");
    },
    onError: (error) => handleApolloError(error, `Error updating scenario.`),
  });

  const [scenarioLoadError, setScenarioLoadError] = React.useState<string>();
  const { loading: scenarioLoading } = useQuery(gql(getScenarios), {
    variables: { ids: [state.edited.asset.id] },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const scenarios = data.getScenarios as Scenario[];
      if (scenarios[0]) {
        dispatch(["resetOriginalAndEditedScenario", scenarios[0]]);
      } else {
        setScenarioLoadError("Scenario not found; scenario does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting scenario data."),
  });

  const history = useHistory();
  const handleCopy = React.useCallback(() => {
    history.push(scenarioDetailsCreatePagePath(state.edited.asset.id));
  }, [history, state.edited.asset.id]);

  const propertiesStyle = { minWidth: "80%" };

  return (
    <>
      <UnsavedChangesPrompt when={state.dataChanged} />
      <AssetDetailsPage
        headerActionButtons={
          <>
            <EditAssetHeaderBar
              updateAssetMutation={updateScenarioMutation}
              handleRescan={handleRescan}
              handleCopy={handleCopy}
              typeString="Scenario"
              state={state}
              dispatch={dispatch}
            />
            <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
          </>
        }
        loading={scenarioLoading}
        loadError={scenarioLoadError}
      >
        <AssetPane state={state} dispatch={dispatch} propertiesStyle={propertiesStyle} />
        <div style={relationPaneStyle}>
          <ScenarioRelationPane dispatch={dispatch} relations={state.edited.relations} />
        </div>
      </AssetDetailsPage>
    </>
  );
};
