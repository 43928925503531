/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Uuid } from "../Utils/Types";
import { useReactiveVar } from "@apollo/client";
import { FileInfo } from "Api";
import { appConfigurationsVar } from "../GlobalState";
import { themedUseStyletron as useStyletron } from "../DesignSystem/LightTheme";
import { replaceIfUntitled } from "../Utils/Strings";
import { classificationOptionsAsText, mbsSizeAsText } from "../Api/ApiSerialization";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { TextEntry } from "../Utils/TableCells";

export interface FileInfoWithAliasId {
  fileInfo: FileInfo;
  aliasId: Uuid;
}

export interface SelectableDuplicateFileInfo {
  item: FileInfoWithAliasId;
  isSelected: boolean;
}

const searchResultTableStyle: React.CSSProperties = {
  width: "70%",
  marginRight: "auto",
  // Disables the 2px cell border in FireFox when you ctrl-click on a table cell.
  MozUserSelect: "none",
  alignItems: "flex-start",
};

export function DuplicateFileTable({
  selectableDuplicateFileInfos,
  onClick,
}: {
  selectableDuplicateFileInfos: SelectableDuplicateFileInfo[];
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectableDuplicateFileInfo: SelectableDuplicateFileInfo
  ) => void;
}): JSX.Element {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, assetId: Uuid) => {
      const selectableItem = selectableDuplicateFileInfos.find((row) => row.item.aliasId === assetId);
      if (selectableItem) {
        onClick(event, selectableItem);
      }
    },
    [onClick, selectableDuplicateFileInfos]
  );

  const styleOverrides = React.useMemo(() => {
    return {
      // TODO: Find a way to create a common table style, possibly using Styletron.
      Table: {
        style: () => ({
          boxSizing: "content-box",
        }),
      },
      TableBodyRow: {
        style: ({ $theme, $rowIndex }: any) => ({
          backgroundColor: selectableDuplicateFileInfos[$rowIndex].isSelected
            ? $theme.colors.selectionBackgroundPrimary
            : $theme.colors.backgroundPrimary,
          ":hover": {
            backgroundColor: selectableDuplicateFileInfos[$rowIndex].isSelected
              ? $theme.colors.selectionBackgroundPrimaryDark
              : $theme.colors.backgroundTertiary,
          },
        }),
      },
      TableBodyCell: {
        style: () => ({
          // Baseweb adds padding automatically for aesthetics to the table data;
          // we need to rmeove it to make sure we're able to receive click events
          // for everything that appears to the user to be inside the table cell.
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "0",
          paddingLeft: "0",
        }),
      },
    };
  }, [selectableDuplicateFileInfos]);

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  return (
    <div style={searchResultTableStyle}>
      <TableBuilder data={selectableDuplicateFileInfos} overrides={styleOverrides}>
        <TableBuilderColumn id="title" header="Title" sortable>
          {(row: SelectableDuplicateFileInfo) => (
            <TextEntry
              text={replaceIfUntitled(row.item?.fileInfo.attributes?.name)}
              dataId={row.item.aliasId}
              styleClass={cellStyle}
              onClick={handleClick}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="classificationLevel" header="Classification" sortable>
          {(row: SelectableDuplicateFileInfo) => (
            <TextEntry
              text={classificationOptionsAsText(
                row.item.fileInfo.securityMarkings?.classificationLevel,
                isSensitiveSecurityContext
              )}
              styleClass={cellStyle}
              dataId={row.item.aliasId}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="fileSize" header="File Size" sortable>
          {(row: SelectableDuplicateFileInfo) => (
            <TextEntry
              text={mbsSizeAsText(row.item?.fileInfo.attributes?.sizeMbs)}
              styleClass={cellStyle}
              dataId={row.item.aliasId}
              onClick={handleClick}
            ></TextEntry>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}
