/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React, { useState, useMemo } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { FileInfo } from "Api";
import { sortFileInfo } from "../Api/FileInfo";
import { FileDetailsLink } from "../Shared/NavigationLinks";
import { fileUploadDateAsText, mbsSizeAsText, classificationOptionsAsText } from "../Api/ApiSerialization";
import { appConfigurationsVar } from "../GlobalState";
import { useReactiveVar } from "@apollo/client";
import { themedUseStyletron as useStyletron } from "../DesignSystem/LightTheme";
import { ComponentEntry, TextEntry } from "../Utils/TableCells";
import { Uuid } from "../Utils/Types";
import { LabelSmall } from "baseui/typography";

const searchResultTableStyle: React.CSSProperties = {
  width: "100%",
  height: "75%",
  margin: "auto",
};

export interface FileSearchResultTableProps {
  files: FileInfo[];
}

export interface SelectableFileInfoItem {
  file: FileInfo;
  isSelected: boolean;
}

export default function SearchResultTable({
  fileSearchResults,
  onClick,
  selectedFileInfo,
  overflow,
}: {
  fileSearchResults: FileInfo[];
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectableFileInfoItem: SelectableFileInfoItem
  ) => void;
  selectedFileInfo?: SelectableFileInfoItem;
  overflow?: string;
}): JSX.Element {
  const [sortColumn, setSortColumn] = useState("uploadDate");
  const [sortAsc, setSortAsc] = useState(true);

  const sortedData = useMemo(() => {
    const sortedFileInfo = sortFileInfo(fileSearchResults, sortAsc, sortColumn);
    const sortedSelectableFileInfo: SelectableFileInfoItem[] = sortedFileInfo.map((fileInfo) => {
      return { file: fileInfo, isSelected: fileInfo.id === selectedFileInfo?.file.id };
    });
    return sortedSelectableFileInfo;
  }, [fileSearchResults, sortAsc, sortColumn, selectedFileInfo?.file.id]);

  function handleSort(id: string): void {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, assetId: Uuid) => {
      const selectableItem = sortedData.find((row) => row.file.id === assetId);
      if (selectableItem && onClick) {
        onClick(event, selectableItem);
      }
    },
    [onClick, sortedData]
  );

  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  const styleOverrides = React.useMemo(() => {
    return {
      // TODO: Find a way to create a common table style, possibly using Styletron.
      Table: {
        style: () => ({
          height: "1px",
          overflow: overflow,
        }),
      },
      Root: {
        style: {
          height: "100%",
        },
      },
      TableBodyRow: {
        style: ({ $theme, $rowIndex }: any) => ({
          backgroundColor: sortedData[$rowIndex].isSelected
            ? $theme.colors.selectionBackgroundPrimary
            : $theme.colors.backgroundPrimary,
          ":hover": {
            backgroundColor: sortedData[$rowIndex].isSelected
              ? $theme.colors.selectionBackgroundPrimaryDark
              : $theme.colors.backgroundTertiary,
          },
        }),
      },
      TableBodyCell: {
        style: () => ({
          // Baseweb adds padding automatically for aesthetics to the table data;
          // we need to remove it to make sure we're able to receive click events
          // for everything that appears to the user to be inside the table cell.
          paddingTop: "0",
          paddingRight: "0",
          paddingBottom: "0",
          paddingLeft: "0",
        }),
      },
    };
  }, [sortedData, overflow]);

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "inline-block",
    maxWidth: "20rem",
    minWidth: "100%",
    height: "100%",
    paddingTop: theme.sizing.scale650,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale600,
    paddingBottom: theme.sizing.scale600,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  });

  return (
    <div style={searchResultTableStyle}>
      <TableBuilder
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? "ASC" : "DESC"}
        onSort={handleSort}
        overrides={styleOverrides}
      >
        <TableBuilderColumn id="name" header="File Name" sortable>
          {(row: SelectableFileInfoItem) => (
            <ComponentEntry dataId={row.file.id} styleClass={cellStyle} onClick={handleClick}>
              <FileDetailsLink fileId={row.file.id} fileName={row.file.attributes?.name} />
            </ComponentEntry>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="classificationLevel" header="Classification" sortable>
          {(row: SelectableFileInfoItem) => (
            <TextEntry
              text={classificationOptionsAsText(
                row.file.securityMarkings?.classificationLevel,
                isSensitiveSecurityContext
              )}
              dataId={row.file.id}
              styleClass={cellStyle}
              onClick={handleClick}
              dataTestId={`${row.file.id}-classificationLevel`}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="uploadDateUtc" header="Upload Date" sortable>
          {(row: SelectableFileInfoItem) => (
            <TextEntry
              text={fileUploadDateAsText(row.file)}
              dataId={row.file.id}
              styleClass={cellStyle}
              onClick={handleClick}
              dataTestId={`${row.file.id}-uploadDate`}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn id="sizeMbs" header="File Size" sortable>
          {(row: SelectableFileInfoItem) => (
            <TextEntry
              text={mbsSizeAsText(row.file.attributes?.sizeMbs)}
              dataId={row.file.id}
              styleClass={cellStyle}
              onClick={handleClick}
              dataTestId={`${row.file.id}-fileSize`}
            />
          )}
        </TableBuilderColumn>
      </TableBuilder>
      {sortedData.length === 50 ? (
        <LabelSmall style={{ marginTop: "1.6rem" }}>{`Displaying first 50 results.`}</LabelSmall>
      ) : (
        ""
      )}
    </div>
  );
}
