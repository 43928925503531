import React, { useState, useCallback } from 'react';
import { SafesimCallout, SafesimFormGroup } from './allSafesimComponents';
import { SafesimCardCollapse } from './SafesimCardCollapse';
import { SafesimMeasurementInput } from './SafesimMeasurementInput';

/**
 * Functional component that displays three measurement inputs for entering min, max, and step values.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {number} min the minimum value of the range
 * @param {number} max the maximum value of the range
 * @param {number} step the step size of the range
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {string} units unit to display next to value
 * @param {boolean} decimal whether decimal values are allowed. False if only integer values are allowed.
 * @param {function} onCancel callback for when the value is in edit mode, then cancelled
 * @param {JSX.Element} unitPopoverContent element to show in popup when clicking unit tag
 * @returns Inline SafesimFormGroup containing three disabled measurement inputs for min,max, and step, each with edit buttons
 */
export const SafesimRangeInput = (props) => {
  const {
    label,
    labelContent,
    min,
    max,
    step,
    editDisabledDisplayString,
    onValueChange,
    labelClassName,
    valueClassName,
    units,
    decimal,
    onValidation,
    onCancel,
    unitPopoverContent,
    ...rest
  } = props;
  const [workingMin, setWorkingMin] = useState(min);
  const [workingMax, setWorkingMax] = useState(max);
  const [workingStep, setWorkingStep] = useState(step);
  const [isValid, setIsValid] = useState(true);
  const [invalidMessage, setInvalidMessage] = useState('');

  const validationCheck = useCallback((min, max, step) => {
    if (min > max) {
      setIsValid(false);
      setInvalidMessage('Min must be smaller than Max');
      return false;
    } else if (step > max - min) {
      setIsValid(false);
      setInvalidMessage('Step must be smaller than difference of Max and Min');
      return false;
    } else {
      setIsValid(true);
      return true;
    }
  }, []);

  const onMinValidation = useCallback(
    (num, string) => {
      return string && !isNaN(string) && validationCheck(num, workingMax, workingStep);
    },
    [validationCheck, workingMax, workingStep]
  );

  const onMaxValidation = useCallback(
    (num, string) => {
      return string && !isNaN(string) && validationCheck(workingMin, num, workingStep);
    },
    [validationCheck, workingMin, workingStep]
  );

  const stepValidationCheck = useCallback((min, max, step) => {
    if (step < 0) {
      setIsValid(false);
      setInvalidMessage('Step must be a positive number');
      return false;
    } else if (step > max - min) {
      setIsValid(false);
      setInvalidMessage('Step must be smaller than difference of Max and Min');
      return false;
    } else {
      setIsValid(true);
      return true;
    }
  }, []);

  const onStepValidation = useCallback(
    (num, string) => {
      return string && !isNaN(string) && stepValidationCheck(workingMin, workingMax, num);
    },
    [stepValidationCheck, workingMax, workingMin]
  );

  return (
    <SafesimCardCollapse header={label} headerContent={labelContent} buttonClassName={labelClassName} {...rest}>
      {/* form groups for spacing */}
      {/* min */}
      <SafesimFormGroup inline={true}>
        <SafesimMeasurementInput
          label={'min'}
          value={workingMin}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          onValidation={onMinValidation}
          unitPopoverContent={unitPopoverContent}
          onCancel={onCancel}
          onValueChange={(num) => {
            if (workingStep === 0) {
              onValueChange?.(num, workingMax, (workingMax - num) / 2);
              setWorkingStep((workingMax - num) / 2);
            } else {
              onValueChange?.(num, workingMax, workingStep);
            }
            setWorkingMin(num);
          }}
        />
      </SafesimFormGroup>
      {/* max */}
      <SafesimFormGroup inline={true}>
        <SafesimMeasurementInput
          label={'max'}
          value={workingMax}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          onValidation={onMaxValidation}
          unitPopoverContent={unitPopoverContent}
          onCancel={onCancel}
          onValueChange={(num) => {
            if (workingStep === 0) {
              onValueChange?.(workingMin, num, (num - workingMin) / 2);
              setWorkingStep((num - workingMin) / 2);
            } else {
              onValueChange?.(workingMin, num, workingStep);
            }
            setWorkingMax(num);
          }}
        />
      </SafesimFormGroup>
      {/* step */}
      <SafesimFormGroup inline={true}>
        <SafesimMeasurementInput
          label={'step'}
          value={workingStep}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          onValidation={onStepValidation}
          unitPopoverContent={unitPopoverContent}
          onCancel={onCancel}
          onValueChange={(num) => {
            onValueChange?.(workingMin, workingMax, num);
            setWorkingStep(num);
          }}
        />
      </SafesimFormGroup>
      {!isValid && invalidMessage && (
        <SafesimFormGroup>
          <SafesimCallout intent='danger'>{invalidMessage}</SafesimCallout>
        </SafesimFormGroup>
      )}
    </SafesimCardCollapse>
  );
};
