/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { PageNotFound } from "../Utils/PageNotFound";
import { StudyDetailsCreatePage } from "./StudyDetailsCreatePage";
import { StudyDetailsEditPage } from "./StudyDetailsEditPage";

export const StudiesIndex = (): JSX.Element => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        {/* A <Switch> looks through its children <Route>s and
               renders the first one that matches the current URL. */}
        <Route path={`${match.path}/details/edit`}>
          <StudyDetailsEditPage />
        </Route>
        <Route path={`${match.path}/details/create`}>
          <StudyDetailsCreatePage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};
