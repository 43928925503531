import { Classes } from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { NumberOnlyRegex } from '../utilities/stringModifiers';
import { SafesimNumericInput, SafesimLabel } from './allSafesimComponents';
import { SafesimPopoverTag } from './SafesimPopoverTag';
import { debounceFunction, SafesimConfirmRejectPopover } from './SafesimConfirmRejectPopover';
import { SafesimControlGroup } from './SafesimControlGroup';

/**
 * Functional component that displays a string in a disabled input that can be edited.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string | number} value Value to display in the disabled SafesimInputGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {boolean} disallowBlankValue boolean controlling if an empty string is an acceptable value
 * @param {function} onValidation callback for input validation when value is changed
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {string} units unit to display next to value
 * @param {boolean} decimal whether decimal values are allowed. False if only integer values are allowed.
 * @param {boolean} editDisabled the starting state of the input. True if editing is disabled at the start.
 * @param {function} onCancel callback for when the value is in edit mode, then cancelled
 * @param {JSX.Element} unitPopoverContent element to show in popup when clicking unit tag
 * @param {JSX.Element} rightSideContent element displayed to the right of the config input
 * @returns Inline SafesimFormGroup containing a disabled input group, with a popover for the edit button.
 */
export const SafesimMeasurementInput = (props) => {
  const {
    label,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    labelClassName,
    valueClassName,
    units,
    decimal,
    editDisabled = true,
    onValidation,
    onCancel,
    unitPopoverContent,
    rightSideContent,
    ...rest
  } = props;
  const [isEditDisabled, setIsEditDisabled] = useState(editDisabled);
  const [workingValue, setWorkingValue] = useState({ number: value, string: String(value) });
  const [isWorkingValueValid, setIsWorkingValueValid] = useState(true);
  const [showEdit, setShowEdit] = useState(false);

  const validateWorkingValue = useCallback(
    (numberValue, stringValue) => {
      setIsWorkingValueValid(onValidation?.(numberValue, stringValue) ?? true);
    },
    [onValidation]
  );

  useEffect(() => {
    setIsEditDisabled(editDisabled);
  }, [editDisabled]);

  useEffect(() => {
    setWorkingValue({ number: value, string: String(value) });
  }, [value]);

  useEffect(() => {
    isEditDisabled ? validateWorkingValue(value, String(value)) : validateWorkingValue(workingValue.number, workingValue.string);
  }, [validateWorkingValue, workingValue, isEditDisabled, value]);

  const onConfirmChanges = useCallback(() => {
    if (isWorkingValueValid) {
      /// resolve when workingValue.string is not a number, assign NaN ///
      const valueToUpdate = workingValue.string && !isNaN(Number(workingValue.string)) ? workingValue.number : NaN;
      setWorkingValue({ number: valueToUpdate, string: valueToUpdate?.toString() });
      validateWorkingValue(valueToUpdate, valueToUpdate?.toString());
      onValueChange?.(!isNaN(valueToUpdate) ? valueToUpdate : undefined, !isNaN(valueToUpdate) ? valueToUpdate.toString() : undefined); ///workingValue.number, workingValue.number?.toString());
      setIsEditDisabled(true);
    }
  }, [isWorkingValueValid, onValueChange, validateWorkingValue, workingValue]);

  const onRejectChanges = useCallback(() => {
    setIsEditDisabled(true);
    setWorkingValue({ number: value, string: String(value) });
    validateWorkingValue(value, String(value));
    onCancel?.();
  }, [validateWorkingValue, value, onCancel]);

  const handleKeyDown = useCallback(
    (event) => {
      // only check enter and escape keys. Let all others pass
      if (event.key.toLowerCase() === 'enter' && isWorkingValueValid) {
        onConfirmChanges();
      } else if (event.key.toLowerCase() === 'escape') {
        onRejectChanges();
      }
    },
    [onConfirmChanges, onRejectChanges, isWorkingValueValid]
  );

  const debounceOnMouseLeave = debounceFunction(() => setShowEdit(false));

  useEffect(() => {
    return () => debounceOnMouseLeave.cancel();
  }, [debounceOnMouseLeave]);

  return (
    // trigger mouse events on surrounding div, so form label and popover are considered "inside" the form.
    <SafesimControlGroup
      style={{ padding: '5px' }}
      fill={true}
      onMouseEnter={() => {
        debounceOnMouseLeave.cancel();
        setShowEdit(true);
      }}
      onMouseLeave={debounceOnMouseLeave}
      {...rest}
    >
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={Classes.FIXED} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimConfirmRejectPopover
        isOpen={!isEditDisabled || showEdit} // popover should be open on hover to show edit button or when actively editing
        isEdit={!isEditDisabled}
        onEditClicked={() => setIsEditDisabled(false)}
        onConfirmChanges={onConfirmChanges}
        onRejectChanges={onRejectChanges}
        confirmDisabled={!isWorkingValueValid}
      >
        <SafesimNumericInput
          value={isEditDisabled ? editDisabledDisplayString ?? workingValue.number : workingValue.string === 'NaN' ? '' : workingValue.string}
          onValueChange={(num, str) => {
            if ((str === '' || str === '-' || str === '.' || str === '-.' || NumberOnlyRegex.test(str)) && (decimal || !str.includes('.'))) {
              setWorkingValue({ number: num, string: str });
              validateWorkingValue(num, str);
            }
          }}
          disabled={isEditDisabled}
          fill={true}
          rightElement={units && <SafesimPopoverTag popoverContent={!isEditDisabled && unitPopoverContent} text={units} />}
          onKeyDown={handleKeyDown}
        />
      </SafesimConfirmRejectPopover>
      {rightSideContent}
    </SafesimControlGroup>
  );
};
