/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useLocation } from "react-router-dom";
import { LabelXSmall } from "baseui/typography";
import { gql, useQuery } from "@apollo/client";
import { Uuid } from "../Utils/Types";
import GetFiles from "../Api/Gql/GetFiles.gql";
import { FileInfo } from "Api";
import { LoadingPlaceholder } from "../Shared/LoadingPlaceholder";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { FileEditor } from "./FileEditor";

interface FileDetailsQueryParams {
  fileId: Uuid;
}

function useFileDetailsParams(): FileDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  const fileDetailsParams: FileDetailsQueryParams = {
    fileId: searchParams.get("fileId") as Uuid,
  };
  return fileDetailsParams;
}

export const FileDetailsPage = (): JSX.Element => {
  const fileDetailsParams = useFileDetailsParams();

  const fileId = fileDetailsParams.fileId;
  if (!fileId) {
    return <InvalidFileDetailsQueryParameters />;
  }

  return <FileDetails fileId={fileId} readonly={false} />;
};

export const FileDetails = ({ fileId, readonly }: { fileId: string; readonly: boolean }): JSX.Element => {
  const { loading, error, data, refetch } = useQuery(gql(GetFiles), {
    variables: { ids: [fileId] },
  });

  if (loading) {
    return <LoadingPlaceholder message="Loading file details" />;
  }

  if (error) {
    return <LabelXSmall>{`Could not retrieve file details; ${error.message}`}</LabelXSmall>;
  }

  const fileInfos = data?.getFiles as FileInfo[];

  const fileInfo = fileInfos[0];
  if (!fileInfo) {
    return <LabelXSmall>{`No file found for id ${fileId}`}</LabelXSmall>;
  }

  return <FileEditor fileInfo={fileInfo} readonly={readonly} onSave={refetch} />;
};

function InvalidFileDetailsQueryParameters(): JSX.Element {
  return (
    <LabelXSmall>{"File details query parameter format is invalid; please specify fileId as a UUID."}</LabelXSmall>
  );
}

export const FileReferencedModal = ({
  isOpen,
  numberOfReferences,
  onSave,
  onCancel,
}: {
  isOpen: boolean;
  numberOfReferences: number;
  onSave: () => void;
  onCancel: () => void;
}): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onSave}>
      <ModalHeader>File is referenced by other assets</ModalHeader>
      <ModalBody>
        This file is referenced by {numberOfReferences} other assets. Are you sure you want to continue with the save?
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={"tertiary"} onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton kind={"primary"} onClick={onSave}>
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
