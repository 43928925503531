/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { BaseInputOverrides, Input, SharedProps } from "baseui/input";
import { Option, Select } from "baseui/select";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { Textarea } from "baseui/textarea";
import * as React from "react";
import { TaskInfo, TaskMeasure } from "../../Api/Api";
import GetTaskCapabilityOptions from "../../Api/Gql/GetTaskCapabilityOptions.gql";
import GetTasks from "../../Api/Gql/GetTasks.gql";
import UpdateTask from "../../Api/Gql/UpdateTask.gql";
import { Row, VerticalStack } from "../../DesignSystem/Containers";
import { handleApolloError } from "../../Shared/Errors";
import { LoadingPlaceholder } from "../../Shared/LoadingPlaceholder";
import { notify } from "../../Shared/Notify";
import { Uuid } from "../../Utils/Types";
import { HeadingLarge } from "baseui/typography";

export function TaskDetails({ taskId }: { taskId: Uuid }): JSX.Element {
  const GET_TASK_CAPABILITY_OPTIONS_QUERY_NAME = "GetTaskCapabilityOptions";

  const [options, setOptions] = React.useState([]);
  const [task, setTask] = React.useState<TaskInfo>();

  const { loading: taskLoading } = useQuery(gql(GetTasks), {
    fetchPolicy: "network-only",
    variables: { taskIds: [taskId] },
    onCompleted: (data: { getTasks: TaskInfo[] }) => {
      const loadedTask = data?.getTasks[0];
      setTask(loadedTask);

      const selectedCapabilities = loadedTask?.requiredCapabilities?.map((capability: string) => {
        return { id: capability, label: capability };
      });

      setSelectedCapabilities(selectedCapabilities ?? []);
    },
  });

  // Get capabilities that have previously been used in other tasks.
  const { loading: picklistLoading } = useQuery(gql(GetTaskCapabilityOptions), {
    fetchPolicy: "network-only",
    onCompleted: (data: { getTaskCapabilityOptions: string[] }) => {
      // Convert the capablity string[] to an Option[] so we can use in the Select control
      const existingOptions = data?.getTaskCapabilityOptions?.map((capability) => {
        return { id: capability, label: capability };
      });

      setOptions(existingOptions);
    },
    onError: (error) => handleApolloError(error, "Error loading capabilites picklist"),
  });

  const [selectedCapabilities, setSelectedCapabilities] = React.useState<Option[]>([]);

  const [updateTaskMutation] = useMutation(gql(UpdateTask), {
    fetchPolicy: "network-only",
    refetchQueries: [GET_TASK_CAPABILITY_OPTIONS_QUERY_NAME],
    onCompleted: () => {
      notify.positive("Task saved");
    },
    onError: (error) => handleApolloError(error, "Error updating task"),
  });

  const handleSave = React.useCallback(() => {
    const copied: TaskInfo = { ...task };

    copied.requiredCapabilities = selectedCapabilities.map((capability) => {
      return capability.label.toString();
    });

    updateTaskMutation({
      variables: { task: copied },
    });
  }, [selectedCapabilities, task, updateTaskMutation]);

  return (
    <>
      {taskLoading || picklistLoading ? (
        <LoadingPlaceholder message={`Loading task...`} />
      ) : (
        <VerticalStack>
          <HeadingLarge>{`${task?.category} ${task?.hierarchyNumber} ${task?.title}`}</HeadingLarge>

          <FormControl label={"Capabilities"}>
            <Row>
              <Select
                creatable={true}
                onChange={(params) => {
                  setSelectedCapabilities([...params.value]);
                }}
                options={options}
                multi={true}
                value={selectedCapabilities}
                placeholder="Enter capabilities"
              ></Select>
              <Button onClick={() => handleSave()} $style={{ marginLeft: ".5rem" }}>
                Save
              </Button>
            </Row>
          </FormControl>

          <FormControl label={"Priority"}>
            <Input value={task?.priority ?? ""} disabled={true} />
          </FormControl>
          <FormControl label={"DJS Approval Date"}>
            <Input value={task?.djsApprovalDate ?? ""} disabled={true} />
          </FormControl>
          <FormControl label={"Description"}>
            <Textarea value={task?.description ?? ""} disabled={true} overrides={resizableTextAreaOverrides} />
          </FormControl>
          <FormControl label={"Notes"}>
            <Textarea value={task?.notes ?? ""} disabled={true} overrides={resizableTextAreaOverrides} />
          </FormControl>
          <FormControl label={"Measures"}>
            <TableBuilder data={task?.measures}>
              <TableBuilderColumn id="number" header="Number" sortable>
                {(row: TaskMeasure) => `${row.measureNumber}`}
              </TableBuilderColumn>
              <TableBuilderColumn id="unit" header="Unit" sortable>
                {(row: TaskMeasure) => `${row.unit}`}
              </TableBuilderColumn>
              <TableBuilderColumn id="description" header="Description" sortable>
                {(row: TaskMeasure) => `${row.description}`}
              </TableBuilderColumn>
            </TableBuilder>
          </FormControl>
        </VerticalStack>
      )}
    </>
  );
}

const resizableTextAreaOverrides: BaseInputOverrides<SharedProps> = {
  Input: {
    style: {
      maxHeight: "19rem",
      minHeight: "6rem",
      resize: "vertical",
    },
  },
};
