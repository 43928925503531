# import "./fragments/securityMarkingsData.gql"
# import "./fragments/fileFoldersData.gql"

query GetScenarios($ids: [UUID!]!) {
  getScenarios(ids: $ids) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      version
      description
      keywords
      notes
      startTime
      customProps
      geoLocation {
        latitude
        longitude
      }
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
    relatedModels {
      id
      creationDate
      lastModifiedDate
      metadata {
        title
        version
        description
        keywords
        subject
        notes
        simulationFrameworks
        customProps
      }
      securityMarkings {
        __typename
        ...securityMarkingsData
      }
    }
  }
}
