/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Platform } from "Api";
import React from "react";
import AddPlatform from "../Api/Gql/AddPlatform.gql";
import getPlatforms from "../Api/Gql/GetPlatforms.gql";
import { useCopySettings } from "../Asset/Shared/CopyHelpers";
import { notify } from "../Shared/Notify";
import { newPlatformDetailsState, PlatformDetailsReducer } from "./PlatformDetailsState";
import { handleApolloError } from "../Shared/Errors";
import { initializeAssetCopy } from "../Api/ApiExtensions";
import { PlatformDetails } from "./PlatformDetails";
import { AssetWikiCreatePage } from "../Asset/Editor/AssetWikiCreatePage";

export function PlatformDetailsCreatePage(): JSX.Element {
  const copySettings = useCopySettings();

  const [state, dispatch] = React.useReducer(
    PlatformDetailsReducer,
    newPlatformDetailsState(undefined, copySettings.isInCopyMode)
  );

  const [addPlatformMutation] = useMutation(gql(AddPlatform), {
    variables: { platform: state.edited.asset },
    onCompleted: () => {
      notify.positive("Platform saved");
    },
    onError: (error) => handleApolloError(error, `Error creating platform.`),
  });

  const [platformLoadError, setPlatformLoadError] = React.useState<string>();

  // TODO: Consider a usePlatform hook to wrap getPlatforms() and onComplete pass a single, nicely typed platform
  const { loading: platformLoading } = useQuery(gql(getPlatforms), {
    variables: { ids: [copySettings.assetId] },
    fetchPolicy: "network-only",
    skip: !copySettings.isInCopyMode,
    onCompleted: (data) => {
      const platforms = data.getPlatforms as Platform[];
      if (platforms[0]) {
        initializeAssetCopy(platforms[0]);
        dispatch(["resetOriginalAndEditedPlatform", platforms[0]]);
      } else {
        setPlatformLoadError("Platform not found; platform does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting platform data."),
  });

  return (
    <AssetWikiCreatePage
      state={state}
      dispatch={dispatch}
      onAddAsset={addPlatformMutation}
      loading={platformLoading}
      loadError={platformLoadError}
    >
      <PlatformDetails state={state} dispatch={dispatch} />
    </AssetWikiCreatePage>
  );
}
