/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deepEquals(object1: any, object2: any): boolean {
  if (object1 === object2) {
    return true;
  }

  const safeObject1 = object1 ?? {};
  const safeObject2 = object2 ?? {};

  // Both objects are not empty
  const object1AsText = JSON.stringify(safeObject1);
  const object2AsText = JSON.stringify(safeObject2);
  return object1AsText === object2AsText;
}
