import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

/**
 * React hook that handles blocking/unblocking navigation based on a specified condition
 * @param {(navTransition:{action:string,location:import('react-router-dom').Location,retry:()=>void})=>boolean} onTryNavigate A callback that is invoked upon attempted navigation and returns a boolean for whether to allow the navigation (true) or block it (false)
 * @param {boolean} when A condition for which to block navigation
 */
export const useBlocker = (onTryNavigate, when = true) => {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((navTransition) => {
      const autoUnblockingTransition = {
        ...navTransition,
        retry: () => {
          unblock();
          navTransition.retry();
        },
      };

      onTryNavigate(autoUnblockingTransition);
    });
    
    return unblock;
  }, [navigator, onTryNavigate, when]);
};
