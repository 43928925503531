/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { AssetSummary, Platform, PlatformInput } from "Api";
import { emptyPlatformInput, isDataChanged, toAssetSummaries, toPlatformMetadataInput } from "../Api/ApiExtensions";
import {
  AssetContent,
  AssetDetailsState,
  AssetWikiPageAction,
  AssetWikiPageReducer,
  resetOriginalAndEdited,
} from "../Asset/Editor/AssetDetailsState";
import { COMMON_FIXED_SCHEMA_FIELDS } from "../Asset/Editor/AssetMetadataFields";
import { FieldSpec, simpleTextFieldSpec } from "../Asset/Editor/FieldSpec";
import { Uuid, newId } from "../Utils/Types";
import { addToNewArray } from "../Utils/Array";

export const SUBJECT_FIELD = simpleTextFieldSpec("Subject");
// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
export const PLATFORM_FIXED_SCHEMA_FIELDS: FieldSpec[] = [...COMMON_FIXED_SCHEMA_FIELDS, SUBJECT_FIELD];

export function newPlatformDetailsState(platformId?: Uuid, copying?: boolean): AssetDetailsState<PlatformInput> {
  const newPlatform = emptyPlatformInput();
  newPlatform.id = platformId ?? newId();
  return new AssetDetailsState<PlatformInput>("PLATFORM", PLATFORM_FIXED_SCHEMA_FIELDS, newPlatform, [], copying);
}

type ResetOriginalAndEditedPlatformAction = ["resetOriginalAndEditedPlatform", Platform];

function resetOriginalAndEditedPlatform(
  state: AssetDetailsState<PlatformInput>,
  platform: Platform
): AssetDetailsState<PlatformInput> {
  const platformInput: PlatformInput = {
    id: platform.id,
    metadata: toPlatformMetadataInput(platform.metadata),
    securityMarkings: platform.securityMarkings,

    relatedModelIds: platform.relatedModels?.map((model) => model.id),

    // Will not be used by resetOriginalAndEdited
    fileFolders: [],
  };

  const modelSummaries: AssetSummary[] = toAssetSummaries("MODEL", platform.relatedModels);

  const allRelations = [...modelSummaries];

  return resetOriginalAndEdited<PlatformInput>(
    state,
    platformInput,
    platform.creationDate,
    platform.lastModifiedDate,
    platform.fileFolders,
    allRelations
  );
}

type AddRelatedModelAction = ["addRelatedModel", AssetSummary];

function addRelatedModel(
  state: AssetDetailsState<PlatformInput>,
  resourceToAdd: AssetSummary
): AssetDetailsState<PlatformInput> {
  const asset = { ...state.edited.asset };
  const relations = addToNewArray(state.edited.relations, resourceToAdd);

  switch (resourceToAdd.type) {
    case "MODEL":
      asset.relatedModelIds = addToNewArray(state.edited.asset.relatedModelIds, resourceToAdd.id);
      break;
    default:
      console.error(`Unhandled assetType ${resourceToAdd.type}`);
      break;
  }

  const editedAfterUpdate: AssetContent<PlatformInput> = {
    ...state.edited,
    asset: asset,
    relations: relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

type RemoveRelatedModelAction = ["removeRelatedModel", AssetSummary];

function removeRelatedModel(
  state: AssetDetailsState<PlatformInput>,
  assetToRemove: AssetSummary
): AssetDetailsState<PlatformInput> {
  const asset = { ...state.edited.asset };
  const relations = state.edited.relations?.filter((model) => model.id !== assetToRemove.id);

  switch (assetToRemove.type) {
    case "MODEL":
      asset.relatedModelIds = state.edited.asset.relatedModelIds?.filter((modelId) => modelId !== assetToRemove.id);
      break;
    default:
      console.error(`Could not delete relation ${assetToRemove} type not supported`);
      break;
  }

  const editedAfterUpdate: AssetContent<PlatformInput> = {
    ...state.edited,
    asset: asset,
    relations: relations,
  };

  return {
    ...state,
    edited: editedAfterUpdate,
    dataChanged: isDataChanged(state.original.asset, editedAfterUpdate.asset),
  };
}

export type PlatformDetailsPageAction =
  | AssetWikiPageAction
  | ResetOriginalAndEditedPlatformAction
  | AddRelatedModelAction
  | RemoveRelatedModelAction;

export function PlatformDetailsReducer(
  state: AssetDetailsState<PlatformInput>,
  action: PlatformDetailsPageAction
): AssetDetailsState<PlatformInput> {
  const actionType = action[0];
  switch (actionType) {
    case "resetOriginalAndEditedPlatform":
      return resetOriginalAndEditedPlatform(state, action[1]);
    case "addRelatedModel":
      return addRelatedModel(state, action[1]);
    case "removeRelatedModel":
      return removeRelatedModel(state, action[1]);
    default:
      return AssetWikiPageReducer(state, action);
  }
}
