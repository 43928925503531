/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

export class SiteProps {
  readonly baseUrl: string;
  readonly subUrl: string;
  readonly useKeycloak: boolean;

  get topUrl(): string {
    return `${this.baseUrl}${this.subUrl}`;
  }

  constructor(baseUrl: string, subUrl: string, useKeycloak: boolean) {
    this.baseUrl = baseUrl;
    this.subUrl = subUrl;
    this.useKeycloak = useKeycloak;
  }
}

// TODO: Move the reference to the current page state to global state. Possibly
// as a reactive var? It would possibly simplify things.
export const SiteContext = React.createContext<SiteProps>(new SiteProps("(not set)", "(not set)", false));
