/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React from "react";
import { EndAnchoredRow, EndAnchoredRow, VerticalSpacer } from "../../DesignSystem/Containers";
import { DetailsPage } from "../../Shared/DetailsPages";
import { AssetInput } from "../../Api/ApiExtensions";
import { AssetDetailsState, AssetDetailsPageAction } from "./AssetDetailsState";
import { CreateAssetHeaderButtons, classificationHeaderContainerStyle } from "./AssetDetailsHeaders";
import { ClassificationHeader } from "../../Shared/ClassificationHeader";

export function AssetWikiCreatePage({
  state,
  dispatch,
  onAddAsset,
  loading,
  loadError,
  children,
}: {
  state: AssetDetailsState<AssetInput>;
  dispatch: React.Dispatch<AssetDetailsPageAction>;
  onAddAsset: () => void;
  loading: boolean;
  loadError?: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <DetailsPage loading={loading} loadError={loadError}>
      <EndAnchoredRow>
        <div style={classificationHeaderContainerStyle}>
          <ClassificationHeader
            securityMarkings={state.edited.asset.securityMarkings}
            dispatch={dispatch}
          ></ClassificationHeader>
        </div>

        <CreateAssetHeaderButtons onAddAsset={onAddAsset} state={state} />
      </EndAnchoredRow>
      <VerticalSpacer height="1.5rem" />
      {React.Children.toArray(children)}
    </DetailsPage>
  );
}
