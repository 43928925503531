/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import { Tabs, Tab } from "baseui/tabs-motion";
import { FlexGridItem } from "baseui/flex-grid";
import { AssetFileManager } from "../Shared/AssetFileManager";
import { HorizontalStack, VerticalStack } from "../../DesignSystem/Containers";
import { AssetPictureUploadModal } from "../../Shared/AssetPictureUploadModal";
import { RedactableImage } from "../../Shared/RedactableImage";
import { WikiCardInfo, WikiStaticTextBlock } from "../../Shared/WikiStaticTextBlock";
import { MetadataFieldsAddEditor } from "./MetadataFieldsAddEditor";
import { FileUploadDetails } from "../Tree/FileUploadDetails";
import { AssetIconWithText } from "../Shared/AssetIcon";
import { AssetDetailsState, AssetWikiInput, AssetWikiPageAction } from "./AssetDetailsState";
import { AssetType } from "Api";
import { prettyAssetType as prettyAssetTypeName } from "../../Api/ApiExtensions";

export function AssetWikiDetails({
  state,
  dispatch,
  type,
  wikiCardInfo,
  assetPane,
}: {
  state: AssetDetailsState<AssetWikiInput>;
  dispatch: React.Dispatch<AssetWikiPageAction>;
  type: AssetType;
  wikiCardInfo: WikiCardInfo[];
  assetPane: React.ReactNode;
}): JSX.Element {
  const [activeTabKey, setActiveTabKey] = React.useState<React.Key>(0);
  const [isFileUploaderOpen, setIsFileUploaderOpen] = React.useState(false);

  const prettyTypeName = prettyAssetTypeName(type);

  return (
    <>
      <HorizontalStack>
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: "25rem",
                flexGrow: 0,
              }),
            },
          }}
        >
          <VerticalStack>
            <RedactableImage
              idReference={state.edited.asset.metadata.imageId}
              onSetImagePressed={() => setIsFileUploaderOpen(true)}
              defaultImageType={type}
            />
            <WikiStaticTextBlock wikiCardInfo={wikiCardInfo}></WikiStaticTextBlock>
          </VerticalStack>
        </FlexGridItem>
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: "40%",
                flexGrow: 1,
              }),
            },
          }}
        >
          <Tabs
            activeKey={activeTabKey}
            onChange={({ activeKey }) => {
              setActiveTabKey(activeKey);
            }}
            activateOnFocus
          >
            <Tab
              title={<AssetIconWithText type={type} title={`${prettyTypeName} Data`} />}
              overrides={{
                Tab: { props: { "data-testid": "data-tab" } },
              }}
            >
              <MetadataFieldsAddEditor state={state} dispatch={dispatch}></MetadataFieldsAddEditor>
            </Tab>
            <Tab
              title="Related Resources"
              overrides={{
                Tab: { props: { "data-testid": "related-assets-tab" } },
              }}
            >
              {assetPane}
            </Tab>
            <Tab
              title="Related Files"
              overrides={{
                Tab: { props: { "data-testid": "related-files-tab" } },
              }}
            >
              <HorizontalStack>
                <FlexGridItem
                  overrides={{
                    Block: {
                      style: () => ({
                        minWidth: "20%",
                        flexGrow: 1,
                      }),
                    },
                  }}
                >
                  <AssetFileManager
                    root={state.edited.fileTreeRoot}
                    downloadZipBlocked={state.downloadZipBlocked}
                    dispatch={dispatch}
                  />
                </FlexGridItem>
                {state.selectedFileTreeNode?.type === "File" ? (
                  <FlexGridItem
                    overrides={{
                      Block: {
                        style: () => ({
                          minWidth: "65%",
                          flexGrow: 1,
                        }),
                      },
                    }}
                  >
                    <FileUploadDetails fileUpload={state.selectedFileTreeNode.file} dispatch={dispatch} />
                  </FlexGridItem>
                ) : (
                  ""
                )}
              </HorizontalStack>
            </Tab>
          </Tabs>
        </FlexGridItem>
      </HorizontalStack>
      <AssetPictureUploadModal
        isModalOpen={isFileUploaderOpen}
        onClose={() => setIsFileUploaderOpen(false)}
        onUploadComplete={(data) =>
          data.successfulUploadInfos.length == 1 ? dispatch(["addImageId", data.successfulUploadInfos[0].id]) : null
        }
      />
    </>
  );
}
