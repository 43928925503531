/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { SecurityMarkings, MarkedString } from "Api";
import { FormControl } from "baseui/form-control";
import React from "react";
import { FieldSpec } from "../Editor/FieldSpec";
import { DateEditor } from "./DateEditor";
import { FieldHeader } from "./FieldHeader";
import { GeoLocationEditor } from "./GeoLocationEditor";
import { MultiLineTextEditor } from "./MultiLineTextEditor";
import { NumericTextEditor } from "./NumericTextEditor";
import { PickListEditor } from "./PickListEditor";
import { SimpleTextEditor } from "./SimpleTextEditor";
import { TagListEditor } from "./TagListEditor";
import { RedactedText } from "../../Shared/RedactedElements";
import { isRedaction } from "../../Api/ApiExtensions";

function createField(
  field: FieldSpec,
  value: any,
  disabled?: boolean,
  onChange?: (newValue: any) => void,
  shouldShowError?: boolean
): JSX.Element {
  switch (field.type) {
    case "NumericTextField":
      return <NumericTextEditor field={field} value={value} disabled={disabled} onChange={onChange} />;
    case "SimpleTextField":
      return (
        <SimpleTextEditor
          value={value}
          disabled={disabled}
          onChange={onChange}
          label={field.title}
          shouldShowError={shouldShowError}
        />
      );
    case "MultiLineTextField":
      return <MultiLineTextEditor value={value} disabled={disabled} onChange={onChange} />;
    case "TagListField":
      return (
        <TagListEditor
          querySpec={field.querySpec}
          field={field}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
    case "PickListField":
      return <PickListEditor field={field} value={value} disabled={disabled} onChange={onChange} />;
    case "DateTextField":
      return <DateEditor value={value} disabled={disabled} onChange={onChange} />;
    case "GeoLocationField":
      // Disabled hardcorded: true for PBI 2943
      return <GeoLocationEditor value={value} disabled={true} onChange={onChange} />;
    default:
      const _exhaustiveCheck: never = field;
      return _exhaustiveCheck;
  }
}

export const FieldEditor = ({
  field,
  value,
  allowRemoval,
  disabled,
  onChange,
  onRemove,
  errorMessage,
}: {
  field: FieldSpec;
  value: any;
  allowRemoval?: boolean;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
  onRemove?: () => void;
  shouldShowError?: boolean;
  errorMessage?: string;
}): JSX.Element => {
  const isRedacted = isRedaction(value);

  const markedValue = value?.hasOwnProperty("contents") ? value.contents : value;
  const handleMarkingsChanged = React.useCallback(
    (newSecurityMarking: SecurityMarkings) => {
      const markedString: MarkedString = {
        __typename: "MarkedString",
        contents: markedValue,
        securityMarkings: newSecurityMarking,
      };

      if (onChange) {
        onChange(markedString);
      }
    },
    [markedValue, onChange]
  );

  const securityMarkings = value?.hasOwnProperty("securityMarkings") ? value.securityMarkings : null;
  const handleTextChanged = React.useCallback(
    (newText: string) => {
      if (!securityMarkings) {
        if (onChange) {
          onChange(newText);
        }
      } else {
        const markedString: MarkedString = {
          __typename: "MarkedString",
          contents: newText,
          securityMarkings: securityMarkings,
        };
        if (onChange) {
          onChange(markedString);
        }
      }
    },
    [securityMarkings, onChange]
  );

  const shouldShowError = errorMessage ? true : false;

  return (
    <FormControl
      error={shouldShowError ? errorMessage : null}
      label={() => (
        <FieldHeader
          fieldTitle={field.title}
          portionMarking={securityMarkings}
          isMarkable={field.markable}
          allowMarkingEdits={!isRedacted && !disabled}
          allowRemoval={allowRemoval && !isRedacted && !disabled}
          onMarkingsChange={handleMarkingsChanged}
          onRemove={onRemove}
        />
      )}
    >
      {isRedacted ? <RedactedText /> : createField(field, markedValue, disabled, handleTextChanged, shouldShowError)}
    </FormControl>
  );
};
