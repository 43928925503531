/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ClassificationLevel, CompartmentMarking } from "./Api";
import { ClassificationOption, classificationOptionToLevel, getUserClassificationLevels } from "../Shared/Security";
import { classificationOptionsAsText } from "./ApiSerialization";

export type ClassificationCompartments = Record<string, string[]>;
export type CountryCompartments = Record<string, ClassificationCompartments>;
export type AuthorizationSet = Record<string, CountryCompartments>;

export class ClearedAccess {
  private _authorizationSet?: AuthorizationSet;
  private _clearance: ClassificationLevel[];
  private _initializedWithValidSource: boolean = false;

  constructor(clearanceSource: ClearanceInfo) {
    this._initializedWithValidSource = this.isValidClearanceSource(clearanceSource);

    if (!this._initializedWithValidSource) {
      this._authorizationSet = {};
      this._clearance = [];
      return;
    }

    this._authorizationSet = clearanceSource["urn:us:gov:dod:sap:authorizationSet"];
    //TODO watch this may break unexpectedly elsewhere
    this._clearance = clearanceSource["urn:us:gov:ic:uias:clearance"] as ClassificationLevel[];
  }

  private isValidClearanceSource(clearanceSource: ClearanceInfo): boolean {
    if (!clearanceSource) {
      return false;
    }

    if (!clearanceSource["urn:us:gov:dod:sap:authorizationSet"] || !clearanceSource["urn:us:gov:ic:uias:clearance"]) {
      return false;
    }

    return true;
  }

  public initializedWithValidSource(): boolean {
    return this._initializedWithValidSource;
  }

  public static getHighestLevel(classificationLevels?: string[]): ClassificationLevel {
    if (!classificationLevels) {
      return ClassificationLevel.UNCLASSIFIED;
    }

    if (classificationLevels.find((level) => level === ClassificationLevel.TOP_SECRET)) {
      return ClassificationLevel.TOP_SECRET;
    } else if (classificationLevels.find((level) => level === ClassificationLevel.SECRET)) {
      return ClassificationLevel.SECRET;
    } else if (classificationLevels.find((level) => level === ClassificationLevel.CONFIDENTIAL)) {
      return ClassificationLevel.CONFIDENTIAL;
    } else {
      return ClassificationLevel.UNCLASSIFIED;
    }
  }

  private expandCompartments(
    compartments: ClassificationLevel[],
    countryCompartments?: CountryCompartments
  ): ClassificationCompartments {
    const allValidCompartments: ClassificationCompartments = {};
    compartments.forEach((level) => {
      const compartmentsAtLevel = countryCompartments[level] ?? {};
      Object.keys(compartmentsAtLevel).forEach((category) => {
        if (!allValidCompartments[category]) {
          allValidCompartments[category] = [];
        }

        const allCompartmentsSet = new Set(allValidCompartments[category].concat(compartmentsAtLevel[category]));
        allValidCompartments[category] = Array.from(allCompartmentsSet);
      });
    });

    return allValidCompartments;
  }

  private createDefaultCompartment(
    classificationLevel: ClassificationLevel,
    availableCategoryCompartments?: ClassificationCompartments
  ): CompartmentMarking {
    const newCompartment: CompartmentMarking = {
      classificationLevel,
      categoryToCompartments: {},
    };

    if (availableCategoryCompartments) {
      const firstCategory = Object.keys(availableCategoryCompartments)[0];
      if (firstCategory) {
        newCompartment.categoryToCompartments[firstCategory] = [];
      } else {
        console.error("There were no available category compartments.");
      }
    }
    return newCompartment;
  }

  private getCountryCompartments(countryName: string): CountryCompartments {
    if (!this._authorizationSet) return {};
    return this._authorizationSet[countryName] ?? {};
  }

  // TODO: Long term this will need to handle multiple countries. There is
  // another PBI in place to handle expanding security info to include
  // multiple countries.
  private getDefaultCountryCompartments(): CountryCompartments {
    return this.getCountryCompartments("USA");
  }

  public getDefaultCompartment(classificationOption: ClassificationOption): CompartmentMarking {
    const userCompartmentsAtLevel = this.getCompartmentsAtLevel(classificationOption);
    const newCompartmentMarking = this.createDefaultCompartment(
      classificationOptionToLevel(classificationOption),
      userCompartmentsAtLevel
    );
    return newCompartmentMarking;
  }

  public getCompartments(country?: string): CompartmentMarking[] {
    const countryCompartments = country ? this.getCountryCompartments(country) : this.getDefaultCountryCompartments();
    return Object.keys(countryCompartments).map((level) => ({
      classificationLevel: level as ClassificationLevel,
      categoryToCompartments: countryCompartments[level],
    }));
  }

  public getSpecificCompartmentsAsString(compartmentName: string, country?: string): string[] {
    const countryCompartments = country ? this.getCountryCompartments(country) : this.getDefaultCountryCompartments();
    const compartments = Object.keys(countryCompartments).map((level) => ({
      classificationLevel: level as ClassificationLevel,
      categoryToCompartments: countryCompartments[level],
    }));
    let compartmentsAsStrings: string[] = [];
    compartments.forEach((compartment) => {
      if (compartment.categoryToCompartments[compartmentName]) {
        compartmentsAsStrings = compartmentsAsStrings.concat(compartment.categoryToCompartments[compartmentName]);
      }
    });
    return compartmentsAsStrings;
  }

  public getMaxClearance(): ClassificationLevel {
    return ClearedAccess.getHighestLevel(this._clearance);
  }

  public isSciCleared(): boolean {
    const maxClearance = this.getMaxClearance();
    if (maxClearance == ClassificationLevel.TOP_SECRET) {
      const topSecretCompartments = this.getCompartmentsAtLevel(ClassificationLevel.TOP_SECRET)["SCI"];
      return topSecretCompartments && topSecretCompartments.length > 0;
    }
    return false;
  }

  public getAllClearanceLevels(): ClassificationLevel[] {
    const maxClearance = this.getMaxClearance();
    return getUserClassificationLevels(maxClearance);
  }

  public getHumanReadableClearance(isSensitiveSecurityContext: boolean = true): string[] {
    return this.getAllClearanceLevels().map((value) => {
      return classificationOptionsAsText(value, isSensitiveSecurityContext);
    }) as string[];
  }

  public toHumanReadableClearance(classLevel: ClassificationLevel): string {
    switch (classLevel) {
      case "U": {
        return "UNCLASSIFIED";
      }
      case "C": {
        return "CONFIDENTIAL";
      }
      case "S": {
        return "SECRET";
      }
      case "TS": {
        return "TOP SECRET";
      }
      default: {
        console.warn(`unable to process clearance ${classLevel}`);
        return "";
      }
    }
  }

  // TODO: Consider not directly exposing JWT structure
  public getCompartmentsAtLevel(classificationOption?: ClassificationOption): ClassificationCompartments {
    const countryCompartments = this.getDefaultCountryCompartments();
    switch (classificationOption) {
      case ClassificationLevel.TOP_SECRET:
        return countryCompartments[ClassificationLevel.TOP_SECRET];
      case ClassificationLevel.SECRET:
        return this.expandCompartments(
          [ClassificationLevel.SECRET, ClassificationLevel.TOP_SECRET],
          countryCompartments
        );
      case ClassificationLevel.CONFIDENTIAL:
        return this.expandCompartments(
          [ClassificationLevel.CONFIDENTIAL, ClassificationLevel.SECRET, ClassificationLevel.TOP_SECRET],
          countryCompartments
        );
      default:
        return {};
    }
  }
}

export interface ClearanceInfo {
  "urn:us:gov:ic:uias:clearance": string[];
  "urn:us:gov:dod:sap:authorizationSet": AuthorizationSet;
}
