import React, { useEffect, useMemo, useState } from 'react';
import { SafesimButton, SafesimText, SafesimNumericInput, SafesimPopover } from './allSafesimComponents';
import { SafesimCardCollapse } from './SafesimCardCollapse';
import { SafesimValueDisplay } from './SafesimValueDisplay';
import { NumberOnlyRegex } from '../utilities/stringModifiers';
import { SafesimControlGroup } from './SafesimControlGroup';

interface Props {
  label: string;
  labelContent?: JSX.Element;
  value: number[];
  editDisabledDisplayString?: string;
  onValueChange: (value: number[]) => void;
  validator?: (value: number) => boolean;
  labelClassName?: string;
  valueClassName?: string;
  units?: string;
  decimal?: boolean;
  editDisabled?: boolean;
  unitPopoverContent?: JSX.Element;
  modified: boolean;
}

export const SafesimFormSeriesInput = (props: Props): JSX.Element => {
  const {
    label,
    labelContent,
    value,
    editDisabledDisplayString,
    onValueChange,
    validator,
    labelClassName,
    valueClassName,
    units,
    decimal,
    editDisabled = false,
    unitPopoverContent,
    modified,
  } = props;

  // if there is a validator, store array of values as valid/invalid
  const [isValuesValid, setIsValuesValid] = useState<boolean[] | undefined>(undefined);

  // if value loads or changes, verify validity
  useEffect(() => {
    setIsValuesValid(validator ? value.map((val) => validator(val)) : undefined);
  }, [value, validator]);

  const collapsedLabel = useMemo(
    () => (
      <SafesimValueDisplay
        className={valueClassName}
        editAction={false}
        label={<SafesimText ellipsize={true}>{label}</SafesimText>}
        value={editDisabledDisplayString ?? value.join(', ')}
        units={units}
        rightSideContent={labelContent}
      />
    ),
    [label, editDisabledDisplayString, value, labelContent, units, valueClassName]
  );

  return (
    <>
      {editDisabled ? (
        collapsedLabel
      ) : (
        <SafesimCardCollapse header={label} headerContent={labelContent} isOpen={true} hideCaret={true}>
          <div className={modified ? 'border-green' : ''} style={{ padding: '5px' }}>
            {units !== undefined ? (
              <SafesimText className={`alignTextCenter ${labelClassName}`}>
                Units:{' '}
                <SafesimPopover content={!editDisabled && unitPopoverContent} disabled={!unitPopoverContent} minimal={true}>
                  <SafesimButton>{units}</SafesimButton>
                </SafesimPopover>
              </SafesimText>
            ) : null}
            {value.map((val, index, array) => {
              return (
                <SafesimControlGroup key={index}>
                  <SafesimNumericInput
                    className={`${valueClassName} PROOF ${validator && isValuesValid ? (!isValuesValid[index] ? 'makeRed' : '') : ''}`}
                    key={index}
                    value={val}
                    onValueChange={(num: number, str: string) => {
                      if ((str === '' || NumberOnlyRegex.test(str)) && (decimal || !str.includes('.'))) {
                        const newValue = [...value];
                        newValue[index] = num;
                        onValueChange(newValue);

                        const isValid = validator?.(num);
                        // if tracking validity, update state
                        if (isValid !== undefined && isValuesValid) {
                          const newValidArray = [...isValuesValid];
                          newValidArray[index] = isValid;
                          setIsValuesValid(newValidArray);
                        }
                      }
                    }}
                    disabled={false}
                    fill={true}
                  />
                  <SafesimButton
                    icon={'minus'}
                    disabled={array.length === 1}
                    onClick={() => {
                      const newValue = [...value];
                      newValue.splice(index, 1);
                      onValueChange(newValue);
                    }}
                  />
                </SafesimControlGroup>
              );
            })}
            <SafesimControlGroup fill style={{ margin: '2px', width: '98%' }}>
              <SafesimButton
                icon={'plus'}
                onClick={() => {
                  onValueChange([...value, 0]);
                }}
              />
            </SafesimControlGroup>
          </div>
        </SafesimCardCollapse>
      )}
    </>
  );
};
