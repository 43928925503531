/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ModelInfoInput } from "Api";
import React from "react";
import { WikiCardInfo } from "../Shared/WikiStaticTextBlock";
import { getRelativeLastModifiedTime, formattedDateTime } from "../Api/ApiSerialization";
import { AssetDetailsState } from "../Asset/Editor/AssetDetailsState";
import { AssetWikiDetails } from "../Asset/Editor/AssetWikiDetails";
import { ModelRelationPane } from "./ModelRelationPane";
import { ModelDetailsPageAction } from "./ModelDetailsState";
import { AssetIconWithText } from "../Asset/Shared/AssetIcon";

export function ModelDetails({
  state,
  dispatch,
}: {
  state: AssetDetailsState<ModelInfoInput>;
  dispatch: React.Dispatch<ModelDetailsPageAction>;
}): JSX.Element {
  const wikiCardInfo = React.useMemo<WikiCardInfo[]>(
    () => [
      {
        field: "Resource Type",
        value: <AssetIconWithText type="MODEL" />,
      },
      { field: "Title", value: state.edited.asset.metadata.title },
      { field: "Subject", value: state.edited.asset.metadata.subject },
      { field: "Simulation Frameworks", value: state.edited.asset.metadata.simulationFrameworks?.toString() },
      { field: "Description", value: state.edited.asset.metadata.description },
      { field: "Last Updated", value: getRelativeLastModifiedTime(state.creationDate, state.lastSaveDate) },
      { field: "Created On", value: formattedDateTime(state.creationDate) },
      { field: "Keywords", value: state.edited.asset.metadata.keywords?.toString() },
    ],
    [state.edited.asset.metadata, state.creationDate, state.lastSaveDate]
  );

  return (
    <AssetWikiDetails
      state={state}
      dispatch={dispatch}
      wikiCardInfo={wikiCardInfo}
      type="MODEL"
      assetPane={<ModelRelationPane dispatch={dispatch} relations={state.edited.relations} />}
    />
  );
}
