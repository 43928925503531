/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { ScenarioSpec, ScenarioWizardStep, toScenarioInput } from "./ScenarioSpec";
import { StepBody } from "./StepBody";
import { gql, useMutation } from "@apollo/client";
import addScenario from "../../Api/Gql/AddScenario.gql";
import { handleApolloError } from "../../Shared/Errors";
import { scenarioDetailsEditPagePath } from "../../Shared/NavigationLinks";
import { useHistory } from "react-router-dom";
import { AssetDetailsPage } from "../../Asset/Editor/AssetDetailsPage";
import { AssetPane } from "../../Asset/Shared/AssetPane";
import { GEOLOCATION_FIELD, START_TIME_FIELD, ScenarioDetailsReducer } from "../../Scenario/ScenarioDetailsState";
import { ScenarioRelationPane } from "../../Scenario/ScenarioRelationPane";
import { ClassificationHeader } from "../../Shared/ClassificationHeader";
import { AssetDetailsState } from "../../Asset/Editor/AssetDetailsState";
import { COMMON_FIXED_SCHEMA_FIELDS } from "../../Asset/Editor/AssetMetadataFields";
import { FieldSpec } from "../../Asset/Editor/FieldSpec";
import { useMarkingsDisableSave } from "../../Shared/ApiHooks";

export function RefineScenarioStep({
  scenarioSpec,
  onStepChange,
}: {
  scenarioSpec?: ScenarioSpec;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  const SCENARIO_FIXED_SCHEMA_FIELDS: FieldSpec[] = [
    ...COMMON_FIXED_SCHEMA_FIELDS,
    START_TIME_FIELD,
    GEOLOCATION_FIELD,
  ];

  const scenarioInput = scenarioSpec?.scenarioInput ?? toScenarioInput(scenarioSpec);

  const [state, dispatch] = React.useReducer(
    ScenarioDetailsReducer,
    new AssetDetailsState("SCENARIO", SCENARIO_FIXED_SCHEMA_FIELDS, scenarioInput, scenarioSpec?.models ?? [], true)
  );

  const history = useHistory();

  const [addScenarioMutation, { loading: creatingScenario }] = useMutation(gql(addScenario), {
    variables: { scenario: state.edited.asset },
    onCompleted: () => {
      const editNewScenarioPath = scenarioDetailsEditPagePath(state.edited.asset.id);
      history.push(editNewScenarioPath);
    },
    onError: (error) => handleApolloError(error, `Error creating scenario;`),
  });

  const createScenario = React.useCallback(() => {
    addScenarioMutation();
  }, [addScenarioMutation]);

  const handleStepChange = React.useCallback(
    (newStep: ScenarioWizardStep) => {
      switch (newStep) {
        case "Create Scenario":
          createScenario();
          break;
        case "Pick Models":
          onStepChange(newStep);
          break;
        default:
          throw Error(`Could not transition to ${newStep}; expected either 'Pick Models' or 'Create Scenario'`);
      }
    },
    [createScenario, onStepChange]
  );

  const isSaveDisabled = useMarkingsDisableSave(state.edited.asset.securityMarkings);

  const propertiesStyle = { minWidth: "75%" };

  return (
    <StepBody
      stepName={`Create Scenario`}
      previousStep={"Pick Models"}
      previousDisabled={false}
      nextLoading={creatingScenario}
      nextStep="Create Scenario"
      nextDisabled={isSaveDisabled}
      onStepChange={handleStepChange}
      isFinalStep={true}
    >
      <AssetDetailsPage
        headerActionButtons={
          <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
        }
      >
        <AssetPane state={state} dispatch={dispatch} propertiesStyle={propertiesStyle} />
        <ScenarioRelationPane dispatch={dispatch} relations={state.edited.relations} />
      </AssetDetailsPage>
    </StepBody>
  );
}
