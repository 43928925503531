/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import { Button, KIND, SIZE } from "baseui/button";
import { KmButtons } from "../DesignSystem/KmButtons";
import { PopoverPlacement, StatefulPopover } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { ChevronDown } from "baseui/icon";
import { OverflowActions } from "./OverflowMenu";

export function IconButton({
  dataTestId,
  style,
  onClick,
  children,
}: {
  dataTestId?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <Button
      size={SIZE.mini}
      overrides={
        (KmButtons.style.icon,
        {
          BaseButton: {
            style: {
              ...style,
            },
            props: {
              "data-testid": dataTestId,
            },
          },
        })
      }
      onClick={onClick}
      kind={"tertiary"}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </Button>
  );
}

export function ButtonDropdown({
  buttonContent,
  popoverPlacement,
  menuActions,
  dataTestId,
  listProperties,
}: {
  buttonContent: React.ReactNode;
  popoverPlacement: PopoverPlacement;
  menuActions: OverflowActions[];
  dataTestId?: string;
  listProperties?: React.CSSProperties;
}): JSX.Element {
  return (
    <>
      <StatefulPopover
        focusLock
        placement={popoverPlacement}
        content={({ close }) => (
          <StatefulMenu
            items={menuActions}
            onItemSelect={(selectedItem) => {
              close();
              selectedItem.item.onClick();
            }}
            overrides={{
              List: { style: listProperties },
            }}
          />
        )}
      >
        <Button data-testid={dataTestId} kind={KIND.tertiary} endEnhancer={() => <ChevronDown size={24} />}>
          {buttonContent}
        </Button>
      </StatefulPopover>
    </>
  );
}
