import { logger, consoleTransport } from 'react-native-logs';

/**
 * Const value used to indicate if the compilation mode is for production or dev/test
 * useful for production specific optimization and dev/test testing
 */
export const __DEV__ = process.env.NODE_ENV !== 'production';

/**
 * Standard configuration for the react-native-logs logger.
 * All logs always go to console
 * Web color scheme is utilized
 * Standard log levels
 * logging done async
 */
const config = {
  transport: consoleTransport,
  severity: __DEV__ ? 'debug' : 'info',
  transportOptions: {
    colors: `web`,
  },
  levels: {
    debug: 0,
    warning: 1,
    info: 2,
    error: 3,
  },
  async: true,
  dateFormat: 'time',
  printLevel: true,
  printDate: true,
  enabled: true,
};

/**
 * Const reference to the standard logger object
 * All logging should be done utilizing this item
 */
export const wrappedLogger: any = logger.createLogger(config);
