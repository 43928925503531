/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import * as React from "react";
import { TaskInfo, TaskList, TaskListSummary } from "../../Api/Api";
import { CenteredContent, EndAnchoredRow, VerticalStack } from "../../DesignSystem/Containers";
import { TaskListTable } from "./TaskListTable";
import { HeadingLarge, LabelLarge } from "baseui/typography";
import { TaskDetails } from "./TaskDetails";
import { Uuid } from "../../Utils/Types";
import { TaskTable } from "./TaskTable";
import { BlockProps } from "baseui/block";
import { Button } from "baseui/button";
import { handleApolloError } from "../../Shared/Errors";
import GetTaskListSummaries from "../../Api/Gql/GetTaskListSummaries.gql";
import RemoveTaskList from "../../Api/Gql/RemoveTaskList.gql";
import GetTaskLists from "../../Api/Gql/GetTaskLists.gql";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingPlaceholder } from "../../Shared/LoadingPlaceholder";
import { UploadTaskListModal } from "./UploadTaskListModal";
import { notify } from "../../Shared/Notify";

export const TaskListPage = (): JSX.Element => {
  const { data, loading, refetch } = useQuery(gql(GetTaskListSummaries), {
    onError: (error) => handleApolloError(error, `Error getting task lists`),
  });

  const [addTaskListModalIsOpen, setTaskListModalIsOpen] = React.useState(false);

  const handleImport = React.useCallback(() => {
    setTaskListModalIsOpen(true);
  }, []);

  const taskLists = data?.getTaskListSummaries as TaskListSummary[];

  return (
    <>
      <UploadTaskListModal
        isOpen={addTaskListModalIsOpen}
        onUploadComplete={(newTaskList) => {
          if (newTaskList) {
            refetch();
          }
        }}
        onClose={() => setTaskListModalIsOpen(false)}
      />
      {loading ? (
        <LoadingPlaceholder message={`Loading task list`} />
      ) : (
        <div style={{ margin: "1rem" }}>
          <TaskListPane taskLists={taskLists} onImport={handleImport} />
        </div>
      )}
    </>
  );
};

function TaskListPane({ taskLists, onImport }: { taskLists: TaskListSummary[]; onImport: () => void }): JSX.Element {
  const [selectedTaskListId, setSelectedTaskListId] = React.useState<Uuid>();
  const [selectedTask, setSelectedTask] = React.useState<TaskInfo | undefined>();

  const [removeTaskList] = useMutation(gql(RemoveTaskList), {
    refetchQueries: [gql(GetTaskListSummaries)],
  });

  const handleDeleteTaskList = React.useCallback(
    (id: Uuid, title: string) => {
      removeTaskList({ variables: { taskListId: id } }).then(() => {
        setSelectedTaskListId(undefined);
        setSelectedTask(undefined);
        notify.positive(`List named ${title} deleted`);
      });
    },
    [removeTaskList]
  );

  const handleTaskListClick = React.useCallback(
    (_event, dataId) => {
      setSelectedTaskListId(dataId);
      setSelectedTask(undefined);
    },
    [setSelectedTaskListId]
  );

  const handleTaskClick = React.useCallback((_event, task) => {
    setSelectedTask(task);
  }, []);

  const { data: taskListData, loading: taskListLoading } = useQuery(gql(GetTaskLists), {
    variables: {
      taskListIds: selectedTaskListId ? [selectedTaskListId] : [],
    },
    onError: (error) => handleApolloError(error, `Error getting tasks for task list ${selectedTaskListId}`),
  });

  const taskList = taskListData?.getTaskLists[0] as TaskList;

  const taskListTableProps: BlockProps = {
    overrides: {
      Block: {
        style: ({ _$theme }) => ({
          width: "20rem",
          flexGrow: 0,
        }),
      },
    },
  };

  const selectedTasks = selectedTask ? [selectedTask] : [];
  return (
    <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale1200">
      <FlexGridItem {...taskListTableProps}>
        <VerticalStack gap={"1rem"} style={TASK_COLUMN_STYLE}>
          <EndAnchoredRow style={{ paddingTop: 5, paddingLeft: 5 }}>
            <HeadingLarge>{`Task Lists`}</HeadingLarge>
            <Button onClick={onImport}>{"Import"}</Button>
          </EndAnchoredRow>
          <TaskListTable
            taskLists={taskLists}
            selectedId={selectedTaskListId}
            onClick={handleTaskListClick}
            onDelete={handleDeleteTaskList}
          />
        </VerticalStack>
      </FlexGridItem>
      <FlexGridItem>
        {taskList ? (
          <VerticalStack gap={"1rem"} style={TASK_COLUMN_STYLE}>
            <HeadingLarge>{`${taskList.name} Tasks`}</HeadingLarge>
            <TaskTable tasks={taskList.tasks} selectedTasks={selectedTasks} onClick={handleTaskClick} />
          </VerticalStack>
        ) : taskListLoading ? (
          <LoadingPlaceholder message={`Loading tasks...`} />
        ) : (
          <CenteredContent height={TASK_PANE_HEIGHT}>
            <LabelLarge>{`Select a task list from the left to browse what's available, or import a new custom task list`}</LabelLarge>
          </CenteredContent>
        )}
      </FlexGridItem>
      <FlexGridItem>
        {taskList &&
          (selectedTask ? (
            <div style={TASK_COLUMN_STYLE}>
              <TaskDetails taskId={selectedTask.id} />
            </div>
          ) : (
            <CenteredContent height={TASK_PANE_HEIGHT}>
              <LabelLarge>{`Choose a task from the left to see its details.`}</LabelLarge>
            </CenteredContent>
          ))}
      </FlexGridItem>
    </FlexGrid>
  );
}

const TASK_PANE_HEIGHT = "70vh";

const TASK_COLUMN_STYLE: React.CSSProperties = {
  overflow: "auto",
  height: TASK_PANE_HEIGHT,
};
