import React from 'react';
import { SubMenu, MenuItem } from 'react-pro-sidebar';
import PropTypes from 'prop-types';

export const SafesimSidebarMenuItem = (props) => {
  const { title, children, active, ...other } = props;

  if (children?.length)
    return (
      <SubMenu title={title} {...other}>
        {children}
      </SubMenu>
    );

  return (
    <MenuItem active={active} {...other}>
      {title}
    </MenuItem>
  );
};

SafesimSidebarMenuItem.propTypes = {
  icon: PropTypes.node,
  active: PropTypes.bool,
  title: PropTypes.node,
  children: PropTypes.arrayOf(SafesimSidebarMenuItem),
};
