/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import {
  faObjectGroup,
  faChartBar,
  faCircleQuestion,
  faBookOpen,
  faAlignJustify,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssetType } from "Api";
import { isString } from "lodash";
import React from "react";
import { prettyAssetType } from "../../Api/ApiExtensions";

export function AssetIconWithText({
  type,
  style,
  size,
  title,
}: {
  type: AssetType;
  style?: React.CSSProperties;
  size?: "lg";
  title?: string;
}): JSX.Element {
  const displayText = title ?? prettyAssetType(type);

  return (
    <>
      <AssetIcon type={type} style={style} size={size} />
      <span style={{ paddingLeft: ".5rem" }}>{displayText}</span>
    </>
  );
}

export default function AssetIcon({
  type,
  style,
  size,
  title,
}: {
  type: AssetType;
  style?: React.CSSProperties;
  size?: "lg";
  title?: string;
}): JSX.Element {
  switch (type) {
    case "MODEL":
      return <FontAwesomeIcon icon={faObjectGroup} size={size} title={title ?? "Model"} style={style} />;
    case "PLATFORM":
      return <FontAwesomeIcon icon={faSitemap} size={size} title={title ?? "Platform"} style={style} />;
    case "SCENARIO":
      return <FontAwesomeIcon icon={faChartBar} size={size} title={title ?? "Scenario"} style={style} />;
    case "STUDY":
      // Note that we may need to have a property of the this component that
      // scales the overlapping icons if you change the size of the base icon.
      return (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faBookOpen} size={size} title={title ?? "Study"} transform="grow-3" style={style} />
          <FontAwesomeIcon
            icon={faAlignJustify}
            size="sm"
            title={title ?? "Study"}
            color="white"
            transform="shrink-4 left-6"
          />
          <FontAwesomeIcon
            icon={faAlignJustify}
            size="sm"
            title={title ?? "Study"}
            color="white"
            transform="shrink-4 right-10"
          />
        </span>
      );
    default:
      return <FontAwesomeIcon icon={faCircleQuestion} size={isString(size) ? size : "lg"} title="Unknown type" />;
  }
}
