query GetModelUserDefinedSchema {
  getModelUserDefinedSchema {
    id
    fields {
      selectMultiple {
        id
        attributeName
        valueOptions {
          value
          description
        }
      }
    }
  }
}
