/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { SecurityControlColorConfig, ClassificationLevel, CompartmentMarking, SecurityMarkings } from "../Api/Api";
import {
  classificationLevelsAsPortionText,
  classificationOptionsAsText,
  classificationOptionsAsColors,
} from "../Api/ApiSerialization";
import { Option } from "baseui/select";
import { isPermittedForAllUsers } from "../Utils/Auth";
import { partition } from "lodash";
import { ClearedAccess } from "../Api/ClearedAccess";

/////////////////////////////////////////////////////////////////
// Standards for how security markings are displayed are governed by
// the DoD 5200 standard:
// https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodm/520001m_vol2.pdf
/////////////////////////////////////////////////////////////////

export type ClassificationOption = ClassificationLevel | "FOUO" | "CUI" | "TS-SCI";

export interface ClassificationComboOption extends Option {
  classificationOption: ClassificationOption;
}

// TODO: take clearance info object instead of classificationlevel
export const getUserClassificationOptions = (
  clearanceLevel: ClassificationLevel,
  includeUnclassVariations: boolean = true
): ClassificationOption[] => {
  const options = [];
  options.push(ClassificationLevel.UNCLASSIFIED);

  if (includeUnclassVariations) {
    options.push("FOUO");
    options.push("CUI");
  }

  if (clearanceLevel == ClassificationLevel.UNCLASSIFIED) return options;
  options.push(ClassificationLevel.CONFIDENTIAL);
  if (clearanceLevel == ClassificationLevel.CONFIDENTIAL) return options;
  options.push(ClassificationLevel.SECRET);
  if (clearanceLevel == ClassificationLevel.SECRET) return options;
  options.push(ClassificationLevel.TOP_SECRET);
  return options;
};

export const getUserClassificationLevels = (clearanceLevel: ClassificationLevel): ClassificationLevel[] => {
  const options = [];
  options.push(ClassificationLevel.UNCLASSIFIED);
  if (clearanceLevel == ClassificationLevel.UNCLASSIFIED) return options;
  options.push(ClassificationLevel.CONFIDENTIAL);
  if (clearanceLevel == ClassificationLevel.CONFIDENTIAL) return options;
  options.push(ClassificationLevel.SECRET);
  if (clearanceLevel == ClassificationLevel.SECRET) return options;
  options.push(ClassificationLevel.TOP_SECRET);
  return options;
};

// TODO: FOUO / CUI should probably no longer be in here.

export function classificationOptionToLevel(classificationOption: ClassificationOption): ClassificationLevel {
  switch (classificationOption) {
    case ClassificationLevel.UNCLASSIFIED:
    case "FOUO":
    case "CUI":
      return ClassificationLevel.UNCLASSIFIED;
    case ClassificationLevel.CONFIDENTIAL:
      return ClassificationLevel.CONFIDENTIAL;
    case ClassificationLevel.SECRET:
      return ClassificationLevel.SECRET;
    case ClassificationLevel.TOP_SECRET:
      return ClassificationLevel.TOP_SECRET;
    default:
      return null;
  }
}

// TODO: FOUO / CUI should probably no longer be in here.

export function getClassificationOptionFromMarkings(securityMarkings: SecurityMarkings): ClassificationOption {
  if (securityMarkings) {
    switch (securityMarkings.classificationLevel) {
      case ClassificationLevel.UNCLASSIFIED:
        if (securityMarkings.compartments.length > 0) {
          if (securityMarkings.compartments.filter((value) => value.categoryToCompartments["FOUO"]).length > 0) {
            return "FOUO";
          } else if (securityMarkings.compartments.filter((value) => value.categoryToCompartments["CUI"]).length > 0) {
            return "CUI";
          }
        } else {
          return ClassificationLevel.UNCLASSIFIED;
        }
        break;
      case ClassificationLevel.CONFIDENTIAL:
        return ClassificationLevel.CONFIDENTIAL;
      case ClassificationLevel.SECRET:
        return ClassificationLevel.SECRET;
      case ClassificationLevel.TOP_SECRET:
        return ClassificationLevel.TOP_SECRET;
      default:
        return null;
    }
  } else {
    return null;
  }
}

export const getClassificationComboOptions = (
  isSensitiveSecurityContext: boolean,
  userClearanceLevel: ClassificationLevel
): ClassificationComboOption[] => {
  const userClassificationOptions = getUserClassificationOptions(userClearanceLevel, false);
  return userClassificationOptions.map((classLevel) => {
    return {
      id: classLevel,
      classificationOption: classLevel,
      label: classificationOptionsAsText(classLevel, isSensitiveSecurityContext),
    };
  });
};

export function generatePortionMarkingsText(
  sensitiveSecurityContext: boolean,
  securityMarkings?: SecurityMarkings
): string {
  const rawText = generateSecurityMarkingsText(sensitiveSecurityContext, securityMarkings, true);
  return rawText.length ? `(${rawText})` : "";
}

export function generateSecurityBannerText(
  sensitiveSecurityContext: boolean,
  securityMarkings?: SecurityMarkings
): string {
  const rawText = generateSecurityMarkingsText(sensitiveSecurityContext, securityMarkings, false);
  return rawText.length ? rawText : "Unspecified";
}

export function generateShortBannerTextFromUserClearance(
  clearedAccess: ClearedAccess,
  sensitiveSecurityContext: boolean
): string {
  return generateBannerTextFromClearedAccess(clearedAccess, sensitiveSecurityContext, false);
}

export function generateTooltipTextFromUserClearance(
  clearedAccess: ClearedAccess,
  sensitiveSecurityContext: boolean
): string {
  return generateBannerTextFromClearedAccess(clearedAccess, sensitiveSecurityContext, true);
}

function controlledUnclassMarking(
  disseminationControls: string[] | undefined,
  sensitiveSecurityContext: boolean,
  portion: boolean | undefined
): string {
  let classificationText;
  if (sensitiveSecurityContext) {
    classificationText = !portion ? "UNCLASSIFIED" : "U";
  } else {
    classificationText = !portion ? "Cherry - 1" : "C1";
  }

  let remainingDisseminationControls = disseminationControls ?? [];
  if (remainingDisseminationControls.length === 0) {
    return classificationText;
  }

  let markingText = "";

  if (remainingDisseminationControls.includes("CUI")) {
    const cuiText = sensitiveSecurityContext ? "CUI" : !portion ? "Cherry - 1b" : "C1b";
    markingText += cuiText;
    remainingDisseminationControls = remainingDisseminationControls.filter((control) => control !== "CUI");
  }

  if (remainingDisseminationControls.includes("FOUO")) {
    let fouoText;
    if (sensitiveSecurityContext) {
      fouoText = !portion ? "FOR OFFICIAL USE ONLY" : "U//FOUO";
    } else {
      fouoText = !portion ? "Cherry - 1a" : "C1a";
    }

    if (markingText.length > 0) {
      markingText += "//";
    }
    markingText += fouoText;
    remainingDisseminationControls = remainingDisseminationControls.filter((control) => control !== "FOUO");
  }

  const otherControlsText = generateDisseminationCompartmentText(remainingDisseminationControls);
  if (otherControlsText.length > 0) {
    if (markingText.length === 0) {
      markingText += classificationText;
    }
    markingText += otherControlsText;
  }
  return markingText;
}

function generateSecurityMarkingsText(
  sensitiveSecurityContext: boolean,
  securityMarkings?: SecurityMarkings,
  portion?: boolean
): string {
  if (!securityMarkings) return "";

  const classificationText = generateClassificationText(
    securityMarkings?.classificationLevel,
    sensitiveSecurityContext,
    portion
  );
  if (isPermittedForAllUsers(securityMarkings?.classificationLevel)) {
    return controlledUnclassMarking(securityMarkings?.disseminationControls, sensitiveSecurityContext, portion);
  }
  if (!classificationText) {
    return "";
  }
  const fullCompartmentList = generateFullCompartmentList(securityMarkings.compartments);
  const compartmentText = generateCompartmentText(fullCompartmentList, portion, securityMarkings.disseminationControls);

  return `${classificationText}${compartmentText}`;
}

function generateBannerTextFromClearedAccess(
  userClearance: ClearedAccess,
  sensitiveSecurityContext: boolean,
  shortenMarkingText?: boolean
): string {
  const highestUserLevel = userClearance.getMaxClearance();
  const classificationText = generateClassificationText(highestUserLevel, sensitiveSecurityContext, shortenMarkingText);

  if (!classificationText) {
    console.error(
      "Failed to determine the classification text from the user's clearance info. The user's clearance info is: ",
      userClearance
    );
    return "Could not determine user's clearance.  Please contact an administrator.";
  }

  const compartmentsAtHighestLevel = userClearance.getCompartmentsAtLevel(highestUserLevel);
  const compartmentText = generateCompartmentTextWithoutDisseminationControls(
    compartmentsAtHighestLevel,
    shortenMarkingText
  );

  return `${classificationText}${compartmentText}`;
}

function generateClassificationText(
  classificationLevel: ClassificationLevel,
  isSensitiveSecurityContext: boolean,
  isPortionMarking: boolean = false
): string {
  return !isPortionMarking
    ? classificationOptionsAsText(classificationLevel, isSensitiveSecurityContext)
    : classificationLevelsAsPortionText(classificationLevel, isSensitiveSecurityContext);
}

export function generateBannerColorFromClearedAccess(
  userClearance: ClearedAccess,
  securityControlColors: SecurityControlColorConfig[]
): SecurityControlColorConfig {
  const maxPermittedSecurityControl: ClassificationOption = userClearance.isSciCleared()
    ? "TS-SCI"
    : userClearance.getMaxClearance();
  const classificationColor = classificationOptionsAsColors(maxPermittedSecurityControl, securityControlColors);

  if (!classificationColor) {
    console.error(
      `Failed to determine the classification color from the user's clearance info. The user's` +
        ` clearance info is: ${userClearance}. Defaulting banner color to gray.`
    );
    return { securityControl: "", backgroundColor: "#808080", textColor: "#000000" };
  }

  return classificationColor;
}

function generateFullCompartmentList(compartments?: CompartmentMarking[]): Record<string, string[]> {
  const fullCompartmentList: Record<string, string[]> = {};
  if (compartments && compartments.length > 0) {
    const keyIndex = 0;
    const valueIndex = 1;

    // TODO: Look at Record.ts or Array.ts to see if there are helpers to simplify this.
    // Banner will show highest level of classification with compartments from all classification levels.
    compartments?.forEach((element) => {
      const categoryToCompartments = element?.categoryToCompartments ?? [];
      Object.entries(categoryToCompartments).forEach((category) => {
        category[valueIndex].forEach((compartment) => {
          const categoryName = category[keyIndex];
          if (!fullCompartmentList[categoryName]?.includes(compartment)) {
            fullCompartmentList[categoryName] = fullCompartmentList[categoryName]?.concat(compartment) ?? [compartment];
          }
        });
      });
    });
  }
  return fullCompartmentList;
}

export function generateCompartmentText(
  categoryToCompartments: Record<string, string[]>,
  portion?: boolean,
  disseminationControls?: string[]
): string {
  let compartmentsText = generateCompartmentTextWithoutDisseminationControls(categoryToCompartments);
  // add all dissemination controls
  if (disseminationControls && disseminationControls?.length > 0) {
    compartmentsText += generateDisseminationCompartmentText(disseminationControls);
  }
  return compartmentsText;
}

export function generateCompartmentTextWithoutDisseminationControls(
  categoryToCompartments: Record<string, string[]>,
  portion?: boolean
): string {
  let compartmentsText = "";
  if (categoryToCompartments) {
    if (categoryToCompartments["SCI"]) {
      compartmentsText += generateSciCompartmentText(categoryToCompartments["SCI"]);
    }
    if (categoryToCompartments["SAR"]) {
      compartmentsText += generateSarCompartmentText(categoryToCompartments["SAR"], portion);
    }
    // add any remaining compartments
    Object.entries(categoryToCompartments)
      .filter(([category]) => category != "SCI" && category != "SAR")
      .forEach(([category, compartments]) => {
        //adds '//' between categories instead of just '/'
        compartmentsText += "/";
        const displayCompartments = [...compartments].sort();
        displayCompartments.forEach((compartment: string) => {
          compartmentsText += `/${category + `-` + compartment}`;
        });
      });
  }

  return compartmentsText;
}

export function generateSciCompartmentText(sciEntries: string[]): string {
  let sciText = "";
  // add second '/' to separate from the classification level
  const sortedSciEntries = [...sciEntries].sort();
  sciText += "/";
  sortedSciEntries.forEach((element) => {
    sciText += `/${element}`;
  });
  return sciText;
}

function generateSarCompartmentText(sarEntries: string[], portion?: boolean): string {
  const pidFormat = getPidFormat(sarEntries[0]);
  let sarText = "";
  // add second '/' to separate from the classification level
  const sortedSarEntries = [...sarEntries].sort();
  sarText += "/";
  if (sortedSarEntries.length >= 3 && !portion) {
    sarText += `/SAR-MULTIPLE PROGRAMS`;
  } else {
    sortedSarEntries.forEach((compartment: string) => {
      sarText += `/SAR-${getPid(compartment, pidFormat)}`;
    });
  }
  return sarText;
}

export enum PidFormat {
  PID_OR_UNKNOWN,
  AGENCY_AND_PID_AND_NUMBER,
  AGENCY_AND_PID,
  PID_AND_NUMBER,
}

export function getPidFormat(sampleCompartment: string | null | undefined): PidFormat {
  if (!sampleCompartment) return PidFormat.PID_OR_UNKNOWN;

  // The expected compartment string from MXS should be agency:pid. Depending on the
  // configuration it can also return the following:
  //   pid:number
  //   agency:pid:number
  //
  // Since the config keeps changing we will try to account for all 3 options.

  const parts = sampleCompartment.split(":");

  switch (parts.length) {
    case 1:
      // If it does not contain ":" there is nothing to extract return the input
      return PidFormat.PID_OR_UNKNOWN;
    case 2:
      const mxsId = Number(parts[1]);
      if (isNaN(mxsId)) {
        // We can infer that MXS is sending agency:pid
        return PidFormat.AGENCY_AND_PID;
      } else {
        // We can infer that MXS is sending pid:number
        return PidFormat.PID_AND_NUMBER;
      }
    case 3:
      return PidFormat.AGENCY_AND_PID_AND_NUMBER;
    default:
      // MXS expresses the compartment as agency:pid:number at its most verbose.
      // If there are more than 3 parts we do not know how to hanlde it, just return
      // the input.
      return PidFormat.PID_OR_UNKNOWN;
  }
}

export function getPid(compartment: string, pidFormat: PidFormat): string {
  // TODO: Add validation that compartment is actually in the pid format provided.  As it
  //   stands now you could provide DOD:ABC but say that it supposed to be AGENCY_AND_PID_AND_NUMBER

  const parts = compartment.split(":");

  switch (pidFormat) {
    case PidFormat.PID_AND_NUMBER:
      return parts[0];
    case PidFormat.AGENCY_AND_PID:
    case PidFormat.AGENCY_AND_PID_AND_NUMBER:
      return parts[1];
    case PidFormat.PID_OR_UNKNOWN:
      return compartment;
    default:
      return compartment;
  }
}

function generateDisseminationCompartmentText(disseminationControls: string[]): string {
  // according to 5200 guidelines, these values are separated from all other dissemination controls
  const firstDisseminationControlsList = ["FOUO", "OC", "CI", "NOFORN", "PI", "RELTO", "RELIDO", "FISA", "DO"];
  const [firstDisseminationGroup, secondDisseminationGroup] = partition(disseminationControls, (elem) => {
    return firstDisseminationControlsList.includes(elem);
  });
  let dissControlString = "";
  if (firstDisseminationGroup.length > 0) {
    dissControlString += "/";
    firstDisseminationGroup.forEach((element) => {
      dissControlString += `/${element}`;
    });
  }
  if (secondDisseminationGroup.length > 0) {
    dissControlString += "/";
    secondDisseminationGroup.forEach((element) => {
      dissControlString += `/${element}`;
    });
  }
  return dissControlString;
}

export function getUnusedCompartmentOptions(
  assignedCompartments: ClassificationLevel[],
  isSensitiveSecurityContext: boolean,
  userClearanceLevel: ClassificationLevel
): ClassificationComboOption[] {
  return getClassificationComboOptions(isSensitiveSecurityContext, userClearanceLevel).filter(
    (option) =>
      !assignedCompartments.includes(classificationOptionToLevel(option.classificationOption)) &&
      classificationOptionToLevel(option.classificationOption) != ClassificationLevel.UNCLASSIFIED
  );
}

export function canUserMarkCompartmentAtLevel(
  markingLevel: ClassificationLevel,
  userCompartmentLevel: CompartmentMarking
): boolean {
  return isLevelPermitted(markingLevel, userCompartmentLevel.classificationLevel);
}

function isLevelPermitted(markingLevel?: ClassificationLevel, userClearanceLevel?: ClassificationLevel): boolean {
  const isPermitted = !markingLevel ? true : getUserClassificationOptions(userClearanceLevel).includes(markingLevel);
  return isPermitted;
}
