/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import addStudy from "../Api/Gql/AddStudy.gql";
import { AssetDetailsPage } from "../Asset/Editor/AssetDetailsPage";
import { notify } from "../Shared/Notify";
import { newStudyDetailsState, StudyDetailsReducer } from "./StudyDetailsState";
import { CreateAssetHeaderBar } from "../Asset/Editor/AssetDetailsHeaders";
import { useCopySettings } from "../Asset/Shared/CopyHelpers";
import { handleApolloError } from "../Shared/Errors";
import { Study } from "Api";
import getStudies from "../Api/Gql/GetStudies.gql";
import { AssetPane } from "../Asset/Shared/AssetPane";
import { ClassificationHeader } from "../Shared/ClassificationHeader";
import { initializeAssetCopy } from "../Api/ApiExtensions";

export const StudyDetailsCreatePage = (): JSX.Element => {
  const copySettings = useCopySettings();

  const [state, dispatch] = React.useReducer(
    StudyDetailsReducer,
    newStudyDetailsState(undefined, copySettings.isInCopyMode)
  );

  const [addStudyMutation] = useMutation(gql(addStudy), {
    variables: { study: state.edited.asset },
    onCompleted: () => {
      notify.positive("Study saved");
    },
    onError: (error) => handleApolloError(error, `Error creating study.`),
  });

  const handleAddStudy = React.useCallback((): void => {
    addStudyMutation();
  }, [addStudyMutation]);

  const [studyLoadError, setStudyLoadError] = React.useState<string>();

  // TODO: Consider a useStudy hook to wrap getStudies() and onComplete pass a single, nicely typed study
  const { loading: studyLoading } = useQuery(gql(getStudies), {
    variables: { ids: [copySettings.assetId] },
    fetchPolicy: "network-only",
    skip: !copySettings.isInCopyMode,
    onCompleted: (data) => {
      const studies = data.getStudies as Study[];
      if (studies[0]) {
        initializeAssetCopy(studies[0]);
        dispatch(["resetOriginalAndEditedStudy", studies[0]]);
      } else {
        setStudyLoadError("Study not found; study does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting study data."),
  });

  const propertiesStyle = { minWidth: "100%" };

  return (
    <AssetDetailsPage
      headerActionButtons={
        <>
          <CreateAssetHeaderBar onAddAsset={handleAddStudy} typeString="Study" state={state} dispatch={dispatch} />
          <ClassificationHeader securityMarkings={state.edited.asset.securityMarkings} dispatch={dispatch} />
        </>
      }
      loading={studyLoading}
      loadError={studyLoadError}
    >
      <AssetPane state={state} dispatch={dispatch} propertiesStyle={propertiesStyle} />
    </AssetDetailsPage>
  );
};
