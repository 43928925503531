/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { deepEquals } from "@radiance/safesim_component_library";
import GetFileReferences from "../Api/Gql/GetFileReferences.gql";
import updateFileInfo from "../Api/Gql/UpdateFileInfo.gql";
import { FileInfo, SecurityMarkings, MetadataScan, FileReferenceReport, FileInfoInput, ProtectedUrl } from "Api";
import { Button } from "baseui/button";
import { Value, Select } from "baseui/select";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import React from "react";
import { fileUploadDateAsText, fileSizeAsText, fileUploadUserNameAsText } from "../Api/ApiSerialization";
import { FieldSpec, simpleTextFieldSpec } from "../Asset/Editor/FieldSpec";
import { FieldEditor } from "../Asset/Shared/FieldEditor";
import { Row, VerticalStack } from "../DesignSystem/Containers";
import { useMarkingsDisableSave } from "../Shared/ApiHooks";
import { BackLink } from "../Shared/BackLink";
import { DetailsPage, DetailsHeaderBar } from "../Shared/DetailsPages";
import { handleApolloError, handleAxiosError } from "../Shared/Errors";
import { notify } from "../Shared/Notify";
import { UnsavedChangesPrompt } from "../Shared/UnsavedChangesPrompt";
import { shallowCopy } from "../Utils/Array";
import { AxiosContext } from "../Utils/AuthContext";
import { SiteContext } from "../Utils/SiteProps";
import { FileReferencedModal } from "./FileDetailsPage";
import { FileTopics } from "./ModeledTopics";
import { MetadataScanEditor, MetadataScanDisplayType } from "../Asset/Editor/MetadataScanEditor";
import { defaultMarkings } from "../Api/ApiExtensions";
import { ClassificationHeader } from "../Shared/ClassificationHeader";
import { FILE_FIXED_SCHEMA_FIELDS } from "./FileMetadataFields";

// TODO: FileEditor is giant and complex; we should try and find a way to simplify it.
export const FileEditor = ({
  fileInfo,
  readonly,
  onSave,
}: {
  fileInfo: FileInfo;
  readonly: boolean;
  onSave: () => void;
}): JSX.Element => {
  const fileName = fileInfo.attributes?.name ?? "";
  const fileNameWithoutExtension = fileName.includes(".")
    ? fileName.substring(0, fileName?.lastIndexOf("."))
    : fileName;
  const fileExtension = fileName.includes(".") ? fileName.substring(fileName?.lastIndexOf(".")) : "";
  const validFileRegex = new RegExp('[/\\?%*:|"<>]');

  const [fileTitle, setFileTitle] = React.useState<string>(fileNameWithoutExtension);
  const [isFileTitleValid, setIsFileTitleValid] = React.useState<boolean>(!validFileRegex.test(fileTitle));
  const [securityMarkings, setSecurityMarkings] = React.useState<SecurityMarkings>();

  // Baseline markings help make sure we can safely use the equals method,
  // even when security markings are null.
  const baselineMarkings = React.useMemo(() => {
    const markings = fileInfo?.securityMarkings ?? defaultMarkings();
    setSecurityMarkings(markings);
    return markings;
  }, [fileInfo?.securityMarkings]);

  React.useEffect(() => {
    setFileTitle(fileNameWithoutExtension);
  }, [fileNameWithoutExtension]);

  // TODO: The pattern other editors use is to create an Input object based on the current
  //  metadata, and compare that against the input from the metadata. Switching to that
  //  pattern may let us reduce the number of state variables and simplify this component.
  const securityMarkingsChanged = React.useMemo(() => {
    return !deepEquals(baselineMarkings, securityMarkings);
  }, [baselineMarkings, securityMarkings]);

  const fileNameChanged = React.useMemo(() => {
    const bool = fileName.substring(0, fileName?.lastIndexOf(".")) !== fileTitle;
    return bool;
  }, [fileName, fileTitle]);

  const reversedMetadataScans = React.useMemo<MetadataScan[]>(
    () => shallowCopy(fileInfo?.metadataScans)?.reverse() ?? [],
    [fileInfo.metadataScans]
  );

  const [referencedModalOpen, setReferencedModalOpen] = React.useState(false);
  const [fileReferenceCount, setFileReferenceCount] = React.useState(0);

  // Note that notifyOnNetworkStatusChange is needed here otherwise Apollo will not fire
  // onCompleted after the first time the query is executed.
  const [fileRefQuery] = useLazyQuery(gql(GetFileReferences), {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const report: FileReferenceReport[] = response?.getFileReferences;
      if (report && report[0].references.length > 1) {
        setFileReferenceCount(report[0].references.length);
        setReferencedModalOpen(true);
      } else {
        const newFileInfo: FileInfoInput = {
          id: fileInfo.id,
          securityMarkings: securityMarkings,
          fileNameNoExtension: fileTitle,
        };

        updateFileMutation({ variables: { fileInfo: newFileInfo } });
      }
    },
    onError: (error) => handleApolloError(error, "Error determining if file is referenced."),
  });

  const [updateFileMutation] = useMutation(gql(updateFileInfo), {
    onCompleted: (_) => {
      setReferencedModalOpen(false);
      notify.positive("File updated successfully.");
      onSave();
    },
    onError: (error) => handleApolloError(error, "An error occured while saving file information."),
  });
  const siteProps = React.useContext(SiteContext);
  const axiosContext = React.useContext(AxiosContext);

  const handleDownload = React.useCallback(async () => {
    try {
      const downloadFileTokenResponse = await axiosContext.get(`/downloadFileToken?fileId=${fileInfo.id}`);
      const protectedUrl: ProtectedUrl = downloadFileTokenResponse.data;
      window.location.href = `${siteProps.topUrl}${protectedUrl.urlFragment}`;
    } catch (error) {
      handleAxiosError(error, `Could not get secure download token for file ${fileInfo.id}`);
    }
  }, [axiosContext, fileInfo.id, siteProps.topUrl]);

  const handleSave = React.useCallback(() => {
    fileRefQuery({ variables: { ids: [fileInfo.id] } });
  }, [fileInfo.id, fileRefQuery]);

  const handleContinueWithSave = React.useCallback(() => {
    const newFileInfo: FileInfoInput = {
      id: fileInfo.id,
      securityMarkings: securityMarkings,
      fileNameNoExtension: fileTitle,
    };

    updateFileMutation({ variables: { fileInfo: newFileInfo } });
  }, [fileInfo.id, fileTitle, securityMarkings, updateFileMutation]);

  const handleTextChange = React.useCallback((value) => {
    setFileTitle(value);
    setIsFileTitleValid(!validFileRegex.test(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiscardPressed = React.useCallback(() => {
    if (fileNameChanged) {
      setFileTitle(fileNameWithoutExtension);
    }
    if (securityMarkingsChanged) {
      setSecurityMarkings(baselineMarkings);
    }
  }, [baselineMarkings, fileNameChanged, fileNameWithoutExtension, securityMarkingsChanged]);

  const [selectedScan, setSelectedScan] = React.useState<Value>([
    {
      label: `Scan ${reversedMetadataScans.length}`,
      id: reversedMetadataScans[0].id,
    },
  ]);

  const scans = React.useMemo(() => {
    setSelectedScan([
      {
        label: `Scan ${reversedMetadataScans.length}`,
        id: reversedMetadataScans[0].id,
      },
    ]);

    return reversedMetadataScans.map((metadataScan, index) => ({
      label: `Scan ${reversedMetadataScans.length - index}`,
      id: metadataScan.id,
    }));
  }, [reversedMetadataScans]);

  const displayScan = React.useMemo(() => {
    return reversedMetadataScans.filter((scan) => {
      return scan.id === selectedScan[0]?.id;
    })[0];
  }, [selectedScan, reversedMetadataScans]);

  const handleChange = React.useCallback((params) => {
    setSelectedScan(params.value);
  }, []);

  const markingsDisableSave = useMarkingsDisableSave(securityMarkings);

  const isDataChanged = !fileNameChanged && !securityMarkingsChanged;

  return (
    <>
      <UnsavedChangesPrompt when={securityMarkingsChanged} />
      <FileReferencedModal
        isOpen={referencedModalOpen}
        numberOfReferences={fileReferenceCount}
        onSave={handleContinueWithSave}
        onCancel={() => {
          setFileReferenceCount(0);
          setReferencedModalOpen(false);
        }}
      />
      <DetailsPage>
        {!readonly && <BackLink />}
        <DetailsHeaderBar title={fileTitle + fileExtension}>
          <Row gap="1rem">
            <Button kind={"tertiary"} onClick={handleDownload}>
              Download
            </Button>
            {!readonly && (
              <Button kind={"tertiary"} disabled={isDataChanged} onClick={handleDiscardPressed}>
                Discard Changes
              </Button>
            )}
            {!readonly && (
              <Button
                kind={"primary"}
                disabled={isDataChanged || !isFileTitleValid || markingsDisableSave}
                onClick={handleSave}
              >
                Save
              </Button>
            )}
          </Row>
        </DetailsHeaderBar>
        <VerticalStack gap=".5rem">
          <ClassificationHeader
            securityMarkings={securityMarkings}
            onMarkingsChanged={setSecurityMarkings}
            readOnly={readonly}
          />
          {!readonly && (
            <FieldEditor
              field={simpleTextFieldSpec("File Title")}
              allowRemoval={false}
              value={fileTitle}
              disabled={readonly}
              onChange={handleTextChange}
              errorMessage={
                !isFileTitleValid ? `File name cannot contain the characters /, \\, ?, %, *, :, |, ", <, >` : undefined
              }
            />
          )}
        </VerticalStack>
        <VerticalStack gap=".5rem">
          <LabelSmall>Uploaded on</LabelSmall>
          <LabelXSmall $style={{ marginBottom: ".5rem" }}>{fileUploadDateAsText(fileInfo)}</LabelXSmall>

          <LabelSmall>Uploaded by</LabelSmall>
          <LabelXSmall $style={{ marginBottom: ".5rem" }}>{fileUploadUserNameAsText(fileInfo)}</LabelXSmall>

          <LabelSmall>File Size</LabelSmall>
          <LabelXSmall>{fileSizeAsText(fileInfo)}</LabelXSmall>
        </VerticalStack>
        {fileInfo?.attributes?.name?.includes(".pdf") && (
          <FileTopics fileId={fileInfo.id} style={{ marginBottom: ".5rem", marginTop: ".5rem" }} />
        )}
        <Select
          options={scans}
          value={selectedScan}
          placeholder="Select Scan"
          clearable={false}
          onChange={(params) => {
            handleChange(params);
          }}
          overrides={{
            ValueContainer: {
              props: {
                "data-testid": `file-scan-selector`,
              },
            },
            DropdownContainer: {
              props: {
                "data-testid": `file-scan-dropdown`,
              },
            },
          }}
        />
        {displayScan && (
          <FileMetadataScan
            allSchemaFields={FILE_FIXED_SCHEMA_FIELDS}
            metadataScan={displayScan}
            style={{ marginLeft: "1rem", marginTop: ".5rem" }}
          />
        )}
      </DetailsPage>
    </>
  );
};

const FileMetadataScan = ({
  allSchemaFields,
  metadataScan,
  style,
}: {
  allSchemaFields: FieldSpec[];
  metadataScan: MetadataScan;
  style?: React.CSSProperties;
}): JSX.Element => {
  const componentStyle = style ?? { marginLeft: "1rem" };

  return (
    <>
      <div style={componentStyle}>
        <MetadataScanEditor
          fields={allSchemaFields}
          metadataScan={metadataScan}
          defaultViewTab={MetadataScanDisplayType.List}
        />
      </div>
    </>
  );
};
