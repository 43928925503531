/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { v4 as uuidv4 } from "uuid";

/**
 * A string containing a UUID
 */
export type Uuid = string & { _brand: "uuid" };
/**
 * The "empty" UUID
 */
export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000" as Uuid;

export function newId(): Uuid {
  return uuidv4() as Uuid;
}
