import React from 'react';
import { SafesimNonIdealState, SafesimButtonHome, SafesimButtonGroup, SafesimButtonBack, SafesimCard, SafesimIcon } from '../components/allSafesimComponents';
import {wrappedLogger} from './wrappedLogger';

/**
 * Component used to provide a general error boundary wrapper for components.
 * This component should be used as the encompassing container for any Safesim component for which a fall back UI is
 * not required or needs to be custom specified, passed in through fallbackUI prop.
 * If the subcomponent should cause an exception to be thrown on render, this ErrorBoundary will catch
 * the exception and display the fallback UI in stead of the web app crashing
 */
export class SafesimErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    /// the component state tracks that an error has occured and what the error is ///
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    /// Update state so the next render will show the fallback UI ///
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    wrappedLogger?.error?.(error);
    wrappedLogger?.error?.(errorInfo);
  }

  render() {
    return <>{this.state.hasError ? this.props.fallbackUI : this.props.children}</>;
  }
}

/**
 * Component used to provide an error wrapper for full page components.
 * This component should be used as the encompassing container for any Safesim full page component
 * If the subcomponent should cause an exception to be thrown on render, this ErrorBoundary will catch
 * the exception and display the fallback UI in stead of the web app crashing
 */
export class SafesimPageComponentErrorBoundary extends SafesimErrorBoundary {
  render() {
    /// if an option is selected, reset the error before the next render ///
    const OnClick = () => {
      this.setState({ hasError: false, error: undefined });
    };

    return this.state.hasError ? (
      <SafesimCard>
        <SafesimNonIdealState
          icon={<SafesimIcon intent='danger' icon='error' size={60} />}
          title={'Loading Error'}
          description={this.state.error?.toString()}
          action={
            <SafesimButtonGroup>
              <SafesimButtonBack onClick={OnClick} />
              <SafesimButtonHome onClick={OnClick} />
            </SafesimButtonGroup>
          }
        />
        :
      </SafesimCard>
    ) : (
      this.props.children
    );
  }
}