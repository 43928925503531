import React, { useCallback, useEffect, useState } from 'react';
import { safesimFillClass, safesimInputGroupClass } from '../utilities/aliasedClassNames';
import { SafesimFormGroup, SafesimInputGroup, SafesimCheckbox, SafesimTag } from './allSafesimComponents';
import { debounceFunction, SafesimConfirmRejectPopover } from './SafesimConfirmRejectPopover';

/**
 * Functional component that displays a form group with a checkbox.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {string} units unit to display next to value
 * @param {boolean} value whether the checkbox is in a checked or unchecked state
 * @param {boolean} editDisabled the starting state of the input. True if editing is disabled at the start.
 * @param {function} onCancel callback for when the value is in edit mode, then cancelled
 * @returns Inline SafesimFormGroup containing a form group with a checkbox and edit buttons.
 */
export const SafesimBooleanInput = (props) => {
  const { label, labelContent, value, editDisabledDisplayString, onValueChange, labelClassName, valueClassName, units, editDisabled = true, onCancel } = props;
  const [isEditDisabled, setIsEditDisabled] = useState(editDisabled);
  const [workingValue, setWorkingValue] = useState(value);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setIsEditDisabled(editDisabled);
  }, [editDisabled]);

  useEffect(() => {
    setWorkingValue(value);
  }, [value]);

  const onConfirmChanges = useCallback(() => {
    onValueChange?.(workingValue);
    setIsEditDisabled(true);
  }, [onValueChange, workingValue]);

  const onRejectChanges = useCallback(() => {
    setIsEditDisabled(true);
    setWorkingValue(value);
    onCancel?.();
  }, [value, onCancel]);

  const debounceOnMouseLeave = debounceFunction(() => setShowEdit(false));

  useEffect(() => {
    return () => debounceOnMouseLeave.cancel();
  }, [debounceOnMouseLeave]);

  return (
    <div
      onMouseEnter={() => {
        debounceOnMouseLeave.cancel();
        setShowEdit(true);
      }}
      onMouseLeave={debounceOnMouseLeave}
    >
      <SafesimFormGroup label={label} className={labelClassName} labelInfo={labelContent} inline={true}>
        <SafesimConfirmRejectPopover
          isOpen={!isEditDisabled || showEdit} // popover should be open on hover to show edit button or when actively editing
          isEdit={!isEditDisabled}
          onEditClicked={() => setIsEditDisabled(false)}
          onConfirmChanges={onConfirmChanges}
          onRejectChanges={onRejectChanges}
        >
          {/* add form group div to keep spacing and styling consistent */}
          <div className={safesimInputGroupClass + safesimFillClass} style={{ paddingLeft: '6px' }}>
            {editDisabledDisplayString && isEditDisabled ? (
              <SafesimInputGroup className={valueClassName} value={editDisabledDisplayString} disabled={true} fill={true} />
            ) : (
              <>
                <SafesimCheckbox className={valueClassName} checked={workingValue} disabled={isEditDisabled} fill={true} onChange={(e) => setWorkingValue(e.target.checked)} />
                {units && <SafesimTag minimal={true}>{units}</SafesimTag>}
              </>
            )}
          </div>
        </SafesimConfirmRejectPopover>
      </SafesimFormGroup>
    </div>
  );
};
