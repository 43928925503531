/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { useStyletron } from "baseui";
import { appConfigurationsVar } from "./GlobalState";
import { useReactiveVar } from "@apollo/client";
import { BannerMode } from "./Api/Api";
import { UserContext } from "./Utils/UserContext";
import {
  generateBannerColorFromClearedAccess,
  generateShortBannerTextFromUserClearance,
  generateTooltipTextFromUserClearance,
} from "./Shared/Security";
import { StatefulTooltip } from "baseui/tooltip";

export const SecurityBanner = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const securityBannerMode = appConfigurations.bannerMode;

  const iFrameDetectionEnabled = appConfigurations.iFrameDetectionEnabled;
  const [css, theme] = useStyletron();
  const clearanceInfo = React.useContext(UserContext)?.clearanceInfo;

  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;

  let shortBannerText: string = appConfigurations.bannerText;
  let tooltipText = "";
  let bannerColor = appConfigurations.bannerColor;
  let bannerTextColor = appConfigurations.bannerTextColor;
  if (securityBannerMode === BannerMode.USER_CLEARANCE) {
    shortBannerText = generateShortBannerTextFromUserClearance(clearanceInfo, isSensitiveSecurityContext);
    tooltipText = generateTooltipTextFromUserClearance(clearanceInfo, isSensitiveSecurityContext);
    const bannerColorInfo = generateBannerColorFromClearedAccess(
      clearanceInfo,
      appConfigurations.securityControlColors
    );
    bannerColor = bannerColorInfo.backgroundColor;
    bannerTextColor = bannerColorInfo.textColor;
  }

  const bannerHeight = "1.5rem";
  const commonBannerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    justifyItems: "center",
    backgroundColor: bannerColor,
    height: bannerHeight,
    color: bannerTextColor,
    textAlign: "center",
    fontFamily: theme.typography.font650.fontFamily,
    zIndex: 1, // Would prefer not to use z-index if possible (it can make things more unclear). Try to make banner outside of the scrollable element.
    width: "100%",
  };
  const topBannerStyle: React.CSSProperties = {
    position: "sticky",
    top: 0,
  };
  // TODO: The bottom banner overlaps elements on the screen.
  // Fix this so it is below the end of the page.
  const bottomBannerStyle: React.CSSProperties = {
    position: "fixed",
    bottom: 0,
  };

  const isInIFrame = React.useMemo(() => {
    if (iFrameDetectionEnabled) {
      return runningInIFrame();
    } else {
      return false;
    }
  }, [iFrameDetectionEnabled]);

  return (
    <>
      {!isInIFrame && (
        <StatefulTooltip
          content={<div style={{ maxHeight: "400px", overflowY: "auto" }}>{tooltipText}</div>}
          returnFocus
          autoFocus
        >
          <div
            aria-label="SecurityBannerTop"
            className={css({
              ...commonBannerStyle,
              ...topBannerStyle,
            })}
          >
            {shortBannerText}
          </div>
        </StatefulTooltip>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: bannerHeight,
        }}
      >
        {React.Children.toArray(children)}
      </div>
      {!isInIFrame && (
        <StatefulTooltip
          content={<div style={{ maxHeight: "400px", overflowY: "auto" }}>{tooltipText}</div>}
          returnFocus
          autoFocus
        >
          <div
            className={css({
              ...commonBannerStyle,
              ...bottomBannerStyle,
            })}
          >
            {shortBannerText}
          </div>
        </StatefulTooltip>
      )}
    </>
  );
};

const runningInIFrame = (): boolean => {
  let isInIFrame = false;
  try {
    // This is strange. Logging top to the console results in a security error when
    // the contents of the iFrame are not in the same domain and port.  If you do not
    // log it it does not throw an error.
    isInIFrame = self !== top;
  } catch (e) {
    if (
      e instanceof DOMException &&
      e.name === "SecurityError" &&
      e.message.toLowerCase().includes("blocked a frame")
    ) {
      isInIFrame = true;
    } else {
      throw e;
    }
  }

  return isInIFrame;
};
