/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { QuerySettingsInput } from "Api";
import { Spinner } from "baseui/spinner";
import { LabelSmall } from "baseui/typography";
import React from "react";
import { TitledBorder } from "../DesignSystem/Containers";
import { AssetSummaryTable } from "./AssetSummaryTable";
import { useFindAssetsQuery } from "../Shared/ApiHooks";

export const RecentActivity = (): JSX.Element => {
  const querySettings: QuerySettingsInput = {
    paging: {
      size: 7,
      index: 0,
    },
    sort: {
      fieldName: "lastModifiedDate",
      order: "DESCENDING",
    },
  };
  const { loading, results: recentlyChangedAssets } = useFindAssetsQuery(undefined, querySettings);

  return (
    <TitledBorder title={"Recent Activity"}>
      {loading ? (
        <>
          <LabelSmall>{"Loading recent activity..."}</LabelSmall>
          <Spinner />
        </>
      ) : (
        <AssetSummaryTable assetList={recentlyChangedAssets} />
      )}
    </TitledBorder>
  );
};
