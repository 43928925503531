/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import getModels from "../Api/Gql/GetModels.gql";
import updateModel from "../Api/Gql/UpdateModel.gql";
import RescanModelFiles from "../Api/Gql/RescanModelFiles.gql";
import { MetadataSchema, ModelChangeSet, ModelInfo } from "Api";
import { Uuid } from "../Utils/Types";
import { handleApolloError } from "../Shared/Errors";
import { newModelDetailsState, ModelDetailsReducer } from "./ModelDetailsState";
import getModelUserDefinedSchema from "../Api/Gql/GetModelUserDefinedSchema.gql";
import { modelDetailsCreatePagePath } from "../Shared/NavigationLinks";
import { notify } from "../Shared/Notify";
import { ModelDetails } from "./ModelDetails";
import { AssetWikiEditPage } from "../Asset/Editor/AssetWikiEditPage";

interface ModelDetailsQueryParams {
  modelId: Uuid;
}
function useModelDetailsParams(): ModelDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  // TODO: Conditionally show an invalid uuid page if the Uuid fails to parse.
  const modelDetailsParams: ModelDetailsQueryParams = {
    modelId: searchParams.get("modelId") as Uuid,
  };
  return modelDetailsParams;
}

export function ModelDetailsEditPage(): JSX.Element {
  const modelDetailsParams = useModelDetailsParams();

  const [state, dispatch] = React.useReducer(ModelDetailsReducer, newModelDetailsState(modelDetailsParams.modelId));

  const [rescanModelFilesMutation] = useMutation(gql(RescanModelFiles), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const changeSet: ModelChangeSet = data.rescanModelFiles;
      if (changeSet.hasChanged) {
        dispatch(["resetOriginalAndEditedModel", changeSet.updatedModel]);
        notify.positive("Model metadata updated");
      } else {
        notify.positive("No changes to model metadata");
      }
    },
    onError: (error) => handleApolloError(error, "Error re-scanning model."),
  });

  const [rescanPressed, setRescanPressed] = React.useState<number>(0);
  const handleRescan = React.useCallback(() => {
    rescanModelFilesMutation({
      variables: { modelId: state.edited.asset.id },
    });
    //TODO is there a more elegant way to do this? rerendering whole ModelDetailsPage component
    setRescanPressed(rescanPressed + 1);
  }, [rescanPressed, rescanModelFilesMutation, state.edited.asset.id]);

  const [updateModelMutation] = useMutation(gql(updateModel), {
    variables: { model: state.edited.asset },
    onCompleted: (data) => {
      const updatedModel = data.updateModel as ModelInfo;
      dispatch(["resetOriginalAndEditedModel", updatedModel]);
      notify.positive("Model saved");
    },
    onError: (error) => handleApolloError(error, `Error updating model.`),
  });

  const [modelLoadError, setModelLoadError] = React.useState<string>();
  const { loading: modelLoading } = useQuery(gql(getModels), {
    variables: { ids: [modelDetailsParams.modelId] },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const models = data.getModels as ModelInfo[];
      if (models[0]) {
        dispatch(["resetOriginalAndEditedModel", models[0]]);
      } else {
        setModelLoadError("Model not found; model does not exist or you do not have permission to see it.");
      }
    },
    onError: (error) => handleApolloError(error, "Error getting model data."),
  });

  const { loading: customMetadataLoading } = useQuery(gql(getModelUserDefinedSchema), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      dispatch(["setSchema", data?.getModelUserDefinedSchema as MetadataSchema]);
    },
  });

  const history = useHistory();
  const handleCopy = React.useCallback(() => {
    history.push(modelDetailsCreatePagePath(state.edited.asset.id));
  }, [history, state.edited.asset.id]);

  return (
    <AssetWikiEditPage
      state={state}
      dispatch={dispatch}
      onSave={updateModelMutation}
      onRescan={handleRescan}
      onCopy={handleCopy}
      loading={customMetadataLoading || modelLoading}
      loadError={modelLoadError}
    >
      <ModelDetails state={state} dispatch={dispatch} />
    </AssetWikiEditPage>
  );
}
