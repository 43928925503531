/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import * as Arrays from "../../Utils/Array";
import { ScenarioSpec, ScenarioWizardStep } from "./ScenarioSpec";
import GenerateScenarioOutline from "../../Api/Gql/GenerateScenarioOutline.gql";
import { gql, useQuery } from "@apollo/client";
import { ScenarioOutline, TaskInfo } from "Api";
import { LabelLarge, LabelSmall } from "baseui/typography";
import { LoadingPlaceholder } from "../../Shared/LoadingPlaceholder";
import { handleApolloError } from "../../Shared/Errors";
import { distinctBy } from "../../Utils/Array";
import { compareCaseInsensitive } from "../../Utils/Sort";
import { TaskTable } from "../TaskList/TaskTable";
import { CenteredContent, VerticalSpacer, VerticalStack } from "../../DesignSystem/Containers";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { TextEntry } from "../../Utils/TableCells";
import { useStyletron } from "baseui";
import { SelectedTableRowStyle } from "../SmrStyles";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { StepBody } from "./StepBody";

export function DefineOutlineStep({
  scenarioSpec,
  onSpecUpdated,
  onStepChange,
}: {
  scenarioSpec?: ScenarioSpec;
  onSpecUpdated: (updatedSpec: ScenarioSpec) => void;
  onStepChange: (newStep: ScenarioWizardStep) => void;
}): JSX.Element {
  const { data, loading } = useQuery(gql(GenerateScenarioOutline), {
    variables: { text: scenarioSpec?.missionDescription },
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, `Error getting task suggestions`),
  });
  const outline = data?.generateScenarioOutline as ScenarioOutline;
  const uniqueTasks = React.useMemo(() => {
    const verbSuggestions = outline?.verbSuggestions ?? [];

    const allTasks = verbSuggestions.flatMap((verbTaskSuggestion) => verbTaskSuggestion.taskSuggestions);
    const distinctTasks = distinctBy(allTasks, (task) => task.id);
    distinctTasks.sort((task1, task2) => compareCaseInsensitive(task1.title, task2.title));
    return distinctTasks;
  }, [outline]);

  function handleLocationSelected(_event: React.MouseEvent<HTMLDivElement, MouseEvent>, dataId: string): void {
    const newSpec: ScenarioSpec = {
      ...scenarioSpec,
      location: dataId,
      // Clear out all other values, because changing the task invalidates the subsequent selections.
      measures: undefined,
      models: undefined,
    };

    onSpecUpdated(newSpec);
  }

  function handleTaskSelected(_event: React.MouseEvent<HTMLDivElement, MouseEvent>, task: TaskInfo): void {
    const newTasks = Arrays.toggleAdd(scenarioSpec?.tasks, task);
    const newSpec: ScenarioSpec = {
      ...scenarioSpec,
      tasks: newTasks,
      // Clear out all other values, because changing the task invalidates the subsequent selections.
      measures: undefined,
      models: undefined,
    };

    onSpecUpdated(newSpec);
  }

  if (loading) {
    return <LoadingPlaceholder height="20rem" message={`Analyzing mission...`} />;
  }

  return (
    <StepBody
      stepName={`Define Outline`}
      previousStep={"Describe Mission"}
      previousDisabled={false}
      nextStep={"Choose Measures"}
      nextDisabled={!scenarioSpec?.tasks?.length}
      onStepChange={onStepChange}
    >
      <div style={{ padding: "1rem 3rem 1rem 2rem" }}>
        {uniqueTasks.length > 0 ? (
          <>
            <LocationsPane
              locations={outline.conditions.locations}
              selectedLocations={scenarioSpec?.location ? [scenarioSpec.location] : []}
              onLocationSelected={handleLocationSelected}
            />
            <VerticalSpacer height="3rem" />
            <TaskPane
              tasks={uniqueTasks}
              selectedTasks={scenarioSpec?.tasks ?? []}
              onTaskSelected={handleTaskSelected}
            />
          </>
        ) : (
          <CenteredContent height="20rem">
            <LabelLarge>{`No results found; try elaborating on the details of what should happen during the mission.`}</LabelLarge>
          </CenteredContent>
        )}
      </div>
    </StepBody>
  );
}

function TaskSummary({ task }: { task: TaskInfo }): JSX.Element {
  return (
    <VerticalStack>
      <LabelSmall>{`${task.category} ${task.hierarchyNumber}`}</LabelSmall>
      <LabelLarge>{task.title}</LabelLarge>
      <LabelSmall>{task.description}</LabelSmall>

      <FormControl label={`Required capabilities:`}>
        <Input disabled value={task?.requiredCapabilities?.join(", ") ?? "(None)"} />
      </FormControl>
    </VerticalStack>
  );
}

function LocationsPane({
  locations,
  selectedLocations,
  onLocationSelected,
}: {
  locations?: string[];
  selectedLocations: string[];
  onLocationSelected: (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, dataId: string) => void;
}): JSX.Element {
  const validLocations = locations ?? [];
  const styleOverrides = SelectedTableRowStyle((rowIndex) => validLocations.includes(selectedLocations[rowIndex]));

  const [css, theme] = useStyletron();

  // Value's sourced from StyledTableBodyCell in Baseweb's toolkit:
  // https://github.com/uber/baseweb/blob/master/src/table-semantic/styled-components.js
  const cellStyle = css({
    display: "flex",
    height: "2rem",
    paddingTop: theme.sizing.scale600,
    paddingLeft: theme.sizing.scale600,
    paddingRight: theme.sizing.scale1000,
    paddingBottom: theme.sizing.scale600,
  });

  return (
    <VerticalStack>
      <LabelLarge>{`Location`}</LabelLarge>
      {locations && locations.length > 0 ? (
        <>
          <div style={{ padding: "1rem 0rem 1rem 0rem" }}>
            <LabelSmall>{`Based on your mission description, these are the candidate locations we inferred. Which should be used for the scenario?`}</LabelSmall>
          </div>
          <TableBuilder data={locations} overrides={styleOverrides}>
            <TableBuilderColumn id="name" header="Name" sortable>
              {(row: string) => (
                <TextEntry text={row} styleClass={cellStyle} dataId={row} onClick={onLocationSelected} />
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      ) : (
        <CenteredContent height="8rem">
          <LabelSmall>{`No locations found; we will try and choose an appropriate one when the scenario is generated.`}</LabelSmall>
        </CenteredContent>
      )}
    </VerticalStack>
  );
}

function TaskPane({
  tasks,
  selectedTasks,
  onTaskSelected,
}: {
  tasks?: TaskInfo[];
  selectedTasks: TaskInfo[];
  onTaskSelected: (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, task: TaskInfo) => void;
}): JSX.Element {
  const lastSelectedTask = selectedTasks.length > 0 ? selectedTasks[selectedTasks.length - 1] : undefined;
  return (
    <>
      <LabelLarge>{`Tasks`}</LabelLarge>
      <div style={{ padding: "1rem 0rem 1rem 0rem" }}>
        <LabelSmall>{`We found some tasks that look like they match your mission. Which feels the most appropriate?`}</LabelSmall>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "3rem",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <TaskTable tasks={tasks ?? []} selectedTasks={selectedTasks} onClick={onTaskSelected} />
        </div>
        {lastSelectedTask && (
          <div style={{ width: "20vw" }}>
            <TaskSummary task={lastSelectedTask} />
          </div>
        )}
      </div>
    </>
  );
}
