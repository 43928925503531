/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import { useStyletron } from "baseui";
import { Table } from "baseui/table-semantic";
import { compareCaseInsensitive } from "../../Utils/Sort";
import { generatePortionMarkingsText } from "../../Shared/Security";
import { appConfigurationsVar } from "../../GlobalState";
import { useReactiveVar } from "@apollo/client";
import { isMarkedValueRecord, isNumericValue, isRedaction, isValueRecord } from "../../Api/ApiExtensions";
import { MarkableValueRecord } from "Api";
import { RedactedText } from "../../Shared/RedactedElements";

const columns = ["Name", "Value", "Classification"];

function toTableValue(propertyValue?: any): any | undefined {
  if (Array.isArray(propertyValue)) {
    return propertyValue.join(", ");
  } else if (isNumericValue(propertyValue)) {
    let valueAsString = propertyValue.toString();
    if (propertyValue.unitOfMeasure) {
      valueAsString += ` ${propertyValue.unitOfMeasure}`;
    }
    return valueAsString;
  } else if (isRedaction(propertyValue)) {
    return <RedactedText />;
  } else {
    return propertyValue;
  }
}

function toTableRow(isSensitiveSecurityContext: boolean, propertyName: string, propertyValue?: any): any[] {
  if (!propertyValue) {
    return [propertyName, undefined, undefined];
  }

  let tableValue: string | undefined;
  let securityMarkings: string | undefined;

  if (isMarkedValueRecord(propertyValue)) {
    tableValue = toTableValue(propertyValue.contents.value);
    securityMarkings = generatePortionMarkingsText(isSensitiveSecurityContext, propertyValue.securityMarkings);
  } else if (isValueRecord(propertyValue)) {
    tableValue = toTableValue(propertyValue.value);
    securityMarkings = undefined;
  } else {
    tableValue = toTableValue(propertyValue);
    securityMarkings = undefined;
  }

  return [propertyName, tableValue, securityMarkings];
}

function CustomPropsTable({ customProps }: { customProps: Record<string, MarkableValueRecord> }): JSX.Element {
  const [css] = useStyletron();

  const isSensitiveSecurityContext = useReactiveVar(appConfigurationsVar).isSensitiveSecurityContext;

  const rows: any[] = React.useMemo(() => {
    const sortedProps = [...Object.keys(customProps)].sort(compareCaseInsensitive);
    return sortedProps.map((key) => {
      const propertyValue = customProps[key];
      return toTableRow(isSensitiveSecurityContext, key, propertyValue);
    });
  }, [customProps, isSensitiveSecurityContext]);

  return (
    <div className={css({ height: "1600px", width: "60%" })}>
      <Table columns={columns} data={rows} />
    </div>
  );
}

export default CustomPropsTable;
