/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Button } from "baseui/button";
import { ButtonGroup, MODE } from "baseui/button-group";
import { LabelSmall } from "baseui/typography";
import * as React from "react";
import { MetadataScan, ScanResult } from "../../Api/Api";
import { concatUnspecifiedFields, flattenPresentMetadata } from "./AssetMetadataFields";
import { FieldSpec } from "./FieldSpec";
import CustomPropsTable from "./CustomPropsTable";
import MetadataPropsEditor from "./MetadataPropsEditor";

export enum MetadataScanDisplayType {
  Table = 0,
  List = 1,
}

export const MetadataScanEditor = ({
  metadataScan,
  fields,
  defaultViewTab,
}: {
  metadataScan?: MetadataScan;
  fields: FieldSpec[];
  defaultViewTab?: MetadataScanDisplayType;
}): JSX.Element => {
  const [selected, setSelected] = React.useState<MetadataScanDisplayType>(
    defaultViewTab ?? MetadataScanDisplayType.Table
  );

  const metadataValues = React.useMemo(() => {
    return flattenPresentMetadata(metadataScan?.scanMetadata);
  }, [metadataScan?.scanMetadata]);

  const aggregatePresentFields = React.useMemo(() => {
    // Add each field to be shown, if it is present.
    const presentFields: FieldSpec[] = fields.filter((field) => metadataValues[field.propertyName] != null);

    return concatUnspecifiedFields(presentFields, metadataScan?.scanMetadata);
  }, [fields, metadataScan?.scanMetadata, metadataValues]);

  if (metadataScan?.scanResult !== ScanResult.SUCCESS) {
    switch (metadataScan?.scanResult) {
      case ScanResult.FILETYPE_NOT_SUPPORTED:
        return <LabelSmall>{"Automatic analysis for files of this type are not yet supported."}</LabelSmall>;
      case ScanResult.ERROR:
      default:
        return <LabelSmall>{"An unexpected error occured. Unable to display results."}</LabelSmall>;
    }
  }

  return (
    <>
      <ButtonGroup
        mode={MODE.radio}
        selected={selected}
        onClick={(_event, index) => {
          setSelected(index);
        }}
      >
        <Button>Table</Button>
        <Button>List</Button>
      </ButtonGroup>
      {selected === MetadataScanDisplayType.Table && <CustomPropsTable customProps={metadataValues} />}
      {selected === MetadataScanDisplayType.List && (
        <MetadataPropsEditor fields={aggregatePresentFields} metadataValues={metadataValues} />
      )}
    </>
  );
};
