import React from 'react';
import { SafesimButton, SafesimOverlaySpinner, SafesimButtonGroup } from './allSafesimComponents';
import PropTypes from 'prop-types';
import { Dropzone, FileItem } from '@dropzone-ui/react';

export const SafesimFileUploader = ({
  cancelButtonText = 'Cancel',
  dragDropAccept = '*',
  dragDropLabel = 'Drag &amp; drop a file here, or click to browse',
  dragDropProps = {},
  files,
  handleFilesChanged,
  isLoading,
  maxFiles = 1,
  onCancelClicked,
  onDeleteClicked,
  onSubmit,
  showCancelButton = true,
  showSubmitButton = true,
  submitButtonText = 'Upload',
}) => {
  return (
    <>
      <SafesimOverlaySpinner isOpen={isLoading}>
        <Dropzone
          minHeight='195px'
          maxFiles={maxFiles}
          view='grid'
          label={dragDropLabel}
          accept={dragDropAccept}
          {...dragDropProps}
          onChange={handleFilesChanged}
          value={files}
        >
          {files.map((file) => (
            <FileItem {...file} resultOnTooltip onDelete={onDeleteClicked} key={file.id} errors={['No Valid Files Received']} />
          ))}
        </Dropzone>
      </SafesimOverlaySpinner>
      {(showSubmitButton || showCancelButton) && (
        <div className='alignRight'>
          <SafesimButtonGroup>
            {showSubmitButton && <SafesimButton name='submit' disabled={!files.length || isLoading} onClick={onSubmit} text={submitButtonText} />}
            {showCancelButton && <SafesimButton name='cancel' minimal={true} onClick={onCancelClicked} text={cancelButtonText} />}
          </SafesimButtonGroup>
        </div>
      )}
    </>
  );
};

SafesimFileUploader.propTypes = {
  cancelButtonText: PropTypes.string,
  dragDropAccept: PropTypes.string,
  dragDropLabel: PropTypes.string,
  dragDropProps: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.any),
  handleFilesChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  maxFiles: PropTypes.number,
  onCancelClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onSubmit: PropTypes.func,
  showCancelButton: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
};
