/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

export function TextEntry<T>({
  text,
  dataId,
  styleClass,
  onClick,
  dataTestId,
}: {
  text?: string;
  dataId: T;
  styleClass: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, dataId: T) => void;
  dataTestId?: string;
}): JSX.Element {
  return (
    <>
      <div
        data-testid={dataTestId}
        className={styleClass}
        onClick={(event) => {
          onClick(event, dataId);
        }}
      >
        {text ?? ""}
      </div>
    </>
  );
}

export function ComponentEntry<T>({
  dataId,
  styleClass,
  onClick,
  dataTestId,
  children,
}: {
  dataId: T;
  styleClass: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, dataId: T) => void;
  dataTestId?: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <div
        data-testid={dataTestId}
        className={styleClass}
        onClick={(event) => {
          onClick(event, dataId);
        }}
      >
        {React.Children.map(children, (child) => {
          return child;
        })}
      </div>
    </>
  );
}
