/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Marked, PickListSchema } from "Api";
import { camelCase } from "lodash";

export interface BasicFieldSpec extends Marked {
  title: string;
  propertyName: string;
  defaultValue: any;
  removable?: boolean;
  markable?: boolean;
}

function validPropertyName(title: string, propertyName?: string): string {
  return propertyName ? propertyName : camelCase(title);
}

export interface SimpleTextFieldSpec extends BasicFieldSpec {
  type: "SimpleTextField";
  defaultValue: "";
}

export function simpleTextFieldSpec(
  title: string,
  valueName?: string,
  removable?: boolean,
  markable?: boolean
): SimpleTextFieldSpec {
  return {
    type: "SimpleTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
    defaultValue: "",
    removable,
    markable,
  };
}

export interface NumericTextFieldSpec extends BasicFieldSpec {
  type: "NumericTextField";
  defaultValue: "";
  markable: false;
}

export function numericTextFieldSpec(title: string, valueName?: string, removable?: boolean): NumericTextFieldSpec {
  return {
    type: "NumericTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
    defaultValue: "",
    removable,
    markable: false,
  };
}

export interface MultiLineTextFieldSpec extends BasicFieldSpec {
  type: "MultiLineTextField";
}

export function multiLineTextFieldSpec(
  title: string,
  valueName?: string,
  removable?: boolean,
  markable?: boolean
): MultiLineTextFieldSpec {
  return {
    type: "MultiLineTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
    defaultValue: "",
    removable,
    markable,
  };
}

export interface TextListQuerySpec {
  matchValuesQuery: string;
  queryParameterName: string;
  responseFieldName: string;
}

export interface TagListFieldSpec extends BasicFieldSpec {
  type: "TagListField";
  querySpec: TextListQuerySpec;
  markable: false;
}

export function tagListFieldSpec(
  querySpec: TextListQuerySpec,
  title: string,
  valueName?: string,
  removable?: boolean
): TagListFieldSpec {
  return {
    type: "TagListField",
    title: title,
    querySpec,
    propertyName: validPropertyName(title, valueName),
    defaultValue: [],
    removable,
    markable: false,
  };
}

export interface PickListFieldSpec extends BasicFieldSpec {
  type: "PickListField";
  schema: PickListSchema;
  markable: false;
}

export function pickListFieldSpec(schema: PickListSchema): PickListFieldSpec {
  return {
    type: "PickListField",
    title: schema.attributeName,
    schema: schema,
    propertyName: schema.attributeName,
    defaultValue: [],
    removable: false,
    markable: false,
  };
}

export interface DateFieldSpec extends BasicFieldSpec {
  type: "DateTextField";
  defaultValue: "";
  markable: false;
}

export function dateFieldSpec(title: string, valueName?: string, removable?: boolean): DateFieldSpec {
  return {
    type: "DateTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
    defaultValue: "",
    removable,
    markable: false,
  };
}

export interface GeoLocationFieldSpec extends BasicFieldSpec {
  type: "GeoLocationField";
  defaultValue: null;
  markable: false;
}

export function geoLocationFieldSpec(title: string, valueName?: string, removable?: boolean): GeoLocationFieldSpec {
  return {
    type: "GeoLocationField",
    title: title,
    propertyName: validPropertyName(title, valueName),
    defaultValue: null,
    removable,
    markable: false,
  };
}

export type FieldSpec =
  | NumericTextFieldSpec
  | SimpleTextFieldSpec
  | MultiLineTextFieldSpec
  | TagListFieldSpec
  | PickListFieldSpec
  | DateFieldSpec
  | GeoLocationFieldSpec;

export function unknownFieldSpecType(fieldSpecType?: FieldSpec["type"]): never {
  throw new Error(`Unknown type ${fieldSpecType}`);
}
