import { isString } from "lodash";

/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export function isBlank(text: string | undefined): boolean {
  return !text || /^\s*$/.test(text);
}

export function toTitleCase(text: string): string {
  return text.replace(/\w\S*/g, function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  });
}

export function replaceIfUntitled(text?: string): string {
  return !text ? "Untitled" : text;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isStringValue(value?: any): value is string {
  return isString(value);
}
