/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import * as React from "react";

export const WarningDialog = ({
  isOpen,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
}: {
  isOpen: boolean;
  titleText: string;
  contentText: string;
  cancelButtonText: string;
  confirmButtonText: string;
  onCancel: () => void;
  onConfirm: () => void;
}): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalHeader>{titleText}</ModalHeader>
      <ModalBody>{contentText}</ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onCancel}>
          {cancelButtonText}
        </ModalButton>
        <ModalButton kind="tertiary" onClick={onConfirm}>
          {confirmButtonText}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
