// All common components. Should be in alphabetical order
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePortal } from './safesim_hooks/usePortalHook';
import { SafesimMenu, SafesimSimpleSelect, SafesimButtonCross, SafesimButton, SafesimMenuItem, SafesimText } from './allSafesimComponents';

/**
 * Functional component that provides a drop down select object that is able to be driven from
 * a supplied list of items.
 * @param {*} props
 * itemArray - Required. Array of items to use to populate the select. Example:  itemArray={["Alpha", "Beta", "Charlie"]}
 * convertItemToString - Optional callback used to convert the selected item to a string for display within the selection button.
 *      Use if your select options is not a list of strings.
 *      Example:  convertItemToString={(item) => {return item.text;}}
 * onSelectItem - Required callback that is invoked when a selection is made.
 *      Example: onSelectItem={(item) => {setOptionToReturn(item)}}
 * selectedItem - Optional string holding display text of selected item. Will display nothing if not in use.
 *      Example:  selectedItem={sar.classification}
 * defaultText - Optional string to display when no item is selected
 * icon - string for Blueprint icon name or icon element. Displayed in select button
 * canClear - optional boolean. determines if select can have no value selected
 *
 * @returns JSX Element - select with dropdown
 */

export const SafesimSelect = (props) => {
  /// retrieve the component specific props ///
  const {
    itemArray,
    onSelectItem,
    convertItemToString,
    intent,
    className,
    disabled,
    defaultText,
    selectedItem,
    icon,
    rightIcon,
    onClick,
    canClear = true,
    buttonProps,
    ...rest
  } = props;

  const enablePortal = usePortal();

  const convertItemToStringDefault = useCallback(
    (item) => {
      return convertItemToString?.(item) ?? item?.toString?.() ?? '';
    },
    [convertItemToString]
  );

  // instead of intent use className
  const isDanger = useMemo(() => (intent === 'danger' ? 'makeRed' : ''), [intent]);

  /// callback set on the Select onItemSelect, sets the selected item text and calls the prop callback onSelectItem ///
  const onItemSelect = useCallback(
    (item) => {
      onSelectItem?.(item);
    },
    [onSelectItem]
  );

  /// Callback used to clear the current selection ///
  const onClear = useCallback(() => {
    onItemSelect(undefined);
  }, [onItemSelect]);

  /// non state driven callback used by the Select component to render the drop down menu ///
  const renderMenu = ({ items, renderItem }) => {
    /// applies the renderItem method to render each item to the element to display, filtering null results ///
    const renderedItems = items?.map(renderItem)?.filter((item) => item !== undefined);
    /// result is a menu, allowing scroll if necessary, containing the rendered items ///
    return (
      <SafesimMenu>
        <div className='safesimSelectDrivenDataMenu'>{renderedItems}</div>
      </SafesimMenu>
    );
  };
  const dataDrivenButton = (
    <SafesimButtonCross
      className='selectClearbutton'
      small={true}
      style={{ margin: '-5px' }}
      disabled={!(itemArray && selectedItem !== undefined && selectedItem !== null) || disabled}
      onClick={onClear}
    />
  );
  return (
    <SafesimSimpleSelect
      {...rest}
      className={className}
      disabled={disabled}
      filterable={itemArray !== undefined}
      items={itemArray ?? []}
      itemListRenderer={itemArray ? renderMenu : undefined}
      itemRenderer={(item, { modifiers, handleClick }) => {
        return !modifiers.matchesPredicate ? null : (
          <SafesimMenuItem key={uuidv4()} text={convertItemToStringDefault(item)} shouldDismissPopover={true} onClick={handleClick} />
        );
      }}
      onItemSelect={onItemSelect}
      itemPredicate={(predicate, item) => {
        return convertItemToStringDefault(item).toLowerCase().includes(predicate.toLowerCase());
      }}
      popoverProps={{
        portalContainer: enablePortal?.window?.document?.body,
        minimal: true,
      }}
    >
      <SafesimButton
        {...buttonProps}
        icon={icon}
        alignText='left'
        disabled={disabled}
        fill={true}
        rightIcon={rightIcon ?? (itemArray ? 'caret-down' : undefined)}
        className={isDanger + ' dropdownStyle dropdown'}
        onClick={onClick}
      >
        <div className='selectTextButtonGrid'>
          <SafesimText ellipsize={true}>
            {
              itemArray && selectedItem !== undefined && selectedItem !== null
                ? convertItemToStringDefault(selectedItem) //print item
                : defaultText ?? 'No Item Selected' //if no selectedItem show default text defaultText
            }
          </SafesimText>
          {canClear ? <div className='alignRight'>{itemArray && selectedItem ? dataDrivenButton : undefined}</div> : null}
        </div>
      </SafesimButton>
    </SafesimSimpleSelect>
  );
};
