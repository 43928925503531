import { Classes } from '@blueprintjs/core';
import React, { useState, useEffect } from 'react';
import { SafesimInputGroup, SafesimTag, SafesimLabel } from './allSafesimComponents';
import { debounceFunction, SafesimConfirmRejectPopover } from './SafesimConfirmRejectPopover';
import { SafesimControlGroup } from './SafesimControlGroup';

/**
 * Functional component that displays a value in a disabled input with an optional edit action.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the SafesimFormGroup
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {string} value Value to display in the disabled SafesimInputGroup
 * @param {string} valueClassName className to apply to SafesimInputGroup
 * @param {string} units unit to display next to value
 * @param {boolean} editAction boolean controlling if there is an edit action available. Used to display the edit button
 * @param {function} editOnClick callback used when clicking the edit button
 * @param {JSX.Element} rightSideContent element displayed to the right of the config input
 * @returns Inline SafesimFormGroup containing a disabled input group, with a popover for the edit button.
 */
export const SafesimValueDisplay = (props) => {
  const { label, labelClassName, labelContent, value, valueClassName, units, editAction, editOnClick, rightSideContent } = props;
  // track showing/hiding edit based on mouseover
  const [showEdit, setShowEdit] = useState(false);

  const debounceOnMouseLeave = debounceFunction(() => setShowEdit(false));

  useEffect(() => {
    return () => debounceOnMouseLeave.cancel();
  }, [debounceOnMouseLeave]);

  return (
    // only show edit if an edit option exists
    <SafesimControlGroup
      style={{ padding: '5px' }}
      fill={true}
      onMouseEnter={() => {
        if (editAction) {
          debounceOnMouseLeave.cancel();
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => editAction && debounceOnMouseLeave()}
    >
      {labelContent}
      {/* set right margin inline, to override BP margin that is dependent on highest form component parent (form and control groups have different selectors) */}
      <SafesimLabel className={Classes.FIXED} style={{ width: '35%', minWidth: '30px', marginRight: '5px', marginBottom: '0px', alignSelf: 'center' }}>
        {label}
      </SafesimLabel>
      <SafesimConfirmRejectPopover
        isOpen={showEdit} // popover should be open on hover to show edit button, doesn't use confirm/cancel
        onEditClicked={editOnClick}
      >
        <SafesimInputGroup className={valueClassName} value={value} disabled={true} fill={true} rightElement={units && <SafesimTag minimal={true}>{units}</SafesimTag>} />
      </SafesimConfirmRejectPopover>
      {rightSideContent}
    </SafesimControlGroup>
  );
};
