# import "./fragments/fileFoldersData.gql"

mutation RescanStudyFiles($studyId: UUID!) {
  rescanStudyFiles(studyId: $studyId) {
    updatedStudy {
      id
      creationDate
      lastModifiedDate
      metadata {
        title
        version
        description
        keywords
        notes
        customProps
      }
      fileFolders {
        __typename
        ...fileFoldersData
      }
    }
    hasChanged
  }
}
