/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useLocation } from "react-router-dom";
import { EMPTY_UUID, Uuid } from "../../Utils/Types";

export interface CopyAssetQueryParams {
  assetId: Uuid;
}

export interface CopySettings {
  assetId: Uuid;
  isInCopyMode: boolean;
}

export function useCopySettings(): CopySettings {
  const urlParams = new URLSearchParams(useLocation().search);

  const rawAssetId = urlParams.get("assetId");
  if (rawAssetId?.trim().length === 0) {
    return {
      isInCopyMode: false,
      assetId: EMPTY_UUID,
    };
  }

  const assetId = rawAssetId as Uuid;
  const copying = assetId ? true : false;

  return {
    isInCopyMode: copying,
    assetId: assetId,
  };
}
