import React, { useState, useCallback } from 'react';
import { SafesimCallout, SafesimFormGroup } from './allSafesimComponents';
import { SafesimCardCollapse } from './SafesimCardCollapse';
import { SafesimFormMeasurementInput } from './SafesimFormMeasurementInput';

// TODO NAT - Props should extend from card collapse props
interface Props {
  label: string;
  labelContent?: JSX.Element;
  min: number;
  max: number;
  step: number;
  editDisabled?: boolean;
  editDisabledDisplayString?: string;
  onValueChange: (min: number, max: number, step: number) => void;
  labelClassName?: string;
  valueClassName?: string;
  units?: string;
  decimal?: boolean;
  unitPopoverContent?: JSX.Element;
  originalValue?: { min: number; max: number; step: number };
  minValidator?: (num: number, str?: string) => boolean;
  maxValidator?: (num: number, str?: string) => boolean;
  stepValidator?: (num: number, str?: string) => boolean;
}

/**
 * Functional component that displays three measurement inputs for entering min, max, and step values.
 * @param {string} label text to display as primary form group label
 * @param {string} labelClassName to apply to the labels for each input
 * @param {*} labelContent any additional information to add to the form group. Passed as labelInfo to formGroup
 * @param {number} min the minimum value of the range
 * @param {number} max the maximum value of the range
 * @param {number} step the step size of the range
 * @param {string} editDisabledDisplayString string to display in input when disabled
 * @param {string} valueClassName className to apply to inputs for min, max, and step
 * @param {function} onValueChange callback for when the value is changed to a valid value
 * @param {string} units unit to display next to value
 * @param {boolean} decimal whether decimal values are allowed. False if only integer values are allowed.
 * @param {JSX.Element} unitPopoverContent element to show in popup when clicking unit tag
 * @param {minimalist, max, step} originalValue original value. If provided, modified values will be highlighted green
 * @param {function} minValidator optional function to validate the minimum value
 * @param {function} maxValidator optional function to validate the maximum value
 * @param {function} stepValidator optional function to validate the step size value
 * @returns Inline SafesimFormGroup containing three disabled measurement inputs for min,max, and step, each with edit buttons
 */
export const SafesimFormRangeInput = (props: Props): JSX.Element => {
  const {
    label,
    labelContent,
    min,
    max,
    step,
    editDisabled = false,
    editDisabledDisplayString,
    onValueChange,
    labelClassName,
    valueClassName,
    units,
    decimal,
    unitPopoverContent,
    originalValue,
    minValidator,
    maxValidator,
    stepValidator,
    ...rest
  } = props;

  // track invalid message to display
  const [invalidMessage, setInvalidMessage] = useState('');
  const [isValueValid, setIsValueValid] = useState(true);

  const validationCheck = useCallback((min: number, max: number, step: number) => {
    if (min > max) {
      setIsValueValid(false);
      setInvalidMessage('Min must be smaller than Max');
      return false;
    } else if (step > max - min) {
      setIsValueValid(false);
      setInvalidMessage('Step must be smaller than difference of Max and Min');
      return false;
    } else {
      setIsValueValid(true);
      return true;
    }
  }, []);

  const onMinValidation = useCallback(
    (num: number, str: string) => {
      return !!str && !isNaN(num) && validationCheck(num, max, step) && (minValidator?.(num, str) ?? true);
    },
    [validationCheck, max, step, minValidator]
  );

  const onMaxValidation = useCallback(
    (num: number, str: string) => {
      return !!str && !isNaN(num) && validationCheck(min, num, step) && (maxValidator?.(num, str) ?? true);
    },
    [validationCheck, min, step, maxValidator]
  );

  const stepValidationCheck = useCallback((min: number, max: number, step: number) => {
    if (step < 0) {
      setIsValueValid(false);
      setInvalidMessage('Step must be a positive number');
      return false;
    } else if (step > max - min) {
      setIsValueValid(false);
      setInvalidMessage('Step must be smaller than difference of Max and Min');
      return false;
    } else {
      setIsValueValid(true);
      return true;
    }
  }, []);

  const onStepValidation = useCallback(
    (num: number, str: string) => {
      return !!str && !isNaN(num) && stepValidationCheck(min, max, num) && (stepValidator?.(num, str) ?? true);
    },
    [stepValidationCheck, min, max, stepValidator]
  );

  return (
    <SafesimCardCollapse header={label} headerContent={labelContent} buttonClassName={labelClassName} {...rest}>
      {/* form groups for spacing */}
      {/* min */}
      <SafesimFormGroup inline={true}>
        <SafesimFormMeasurementInput
          label={'min'}
          value={min}
          labelClassName={labelClassName}
          valueClassName={valueClassName}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          validator={onMinValidation}
          unitPopoverContent={unitPopoverContent}
          onValueChange={(num) => {
            if (step === 0) {
              onValueChange?.(num, max, (max - num) / 2);
            } else {
              onValueChange?.(num, max, step);
            }
          }}
          editDisabled={editDisabled}
          modified={originalValue?.min ? originalValue.min !== min : false} // don't show modification highlight if original value not provided
        />
      </SafesimFormGroup>
      {/* max */}
      <SafesimFormGroup inline={true}>
        <SafesimFormMeasurementInput
          label={'max'}
          value={max}
          labelClassName={labelClassName}
          valueClassName={valueClassName}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          validator={onMaxValidation}
          unitPopoverContent={unitPopoverContent}
          onValueChange={(num) => {
            if (step === 0) {
              onValueChange?.(min, num, (num - min) / 2);
            } else {
              onValueChange?.(min, num, step);
            }
          }}
          editDisabled={editDisabled}
          modified={originalValue?.max ? originalValue.max !== max : false} // don't show modification highlight if original value not provided
        />
      </SafesimFormGroup>
      {/* step */}
      <SafesimFormGroup inline={true}>
        <SafesimFormMeasurementInput
          label={'step'}
          value={step}
          labelClassName={labelClassName}
          valueClassName={valueClassName}
          units={units}
          decimal={decimal}
          editDisabledDisplayString={editDisabledDisplayString}
          validator={onStepValidation}
          unitPopoverContent={unitPopoverContent}
          onValueChange={(num) => {
            onValueChange?.(min, max, num);
          }}
          editDisabled={editDisabled}
          modified={originalValue?.step ? originalValue.step !== step : false} // don't show modification highlight if original value not provided
        />
      </SafesimFormGroup>
      {!isValueValid && invalidMessage && (
        <SafesimFormGroup>
          <SafesimCallout intent='danger'>{invalidMessage}</SafesimCallout>
        </SafesimFormGroup>
      )}
    </SafesimCardCollapse>
  );
};
