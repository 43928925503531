# import "./fragments/fileFoldersData.gql"

mutation RescanModelFiles($modelId: UUID!) {
  rescanModelFiles(modelId: $modelId) {
    updatedModel {
      id
      creationDate
      lastModifiedDate
      metadata {
        title
        version
        description
        keywords
        subject
        notes
        customProps
      }
      fileFolders {
        __typename
        ...fileFoldersData
      }
    }
    hasChanged
  }
}
