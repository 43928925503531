/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
type RecordKey = string | number | symbol;

export function isRecordEmpty<T extends RecordKey, V>(record?: Record<T, V>): boolean {
  if (!record) {
    return false;
  }

  return Object.keys(record).length === 0;
}
