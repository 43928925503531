import { useCallback, useState } from 'react';

export function useSafesimDialogHook(startValues = {}) {
    const [children, setChildren] = useState(startValues.children);
    const [isOpen, setIsOpen] = useState(startValues.isOpen || false);
    const [onClose, setOnCloseCallback] = useState(startValues.onClose === undefined ? () => () => setIsOpen(false) : () => startValues.onClose);
    const [otherProps, setOtherProps] = useState(startValues.otherProps);
    const [title, setTitle] = useState(startValues.title);

    // If useState gets a function, it assumes it's a lazy initializer. The function will return
    // the value when it's needed. Except, our value is itself a function. Hence, we need a function,
    // that returns a function, that returns a function.
    // Anyone calling setOnClose doesn't have to worry about this, and can just pass in
    // a function as normal.
    const setOnClose = useCallback((func) => setOnCloseCallback(() => func), []);

    return {children, isOpen, onClose, otherProps, setChildren, setIsOpen, setOnClose, setOtherProps, setTitle, title};
}