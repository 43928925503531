/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Bar } from "react-chartjs-2";
import { AssetClassificationStats, ClassificationLevel, ClassificationStats } from "Api";
import React from "react";
import { useReactiveVar } from "@apollo/client";
import { appConfigurationsVar } from "../GlobalState";
import { classificationOptionsAsText } from "../Api/ApiSerialization";
// TODO: Move this to a common shim once we make a second chart.
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { UserContext } from "../Utils/UserContext";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

/**
 * Unmarked classifications come across as "null" level.
 */
const NOT_MARKED_CLASSIFICATION = null;

export const ClassificationChart = ({ stats }: { stats: ClassificationStats }): JSX.Element => {
  const appConfigurations = useReactiveVar(appConfigurationsVar);
  const isSensitiveSecurityContext = appConfigurations.isSensitiveSecurityContext;
  const userContext = React.useContext(UserContext);
  const classificationLevels = userContext.clearanceInfo.getAllClearanceLevels();

  const labels = ["Not Marked"].concat(
    classificationLevels.map((level) => classificationOptionsAsText(level, isSensitiveSecurityContext))
  );

  const totalStats = stats.total;
  const totalCounts = getClassificationData(totalStats, classificationLevels);

  const modelClassificationCounts = getClassificationData(stats.models, classificationLevels);

  const platformClassificationCounts = getClassificationData(stats.platforms, classificationLevels);

  const scenarioClassificationCounts = getClassificationData(stats.scenarios, classificationLevels);

  const studyClassificationCounts = getClassificationData(stats.studies, classificationLevels);

  const countToStepSize = (count: number): number => {
    if (count <= 10) {
      return 1;
    } else if (count <= 50) {
      return 5;
    } else if (count <= 100) {
      return 10;
    } else if (count <= 200) {
      return 20;
    } else {
      return 50;
    }
  };

  const stepSize = React.useMemo(() => {
    const maxCount = Math.max(...totalCounts);
    return countToStepSize(maxCount);
  }, [totalCounts]);

  const chartOptions = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: `Assets by Classification (Total: ${totalStats.total})`,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          stepSize: stepSize,
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  const chartData = {
    labels,
    //TODO: possibly need to refactor into the bar graph options and implement some type of loop
    //if there are to be more data types other than Model and Scenario.
    datasets: [
      {
        label: "Models",
        data: modelClassificationCounts,
        backgroundColor: "#1900d4", // Dark blue
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                "Model count: " + modelClassificationCounts[context.dataIndex],
                "Total Asset Count: " + totalCounts[context.dataIndex],
              ];
            },
          },
        },
      },
      {
        label: "Platforms",
        data: platformClassificationCounts,
        backgroundColor: "#FFA833", // Orange
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                "Platform count: " + platformClassificationCounts[context.dataIndex],
                "Total Asset Count: " + totalCounts[context.dataIndex],
              ];
            },
          },
        },
      },
      {
        label: "Scenarios",
        data: scenarioClassificationCounts,
        backgroundColor: "#00d483", // Light green
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                "Scenario count: " + scenarioClassificationCounts[context.dataIndex],
                "Total Asset Count: " + totalCounts[context.dataIndex],
              ];
            },
          },
        },
      },
      {
        label: "Studies",
        data: studyClassificationCounts,
        backgroundColor: "#FF3E99", // Magenta
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                "Study count: " + studyClassificationCounts[context.dataIndex],
                "Total Asset Count: " + totalCounts[context.dataIndex],
              ];
            },
          },
        },
      },
    ],
  };

  return <Bar options={chartOptions} data={chartData} />;
};

function getClassificationData(
  stats: AssetClassificationStats,
  classificationOptions: ClassificationLevel[]
): number[] {
  const assetClassificationsToCount = new Map(stats.classification.map(({ count, level }) => [level, count]));

  return [NOT_MARKED_CLASSIFICATION].concat(classificationOptions).map((level) => {
    const count = assetClassificationsToCount.get(level);
    return count ? count : 0;
  });
}
