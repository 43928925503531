/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import * as XLSX from "xlsx";
import { FileUploader } from "baseui/file-uploader";
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { PickListSchema, PickListValueOption } from "Api";
import { newPickListSchema } from "./PickListSchemaEditor";
import { notify } from "../Shared/Notify";
import { EndAnchoredRow } from "../DesignSystem/Containers";
import { InlineLink } from "../DesignSystem/Links";
import { Button } from "baseui/button";
import { ArrowDown } from "baseui/icon";
import { SiteContext } from "../Utils/SiteProps";

const PICK_LIST_TEMPLATE_RELATIVE_URL = "downloadPickListTemplate";

export const ModelFieldImporterModal = ({
  isOpen,
  onClose,
  onUpload,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (pickListSchema: PickListSchema) => void;
}): JSX.Element => {
  const siteProps = React.useContext(SiteContext);

  const pickListTemplateAbsoluteUrl = React.useMemo(() => {
    return `${siteProps.topUrl}/${PICK_LIST_TEMPLATE_RELATIVE_URL}`;
  }, [siteProps.topUrl]);

  const handleUploadComplete = (pickListSchema: PickListSchema): void => {
    onUpload(pickListSchema);
    onClose();
  };

  const startTemplateDownload = React.useCallback(() => {
    window.location.href = pickListTemplateAbsoluteUrl;
  }, [pickListTemplateAbsoluteUrl]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // The unstable_ModalBackdropScroll will be removed in the next major baseui version.
      // It was recommended to set it to true to prepare for its removal.
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>Add options from file</ModalHeader>
      <ModalBody>
        <div>
          {"To add multiple options to the form field, "}
          <InlineLink href={pickListTemplateAbsoluteUrl} linkText="download the template" />
          {" provided, edit the options, save, and then upload the file."}
        </div>
        <br />
        <ModelFieldFileImporter onUpload={handleUploadComplete} />
      </ModalBody>
      <ModalFooter>
        <EndAnchoredRow>
          <Button kind={"tertiary"} startEnhancer={<ArrowDown size={24} />} onClick={startTemplateDownload}>
            {"Download template"}
          </Button>
          <ModalButton kind="tertiary" onClick={onClose}>
            Cancel
          </ModalButton>
        </EndAnchoredRow>
      </ModalFooter>
    </Modal>
  );
};

export const ModelFieldFileImporter = ({
  onUpload,
}: {
  onUpload: (pickListSchema: PickListSchema) => void;
}): JSX.Element => {
  const [isUploading, setIsUploading] = React.useState(false);
  function reset(): void {
    setIsUploading(false);
    onUpload(newPickListSchema());
  }

  return (
    <>
      <FileUploader
        multiple={false}
        onCancel={reset}
        accept={".xlsx"}
        onDrop={async (acceptedFiles, rejectedFiles) => {
          try {
            setIsUploading(true);

            processRejectedFiles(rejectedFiles);
            const valueOptions = await processAcceptedFiles(acceptedFiles);

            onUpload(newPickListSchema(valueOptions));
            notify.positive("Options successfully imported.");
          } catch (error) {
            notify.negative(`Error: ${error}`);
          } finally {
            setIsUploading(false);
          }
        }}
        progressMessage={isUploading ? `Uploading... hang tight.` : ""}
      />
    </>
  );
};

const processRejectedFiles = (rejectedFiles: File[]): void => {
  if (rejectedFiles.length === 0) return;
  if (!rejectedFiles[0].name.endsWith(".xlsx")) throw `File must be of type ".xlsx".`;
  throw "An unexpected error occured.";
};

const verifyFileTemplate = (workbook: XLSX.WorkBook): void => {
  if (
    workbook.Sheets[workbook.SheetNames[0]]["A1"]["h"] !== "Name" ||
    workbook.Sheets[workbook.SheetNames[0]]["B1"]["h"] !== "Description"
  )
    throw "Incorrect data format. Download and use the provided excel template.";
};

const processAcceptedFiles = async (acceptedFiles: File[]): Promise<PickListValueOption[]> => {
  if (acceptedFiles.length === 0) return;
  if (acceptedFiles.length > 1) throw "Only one file can be imported.";

  const excelRawData = new Uint8Array(await acceptedFiles[0].arrayBuffer());
  const workbook = XLSX.read(excelRawData, { type: "array" });

  verifyFileTemplate(workbook);

  const parsedOptions = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]) as any[];

  if (parsedOptions.length === 0) {
    throw "No data was parsed from file.";
  }

  return parsedOptions.map((option) => {
    return {
      value: option.Name,
      description: option.Description,
    } as PickListValueOption;
  });
};
