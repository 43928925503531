import { TableOverrides } from "baseui/table-semantic";

/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing of this
 * source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export const noPaddingStyle = {
  paddingTop: "0",
  paddingRight: "0",
  paddingBottom: "0",
  paddingLeft: "0",
};

export function SelectedTableRowStyle(isSelected: (rowIndex: number) => boolean): TableOverrides {
  return {
    Table: {
      style: () => ({
        boxSizing: "content-box",
      }),
    },
    TableBodyRow: {
      style: ({ $theme, $rowIndex }: any) => ({
        backgroundColor: isSelected($rowIndex)
          ? $theme.colors.selectionBackgroundPrimary
          : $theme.colors.backgroundPrimary,
        ":hover": {
          backgroundColor: isSelected($rowIndex)
            ? $theme.colors.selectionBackgroundPrimaryDark
            : $theme.colors.backgroundTertiary,
        },
      }),
    },
    TableBodyCell: {
      style: () => ({
        // Baseweb adds padding automatically for aesthetics to the table data;
        // we need to remove it to make sure we're able to receive click events
        // for everything that appears to the user to be inside the table cell.
        ...noPaddingStyle,
      }),
    },
  };
}
