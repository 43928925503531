# import "./fragments/fileFoldersData.gql"
# import "./fragments/securityMarkingsData.gql"
# import "./fragments/relatedModelData.gql"

mutation UpdatePlatform($platform: PlatformInput!) {
  updatePlatform(platform: $platform) {
    id
    creationDate
    lastModifiedDate
    metadata {
      title
      description
      version
      subject
      keywords
      notes
      customProps
      imageId
    }
    relatedModels {
      __typename
      ...relatedModelData
    }
    securityMarkings {
      __typename
      ...securityMarkingsData
    }
    fileFolders {
      __typename
      ...fileFoldersData
    }
  }
}
