/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";

import { PendingWorkSection } from "./PendingWorkSection";
import { SearchSpecInput } from "Api";

const assetsWithoutSecurityMarkingsFilter: SearchSpecInput = {
  classificationFilter: { classificationLevels: [], includeUnmarked: true },
};

export const AssetsMissingSecurityMarkings = (): JSX.Element => {
  return <PendingWorkSection filter={assetsWithoutSecurityMarkingsFilter} />;
};
