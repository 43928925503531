/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Button } from "baseui/button";

import { notify } from "../Shared/Notify";

export const ToastExample = (): JSX.Element => {
  return (
    <React.Fragment>
      <Button
        onClick={() => notify.info("This is an informational message for your awareness; action is not required.")}
      >
        Info Toast
      </Button>
      <Button onClick={() => notify.positive("You've added a new model file.")}>Positive Toast</Button>
      <Button onClick={() => notify.negative("Something went wrong. Please contact helpdesk for additional support.")}>
        Negative Toast
      </Button>
      <Button
        onClick={() => notify.serverError("Something went wrong on the server!!!!!!!!", "this is different text")}
      >
        server error Toast
      </Button>
      <Button
        onClick={() =>
          notify.warning("Your file upload succeeded, but encountered some problems. Please review the logs.")
        }
      >
        Warning Toast
      </Button>
    </React.Fragment>
  );
};
