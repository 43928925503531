/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

/**
 * This class heavily inspired by the axios-hooks library:
 *
 * NPM:
 * https://www.npmjs.com/package/axios-hooks
 *
 * Github:
 * https://github.com/simoneb/axios-hooks
 */
import axios, { AxiosRequestConfig } from "axios";
import { ConfigureOptions, makeUseAxios, Options, RefetchOptions, UseAxiosResult } from "axios-hooks";
import React from "react";

export function useHttpGet<TResponse = any, TBody = any, TError = any>(
  axiosConfig: ConfigureOptions,
  url: string,
  requestConfig?: AxiosRequestConfig<TBody>
): UseAxiosResult<TResponse, TBody, TError> {
  const axiosHook = makeUseAxios(axiosConfig);

  const hookRequestConfig: AxiosRequestConfig<TBody> = requestConfig
    ? {
        ...requestConfig,
        url,
      }
    : { url };
  return axiosHook<TResponse, TBody, TError>(hookRequestConfig);
}

export interface ExecutePost<TBody> {
  (config?: AxiosRequestConfig<TBody> | string, options?: RefetchOptions): void;
}

export interface PostProgress {
  progress?: any;
}

export type UseAxiosPost<TBody = any> = [PostProgress, ExecutePost<TBody>, () => void];

export interface HttpPostConfig<TResponse> {
  onComplete?: (data: TResponse) => void;
  onError?: (reason: any) => void;
}

const postRequestOptions: Options = {
  manual: true,
  autoCancel: false,
};

export function useHttpPost<TResponse = any, TBody = any>(
  axiosConfig: ConfigureOptions,
  url: string,
  postConfig?: HttpPostConfig<TResponse>
): UseAxiosPost<TBody> {
  const [progress, setProgress] = React.useState(undefined);

  const axiosHook = makeUseAxios(axiosConfig);

  const requestConfig: AxiosRequestConfig<TBody> = {
    url,
    method: "POST",
    onUploadProgress: (event) => setProgress(event),
  };

  const [_response, executePost, cancelRequest] = axiosHook(requestConfig, postRequestOptions);

  const executePostWithCallback = React.useCallback(
    (config?: AxiosRequestConfig<TBody> | string, options?: RefetchOptions) => {
      const postPromise = executePost(config, options);
      postPromise
        .then((response) => {
          if (postConfig?.onComplete) {
            setProgress(undefined);
            postConfig.onComplete(response.data);
          }
        })
        .catch((reason) => {
          if (!axios.isCancel(reason)) {
            postConfig?.onError?.(reason);
          }
        });
      // We may need to put a catch on this promise, since errors are reported a different way.
    },
    [executePost, postConfig]
  );

  return [{ progress }, executePostWithCallback, cancelRequest];
}
