const isObject = (x) => typeof x === 'object' && x !== null;

/**
 * Simplified object comparison function. Not intended for edge cases of comprehensive equality comparison.
 * Note: Will probably be replaced with something more robust/performant later.
 * @param {object} object1
 * @param {object} object2
 * @param {boolean|((a:any,b:object)=>number)} [arraySort=false] Optional. An option to configure whether any arrays should be pre-sorted. If true, sorts all arrays by the default comparer; if false, does not sort arrays (thereby making the order of the arrays part of the equality comparison); if a function, sorts the arrays by the function
 * @returns Whether the objects are equal
 */
 export const deepEquals = (object1, object2, arraySort = false) => {
  if (object1 === object2) return true;
  if (!isObject(object1) || !isObject(object2)) return false;

  let object1Current = object1;
  let object2Current = object2;

  // If objects are arrays, then optionally sort the arrays before comparing the values so that the order of the elements becomes arbitrary
  if (arraySort && Array.isArray(object1) && Array.isArray(object2)) {
    const compareFn = arraySort === true ? undefined : arraySort;
    object1Current = [...object1].sort(compareFn);
    object2Current = [...object2].sort(compareFn);
  }

  const keys1 = Object.keys(object1Current);
  const keys2 = Object.keys(object2Current);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1Current[key];
    const val2 = object2Current[key];
    if (!deepEquals(val1, val2, arraySort)) return false;
  }
  return true;
};