# import "./fragments/securityMarkingsData.gql"

query GetRecentlyModifiedAssetsInMyTopics($maxAssetsPerTopic: Int) {
  getRecentlyModifiedAssetsInMyTopics(maxAssetsPerTopic: $maxAssetsPerTopic) {
    topics {
      topicName
      assets {
        id
        type
        title
        description
        version
        creationDate
        lastModifiedDate
        securityMarkings {
          __typename
          ...securityMarkingsData
        }
      }
    }
  }
}
