import { useCallback, useEffect, useRef } from "react";

/**
 * React hook for a declarative implementation of the JavaScript `setTimeout` function
 * @param {function():void} callback A callback to execute whenever the timeout completes
 * @param {number|null} delay The timeout delay in milliseconds, or `null` to disable the timeout
 * @param {bool} [autoStart=true] Whether to start the timeout whenever the hook first runs and restart whenever the timeout delay changes
 */
export const useTimeout = (callback, delay, autoStart = true) => {
  const savedCallback = useRef();
  const timeoutRef = useRef();

  // Clears the current timeout
  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Starts a new timeout
  const start = useCallback(() => {
    if (delay !== null && !timeoutRef.current ) {
      const tick = () => {
        savedCallback.current?.();
      };
      timeoutRef.current = setTimeout(tick, delay);
    }
  }, [delay]);

  // Clears the current timeout and restarts the timeout
  const restart = useCallback(() => {
    clear();
    start();
  }, [clear, start]);

  // Update the ref to the provided callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Whenever the delay is changed, we want to restart the timeout
  useEffect(() => autoStart && restart(), [restart, autoStart]);

  // When the hook unmounts, call `clear` to clear the timeout and unset the ref
  useEffect(() => clear, [clear]);

  return {clear, start, restart};
};